<template>
<div class="min-h-80vh">
    <navigation-site :navThemeTransparent="false"></navigation-site>
    <div class="container space-nav-foot">

        <!-- breadcrumb -->
        <div class="mt-5">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mt-3">
                    <li class="h6 breadcrumb-item"><router-link to='/account'>Mon compte</router-link></li>
                    <li class="h6 breadcrumb-item active" aria-current="page">Profil</li>
                </ol>
            </nav>
        </div>

        <div class="row justify-content-center mt-3 appearContentFromBot2">
            <div class="col-md-8 mt-5">

                <h1 class="h2 text-center">Modifier vos informations</h1>

                    <div class="row g-2 mt-3">

                        <h2 class="col-12 h5 mt-5">Profil</h2>
                        <div class="col-12 d-flex">
                            <div class="form-check">
                                <input class="form-check-input" v-model="type" type="radio" value="individual" id="type_individual">
                                <label class="form-check-label" for="type_individual">
                                    Particulier
                                </label>
                            </div>
                            <div class="form-check ms-3">
                                <input class="form-check-input" v-model="type" type="radio" value="company" id="type_company" checked>
                                <label class="form-check-label" for="type_company">
                                    Entreprise
                                </label>
                            </div>
                        </div>
                       
                        <div class="col-md-6">
                            <input type="text" v-model="firstname" placeholder="Prénom" class="form-control" :class="errorsInfoCls.indexOf('firstname') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-6">
                            <input type="text" v-model="lastname" placeholder="Nom" class="form-control" :class="errorsInfoCls.indexOf('lastname') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="compagny" placeholder="Nom commercial" class="form-control">
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="compagnyType" placeholder="Forme juridique" class="form-control">
                        </div>
                    </div>
                    <!-- adresse (shipping) -->
                    <div class="row g-2">

                        <h2 class="col-12 h5 mt-5">Addresse de livraison</h2>
                        <div class="col-md-6">
                            <input type="text" v-model="shipping.firstname" placeholder="Prénom" class="form-control" :class="errorsCls.indexOf('firstname') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-6">
                            <input type="text" v-model="shipping.lastname" placeholder="Nom" :class="errorsCls.indexOf('lastname') != -1 ? 'b-error' : ''" class="form-control" required>
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="shipping.compagny" placeholder="Nom entreprise" class="form-control">
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="shipping.street1" placeholder="Rue" class="form-control" :class="errorsCls.indexOf('street1') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="shipping.street2" placeholder="Complément d'adresse" class="form-control">
                        </div>
                        <div class="col-md-8">
                            <input type="text" v-model="shipping.city" placeholder="Ville" class="form-control" :class="errorsCls.indexOf('city') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-4">
                            <input type="number" v-model="shipping.zipcode" placeholder="Code postal" class="form-control" :class="errorsCls.indexOf('zipcode') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-12">
                            <countries class="form-control" :defaultCountry="shipping.country" @event-update-country="updateCountryShipping" required></countries>
                        </div>
                        <div class="col-md-12">
                            <input type="number" v-model="shipping.phone" placeholder="Téléphone" class="form-control" :class="errorsCls.indexOf('phone') != -1 ? 'b-error' : ''" required>
                        </div>
                    </div>

                    <div class="mt-3 ms-1 form-check">
                        <input type="checkbox" v-model="addressIdem" class="form-check-input" id="addressIdem">
                        <label for="addressIdem" class="form-check-label t1">Utiliser la même adresse pour la facturation</label>
                    </div>
                    <!-- Adresse facturation -->
                    <div v-if="!addressIdem" class="row g-2">
                        <h2 class="col-12 h5 mt-5">Addresse de facturation</h2>
                        <div class="col-md-12">
                            <input type="text" v-model="billing.firstname" placeholder="Prénom" class="form-control" :class="errorsCls.indexOf('firstname') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="billing.lastname" placeholder="Nom" class="form-control" :class="errorsCls.indexOf('lastname') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="billing.compagny" placeholder="Nom entreprise" class="form-control">
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="billing.street1" placeholder="Rue" :class="errorsCls.indexOf('street1') != -1 ? 'b-error' : ''" class="form-control" required>
                        </div>
                        <div class="col-md-12">
                            <input type="text" v-model="billing.street2" placeholder="Complément d'adresse" class="form-control">
                        </div>
                        <div class="col-md-8">
                            <input type="text" v-model="billing.city" placeholder="Ville" class="form-control" :class="errorsCls.indexOf('city') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-4">
                            <input type="number" v-model="billing.zipcode" placeholder="Code postal" class="form-control" :class="errorsCls.indexOf('zipcode') != -1 ? 'b-error' : ''" required>
                        </div>
                        <div class="col-md-12">
                            <countries class="form-control" :defaultCountry="billing.country" @event-update-country="updateCountryBilling" required></countries>
                        </div>
                        <div class="col-md-12">
                            <input type="number" v-model="billing.phone" placeholder="Téléphone" class="form-control" :class="errorsCls.indexOf('phone') != -1 ? 'b-error' : ''" required>
                        </div>
                    </div>  


                    <div class="mt-3 ms-1 form-check">
                        <input type="checkbox" v-model="newsletter" class="form-check-input" id="newsletter">
                        <label for="newsletter" class="form-check-label t1">Inscription à la newsletter</label>
                    </div>

                    <!-- errors -->
                    <div class="text--error">
                        <span v-for="(errorInfo, index) in errorsInfo" :key="index" class="t1 mb-0 text--error d-block">{{errorInfo}}</span>
                        <span v-for="(error, index) in errors" :key="index" class="t1 mb-0 text--error d-block">{{error}}</span>
                    </div>

                    <div class="mt-4">
                        <button class="btn-u3dm btn-u3dm--primary w-100" @click="send">Enregistrer</button>
                    </div>
      
            </div>
        </div>


            

    </div>
    <footer-site></footer-site>
</div>
</template>

<script>
import Countries from '../Countries.vue';
import {zipcode_fr} from "../../zipcode_fr.json";
import NavigationSite from '../ui/NavigationSite.vue';
import FooterSite from '../ui/FooterSite.vue';

export default {
  components: { Countries, NavigationSite, FooterSite },
    name: "UserProfil",
    data() {
        return {
            ok: "ok",
            firstname: null,
            lastname: null,
            compagny: null,
            compagnyType: null,

            type: 'individual',

            shipping:  {
                firstname: null,
                lastname: null,
                compagny: null,
                street1: null,
                street2: null,
                city: null,
                zipcode: null,
                country: null,
                phone: null
            },

            billing: {
                firstname: null,
                lastname: null,
                compagny: null,
                street1: null,
                street2: null,
                city: null,
                zipcode: null,
                country: null,
                phone: null,
            },

            newsletter: true,
            addressIdem: true,

            // error: null,

            errors: [],
            errorsCls: [],

            errorsInfo: [],
            errorsInfoCls: [],

            // errorsShipping: [],
            // errorsBilling: [],

            
        }
    },
    beforeMount() {
        this.getProfile()
    },
    methods: {
        updateCountryShipping(payload) {
            this.shipping.country = payload
        },
        updateCountryBilling(payload) {
            this.billing.country = payload
        },
      getProfile() {
        this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/get-profile', {credentials: true})
            .then((response) => {
                  this.firstname = response.body[0].firstname
                  this.lastname = response.body[0].lastname
                  this.compagny = response.body[0].company
                  this.compagnyType = response.body[0].company_type
                  this.type = response.body[0].type

                  this.shipping = {
                    firstname: response.body[0].default_shipping_address.firstname,
                    lastname: response.body[0].default_shipping_address.lastname,
                    compagny: response.body[0].default_shipping_address.company,
                    street1: response.body[0].default_shipping_address.street1,
                    street2: response.body[0].default_shipping_address.street2,
                    city: response.body[0].default_shipping_address.city,
                    zipcode: response.body[0].default_shipping_address.zipcode,
                    country: response.body[0].default_shipping_address.country,
                    phone: response.body[0].default_shipping_address.telephone
                  }

                  this.billing = {
                    firstname: response.body[0].default_billing_address.firstname,
                    lastname: response.body[0].default_billing_address.lastname,
                    compagny: response.body[0].default_billing_address.company,
                    street1: response.body[0].default_billing_address.street1,
                    street2: response.body[0].default_billing_address.street2,
                    city: response.body[0].default_billing_address.city,
                    zipcode: response.body[0].default_billing_address.zipcode,
                    country: response.body[0].default_billing_address.country,
                    phone: response.body[0].default_billing_address.telephone
                  }

                  this.newsletter = response.body[0].newsletter
                  this.addressIdem = response.body[0].sameaddress == 1 ? true : false


                },
                () => {
                  // console.log(error);
                }
            );
        // console.log('get')
      },
      send() {

        if (this.addressIdem) {
          this.checkInfo()
          this.checkFormAdress(this.shipping)

        } else {
          this.checkInfo()
          this.checkFormAdress(this.shipping)
          this.checkFormAdress(this.billing)
        }

        if (this.errors.length < 1 && this.errorsInfo < 1) {


          // console.log('clique sur envoyer')
          let formData = new FormData
          formData.append('firstname', this.firstname)
          formData.append('lastname', this.lastname)
          formData.append('type', this.type)
          formData.append('company', this.compagny && this.compagny !== undefined ? this.compagny : '')
          formData.append('companyType', this.compagnyType && this.compagnyType !== undefined ? this.compagnyType : '')

          formData.append('defaultShippingAddress[firstname]', this.shipping.firstname)
          formData.append('defaultShippingAddress[lastname]', this.shipping.lastname)
          formData.append('defaultShippingAddress[company]', this.shipping.compagny)
          formData.append('defaultShippingAddress[street1]', this.shipping.street1)
          formData.append('defaultShippingAddress[street2]', this.shipping.street2 && this.shipping.street2 !== undefined ? this.shipping.street2 : '')
          formData.append('defaultShippingAddress[zipcode]', this.shipping.zipcode)
          formData.append('defaultShippingAddress[city]', this.shipping.city)
          formData.append('defaultShippingAddress[country]', this.shipping.country)
          formData.append('defaultShippingAddress[telephone]', this.shipping.phone)

          formData.append('defaultBillingAddress[firstname]', this.billing.firstname)
          formData.append('defaultBillingAddress[lastname]', this.billing.lastname)
          formData.append('defaultBillingAddress[company]', this.billing.compagny)
          formData.append('defaultBillingAddress[street1]', this.billing.street1)
          formData.append('defaultBillingAddress[street2]', this.shipping.street2 && this.billing.street2 !== undefined ? this.billing.street2 : '')
          formData.append('defaultBillingAddress[zipcode]', this.billing.zipcode)
          formData.append('defaultBillingAddress[city]', this.billing.city)
          formData.append('defaultBillingAddress[country]', this.billing.country)
          formData.append('defaultBillingAddress[telephone]', this.billing.phone)

          // newsletter (0 ou 1)
          // sameAddress (0 ou 1)
          formData.append('newsletter', this.newsletter == true ? 1 : 0)
          formData.append('sameAddress', this.addressIdem == true ? 1 : 0)

          this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/profile', formData, {credentials: true})
              .then(() => {
                    // console.log(response);
                    window.location.reload()
                  },
                  () => {
                    // console.log(error);
                  }
              );
        }
      },
        checkInfo() {
            this.errorsInfo = []
            this.errorsInfoCls = []

            if (!this.firstname) {
                this.errorsInfo.push('Prénom manquant');
                this.errorsInfoCls.push('firstname')
            }
            if (!this.lastname) {
                this.errorsInfo.push('Nom manquant');
                this.errorsInfoCls.push('lastname')
            }
        },
        checkFormAdress(obj) {
            
            this.errors = []
            this.errorsCls = []

            if (!obj.firstname) {
                this.errors.push('Prénom manquant');
                this.errorsCls.push('firstname')
            }
            if (!obj.lastname) {
                this.errors.push('Nom manquant');
                this.errorsCls.push('lastname')
            }
            if (!obj.street1) {
                this.errors.push('Adresse manquante');
                this.errorsCls.push('adress')
            }
            if (!obj.city) {
                this.errors.push('Ville manquante');
                this.errorsCls.push('city')
            }
            if (!obj.zipcode) {
                this.errors.push('Code de postal manquant');
                this.errorsCls.push('zipcode')
            } else if (RegExp(/^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/).test(obj.zipcode) == false) {
                this.errors.push('Code postal invalide');
                this.errorsCls.push('zipcode')
            } else if (zipcode_fr.find(zipcode => zipcode == this.zipcode)) {
                this.errors.push('Code postal invalide');
                this.errorsCls.push('zipcode')
            }

            if (!obj.phone) {
                this.errors.push('Numéro de téléphone manquant');
                this.errorsCls.push('phone')
            } 
            else if (RegExp(/^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/).test(obj.phone) == false) {
                this.errors.push('Numéro de téléphone invalide');
                this.errorsCls.push('phone')
            }

            // if (this.errors.length > 0) {
            //     this.$refs.type.scrollIntoView({behavior: "smooth"})
            // }


  
            return this.errors.length >= 1 ? false : true

            // return true
        },
    }
}
</script>