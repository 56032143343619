<template>
  <div class="project">

    <div class="project-wrapp project-wrapp--lg">

      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">Choisissez votre type de projet</h2>

      <!-- Content -- Cards -->
      <div class="project-content--flex">

        <div class="ask-card project-appearBigCard project-appearBigCard__item--1"
        :class="{'ask-card--active' : currentSelected == 1}"
        @click="next(1)">
          <div class="ask-card__media">
            <component :is="'icon-print'" :width="40" :color="currentSelected == 1 ? '#FC3640' : ''"></component>
          </div>
           <h6 class="ask-card__text">Impression 3D</h6>
        </div>

        <div class="ask-card project-appearBigCard project-appearBigCard__item--2"
        :class="{'ask-card--active' : currentSelected == 3}"
        @click="next(3)">
          <div class="ask-card__media">
            <component :is="'icon-num'" :width="40" :color="currentSelected == 3 ? '#FC3640' : ''"></component>
          </div>
          <h6 class="ask-card__text">Modélisation / Numérisation 3D</h6>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import IconNum from '../icon/IconNum.vue';
import IconPrint from '../icon/IconPrint.vue';

export default {
  components: { IconNum, IconPrint },
  name: "ProjectType",
  data() {
    return {
      currentSelected: 1,

      gtagGroupEvent: 'project_form'
    }
  },
  computed: {
    ...mapState('projectDesign',{
      typeOnStore: state => state.projectDesign.projectType,
      wayCurrent: state => state.steps.wayCurrent
    }),
  },
  beforeMount() {
    if (this.typeOnStore == '' || this.typeOnStore == null)  {
      this.currentSelected = ''
    } else {
      this.currentSelected = this.typeOnStore
    }

    // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P2.TypeProjet.0_view')
        this.updateCurrentGoogleTag('P2.TypeProjet')
        //****

  },
  methods: {
    ...mapActions('projectDesign', [
      'updateProjectType', 'increment', 'adaptWay', 'updateCurrentGoogleTag'
    ]),
    next(id) {
      this.currentSelected = id
      this.updateProjectType(this.currentSelected)
      this.adaptWay({key: 'projectType', value: this.currentSelected })


       // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)
        let endTag = id == 1 ? '2_click_Impression3D' : '2_click_Modelisation3D'
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P2.TypeProjet.' + endTag)
        // this.updateCurrentGoogleTag('P2.TypeProjet')
        //****
     

      this.increment()

    },
  },
};
</script>