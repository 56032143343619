<template>
  <div class="min-h-80vh">
    <navigation-site :navThemeTransparent="false"></navigation-site>
    <div class="container space-nav-foot">

        <!-- breadcrumb -->
        <div class="mt-5">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mt-3">
                    <li class="h6 breadcrumb-item"><router-link to='/account'>Mon compte</router-link></li>
                    <li class="h6 breadcrumb-item active" aria-current="page">Relevé de compte</li>
                </ol>
            </nav>
        </div>

      <div class="row justify-content-center mt-3 appearContentFromBot2">
        <div class="col-md-6 col-xl-5 mt-5">

          <h1 class="h2 text-center">Relevé de compte</h1>

          <div v-if="comptaLines.length === 0" class="mb-5 mt-5">
            Aucun mouvement sur votre compte.
          </div>
          <div v-if="comptaLines.length > 0">
            <div class="row mb-5 mt-5">
              <div class="col-lg-12">
                <strong>
                  Votre solde : {{ comptaLines[0].balance / 100 }} €
                </strong>
              </div>
            </div>

            <table class="table">
              <thead>
              <tr>
                <th>Date</th>
                <th>Informations</th>
                <th>Débit</th>
                <th>Crédit</th>
                <th>Solde</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(line, key) in comptaLines" :key="key">
                <td class="align">{{ line.date_mvt }}</td>
                <td>
                  <a v-if="line.payment !== undefined" :href="urlApp + '/fr/order_sell/payment/' + line.payment.id + '/invoice_download'">{{ line.description }}</a>
                  <span v-if="line.payment === undefined">{{ line.description }}</span>
                </td>
                <td class="text-right"><span v-if="line.amount < 0">{{ line.amount * -1 /100 }}€</span></td>
                <td><span v-if="line.amount > 0">{{ line.amount / 100 }}€</span></td>
                <td>{{ line.balance / 100 }}€</td>
              </tr>
              </tbody>
            </table>

            <div class="row">
              <div class="col-lg-12">
                {{ ibanU3dm.label }} <br>
                RIB : {{ ibanU3dm.rib }} <br>
                IBAN : {{ ibanU3dm.iban }} <br>
                Pour tout virement précisez la référence de la facture dans le libellé du virement.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <footer-site></footer-site>
  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue';
import NavigationSite from '../components/ui/NavigationSite.vue';
import { Iban } from '../variables/variables'

export default {
  name: "AccountCustomerDetail",
  components: { NavigationSite, FooterSite },
  data() {
    return {
      comptaLines: [],
      ibanU3dm: Iban,
      urlApp: process.env.VUE_APP_APP_BASE_URL_WITHOUT_SLASH,
    }
  },
  methods: {
    send() {
    },
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/compta', {credentials: true})
        .then(({body}) => {
          this.comptaLines = body
            }
        )
  }
}
</script>