<template>
  <tr class="justify-content-between border-bot">
    <td>
      <h6 class="h6">{{ item.description }}</h6>
    </td>
    <td>
      <h6 class="h6">{{ item.quantity }}</h6>
    </td>
    <td>
      <h6 class="h6">{{ (item.price / 100) | formatPrice }} € HT</h6>
    </td>
    <td>
      <h6 class="h6">{{ ((item.quantity *item.price) / 100) | formatPrice }} € HT</h6>
    </td>
  </tr>
</template>

<script>
export default {
    name: "ProductOrderProjectItem",
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
      
    }
}
</script>