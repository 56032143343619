<template>
    <div class="confirmation appearPrintConfirmation">

        <!-- media -->
        <div class="confirmation-media mt-3 d-flex justify-content-center align-items-center">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27 54C19.7883 54 13.0079 51.1914 7.90826 46.0917C2.80859 40.9921 0 34.2117 0 27C0 19.7883 2.80859 13.0079 7.90826 7.90826C13.0079 2.80859 19.7883 0 27 0C34.2117 0 40.9921 2.80859 46.0917 7.90826C51.1914 13.0079 54 19.7883 54 27C54 34.2117 51.1914 40.9921 46.0917 46.0917C40.9921 51.1914 34.2117 54 27 54ZM27 3.91304C20.833 3.91304 15.0359 6.31467 10.6748 10.6748C6.31467 15.0359 3.91304 20.833 3.91304 27C3.91304 33.167 6.31467 38.9641 10.6748 43.3252C15.0359 47.6853 20.833 50.087 27 50.087C33.167 50.087 38.9641 47.6853 43.3252 43.3252C47.6853 38.9641 50.087 33.167 50.087 27C50.087 20.833 47.6853 15.0359 43.3252 10.6748C38.9641 6.31467 33.167 3.91304 27 3.91304Z" fill="#161616"/>
                <path d="M24.0298 37.5578C23.5289 37.5578 23.0456 37.3661 22.6817 37.0188L13.9752 28.7368C13.1926 27.9924 13.1613 26.7539 13.9057 25.9703C14.6502 25.1877 15.8887 25.1564 16.6723 25.9008L23.8811 32.7585L37.1942 17.1307C37.8946 16.308 39.1292 16.2092 39.9519 16.9106C40.7746 17.611 40.8734 18.8466 40.172 19.6683L25.5187 36.8701C25.1704 37.279 24.6686 37.5265 24.1315 37.5549C24.0982 37.5568 24.064 37.5578 24.0298 37.5578Z" fill="#161616"/>
            </svg>
        </div>

        <!-- title -->
        <h4 class="h4 mt-5 text-center">{{ filename }} est bien ajouté à votre panier</h4>
        <h4 class="h4 text-center" v-if="tokenUser != ''">Un email vous a été envoyé</h4>


        <div class="text-center mt-5">
            <h6 class="btn-u3dm btn-u3dm--primary" @click="goBasket">Poursuivre la commande</h6>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: "AccordConfirmation",
    data() {
        return {
            gtagGroupEvent: 'impression_form'
        }
    },
    computed: {
        ...mapState("print", {
            filename: (state) => state.printFile.filename
        }),
        ...mapState("printManager", {
            tokenUser: (state) => state.tokenUser
        }),
    },
    methods: {
        goBasket() {
            
            // console.log('Basket/' + this.tokenUser)
            if ((this.tokenUser == undefined || this.tokenUser == '') && this.$route.params.token === undefined ) {
                this.$router.push({ name: 'Basket' })
            } else {
              if(this.$route.params.token !== undefined) {
                this.$router.push('/basket/' + this.$route.params.token)
              }else {
                this.$router.push('/basket/' + this.tokenUser)
              }
            }

        // Google Tag Manager : push event impression form
            //******************************************** */
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I7.1.order_continue')
            //******************************************** */
        }
    }
}
</script>