<template>

  <div class="outer-dim">
    <div v-if="this.color.hexadecimal_code === 'multicouleurs1' || this.color.hexadecimal_code === 'multicouleurs2'" class="left-description-color">
      Merci de transmettre le fichier de couleur après la commande via la messagerie de votre compte.
    </div>
    <div class="dim">
      <div class="dim-form">
        <label for="" class="dim-form__label">X</label>
        <input
          type="number"
          v-model="displayX"
          @change="volumeFileChange('x')"
          class="dim-form__input"
          min="1"
        />
      </div>
      <div class="dim-form">
        <label for="" class="dim-form__label">Y</label>
        <input
          type="number"
          v-model="displayY"
          @change="volumeFileChange('y')"
          class="dim-form__input"
          min="1"
        />
      </div>
      <div class="dim-form">
        <label for="" class="dim-form__label">Z</label>
        <input
          type="number"
          v-model="displayZ"
          @change="volumeFileChange('z')"
          class="dim-form__input"
          min="1"
        />
      </div>
      <div class="dim-form">
        <select name="" id="" v-model="selectedUnity" @change="unityOnChange()">
          <option
            v-for="(unity, index) in optionsUnity"
            :key="index"
            :value="unity.value"
          >
            {{ unity.text }}
          </option>
        </select>
      </div>
    </div>
    <div  class="volume-display t1">
        Volume 
        <span v-if="volumeDisplay !== null">{{ volumeDisplay }} cm³</span>
        <span v-if="volumeDisplay == null">0 cm³</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PrintDimensions",
  data() {
    return {
      rounded: 2,
      coefDim: 1,
      sizeOriginX: null,
      sizeOriginY: null,
      sizeOriginZ: null,
      displayX: null,
      displayY: null,
      displayZ: null,
      selectedUnity: "cm",
      optionsUnity: [
        { text: "mm", value: "mm" },
        { text: "cm", value: "cm" },
        { text: "m", value: "m" },
        { text: "inch", value: "inch" },
      ],
      volumeOrigin: null,
      volumeDisplay: null,
      volume: 0,
      numberOfParts: 0,
      gtagGroupEvent: 'impression_form'
    };
  },
  computed: {
    ...mapState("print", {
      dimensions: (state) => state.printFile.dimensions,
      filename: (state) => state.printFile.filename,
      referentialItems: (state) => state.printFile.referentialItems,
      //materialItemsByTechnologieCategory: (state) => state.printFile.materialItemsByTechnologieCategory,
      application: (state) => state.printFile.application,
      technology: (state) => state.printFile.technology,
      material: (state) => state.printFile.material,
      materialItems: (state) => state.printFile.materialItems,
      color: (state) => state.printFile.color,
      layer: (state) => state.printFile.layer,
      materialNameSelected: (state) => state.printFile.material.name,
      printFile: (state) => state.printFile,
      followPrintId: (state) => state.followId,
    }),
  },
  watch: {
    filename: function(newFile) {
      if (newFile) {
        this.rounded = 2
        this.coefDim = 1
        this.sizeOriginX = null
        this.sizeOriginY = null
        this.sizeOriginZ = null
        this.displayX = null
        this.displayY = null
        this.displayZ = null
        this.selectedUnity = "mm"
        this.volumeOrigin = null
        this.volumeDisplay = null
        this.volume = 0
        this.numberOfParts = 0
        this.resetBystep1()
        this.openFile(this.$parent.viewer.scene)
      }
    },
  },
  methods: {
    ...mapActions("print", [
      "updateDimensionX",
      "updateDimensionY",
      "updateDimensionZ",
      "updateVolume",
      "updateVolumeDisplay",
      "updateReferentialItems",
      "updateFollowId",
      "updateApplication",
      "updateMaterial", 
      "updateColor", 
      "updateLayer",
      "updateMaterialItems",
      "updateTechnologieCategoryList",
      //"updateMaterialItemsByTechnologieCategory",
      "updateMaterialItems",
      "updateColorsList",
      "updateLayersList",
      "updateMaterialItems",
      "updateFinishing",
      "updateFinishingList",
      "updateTechnology"
    ]),
    ...mapActions("printManager", [
      "updateStepNextPossible", "updateLastChildActive", "updateLengthOfTabMaterials", "updateModalFileErrorVisibleIsOpen"
    ]),
    callAPiApplicationType() {

      let formData = new FormData();
      formData.append("volume", this.volumeDisplay);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);

      this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + "application/referential", formData).then((response) => {

            this.updateReferentialItems(response.body);

            if (this.application.id !== null) {

              if (this.application.id > 0) {

                  this.adaptInMaterialWay()
              }

              if (this.application.id == 0) {
            
                this.adaptIntechnologyWay()
              }

            }

        });
    },
    adaptInMaterialWay() {

        let element = this.referentialItems.filter(elt => {
          return elt.id == this.application.id
        })
        
        if (Object.keys(element[0]).indexOf('quote') == -1 || element[0].quote == false ) {
          element = true
        } else {
          element = false
        }
      
        if (element == true) {
          // request makers-material-layers
          let formData = new FormData();

          formData.append("application_id", this.application.id);
          formData.append("volume", this.volumeDisplay * 1000);
          formData.append("dimension[x]", this.dimensions.x);
          formData.append("dimension[y]", this.dimensions.y);
          formData.append("dimension[z]", this.dimensions.z);

          this.$http
            .post(
              process.env.VUE_APP_API_BASE_URL +
                "application/makers-materials-layers-colors",
              formData
            )
            .then((response) => {
   
              this.updateMaterialItems(response.body);
              this.updateLengthOfTabMaterials(response.body.length)
            
              if (this.material.id !== null) {
                
                let listIdMaterials = []
                this.materialItems.forEach(elt => {
                  listIdMaterials.push(elt.id)
                })

                // si l'id du material précédement selectionner et dans le tableau des material 
                if (listIdMaterials.indexOf(this.material.id) != -1) {
                  
                  let materialActu = this.materialItems.filter((elt) => {
                    return elt.id == this.material.id
                  }) 
               
                  this.resetOptions()
                  // ici material
                  this.updateMaterial(materialActu[0])
                  this.updateColorsList(this.material.colors)
                  this.updateLayersList(this.material.layers)
                  this.updateLastChildActive(3)

                } else {

                  this.resetByStep3()
                }

              }
          
            })

        } else {
          // reset by step 2
          this.resetByStep2()

        }
    },
    adaptIntechnologyWay() {

      let formData = new FormData();
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);

      this.$http.post(process.env.VUE_APP_API_BASE_URL + "application/technologies", formData)
        .then((response) => {
          if (Object.keys(response.body).length > 0) {
            this.updateTechnologieCategoryList(response.body)

            if (this.technology.id !== null) {
                let formData = new FormData();
                formData.append("technology_id", this.technology.id);
                formData.append("volume", this.volumeDisplay * 1000);
                formData.append("dimension[x]", this.dimensions.x);
                formData.append("dimension[y]", this.dimensions.y);
                formData.append("dimension[z]", this.dimensions.z);


                this.$http
                  .post(
                    process.env.VUE_APP_API_BASE_URL + "application/technology-materials-layers-colors",
                    formData
                  )
                  .then((response) => {

                    //this.updateMaterialItemsByTechnologieCategory(response.body)
                    this.updateMaterialItems(response.body)

                    if (this.material.id !== null) {
                      let listIdMaterialsByTecnology = []
                        //this.materialItemsByTechnologieCategory.forEach(elt => {
                          this.materialItems.forEach(elt => {
                          listIdMaterialsByTecnology.push(elt.id)
                        })

                        if (listIdMaterialsByTecnology.indexOf(this.material.id) != -1) {
                          //let materialActu = this.materialItemsByTechnologieCategory.filter((elt) => {
                          let materialActu = this.materialItems.filter((elt) => {
                            return elt.id == this.material.id
                          }) 
                          this.resetOptions()
                          this.updateMaterial(materialActu[0])
                          this.updateColorsList(this.material.colors)
                          this.updateLayersList(this.material.layers)
                          this.updateLastChildActive(3)
                      
                        } else {
                          this.resetByStep3()
                        }
                    }
                });

            } else {

              this.updateTechnology(response.body[0])
              
              let formData = new FormData();
              formData.append("technology_id", response.body[0].id);
              formData.append("volume", this.volumeDisplay * 1000);
              formData.append("dimension[x]", this.dimensions.x);
              formData.append("dimension[y]", this.dimensions.y);
              formData.append("dimension[z]", this.dimensions.z);

              this.$http
                .post(
                  process.env.VUE_APP_API_BASE_URL + "application/technology-materials-layers-colors",
                  formData
                )
                .then((response) => {

                  //this.updateMaterialItemsByTechnologieCategory(response.body)
                  this.updateMaterialItems(response.body)

              });

                if (this.material.id !== null) {
                  let listIdMaterialsByTecnology = []
                  //this.materialItemsByTechnologieCategory.forEach(elt => {
                  this.materialItems.forEach(elt => {
                  listIdMaterialsByTecnology.push(elt.id)
                })

                if (listIdMaterialsByTecnology.indexOf(this.material.id) != -1) {
                  this.resetOptions()
          
                  this.updateLastChildActive(3)
              
                } else {
                  
                  this.resetByStep3()
                }
              }
          
            }
             
          }
      });
    },
    callApiTechnologyById(id) {
      let formData = new FormData();
      formData.append("technology_id", id);
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);

      this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + "application/technology-materials-layers-colors",
          formData
        )
        .then((response) => {

          //this.updateMaterialItemsByTechnologieCategory(response.body)
          this.updateMaterialItems(response.body)

      });
    },
    resetBystep1() {
      this.updateApplication({id: null,name: null})
      this.updateReferentialItems([])

      this.updateTechnologieCategoryList([])
      //this.updateMaterialItemsByTechnologieCategory([])
      this.updateTechnology({id:null, name:null})

      this.updateMaterialItems([])
      this.updateMaterial({id: null,name:null,basePrice:null})

      this.resetOptions()

      this.updateStepNextPossible(2)
      this.updateLastChildActive(1)
    },
    resetByStep2() {
      this.updateApplication({id: null,name: null})

      this.updateTechnology({id: null,name: null})
      this.updateTechnologieCategoryList([])
      //this.updateMaterialItemsByTechnologieCategory([])

      this.updateMaterialItems([])
      this.updateMaterial({id: null,name:null,basePrice:null})

      this.resetOptions()

      this.updateStepNextPossible(2)
      this.updateLastChildActive(2)
    },
    resetByStep3() {
      this.updateTechnology({id: null,name: null})

      this.updateMaterial({id: null,name:null,basePrice:null})

      this.resetOptions()

      this.updateStepNextPossible(3)
      this.updateLastChildActive(3)
    },
    resetOptions() {
      this.updateColor({id:null,name:null,plusPrice:null,hexadecimalCode:null})
      this.updateColorsList([])
      this.updateLayer({id:null,height:null,plusPrice:null})
      this.updateLayersList([])
      this.updateFinishing({id: null,name: null,description: null,price: null})
      this.updateFinishingList([])
    },
    openFile(scene) {
      this.calculDimension(scene)

      if (this.volumeDisplay == 0) {
        this.openModalFileError(true)
      } else {
        // Follow print : Upload file, volume, dimensions and return followId
        let formDataFollow = new FormData()
        formDataFollow.append("file", this.printFile.file)
        formDataFollow.append("volume", this.volumeDisplay * 1000)
        formDataFollow.append("dimension[x]", this.dimensions.x)
        formDataFollow.append("dimension[y]", this.dimensions.y)
        formDataFollow.append("dimension[z]", this.dimensions.z)
        this.$http.post(process.env.VUE_APP_API_BASE_URL + "application/follow", formDataFollow).then((response) => {
          this.updateFollowId(response.body)
          let formData = new FormData();
          formData.append("volume", this.volumeDisplay);
          formData.append("dimension[x]", this.dimensions.x);
          formData.append("dimension[y]", this.dimensions.y);
          formData.append("dimension[z]", this.dimensions.z);

          this.$http
              .post(
                  process.env.VUE_APP_API_BASE_URL + "application/referential", formData).then((response) => {
            this.updateReferentialItems(response.body);
          });
          this.updateLastChildActive(2)
        });
      }
    },
    openModalFileError() {
      this.updateModalFileErrorVisibleIsOpen(true)
    },
    volumeFileChange(axeChange) {
      this.recalculateDimension(axeChange)
      this.callAPiApplicationType()
    },
    calculDimension(scene) {
      let aabb = scene.aabb;

      this.volume = 0;
      this.numberOfParts = 0;

      scene.forEachChild( (mesh) => {
        this.volume += this.computeVolume(mesh);
        this.numberOfParts += 1;
      });

      this.volume = this.precisionRound(this.volume,this.rounded);
      this.volumeDisplay = Math.ceil(this.volume * this.getIndice());

      this.sizeOriginX = this.precisionRound((aabb.maxX - aabb.minX) * this.coefDim,this.rounded);
      this.sizeOriginY = this.precisionRound((aabb.maxY - aabb.minY) * this.coefDim,this.rounded);
      this.sizeOriginZ = this.precisionRound((aabb.maxZ - aabb.minZ) * this.coefDim,this.rounded );

      this.displayX = this.sizeOriginX;
      this.displayY = this.sizeOriginY;
      this.displayZ = this.sizeOriginZ;

      this.volumeOrigin = this.volume

      this.updateDimensionX(this.sizeOriginX);
      this.updateDimensionY(this.sizeOriginY);
      this.updateDimensionZ(this.sizeOriginZ);
      this.updateVolume(this.volume)
      this.updateVolumeDisplay(this.volumeDisplay)

    },
    getCoefDimension() {
        let coef = 1;
      if (this.selectedUnity == "mm") {
        coef = 1;
      } else if (this.selectedUnity == "cm") {
        coef = 10;
      } else if (this.selectedUnity == "m") {
        coef = 1000;
      } else if (this.selectedUnity == "inch") {
        coef = 25.4;
      }

      return coef
    },
    recalculateDimension(axeChange) {
      //Coef by unity
      let coef = this.getCoefDimension()

      if (axeChange == "x") {
        this.coefDim = this.displayX / this.sizeOriginX;
        this.updateDimensionX(this.displayX * coef);
        this.updateDimensionY( this.precisionRound(this.sizeOriginY * this.coefDim * coef,this.rounded));
        this.displayY = this.precisionRound(this.sizeOriginY * this.coefDim, this.rounded);
        this.updateDimensionZ(this.precisionRound(this.sizeOriginZ * this.coefDim * coef,this.rounded));
        this.displayZ = this.precisionRound(this.sizeOriginZ * this.coefDim,this.rounded);
      } else if (axeChange == "y") {
        this.coefDim = this.displayY / this.sizeOriginY;
        this.updateDimensionY(this.displayY * coef);
        this.updateDimensionX(this.precisionRound(this.sizeOriginX * this.coefDim * coef,this.rounded));
        this.displayX = this.precisionRound(this.sizeOriginX * this.coefDim,this.rounded);
        this.updateDimensionZ(this.precisionRound(this.sizeOriginZ * this.coefDim * coef,this.rounded));
        this.displayZ = this.precisionRound(this.sizeOriginZ * this.coefDim,this.rounded);
      } else if (axeChange == "z") {
        this.coefDim = this.displayZ / this.sizeOriginZ;
        this.updateDimensionZ(this.displayZ * coef);
        this.updateDimensionX(this.precisionRound(this.sizeOriginX * this.coefDim * coef,this.rounded));
        this.displayX = this.precisionRound(this.sizeOriginX * this.coefDim,this.rounded);
        this.updateDimensionY(this.precisionRound( this.sizeOriginY * this.coefDim * coef, this.rounded));
        this.displayY = this.precisionRound( this.sizeOriginY * this.coefDim,this.rounded);
      } else {
        this.updateDimensionX(this.displayX * coef);
        this.updateDimensionY(this.displayY * coef);
        this.updateDimensionZ(this.displayZ * coef);
      }

      this.volume = this.precisionRound((this.volumeOrigin) * (Math.pow(this.coefDim, 3)), this.rounded)

      this.volumeDisplay = Math.ceil(this.volume * this.getIndice())

      this.updateVolume(this.volume)
      this.updateVolumeDisplay(this.volumeDisplay)

    },
    getIndice() {
      let indice = 1
      if(this.selectedUnity == 'mm'){
        indice = 0.001
      } else if(this.selectedUnity == 'cm'){
        indice = 1
      } else if(this.selectedUnity == 'm'){
        indice = 1000000
      } else if(this.selectedUnity == 'inch'){
        indice = 16.387064
      }

      return indice
    },
    unityOnChange() {
      this.volumeDisplay = Math.ceil(this.volume * this.getIndice())
      this.updateVolumeDisplay(this.volumeDisplay)
      this.recalculateDimension('xyz')
      this.callAPiApplicationType()
    },
    precisionRound(number, precision) {
      let factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    },
    computeVolume(mesh) {
      var sum = 0;
      var ibuf = mesh.indexBuffer;
      var vbuf = mesh.vertexBuffer;
      var i = 0,
        j = 0;
      // walk through all faces, calculating the volume of the mesh
      while (i < mesh.faceCount) {
        var v0, v1, v2;
        var x0, y0, z0, x1, y1, z1, x2, y2, z2;
        v0 = ibuf[j++] * 3;
        v1 = ibuf[j++] * 3;
        // calculate volume of the polyhedron formed by the origin point and this face
        do {
          v2 = ibuf[j++] * 3;
          x0 = vbuf[v0];
          y0 = vbuf[v0 + 1];
          z0 = vbuf[v0 + 2];
          x1 = vbuf[v1];
          y1 = vbuf[v1 + 1];
          z1 = vbuf[v1 + 2];
          x2 = vbuf[v2];
          y2 = vbuf[v2 + 1];
          z2 = vbuf[v2 + 2];
          sum +=
            -x2 * y1 * z0 +
            x1 * y2 * z0 +
            x2 * y0 * z1 -
            x0 * y2 * z1 -
            x1 * y0 * z2 +
            x0 * y1 * z2;
          v1 = v2;
        } while (ibuf[j] != -1);
        // continue to next face
        j++;
        i++;
      }

      return Math.abs(sum / 6);
    },
  },
};
</script>