<template>
  <div class="page">
    <navigation-site :navThemeTransparent="false"></navigation-site>
    <div class="container">
      <div class="mt-5 pt-5 pb-3">
        <div class="pt-5">


          <div class="btn btn-primary">test button</div>  
  
        </div>
      </div>
        <div class="content">
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae sequi minima rem dolorem! Commodi dolorem eveniet tenetur veniam tempore molestias eum repellat repellendus, fugiat, tempora impedit minima illo culpa cum?</p>
        </div>
    </div>
    <footer-site></footer-site>
  </div>
</template>


<script>
import FooterSite from '../components/ui/FooterSite.vue';
import NavigationSite from '../components/ui/NavigationSite.vue';

export default {
  name: "TestModel",
  components: {NavigationSite, FooterSite  },
  data() {
    return {

         
    };
  },

};
</script>


<style lang="scss" scoped>
.page {

  background: #fafafa;
  //  background: #a8a8a8;
}
.content {
    min-height: 1000px;
   padding-top: 50px;
}
</style>