<template>
  <div class="project">
    <div class="project-wrapp">

       <h2 class="project-heading project-appearTitleFromBot">Dimension de l'élément : l x L x h</h2>

      <div class="project-content appear-form">
        <h4 class="h6 m-0 text-center">unité mm</h4>
         <div class="project-form">
            <input
              type="number"
              v-model="dim.x"
              class="project-form__input project-form__input--inline-33"
              placeholder="longueur en mm"
              min="0"
            />
            <input
              type="number"
              v-model="dim.y"
              class="project-form__input project-form__input--inline-33"
              placeholder="largeur en mm"
              min="0"
            />
            <input
              type="number"
              v-model="dim.z"
              class="project-form__input project-form__input--inline-33"
              placeholder="hauteur en mm"
              min="0"
            />
            <div v-if="errorForm" class="project-form--error">{{ errorMessage }}</div>
         </div>
      </div>

      <!-- Action -->
      <div class="project-action appear-form">
        <button v-if="!returnToRecap" class="btn-u3dm btn-u3dm--primary" @click="next">
          Etape suivante
        </button>
        <return-to-recap-button v-if="returnToRecap" :updateValue="updateValue"></return-to-recap-button>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ReturnToRecapButton from "./ReturnToRecapButton";

export default {
  name: 'ProjectDimension',
  components: {ReturnToRecapButton},
  data() {
    return {
      dim: {
        x: '',
        y: '',
        z: '',
      },
      errorMessage: 'tout les champs doivent être remplie et être positifs',
      errorForm: false,

      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState('projectDesign', {
      dimOnStore: (state) => state.projectDesign.dim,
      returnToRecap: state => state.returnToRecap,
      wayCurrent: state => state.steps.wayCurrent
    }),
  },
  beforeMount() {
    this.dim = this.dimOnStore

     // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C1.Dimension.0_view')
          this.updateCurrentGoogleTag('P3Imp_C1.Dimension')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C1.Dimension.0_view')
          this.updateCurrentGoogleTag('P3Mod_C1.Dimension')
        }
        //******************************************** */
  },
  methods: {
    ...mapActions('projectDesign', [
      'updateDim', 'increment', 'updateCurrentGoogleTag'
    ]),
    next() {
      if (this.updateValue()) {

         // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C1.Dimension.3_next')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C1.Dimension.3_next')
        }
        //******************************************** */


        this.increment()
      }
    },
    updateValue() {
      this.checkFormDim()

      if (!this.errorForm) {
        this.updateDim(this.dim)
        return true
      } else {
        return false
      }
    },
    checkFormDim() {
      let errors = []

      if(this.dim.x <= 0) {
        errors.push('x')
      }
        
      if(this.dim.y <= 0) {
          errors.push('y')
      }
        
      if(this.dim.z <= 0) {
          errors.push('z')
      }

      this.errorForm =  errors.length > 0 ? true : false
      // console.log(this.errorForm)
    }
  },
};
</script>