<template>
    <div class="material-card print-base-card print-appearItem" :class="material.id == item.id ? 'is-selected' : ''" @click="selectMaterial(item)" @mouseover="showInfoMaterial(item)">
      <div class="material-card-inner">
        <div class="material-card-media">
          <img src="./Rectangle_54.png" alt="image technologie">
        </div>
        <div>
          <h6 class="material-card-title h6">{{ item.name }}</h6>
          <span class="material-card-price t3">{{ (item.basePrice / 100) | formatPrice }} € HT</span>
          <!-- 
            <div class="material-card-i" @click="openDrawer">
              <icon-tooltip :color="'#C6C6C6'"></icon-tooltip>
            </div>
          -->
        </div>
      </div>
  </div>
</template>

<script>
// import IconTooltip from "../icon/IconTooltip.vue";

import { mapState, mapActions } from 'vuex';

export default {
    //components: { IconTooltip },
    name: "AccordMaterialItem",
    props: {
    item: {
      type: Object,
      default() {
        return { }
      }
    },
    indexItemInTab: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("print", {
      material: (state) => state.printFile.material,
      quantity: (state) => state.printFile.quantity,
      volume: (state) => state.printFile.volume,
      dimensions: (state) => state.printFile.dimensions,
      followPrintId: (state) => state.followId,
      applicationName: (state) => state.printFile.application.name,
      volumeDisplay: (state) => state.printFile.volumeDisplay,
    }),



  },
  methods: {
    ...mapActions("print", [
      "updateColorsList", "updateLayersList", "updateMaterial",  "updateColor", "updateLayer", "updateFinishing","updateInfoMaterial"
    ]),
    ...mapActions("printManager", [
      "updateDrawerMaterialIsVisible", "updateIndexActive", "updateStepNextPossible", "updateLastChildActive",
      "updateInfoTechnologyVisible",
      "updateInfoMaterialVisible",
      "updateInfoLayerVisible",
    ]),
    selectMaterial(item) {
      // At each material choice => save the choice
      let formData = new FormData()
      formData.append("volume", this.volumeDisplay * 1000)
      formData.append("quantity", this.quantity)
      formData.append("price", item.basePrice)
      formData.append("dimension[x]", this.dimensions.x)
      formData.append("dimension[y]", this.dimensions.y)
      formData.append("dimension[z]", this.dimensions.z)
      formData.append("follow_print_id", this.followPrintId)
      formData.append("material_name", item.name)
      formData.append("application_name", this.applicationName)

      this.$http.post(process.env.VUE_APP_API_BASE_URL + "application/follow-price", formData);

      this.updateColorsList(item.colors)
      this.updateLayersList(item.layers)
      //this.updateMaterial({id: item.id, name: item.name, basePrice: item.basePrice})
      this.updateMaterial(item)
      this.updateInfoTechnologyVisible (false)
      this.updateInfoMaterialVisible (true)
      this.updateInfoLayerVisible (false)
      this.updateStepNextPossible(4)

      // reset : color selected, layer selected
      this.updateColor({id:null,name:null,plusPrice:null,hexadecimalCode: null})
      this.updateLayer({id:null,height:null,plusPrice:null})
      this.updateFinishing({id:null,name:null,description:null,price:null})
   
      this.updateLastChildActive(4)

       // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I3.2.Material_select')
        //******************************************** */
    },

    showInfoMaterial(item) {

      this.updateInfoMaterial(item)
      this.updateInfoTechnologyVisible (false)
      this.updateInfoMaterialVisible (true)
      this.updateInfoLayerVisible (false)
      
    },

    openDrawer(e) {
      e.stopPropagation()
      this.updateIndexActive(this.indexItemInTab)
      this.updateDrawerMaterialIsVisible(true)
      

      // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I3.1.Material_info')
        //******************************************** */

    },
  }
}
</script>