<template>
  <div class="print-view">
    <sidebar-print></sidebar-print>

    <div class="print">
      <!-- ----------- -->
      <!-- zone viewer -->
      <!-- ----------- -->
      <div class="zone-viewer" :class="viewerMobileIsOpen == true ? 'viewer-mobile--is-open' : ''">
         <span class="btn-close-viewer btn-u3dm btn-u3dm--dark" @click="closeViewerMovbile">Fermer</span>
        <the-viewer></the-viewer>
      </div>

      <div class="zone-accordion-nav-mobile">
        <accordion-nav-mobile></accordion-nav-mobile>
      </div>

      <!-- -------------- -->
      <!-- zone selection -->
      <!-- -------------- -->
      <div class="zone-select">
        <!-- Print accordion -->
        <accordion-print></accordion-print>

        <accord-confirmation v-if="lastChildActive == 5"></accord-confirmation>
      </div>

      <!-- -------------- -->
      <!-- zone Information -->
      <!-- -------------- -->
      <div class="zone-info">
        <!-- Print info -->
        <info-print></info-print>

      </div>

      <!-- --------- -->
      <!-- zone shop -->
      <!-- --------- -->
      <div class="zone-shop">
        <shop-bar ref="shopBarPrint"></shop-bar>
      </div>
  
      <drawer-material></drawer-material>

    </div><!-- print -->

    <transition name="fade">
      <modal-redirect-project v-if="modalRedirectProjectIsOpen"></modal-redirect-project>
    </transition>

    <transition name="fade">        
      <modal-login-print v-if="modalConnexionIsOpen" @close="closeModalConnexion"></modal-login-print>
    </transition>

    <transition name="fade">
      <modal-file-error-print v-if="modalFileErrorIsOpen" @close="closeModalFileError"></modal-file-error-print>
    </transition>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import SidebarPrint from "../components/print/SidebarPrint.vue";
import TheViewer from "../components/print/TheViewer.vue";
import AccordionPrint from "../components/print/component/AccordionPrint.vue";
import InfoPrint from "../components/print/component/InfoPrint.vue";
import DrawerMaterial from "../components/print/DrawerMaterial.vue";
import AccordionNavMobile from "../components/print/AccordionNavMobile.vue";
import AccordConfirmation from "../components/print/AccordConfirmation.vue"


import ShopBar from '../components/print/ShopBar.vue';
import ModalLoginPrint from '../components/modal/ModalLoginPrint.vue';
import ModalRedirectProject from '../components/print/ModalRedirectProject.vue';
import ModalFileErrorPrint from "../components/modal/ModalFileErrorPrint";

export default {
  name: "Print",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Impression 3D',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers 3D et obtenir instantanément un prix.' }
    ],
  },
  components: {
    ModalFileErrorPrint,
    SidebarPrint,
    TheViewer,
    AccordionPrint,
    InfoPrint,
    DrawerMaterial,
    ShopBar,
    ModalLoginPrint,
    AccordionNavMobile,
    AccordConfirmation,
    ModalRedirectProject
  },
  data() {
    

    return {
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      viewerMobileIsOpen: (state) => state.viewerMobileIsOpen,
      modalConnexionIsOpen: (state) => state.modalConnexionIsOpen,
      modalFileErrorIsOpen: (state) => state.modalFileErrorIsOpen,
      modalRedirectProjectIsOpen: (state) => state.modalRedirectProjectIsOpen
    }),
  },
  beforeMount() {
    if (this.$route.params.token != undefined) {
      this.updateTokenUser(this.$route.params.token)
    } else {
      this.updateTokenUser('')
    }

     // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I1.1.landing_front.view')
        //******************************************** */

  },
  methods: {
    ...mapActions("print", [
        "resetPrint"
    ]),
    ...mapActions("printManager", [
        "resetPrintManager",
        "updateViewerMobileIsOpen",
        "updateModalVisibleIsOpen",
        "updateTokenUser",
        "updateModalFileErrorVisibleIsOpen"
    ]),
    closeModalConnexion() {
      this.updateModalVisibleIsOpen(false)
    },
    closeModalFileError() {
      this.updateModalFileErrorVisibleIsOpen(false)
    },
    closeViewerMovbile() {
      this.updateViewerMobileIsOpen(false)
    },
  },
  beforeDestroy() {
    this.resetPrint()
    this.resetPrintManager()
  }
};
</script>