<template>
  <div 
    class="a-accordion-header" 
    :class="[lastChildActive == 3 ? 'theme--active' : '', stepNextPossible >= 3 ? '' : 'theme--disabled']" 
    @click="correctNavbarWidth" @mouseover="showInfoMaterial"
    >
    <icon-thread :width="32" :color="lastChildActive == 3 ? '#7F1F23' : '#161616'"></icon-thread>
    <h4 class="h4">{{ materialNameSelected ? materialNameSelected : 'Sélectionner votre matériau' }}</h4>
    <span class="d-inline-block tooltip-print" tabindex="0" data-bs-toggle="tooltip" title="Choisissez le matériau le plus adapté à votre projet. Selon le matériau sélectionné, le rendu final et le prix changeront. Si vous avez besoin de conseils, vous avez le chat en bas à droite, ou bien écrivez-nous à contact@united-3dmakers.fr.">
      <i class="bi bi-question-circle"></i>
    </span>
    <span class="t3 color-secondary" v-if="stepNextPossible >= 4">modifier</span>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import IconThread from '../icon/IconThread.vue';

export default {
  name: "AccordHeaderStep3",
  components: { IconThread },
  computed: {
    ...mapState("print", {
      materialNameSelected: (state) => state.printFile.material.name,
    }),
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      stepNextPossible: (state) => state.stepNextPossible,
      infoMaterialVisible: (state) => state.manageOptions.infoMaterialVisible,
    }),
  },
   
   methods: {
    ...mapActions("printManager", [
      "updateInfoTechnologyVisible",
      "updateInfoMaterialVisible",
      "updateInfoLayerVisible",
    ]),
    correctNavbarWidth() {
      if(this.$root.$refs.TabsTechnologieNav !== undefined) {
        this.$root.$refs.TabsTechnologieNav.setCorrectNavbarWidth();
      }
    },
    showInfoMaterial() {
      if (this.lastChildActive >=3 ) {
        this.updateInfoTechnologyVisible (false)
        this.updateInfoMaterialVisible (true)
        this.updateInfoLayerVisible (false)
      }
    }
  }
};
</script>