<template>
  <div class="basket-view">
    <sidebar-basket></sidebar-basket>

    <div class="container basket-view-content">
      <div class="row h-100">
        <!-- zone products -->
        <div class="zone-products col-xl-7">
          <h4 class="h4 pt-4 pb-4">Panier</h4>

          <div class="pe-lg-4">
            <p v-if="shopCompleted == false" class="t1">
              Aucun d'articles dans votre panier
            </p>

            <!-- product list -->
            <div v-else>
              <basket-product-list></basket-product-list>

              <div
                v-if="feeShowAmountTaxExcl > 0"
                class="d-flex justify-content-between align-items-center pt-4 border-top">
                <div class="d-flex align-items-center">
                  <span v-html="feeShowDescription" class="t1 me-2">  </span>

                </div>
                <span class="t1">{{ (feeShowAmountTaxExcl / 100) | formatPrice }} €</span>
              </div>

              <div class="d-flex justify-content-between align-items-center mt-4 pt-4 border-top">
                <h5 class="h5 m-0 me-2">Total de la commande</h5>
                <h5 class="h5">
                  {{ (totalAmountTaxExcl / 100) | formatPrice }} € HT
                </h5>
              </div>
            </div>

            <!-- Add another file -->
            <div
              id="goPrintAddFile"
              class="d-flex align-items-center mt-5 pb-3"
              @click="goPrintAddFile"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 12.8349C7.57297 12.8349 7.22656 12.4885 7.22656 12.0615V3.93896C7.22656 3.51193 7.57297 3.16553 8 3.16553C8.42703 3.16553 8.77344 3.51193 8.77344 3.93896V12.0615C8.77344 12.4885 8.42703 12.8349 8 12.8349Z"
                  fill="#7C7C7C"
                />
                <path
                  d="M12.06 8.77393H3.9375C3.51047 8.77393 3.16406 8.42752 3.16406 8.00049C3.16406 7.57346 3.51047 7.22705 3.9375 7.22705H12.06C12.487 7.22705 12.8334 7.57346 12.8334 8.00049C12.8334 8.42752 12.487 8.77393 12.06 8.77393Z"
                  fill="#7C7C7C"
                />
                <path
                  d="M7.99797 15.9237C3.62875 15.9237 0.0742188 12.3691 0.0742188 7.99992C0.0742188 3.6307 3.62875 0.0761719 7.99797 0.0761719C12.3672 0.0761719 15.9217 3.6307 15.9217 7.99992C15.9217 12.3691 12.3672 15.9237 7.99797 15.9237ZM7.99797 1.62305C4.48187 1.62305 1.62109 4.48383 1.62109 7.99992C1.62109 11.516 4.48187 14.3768 7.99797 14.3768C11.5141 14.3768 14.3748 11.516 14.3748 7.99992C14.3748 4.48383 11.5141 1.62305 7.99797 1.62305Z"
                  fill="#7C7C7C"
                />
              </svg>
              <h6 class="h6 mb-0 ms-2 color-secondary">
                Ajouter un nouveau fichier
              </h6>
            </div>
          </div>
        </div>

        <!------------------>
        <!-- zone command -->
        <!------------------>
        <div class="zone-command col-xl-5 mt-5 mt-xl-0">
          <div class="d-flex align-items-center h-100 p-lg-3">
            <div class="w-100">
              <button
                class="btn-u3dm btn-u3dm--primary w-100 text-center"
                :class="[shopCompleted == false ? 'btn-u3dm--disabled' : '']"
                @click="goOrder"
                :disabled="shopCompleted == false"
                >Commander</button
              >
              <p class="t1 mt-2">
                Les frais de port seront calculés sur la page suivante selon votre choix de livraison
              </p>
               <div class="d-flex align-items-center mt-3">
                 <a :href="downloadCartPdfUrl" v-if="downloadCartPdfUrl !== null">
                   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M17.8322 18.8108H6.165C5.02734 18.8108 4.10156 17.885 4.10156 16.7474V14.3643C4.10156 13.9372 4.44797 13.5908 4.875 13.5908C5.30203 13.5908 5.64844 13.9372 5.64844 14.3643V16.7474C5.64844 17.0324 5.88 17.2639 6.165 17.2639H17.8322C18.1172 17.2639 18.3488 17.0324 18.3488 16.7474V14.3643C18.3488 13.9372 18.6952 13.5908 19.1222 13.5908C19.5492 13.5908 19.8956 13.9372 19.8956 14.3643V16.7474C19.8956 17.885 18.9698 18.8108 17.8322 18.8108Z" fill="#7C7C7C"/>
                     <path d="M11.9991 16.0508C11.8051 16.0508 11.6138 15.9777 11.4676 15.8394L8.38647 12.9257C8.07616 12.6322 8.06257 12.1429 8.356 11.8321C8.64944 11.5218 9.13882 11.5082 9.4496 11.8016L11.2262 13.4811V5.9624C11.2262 5.53537 11.5726 5.18896 11.9996 5.18896C12.4266 5.18896 12.773 5.53537 12.773 5.9624V15.2774C12.773 15.5863 12.5893 15.8657 12.3057 15.9875C12.2063 16.0302 12.1027 16.0508 11.9991 16.0508Z" fill="#7C7C7C"/>
                     <path d="M11.9995 16.0508C11.8964 16.0508 11.7923 16.0302 11.6939 15.9875C11.4103 15.8652 11.2266 15.5863 11.2266 15.2774V5.9624C11.2266 5.53537 11.573 5.18896 12 5.18896C12.427 5.18896 12.7734 5.53537 12.7734 5.9624V13.4816L14.55 11.8016C14.8603 11.5082 15.3497 11.5218 15.6436 11.8321C15.937 12.1424 15.9234 12.6318 15.6131 12.9257L12.532 15.8394C12.3844 15.9777 12.1931 16.0508 11.9995 16.0508Z" fill="#7C7C7C"/>
                   </svg>
                   <h6 class="h6 ps-3 m-0 color-secondary">Télécharger le devis</h6>
                 </a>
              </div>
               <div id="share-cart" class="d-flex align-items-center mt-3" @click="isShareCartModalVisible = true">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.2261 19.9165C15.7533 19.9165 14.5547 18.7184 14.5547 17.2451C14.5547 15.7723 15.7528 14.5737 17.2261 14.5737C18.6989 14.5737 19.8975 15.7719 19.8975 17.2451C19.897 18.7184 18.6989 19.9165 17.2261 19.9165ZM17.2261 16.1211C16.6059 16.1211 16.1016 16.6254 16.1016 17.2456C16.1016 17.8658 16.6059 18.3701 17.2261 18.3701C17.8462 18.3701 18.3506 17.8658 18.3506 17.2456C18.3506 16.6254 17.8458 16.1211 17.2261 16.1211Z" fill="#7C7C7C"/>
                  <path d="M6.77297 14.6714C5.30015 14.6714 4.10156 13.4733 4.10156 12C4.10156 10.5267 5.29969 9.32861 6.77297 9.32861C8.24624 9.32861 9.44437 10.5272 9.44437 12C9.44437 13.4728 8.24624 14.6714 6.77297 14.6714ZM6.77297 10.8755C6.15281 10.8755 5.64844 11.3799 5.64844 12C5.64844 12.6202 6.15281 13.1246 6.77297 13.1246C7.39312 13.1246 7.8975 12.6202 7.8975 12C7.8975 11.3799 7.39312 10.8755 6.77297 10.8755Z" fill="#7C7C7C"/>
                  <path d="M15.5255 17.1663C15.4088 17.1663 15.2907 17.1401 15.1796 17.0843L8.11834 13.5438C7.7363 13.3526 7.58209 12.8876 7.77381 12.5055C7.96506 12.1235 8.43005 11.9693 8.81209 12.161L15.8733 15.7015C16.2554 15.8927 16.4096 16.3577 16.2179 16.7398C16.0819 17.0102 15.8091 17.1663 15.5255 17.1663Z" fill="#7C7C7C"/>
                  <path d="M17.2261 9.42533C15.7533 9.42533 14.5547 8.2272 14.5547 6.75392C14.5547 5.28064 15.7528 4.08252 17.2261 4.08252C18.6989 4.08252 19.8975 5.28064 19.8975 6.75392C19.8975 8.2272 18.6989 9.42533 17.2261 9.42533ZM17.2261 5.62986C16.6059 5.62986 16.1016 6.13424 16.1016 6.75439C16.1016 7.37455 16.6059 7.87892 17.2261 7.87892C17.8462 7.87892 18.3506 7.37455 18.3506 6.75439C18.3506 6.13424 17.8458 5.62986 17.2261 5.62986Z" fill="#7C7C7C"/>
                  <path d="M8.46568 11.924C8.18255 11.924 7.90974 11.7679 7.77381 11.4974C7.58209 11.1159 7.7363 10.6509 8.11834 10.4592L15.1796 6.91541C15.5616 6.72369 16.0261 6.87791 16.2179 7.25994C16.4096 7.6415 16.2554 8.1065 15.8733 8.29822L8.81209 11.842C8.70052 11.8977 8.58193 11.924 8.46568 11.924Z" fill="#7C7C7C"/>
                </svg>
                <h6 class="h6 ps-3 m-0 color-secondary">Partager ce panier</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- zone command -->
    </div>
    <transition name="fade-modal-login">
      <modal-share-cart v-if="isShareCartModalVisible" @close="isShareCartModalVisible = false"></modal-share-cart>
    </transition>
  </div>
</template>

<script>
import BasketProductList from "../components/basket/BasketProductList.vue";
import SidebarBasket from "../components/basket/SidebarBasket.vue";

import { mapState, mapGetters, mapActions } from "vuex";
import ModalShareCart from '../components/modal/ModalShareCart.vue';

export default {
  components: {SidebarBasket, BasketProductList, ModalShareCart},
  name: "Basket",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Panier',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Retrouver votre panier de demande d’impression.' }
    ],
  },
  data() {
    return { 
      urlWithToken: '',
      gtagGroupEvent: 'impression_form',
      downloadCartPdfUrl: null,
      isShareCartModalVisible: false,
      feeThreshold: null,
      feeThresholdDescription: null,
    }
  },
  computed: {
    ...mapState("shop", {
      listProductsPrint: (state) => state.listProductsPrint,
      totalAmountTaxExcl: (state) => state.totalAmountTaxExcl,
      feeShowAmountTaxExcl: (state) => state.feeShowAmountTaxExcl,
      feeShowDescription: (state) => state.feeShowDescription,
    }),
    ...mapGetters("shop", {
      shopCompleted: "shopCompleted",
    }),
    ...mapGetters("authentication", {
      isConnected: "isConnected",
    }),
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'parameters/fee-threshold')
        .then((response) => {
          this.feeThreshold = response.body / 100
        }, () => {
          // console.log(error)
        })

    if (this.$route.params.token != undefined) {
      this.urlWithToken = '/' + this.$route.params.token
      this.callApiGetOrder(this.$route.params.token)

    } else {
      this.urlWithToken = ''
      this.setConnected().then( 
        () => {
         this.callApiGetOrder()
        },
        () => {
          this.updateRoute(this.$route.path)
          this.$router.push({ name: 'Login' })
        }
      );
    }

    // Google Tag Manager : push event order form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'O1.1.Basket_view')
        //******************************************** */

  },
  methods: {
    ...mapActions("shop", [
      "addProductPrint",
      "updateTotalAmountIncl",
      "updateTotalAmountExcl",
      "updateFeeShowAmountTaxExcl","updateFeeShowDescription",
    ]),
    ...mapActions("authentication", [
      "setConnected"
    ]),
    ...mapActions("routeRedirect", [
      "updateRoute"
    ]),
    ...mapActions("printManager", [
      "updateLastChildActive"
    ]),
    goPrintAddFile() {
      // Google Tag Manager : push event order form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'O1.2.Basket_add_file')
        //******************************************** */
      this.updateLastChildActive(1)
      this.$router.push({ name: 'Print', params: { token: this.$route.params.token }})
    },
    goOrder() {
      // Google Tag Manager : push event order form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'O1.3.Basket_order')
        //******************************************** */
      this.$router.push({name: 'Order', query: {token: this.$route.params.token}})
    },
    callApiGetOrder(token = "") {
      let tokenParameter = ''

      if (token != "") {
        tokenParameter = '/' + token
      }
  
      this.$http
        .get(process.env.VUE_APP_API_BASE_URL + "application/get-orders" + tokenParameter, {
          credentials: true,
        })
        .then((response) => {
          this.addProductPrint(response.body.items);
          this.updateFeeShowAmountTaxExcl(response.body["fee_show_amount_tax_excl"]);
          if (response.body["fee_show_description"] != undefined) {
            this.updateFeeShowDescription(response.body["fee_show_description"]);

          }
          
          this.updateTotalAmountIncl(response.body["total_amount_tax_incl"]);
          this.updateTotalAmountExcl(response.body["total_amount_tax_excl"]);
          if(response.body.id !== undefined) {
            this.downloadCartPdfUrl = process.env.VUE_APP_APP_BASE_URL + 'fr/order_sell/cart/invoice/'+ response.body.id + tokenParameter
          }
        });
    }
  },
  
};
</script>