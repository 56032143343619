<template>
  <div
    class="application-card print-base-card print-appearItem"
    :class="application.id == item.id ? 'is-selected' : ''"
    @click="selectApplication"
  >
    <div>
      <h6 class="application-card__text">{{ item.name }}</h6>
      <span v-if="item.quote" class="application-card__devis">mode devis</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AccordApplicationItem",
  props: {
    item: {
      type: Object,
      default() {
        return {
          id: 0,
          name: "non définie",
          quote: false
        };
      },
    },
  },
  data() {
    return {
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("print", {
      volumeDisplay: (state) => state.printFile.volumeDisplay,
      application: (state) => state.printFile.application,
      dimensions: (state) => state.printFile.dimensions,
      technologieCategoryList: (state) => state.printFile.technologieCategoryList,
      technology: (state) => state.printFile.technology,
      
    }),
  },
  methods: {
    ...mapActions("print", [
      "updateMaterialItems", 
      "updateApplication",
      "updateTechnology", 
      "updateMaterial", 
      "updateColor", 
      "updateLayer", 
      "updateFinishing", 
      "updateTechnologieCategoryList",
      //"updateMaterialItemsByTechnologieCategory",
      "updateOldApplication",
      "updateInfoTechnology"
      ]),
    ...mapActions("printManager", [
        "updateLengthOfTabMaterials",
        "updateStepNextPossible", 
        "updateViewAllTechnology",
        "updateLastChildActive",
        "updateModalRedirectProjectIsOpen",
        "updateInfoTechnologyVisible",
        "updateInfoMaterialVisible",
        "updateInfoLayerVisible",
        "updateViewerMobileIsOpen"
    ]),
    selectApplication() {

      if (this.item.id == 0) {

        this.callApiAllTechnologie()
        this.updateViewAllTechnology(true)
        this.updateOldApplication({id: null, name: null, quote: false})
        
        this.updateLastChildActive(3)
        this.updateViewerMobileIsOpen(false)

        this.updateInfoTechnologyVisible (true)
        this.updateInfoMaterialVisible (false)
        this.updateInfoLayerVisible (false)


      } else if (this.item.quote !== true) {

        this.updateTechnology({id: null,name:null,description:null})

        this.callApiMakersMaterialsLayersColors()
        this.updateOldApplication(this.item)

        this.updateLastChildActive(3)
        this.updateViewerMobileIsOpen(false)

      } else {
        this.updateModalRedirectProjectIsOpen(true)
      }
      
      // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I2.1.Select_appli')
        //******************************************** */
      
    },
    callApiMakersMaterialsLayersColors() {
      
      let formData = new FormData();

      formData.append("application_id", this.item.id);
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);

      this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "application/makers-materials-layers-colors",
          formData
        )
        .then((response) => {
        
          this.updateApplication(this.item)
          this.updateMaterialItems(response.body);
          this.updateLengthOfTabMaterials(response.body.length)
          this.updateStepNextPossible(3)
     
        });
        // reset : material selected, color selected, layer selected
        this.updateMaterial({id: null,name:null,basePrice:null})
        this.updateColor({id:null,name:null,plusPrice:null,hexadecimalCode:null})
        this.updateLayer({id:null,height:null,plusPrice:null})
        this.updateFinishing({id:null,name:null,description:null,price:null})
    },

    callApiAllTechnologie() {

      let formData = new FormData();
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);

      this.$http.post(process.env.VUE_APP_API_BASE_URL + "application/technologies", formData)
          .then((response) => {
            if (Object.keys(response.body).length > 0) {
              //console.log("list techno")
              //console.log(response.body)
              this.updateApplication(this.item)
              this.updateTechnologieCategoryList(response.body)

             this.updateStepNextPossible(3)

              // first request for tabsNavTEchnology

              if (this.technology.id == null) {
                this.updateInfoTechnology(response.body[0])
              this.callApiTechnologyById(response.body[0].id)
              this.updateTechnology(response.body[0])

              } else {
                this.callApiTechnologyById(this.technology.id)
                this.updateInfoTechnology(this.technology)
              }
              

              
              
            }

            // reset : material selected, color selected, layer selected
            this.updateMaterial({id: null, name: null, basePrice: null})
            this.updateColor({id: null, name: null, plusPrice: null, hexadecimalCode: null})
            this.updateLayer({id: null, height: null, plusPrice: null})
            this.updateFinishing({id: null, name: null, description: null, price: null})

          });
    },
    callApiTechnologyById(id) {
      let formData = new FormData();
      formData.append("technology_id", id);
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);

      this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + "application/technology-materials-layers-colors",
          formData
        )
        .then((response) => {
          
          //this.updateMaterialItemsByTechnologieCategory(response.body)
          this.updateMaterialItems(response.body);
          this.updateLengthOfTabMaterials(response.body.length)


      });
    }
  }
};
</script>

