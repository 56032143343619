<template>
    <div class="discussion-drawer-outer">

        <div class="discussion-drawer">

            <div class="discussion-drawer-heading border-bottom">
                <h3 class="h5 m-0">Notation de la prestation</h3>
                <span class="cursor-p" @click="close">
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1647 17.9401C16.9669 17.9401 16.7686 17.8646 16.6177 17.7137L6.285 7.38055C5.98313 7.07867 5.98313 6.58883 6.285 6.28695C6.58687 5.98508 7.07672 5.98508 7.37859 6.28695L17.7117 16.6201C18.0136 16.922 18.0136 17.4118 17.7117 17.7137C17.5603 17.8646 17.3625 17.9401 17.1647 17.9401Z" fill="#7C7C7C"/>
                        <path d="M6.83203 17.9401C6.63422 17.9401 6.43594 17.8646 6.285 17.7137C5.98312 17.4118 5.98312 16.922 6.285 16.6201L16.6181 6.28695C16.92 5.98508 17.4099 5.98508 17.7117 6.28695C18.0136 6.58883 18.0136 7.07867 17.7117 7.38055L7.37906 17.7132C7.22813 17.8646 7.03031 17.9401 6.83203 17.9401Z" fill="#7C7C7C"/>
                    </svg>
                </span>
            </div>

            <!-- accept-devis -->
            <div class=" d-flex justify-content-center align-items-center h-100">
                <div class="text-center p-2">
                    <h5 class="h5">Donner une note à la prestation</h5>
                 

                    <div class="mt-5 d-flex justify-content-between">
                        <svg v-for="(rating, index) in ratings" :key="index"
                            class="cursor-p"
                            :class="{'is-selected': ((rate >= rating) && rate != null)}" 
                            @click="set(rating)"
                            xmlns="http://www.w3.org/2000/svg" fill="#C6C6C6" width="34" height="34" viewBox="0 0 24 24">
                        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
                        </svg>
                    </div>



                </div>
            </div>


            <!-- message -->
           
           

            <!-- send message -->
            <!-- <div class="discussion-drawer-write"> -->
            <div class="p-2 w-100">
                <textarea class="w-100" name="message" v-model="message" id="" cols="30" rows="5" placeholder="Entrer votre message"></textarea>
               
                <!-- action -->
                <div class="mt-3">
                    <button class="btn-u3dm btn-u3dm--primary" @click="sendMessage">Envoyer</button>
                </div>
            </div>
            

        </div>
    </div><!-- discussion-drawer-outer -->
</template>

<script>
export default {
    name: "DrawerNotation",
    data() {
        return {
            token: '',
            message: '',
            rate: 3,
            ratings: [1, 2, 3, 4, 5]
        }
    },
    props: {
        orderReference: {
            type: String,
            default: 'reference non definie'
        },
    },
    beforeMount() {
        if (this.$route.params.token != undefined) {
            this.token = '/' + this.$route.params.token
        }
    },
    methods: {
        set(value) {
            this.rate = value;
            return this.value = value;
        },
        sendMessage() {

            
            let formData = new FormData();
                formData.append('rate', this.rate)
                formData.append('comment', this.message);

            this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/rate/'+this.orderReference + this.token, formData, {credentials: true})
                .then(() => {
                    location.reload()
                    this.close()
                })  
            
        },
        close() {
            this.$emit('close')
        }
    },
}
</script>

<style lang="scss" scoped>
.is-selected {
  fill: #FFD700;
}
</style>