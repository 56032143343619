<template>
  <div class="project">
    <div class="project-wrapp project-wrapp--lg">

      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">A partir d'un ...</h2>
  
      <!-- Content page -->
      <div class="project-content project-content--flex">


        <div class="ask-card project-appearBigCard project-appearBigCard__item--1" @click="next(true ,1)"
        :class="{'ask-card--active' : scanOnSite == 1}">
           <div class="ask-card__content">
             <div class="ask-card__media">
              <component :is="'icon-cube'" :width="40" :color="scanOnSite == 1 ? '#FC3640' : ''"></component>
             </div>
              <p class="ask-card__text">Objet</p>
           </div>
        </div>

        <div class="ask-card project-appearBigCard project-appearBigCard__item--2" @click="next(false, 0)"
        :class="{'ask-card--active' : scanOnSite === 0}">
           <div class="ask-card__content">
             <div class="ask-card__media">
              <component :is="'icon-file'" :width="40" :color="scanOnSite === 0 ? '#FC3640' : ''"></component>
             </div>
              <p class="ask-card__text">Fichier <br> ( croquis, image, ... )</p>
           </div>
        </div>
      
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import IconCube from '../icon/IconCube.vue';
import IconFile from '../icon/IconFile.vue';

export default {
    name: 'projectAskTwo',
    components: {
      IconCube, IconFile
    },
    data() {
      return {
        gtagGroupEvent: 'project_form'
      }
    },
    computed: {
      ...mapState('projectDesign', {
        scanOnSite: (state) => state.projectDesign.scanOnSite,
        wayCurrent: state => state.steps.wayCurrent
      })
    },
    beforeMount() {
       // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B0.Design_a_partir.0_view')
        this.updateCurrentGoogleTag('P3Imp_B0.Design_a_partir')
        //******************************************** */
    },
    methods: {
    ...mapActions('projectDesign', [
       'increment', 'adaptWay', 'updateScanOnSite', 'updateCurrentGoogleTag'
    ]),
    next(bool ,booleanNumber) {
        this.updateScanOnSite(booleanNumber)
        this.adaptWay({key: 'projectAskTwo', value: bool })


        // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)
        let endTag = bool == true ? '2_click_object' : '3_click_file' 
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B0.Design_a_partir.' +endTag)
        //******************************************** */

        this.increment()
    },
  },
}
</script>