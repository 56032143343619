<template>
  <div class="project">
    <div class="project-wrapp">

      <!-- Title -->
      <h1 class="project-heading project-appearTitleFromBot">Nommer votre projet</h1>
  
      <!-- Content page -->
      <div>

        <div class="project-form appear-form">
          <input
            type="text"
            v-model="name"
            class="project-form__input"
            placeholder="Nommer votre projet"
            maxlength="40"
            @keypress.enter="next"
          />
          <span class="project-form__helper">{{ nbWords }} / 40</span>
          <p v-if="errorForm" class="project-form--error">{{ errorMessage }}</p>
        </div>
      </div>

      <!-- Button action go next step -->
      <div v-if="!returnToRecap" class="project-action appear-form">
        <button class="btn-u3dm btn-u3dm--primary" @click="next">
          Etape suivante
        </button>
      </div>

      <return-to-recap-button v-if="returnToRecap" :updateValue="updateValue"></return-to-recap-button>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ReturnToRecapButton from "./ReturnToRecapButton";

export default {
  name: 'ProjectName',
  components: {ReturnToRecapButton},
  data() {
    return {
      name: this.nameOnStore,
      errorForm: false,
      errorMessage: 'Vérifier le nom du projet',
      
      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState('projectDesign',{
      nameOnStore: state => state.projectDesign.name,
      returnToRecap: state => state.returnToRecap,
    }),
    ...mapState('authentication',{
      id: state => state.id,
      isMaker: state => state.isMaker,
    }),
    nbWords() {  return this.name.length > 1 ? this.name.length : 0 }
  },
  beforeMount() {
    if (this.nameOnStore == undefined || this.nameOnStore == null) {
      this.name = ''
    } else {
      this.name = this.nameOnStore
    }

    

     // Google Tag Manager : push event projet view
        //******************************************** */
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P1.NomProjet.0_view')
        this.updateCurrentGoogleTag('P1.NomProjet')
        //******************************************** */


  },
  methods: {
    ...mapActions('projectDesign', [
      'updateName', 'increment', 'updateCurrentGoogleTag'
    ]),
    next() {
      if(this.updateValue()) {
        
      // Google Tag Manager : push event account creation view
        //******************************************** */
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P1.NomProjet.1_next')
        this.updateCurrentGoogleTag('P1.NomProjet')
        //******

        this.increment()
      }
    },
    updateValue() {
      if (this.name.trim() == '') {
        this.errorForm = true
        return false;
      } else {
        this.errorForm = false
        this.updateName(this.name)
        return true;
      }
    },
  },
};
</script>