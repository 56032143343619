<script>
import mixinLogin from './ModalLoginMixins.vue'

import {mapActions, mapGetters, mapState} from 'vuex';

export default {
  name: 'ModalSaveLoginPrint',
  mixins: [mixinLogin],
  data() {
    return {
      gtagGroupEvent: 'project_form',
      showMandatoryTelephone: true,
      errorMessages: [],
    };
  },
  computed: {
    ...mapGetters("projectDesign", {
      getStatusOfProject: 'getStatusOfProject',
    }),
    ...mapState("projectDesign", {
      wayCurrent: state => state.steps.wayCurrent
    }),
  },
  methods: {
    ...mapActions('projectDesign', [
      'saveProject',
      'updateSeeProjectAfterSave',
      'updateCurrentGoogleRecapTag',
    ]),
    inactiveUserAndSave() {
      this.$googleTagManager(this.id, this.ismaker != null, 'registration', 'user_form_light.account_creation.attempt')

      if (this.checkFieldsBeforeSend()) {
        this.loader = true

        let formData = new FormData();

        formData.append('email', this.registrationAccount.email);
        formData.append('firstname', this.registrationAccount.firstname);
        formData.append('lastname', this.registrationAccount.lastname);
        formData.append('telephone', this.registrationAccount.telephone);

        this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/register_inactive', formData)
            .then((response) => {
              let data = {
                enableToken: response.body['enable_token'],
                save: true,
                // staupdateProjectAfterSavetus: 2
              }

              if (response.body.enabled == true) {
                this.newClient = false
                this.loader = false
              } else {
                this.$googleTagManager(this.id, this.ismaker != null, 'registration', 'user_form_light.account_creation.success')

                this.saveProject(data).then((response) => {
                  this.updateSeeProjectAfterSave({
                    token: '/' + data.enableToken,
                    reference: JSON.parse(response.body).project_reference
                  })
                  this.projectSaved = true
                  this.loader = false

                  this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'p3.valideprojet.1_validate')

                  if (this.getStatusOfProject === 2) {
                    if (this.wayCurrent == 'IMPRESSION_A') {
                      this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'P3Imp_A6.ValideProjet.1_validate')
                    } else if (this.wayCurrent == 'IMPRESSION_B') {
                      this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'P3Imp_B6.ValideProjet.1_validate')
                    } else if (this.wayCurrent == 'IMPRESSION_C') {
                      this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'P3Imp_C8.ValideProjet.1_validate')
                    } else if (this.wayCurrent == 'MODELISATION_A') {
                      this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'P3Mod_A6.ValideProjet.1_validate')
                    } else if (this.wayCurrent == 'MODELISATION_B') {
                      this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'P3Mod_B7.ValideProjet.1_validate')
                    } else if (this.wayCurrent == 'NUMERISATION_A') {
                      this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'P3Mod_C9.ValideProjet.1_validate')
                    }
                  } else if (this.getStatusOfProject === 1) {
                    this.$googleTagManager(this.id, this.ismaker != null, this.gtagGroupEvent, 'p4.projet.0_save')
                  }

                  this.$parent.next()


                })
              }


            }), () => {
          // console.log(error)
        }
      }

    },
    checkFieldsBeforeSend() {
      let valid = true
      this.errorMessages = []
      if(this.registrationAccount.lastname === '') {
        this.errorMessages.push('Champs nom obligatoire')
        valid = false
      }
      if(this.registrationAccount.firstname === '') {
        this.errorMessages.push('Champs prénom obligatoire')
        valid = false
      }
      if(this.registrationAccount.email === '') {
        this.errorMessages.push('Champs email obligatoire')
        valid = false
      } else {
        if (RegExp(/\S+@\S+\.\S+/).test(this.registrationAccount.email) == false) {
          this.errorMessages.push('Adresse email invalide');
          valid = false
        }
      }

      if(this.registrationAccount.telephone === '') {
        this.errorMessages.push('Champs téléphone obligatoire')
        valid = false
      } else if(!this.registrationAccount.telephone.match(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gmi)) {
        this.errorMessages.push('Téléphone invalide')
        valid = false
      }

      return valid
    },
  }
}
</script>