<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <div :class="sent ? 'm-login-content-save-project' : 'm-login-content'" class="modal-login-content-appear">

      <img class="m-login-cross"
             :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
             alt="icon fermer"
             @click="close"
        />

        <div class="m-login-prospect" v-if="!this.sent">

          <h4 class="m-login-heading h4">Partage du panier</h4>

          <div class="m-login-form">
            <div v-if="errorsEmail.length >= 1" class="t1 text--error">
              {{ this.errorsEmail[0] }}
            </div>
            <input type="email" v-model="userEmail" placeholder="Adresse mail expéditeur" class="m-login-form__input"/>
            <input type="email" v-model="receiverEmail" placeholder="Adresse mail destinataire" class="m-login-form__input"/>
            <textarea v-model="comment" rows="5" cols="57" placeholder="Ajoutez un message si vous le souhaitez" class="m-login-form__textarea"></textarea>
          </div>

          <div class="m-login-action">
            <button class="btn-u3dm btn-u3dm--primary btn--100" @click="shareCart">
              Envoyer
            </button>
          </div>
        </div>
        <div class="m-login-prospect" v-if="this.sent">
          <h4 class="m-login-heading h4">Votre panier a été envoyé avec succès</h4>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalShareCart',
  data() {
    return {
      errorsEmail: [],
      userEmail: '',
      receiverEmail: '',
      comment: '',
      sent: false,
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    shareCart() {
      if (this.checkEmail()) {
        let formData = new FormData();
        formData.append("userEmail", this.userEmail);
        formData.append("receiverEmail", this.receiverEmail);
        if(this.comment !== '') {
          formData.append("comment", this.comment);
        }

        if(this.$route.params.token !== undefined) {
          formData.append("token", this.$route.params.token);
        }

        this.$http.post(process.env.VUE_APP_API_BASE_URL + "cart/share", formData, {credentials: true})
            .then(() => {
              this.sent = true
            }),
            () => {
            };
      }
    },
    checkEmail() {
      this.errorsEmail = [];

      if (!this.userEmail) {
        this.errorsEmail.push('Adresse email de l\'expéditeur invalide');
      } else if (RegExp(/\S+@\S+\.\S+/).test(this.userEmail) == false) {
        this.errorsEmail.push('Adresse email de l\'expéditeur invalide');
      }

      if (!this.receiverEmail) {
        this.errorsEmail.push('Adresse email du destinataire invalide');
      } else if (RegExp(/\S+@\S+\.\S+/).test(this.receiverEmail) == false) {
        this.errorsEmail.push('Adresse email du destinataire invalide');
      }

      if (this.errorsEmail.length >= 1) {
        return false
      } else {
        return true
      }
    },
  }
}
</script>