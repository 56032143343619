<template>
  <div class="project">
    <div class="project-wrapp project-wrapp--lg">

      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">Solution pour scanner l'objet</h2>
  
      <!-- Content page -->
      <div class="project-content project-content--flex">

        <div class="ask-card project-appearBigCard project-appearBigCard__item--1" 
        :class="{'ask-card--active' : howScan == 1}"
        @click="next(1)">
           <div class="ask-card__content">
             <div class="ask-card__media">
              <component :is="'icon-cube'" :width="40" :color="howScan == 1 ? '#FC3640' : ''"></component>
             </div>
              <p class="ask-card__text">Déplacement du maker</p>
           </div>
        </div>

        <div class="ask-card project-appearBigCard project-appearBigCard__item--2" 
        :class="{'ask-card--active' : howScan == 2}"
        @click="next(2)">
            <div class="ask-card__content">
              <div class="ask-card__media">
                <component :is="'icon-file'" :width="40" :color="howScan == 2 ? '#FC3640' : ''"></component>
              </div>
                <p class="ask-card__text">Envoie et retour de l'objet</p>
            </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import IconCube from '../icon/IconCube.vue';
import IconFile from '../icon/IconFile.vue';

export default {
  name: 'ProjectHowScan',
  components: {
    IconCube, IconFile
  },
  data() {
    return {
      gtagGroupEvent: 'project_form'
    }
  },
  computed: {
    ...mapState('projectDesign', {
      returnToRecap: state => state.returnToRecap,
      howScan: state => state.projectDesign.howScan,
      wayCurrent: state => state.steps.wayCurrent
    }),
  },
  beforeMount() {
     // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C2.Transport.0_view')
          this.updateCurrentGoogleTag('P3Imp_C2.Transport')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C2.Transport.0_view')
          this.updateCurrentGoogleTag('P3Mod_C2.Transport')
        }
        //******************************************** */
  },
  methods: {
    ...mapActions('projectDesign', [
      'increment', 'updateHowScan', 'goOnStepByName', 'updateCurrentGoogleTag'
    ]),
    next(number) {

      // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        let endTag = number == 1 ? '2_Click_maker' : '3_Click_send'

        if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C2.Transport.' + endTag)
        }  else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C2.Transport.' + endTag)
        }
        //******************************************** */

      this.updateHowScan(number)
      if(this.returnToRecap) {
        this.goOnStepByName('recap');
      } else {
        this.increment()
      }
    },
  },
}
</script>