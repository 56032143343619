<template>
  <div>
    <!-- before -->
    <div class="order-form-before">
      <div v-if="needShipping" class="order-form-before-row">
        <h5 class="h5">Mode de livraison</h5>
        <p v-if="modeDelivery.name !== null">{{ modeDelivery.label }}</p>
        <div class="order-form-before-edit" @click="prev(1)">modifier</div>
      </div>

      <div class="order-form-before-row" v-if="shippingConfiguration !== 'numerical' && shippingConfiguration !== 'pay'">
        <h5 class="h5">Adresse de livraison</h5>
        <div>
          <div v-if="modeDelivery.name != 'relay'" class="">
            <p>
            {{ shipping.firstname | ucFirst }},
            {{ shipping.lastname | ucFirst }}
          </p>
          <p>
            {{ shipping.adress }}, {{ shipping.zipcode }}
            {{ shipping.city | ucFirst }}
          </p>
          </div>
          <div v-else>
             <p>
            {{ relayAddress.firstname | ucFirst }},
            {{ relayAddress.lastname | ucFirst }}
          </p>
          <p>{{ relayAddress.enterprise }}</p>
          <p>
            {{ relayAddress.adress }}, {{ relayAddress.zipcode }}
            {{ relayAddress.city | ucFirst }}
          </p>
          </div>
        </div>
        <div class="order-form-before-edit" @click="prev(2)">modifier</div>
      </div>
    </div>
    <!-- before -->

    <!-- ----------------------------------------- -->
    <div>
      <h4 class="h4 mt-5 mb-4">Comment souhaitez-vous payer ?</h4>
      <div class="accordion-grabbed">
        <!-- stripe errors -->
        <div ref="errorFormCardExplain" class="mt-2 mb-2">
          <span v-if="cardNumberError" class="text--error d-block"
            >Le numero de carte est incomplet</span
          >
          <span v-if="cardCvcError" class="text--error d-block"
            >Le code CVC est incomplet</span
          >
          <span v-if="cardExpiryError" class="text--error d-block"
            >La date d'expiration est incomplète</span
          >
          <div class="text--error">{{ stripeError }}</div>
        </div>
        <!-- payment : credit card -->
        <accordion-grabbed-item>
          <template v-slot:header>
            <div
              class="accordion-grabbed-header first"
              :class="paymentMode == 'card' ? 'is-open' : ''"
            >
              <input
                type="radio"
                value="card"
                name="paymentMode"
                id="for-card"
                v-model="paymentMode"
              />
              <label for="for-card"><h6 class="h6">Carte de crédit</h6></label>
              <svg
                width="117"
                height="24"
                viewBox="0 0 117 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M113.765 24H85.3346C83.5493 24 82.1016 22.5861 82.1016 20.8426V3.15745C82.1016 1.41388 83.5493 0 85.3346 0H113.765C115.55 0 116.998 1.41388 116.998 3.15745V20.8415C116.998 22.5861 115.55 24 113.765 24Z"
                    fill="#006FCF"
                  />
                  <path
                    d="M72.3974 24H43.9674C42.1821 24 40.7344 22.5861 40.7344 20.8426V3.15745C40.7344 1.41388 42.1821 0 43.9674 0H72.3974C74.1827 0 75.6304 1.41388 75.6304 3.15745V20.8415C75.6304 22.5861 74.1827 24 72.3974 24Z"
                    fill="#36495D"
                  />
                  <path
                    d="M31.0302 24H2.60025C0.814925 24 -0.632812 22.5861 -0.632812 20.8426V3.15745C-0.633894 1.41388 0.813843 0 2.60025 0H31.0302C32.8155 0 34.2633 1.41388 34.2633 3.15745V20.8415C34.2633 22.5861 32.8155 24 31.0302 24Z"
                    fill="#0066B2"
                  />
                  <path
                    d="M15.3373 15.7593H13.5195L14.6556 8.94141H16.4734L15.3373 15.7593Z"
                    fill="white"
                  />
                  <path
                    d="M11.9889 8.94189L10.2555 13.6316L10.051 12.6214L9.43969 9.55584C9.43969 9.55584 9.36611 8.94189 8.57732 8.94189H5.71323L5.67969 9.05708C5.67969 9.05708 6.55612 9.2346 7.58079 9.83693L9.15944 15.7598H11.053L13.9452 8.94189H11.9889Z"
                    fill="white"
                  />
                  <path
                    d="M26.2859 15.7593H27.9544L26.4991 8.94141H25.0373C24.3621 8.94141 24.1987 9.44968 24.1987 9.44968L21.4883 15.7593H23.3829L23.7616 14.747H26.0717L26.2859 15.7593ZM24.2853 13.3468L25.2407 10.7949L25.7785 13.3468H24.2853Z"
                    fill="white"
                  />
                  <path
                    d="M21.6284 10.5809L21.8881 9.11627C21.8881 9.11627 21.0874 8.81934 20.2532 8.81934C19.3508 8.81934 17.2095 9.20504 17.2095 11.0765C17.2095 12.838 19.723 12.8602 19.723 13.7848C19.723 14.7094 17.4681 14.5446 16.7236 13.9613L16.4531 15.4914C16.4531 15.4914 17.2646 15.8771 18.5046 15.8771C19.7446 15.8771 21.6165 15.2494 21.6165 13.5428C21.6165 11.7697 19.0803 11.6048 19.0803 10.8345C19.0813 10.0631 20.8515 10.1624 21.6284 10.5809Z"
                    fill="white"
                  />
                  <path
                    d="M10.051 12.6214L9.43968 9.55584C9.43968 9.55584 9.36611 8.94189 8.57732 8.94189H5.71323L5.67969 9.05708C5.67969 9.05708 7.05709 9.33605 8.37715 10.3801C9.63877 11.3776 10.051 12.6214 10.051 12.6214Z"
                    fill="#F9A533"
                  />
                  <path
                    d="M82.1016 5.69043V19.6358H116.974V5.69043H82.1016Z"
                    fill="white"
                  />
                  <path
                    d="M112.703 11.6397H115.321V5.69043H112.527V6.64147L111.905 5.69043H109.469V6.89402L108.924 5.69043H104.897C104.704 5.69043 104.516 5.70417 104.333 5.7327C104.177 5.757 104.025 5.79082 103.879 5.8352C103.765 5.87007 103.653 5.91128 103.546 5.95989C103.419 6.01695 103.299 6.08141 103.185 6.15644V5.95144V5.69043H90.0436L89.6854 6.72812L89.3208 5.69043H86.263V6.9067L85.7112 5.69043H83.2171L82.1016 8.23499V11.1103V11.6397H83.8685L84.1974 10.7859H84.8196L85.1539 11.6397H98.7462V10.7743L99.4268 11.6397H103.182V11.4495V11.2075C103.28 11.2688 103.38 11.3227 103.482 11.3692C103.585 11.4157 103.69 11.4548 103.796 11.4886C103.927 11.5309 104.061 11.5615 104.193 11.5858C104.409 11.6239 104.626 11.6408 104.83 11.6408H107.078L107.407 10.7869H108.029L108.363 11.6408H112.131V10.7584L112.703 11.6397ZM116.975 19.2692V13.6866H116.67H95.3422L94.7904 14.4484L94.245 13.6866H88.0234V19.6358H94.2212L94.7611 18.8623L95.3184 19.6358H99.1974V18.3519H99.1563C99.6389 18.3519 100.077 18.2663 100.466 18.1258V19.6358H103.171V18.7704L103.851 19.6358H115.589C115.926 19.6358 116.243 19.5798 116.527 19.4794C116.688 19.4224 116.837 19.3516 116.975 19.2692Z"
                    fill="#F7F7F7"
                  />
                  <path
                    d="M116.998 18.6666V14.6543H88.9805V18.6666H116.998Z"
                    fill="white"
                  />
                  <path
                    d="M115.614 17.8646H113.508V18.6677H115.585C116.472 18.6677 117 18.1404 117 17.3891C117 16.6726 116.525 16.2542 115.727 16.2542H114.776C114.541 16.2542 114.376 16.1052 114.376 15.8695C114.376 15.6286 114.54 15.468 114.776 15.468H116.56L116.925 14.6543H114.806C113.92 14.6543 113.392 15.1816 113.392 15.9033C113.392 16.643 113.891 17.0668 114.672 17.0668H115.616C115.851 17.0668 116.021 17.2158 116.021 17.4567C116.019 17.704 115.849 17.8646 115.614 17.8646ZM111.706 17.8646H109.599V18.6677H111.677C112.563 18.6677 113.092 18.1404 113.092 17.3891C113.092 16.6726 112.617 16.2542 111.819 16.2542H110.867C110.633 16.2542 110.468 16.1052 110.468 15.8695C110.468 15.6286 110.633 15.468 110.867 15.468H112.652L113.016 14.6543H110.898C110.012 14.6543 109.484 15.1816 109.484 15.9033C109.484 16.643 109.982 17.0668 110.764 17.0668H111.708C111.943 17.0668 112.113 17.2158 112.113 17.4567C112.111 17.704 111.941 17.8646 111.706 17.8646ZM109 15.469V14.6554H105.713V18.6677H109V17.8593H106.7V17.0509H108.947V16.2478H106.7V15.468H109V15.469ZM103.496 15.4743C103.847 15.4743 104.036 15.6973 104.036 15.9731C104.036 16.2478 103.847 16.4655 103.496 16.4655H102.339V15.4743H103.496ZM102.339 17.2221H102.803L104.03 18.6666H105.257L103.89 17.1872C104.589 17.0604 105.004 16.568 105.004 15.9266C105.004 15.1753 104.459 14.6543 103.583 14.6543H101.353V18.6666H102.339V17.2221ZM99.8266 16.0016C99.8266 16.2711 99.6502 16.4951 99.304 16.4951H98.1246V15.5092H99.304C99.6513 15.5092 99.8266 15.7269 99.8266 16.0016ZM97.1389 14.6543V18.6666H98.1246V17.3426H99.3624C100.26 17.3426 100.818 16.7698 100.818 15.9963C100.818 15.1996 100.272 14.6554 99.397 14.6554H97.1389V14.6543ZM95.689 18.6666H96.9214L95.2724 16.643L96.9095 14.6543H95.7128L94.7032 15.9266L93.7002 14.6543H92.4678L94.1114 16.6599L92.4743 18.6666H93.6721L94.6816 17.3764L95.689 18.6666ZM92.2676 15.469V14.6554H88.9805V18.6677H92.2676V17.8593H89.9673V17.0509H92.2146V16.2478H89.9673V15.468H92.2676V15.469Z"
                    fill="#006FCF"
                  />
                  <path
                    d="M111.334 8.26912L111.322 7.93625L111.527 8.26912L113.1 10.671H114.251V6.65869H113.288V8.98028L113.3 9.27828L113.118 8.98028L111.579 6.65869H110.371V10.671H111.333V8.26912H111.334ZM107.402 8.15499L107.701 7.40367L108 8.15499L108.365 9.04369H107.044L107.402 8.15499ZM109.033 10.671H110.142L108.389 6.65869H107.091L105.331 10.671H106.381L106.728 9.81086H108.682L109.033 10.671ZM104.731 10.671H104.784L105.189 9.8172H104.889C104.214 9.8172 103.815 9.40508 103.815 8.69392V8.63685C103.815 8.00071 104.173 7.52519 104.877 7.52519H105.945V6.65975H104.795C103.527 6.65975 102.835 7.49138 102.835 8.63157V8.70026C102.836 9.89223 103.545 10.6657 104.731 10.671ZM101.228 10.671H102.213V8.90526V6.65869H101.228V8.88201V10.671ZM99.1557 7.4787C99.5073 7.4787 99.6956 7.70166 99.6956 7.97747C99.6956 8.25221 99.5073 8.46989 99.1557 8.46989H97.999V7.4787H99.1557ZM97.999 9.2265H98.4632L99.6902 10.671H100.917L99.5495 9.19163C100.249 9.06588 100.665 8.57239 100.665 7.93097C100.665 7.17965 100.119 6.65869 99.2444 6.65869H97.0144V10.671H98.0001V9.2265H97.999ZM96.2916 7.47236V6.65869H93.0044V10.671H96.2916V9.85101H93.9912V9.05425H96.2386V8.25221H93.9912V7.47236H96.2916ZM88.1159 7.93203L88.0921 6.8489L89.2304 10.6721H90.123L91.2613 6.87215L91.2375 7.92674V10.671H92.194V6.65869H90.4574L89.6772 9.50124L88.8906 6.65869H87.1594V10.671H88.1159V7.93203ZM84.1903 8.15499L84.4901 7.40367L84.7898 8.15499L85.1533 9.04369H83.8333L84.1903 8.15499ZM85.8209 10.671H86.93L85.175 6.65869H83.8776L82.1172 10.671H83.1678L83.5141 9.81086H85.4682L85.8209 10.671Z"
                    fill="#006FCF"
                  />
                  <path
                    d="M61.1163 6.85449H55.2539V17.1437H61.1163V6.85449Z"
                    fill="#F16522"
                  />
                  <path
                    d="M55.6255 11.9997C55.6255 9.91269 56.6263 8.05394 58.1844 6.85563C57.044 5.97961 55.6071 5.45654 54.0436 5.45654C50.3431 5.45654 47.3438 8.3868 47.3438 12.0007C47.3438 15.6147 50.3431 18.545 54.0436 18.545C55.6071 18.545 57.044 18.0219 58.1844 17.1459C56.6263 15.9454 55.6255 14.0867 55.6255 11.9997Z"
                    fill="#E41B24"
                  />
                  <path
                    d="M69.0243 11.9986C69.0243 15.6126 66.0249 18.5428 62.3245 18.5428C60.7609 18.5428 59.324 18.0198 58.1836 17.1438C59.7417 15.9454 60.7426 14.0867 60.7426 11.9997C60.7426 9.91269 59.7417 8.05394 58.1836 6.85563C59.323 5.97961 60.7609 5.45654 62.3245 5.45654C66.0249 5.45549 69.0243 8.38574 69.0243 11.9986ZM68.3848 16.0543V15.844H68.4714V15.8017H68.2496V15.844H68.3361V16.0543H68.3848ZM68.8155 16.0543V15.8007H68.7473L68.6694 15.975L68.5915 15.8007H68.5233V16.0543H68.5709V15.863L68.6445 16.0289H68.6943L68.7679 15.863V16.0553H68.8155V16.0543Z"
                    fill="#F89E1C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="117" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </template>
          <template v-slot:content>
            <div
              v-show="paymentMode == 'card'"
              class="accordion-grabbed-content"
            >
              <!-- <form-credit-card></form-credit-card> -->
              <div class="overflow-hidden">
                <div class="row g-2 pt-3 pb-3">
                  <div class="col-md-6">
                    <div
                      id="card-number"
                      class="form-control stripe-input"
                    ></div>
                  </div>
                  <div class="col-md-6">
                    <div
                      id="card-expiry"
                      class="form-control stripe-input"
                    ></div>
                  </div>
                  <div class="col-md-12">
                    <div id="card-cvc" class="form-control stripe-input"></div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </accordion-grabbed-item>

        <!-- payment : virement -->
        <accordion-grabbed-item>
          <template v-slot:header>
            <div
              class="accordion-grabbed-header last"
              :class="paymentMode == 'virement' ? 'is-open' : ''"
            >
              <input
                type="radio"
                value="virement"
                name="paymentMode"
                id="for-virement"
                v-model="paymentMode"
              />
              <label for="for-virement"><h6 class="h6">Virement</h6></label>
            </div>
          </template>
          <template v-slot:content>
            <div
              class="accordion-grabbed-content"
              v-show="paymentMode == 'virement'"
            >
              <!-- <form-transfer></form-transfer> -->
              <div class="pt-3 pb-3 t1">
                <span class="d-block h6"> Virement à effectuer vers le compte :</span>
                 <div class="text-bold mb-2">
                    <span class="d-block">{{ibanU3dm.label}}</span>
                    <span class="d-block">{{ibanU3dm.iban}}</span>
                    <span class="d-block">{{ibanU3dm.rib}}</span>
                </div>
                <span class="d-block text-secondary">Préciser la référence de la commande dans le libellé du virement</span>
                <span class="d-block text-secondary">A la réception du virement,  votre commande passera en production et vous recevrez une notification par mail</span>
              </div>
            </div>
          </template>
        </accordion-grabbed-item>
      </div>
      <!-- accordion-grabbed for payment -->
    </div>

    <!-- adress bill -->
    <div>
      <h4 class="h4 mt-5 mb-4">Adresse de facturation</h4>

      <div class="accordion-grabbed">
        <accordion-grabbed-item v-if="modeDelivery.name != 'relay' && shippingConfiguration !== 'numerical' && shippingConfiguration !== 'pay'">
          <template v-slot:header>
            <div
              class="accordion-grabbed-header first border-bot-none"
              :class="billAdress == 'for-adress-idem' ? 'is-open' : ''"
            >
              <input
                type="radio"
                value="for-adress-idem"
                name="for-adress-idem"
                id="for-adress-idem"
                v-model="billAdress"
              />
              <label for="for-adress-idem">
                <h6 class="h6">Identique à l'adresse de livraison</h6>
              </label>
            </div>
          </template>
          <template v-slot:content> </template>
        </accordion-grabbed-item>

        <accordion-grabbed-item>
          <template v-slot:header>
            <div
              class="accordion-grabbed-header last"
              :class="billAdress == 'for-no-adress-idem' ? 'is-open' : ''"
            >
              <input
                type="radio"
                value="for-no-adress-idem"
                name="for-no-adress-idem"
                id="for-no-adress-idem"
                v-model="computedBillAdress"
              />
              
              <label for="for-no-adress-idem">
                <h6 class="h6">
                  
                  <span v-if="billAdress !== 'for-no-adress-idem' && shippingConfiguration !== 'numerical'">Utiliser une adresse de facturation différente</span>
                  <span v-if="billAdress === 'for-no-adress-idem' || shippingConfiguration === 'numerical'">Adresse de facturation</span>
                </h6>
              </label>
            </div>
          </template>
          <template v-slot:content>
            <div
              class="accordion-grabbed-content"
              v-if="billAdress == 'for-no-adress-idem' || shippingConfiguration === 'numerical'"
            >
              <form-billing ref="formBilling"></form-billing>
            </div>
          </template>
        </accordion-grabbed-item>
      </div>
      <!-- accordion-grabbed for bill adress -->
    </div>

    <!-- ------------------------------------------------------------ -->
    <div class="form-check mt-5">
      <input
        type="checkbox"
        v-model="cgvAccept"
        class="form-check-input"
        id="for-cgv"
      />
      <label for="for-cgv" class="form-check-label t1">
        J'accepte les
        <a :href="urlCgvCgu" target="_blank" class="link-accent"
          >conditions générales</a
        >
        d'utilisation et de vente
      </label>
    </div>

    <!-- action -->
    <div class="mt-2 mb-3">
      <button-loader
        @clickLoading="finalPayment"
        :title="'Payer maintenant'"
        :class="cgvAccept == false ? 'btn-u3dm--disabled' : ''"
        :isActive="cgvAccept"
        :isLoading="orderBtnPayIsLoading"
      ></button-loader>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ButtonLoader from "../ButtonLoader.vue";
import AccordionGrabbedItem from "./accordionGrabbed/AccordionGrabbedItem.vue";
import FormBilling from "./form/FormBilling.vue";

import { Iban } from '../../variables/variables.js'


export default {
  components: {
    AccordionGrabbedItem,
    FormBilling,
    ButtonLoader,
  },
  name: "FormOrderPayment",
  data() {
    return {
      urlCgvCgu: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_CGU_CGV,
      ibanU3dm: Iban,
      cgvAccept: false,
      paymentMode: "card",
      // billAdress: "a",
      billAdress: "for-no-adress-idem",
      // new - varibale initialiser dans le code, mais pas ici dans les data
      token: null,
      // stripeError: null,

      // ----------------
      // elements
      stripe: null,
      cardNumber: null,
      cardCvc: null,
      cardExpiry: null,

      iban: null,
      card: {
        cvc: "",
        number: "",
        expiry: "",
      },

      // errors
      stripeError: "",
      cardCvcError: "",
      cardExpiryError: "",
      cardNumberError: "",

      // message
      errorMessages: {
        invalid_expiry_year_past: "La carte est expirée",
        incorrect_number: "Numéro de carte erronée",
        invalid_number: "Numéro de carte erronée",
        invalid_expiry_month: "The card's expiration month is invalid.",
        invalid_expiry_year: "The card's expiration year is invalid.",
        invalid_cvc: "The card's security code is invalid.",
        expired_card: "The card has expired.",
        incorrect_cvc: "The card's security code is incorrect.",
        incorrect_zip: "The card's zip code failed validation.",
        card_declined: "The card was declined.",
        missing: "There is no card on a customer that is being charged.",
        processing_error: "An error occurred while processing the card.",
        rate_limit:
          "An error occurred due to requests hitting the API too quickly. Please let us know if you're consistently running into this error.",
      },

      errorsMessageFrench: {
        incomplete_cvc: "Vérifiez le code de sécurité de la carte",
        incomplete_number: "Le numéro de carte est incomplet",
        invalid_expiry_year_past:
          "L’année d’expiration de la carte est incorrecte. Vérifiez la date d'expiration ou utilisez une autre carte.",
        //
        authentication_required:
          "La carte a été refusée car la transaction nécessite une authentification.",
        expired_card:
          "La carte est expirée. Vérifiez la date d'expiration ou utilisez une autre carte.",
        incorrect_number:
          "Le numéro de carte est incorrect. Vérifiez le numéro de la carte ou utilisez une autre carte.",
        incorrect_cvc:
          "Le code de sécurité de la carte est incorrect. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.",
        incorrect_zip:
          "Le code postal de la carte est incorrect. Vérifiez le code postal de la carte ou utilisez une autre carte.",
        invalid_cvc:
          "Le code de sécurité de la carte n’est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une autre carte.",
        invalid_expiry_month:
          "Le mois d'expiration de la carte est incorrect. Vérifiez la date d'expiration ou utilisez une autre carte.",
        invalid_expiry_year:
          "L’année d’expiration de la carte est incorrecte. Vérifiez la date d'expiration ou utilisez une autre carte.",
        invalid_number:
          "Le numéro de carte n'est pas valide. Vérifiez les numéro de la carte ou utilisez une autre carte.",
        processing_error:
          "Une erreur s'est produite lors du traitement de la carte. Vérifiez que les détails de la carte sont corrects ou utilisez une autre carte.",
      },
    };
  },
  computed: {
    ...mapState("order", {
      shipping: (state) => state.shipping,
      billing: (state) => state.billing,
      savingShippingForLater: (state) => state.savingShippingForLater,
      modeDelivery: (state) => state.modeDelivery,
      orderId: (state) => state.orderId,
      priceDelivery: (state) => state.priceDelivery,
      relayAddress: (state) => state.relayAddress,
      shippingRelayIdentifier: (state) => state.shippingRelayIdentifier,
      promoCode: (state) => state.promoCode,
      discountAmountTaxIncl: (state) => state.discountAmountTaxIncl,
      discountAmountTaxExcl: (state) => state.discountAmountTaxExcl,
    }),
    ...mapState("orderManager", {
      orderBtnPayIsLoading: (state) => state.orderBtnPayIsLoading,
      needShipping: (state) => state.needShipping,
      shippingConfiguration: (state) => state.shippingConfiguration,
      billingOrderSell: (state) => state.billing,
      isDeposit: (state) => state.isDeposit,
      deposit: (state) => state.deposit,
    }),
    ...mapState("shop", {
      totalAmountTaxIncl: (state) => state.totalAmountTaxIncl,
      listProductsProject: (state) => state.listProductsProject,
    }),
    ...mapState("authentication", {
      customerId: (state) => state.id,
      firstname: (state) => state.firstname,
      lastname: (state) => state.lastname,
      email: (state) => state.email,
    }),
    computedBillAdress: {
      get() {
        if(this.shippingConfiguration === 'numerical') {
          return 'for-no-adress-idem'
        }
/*        if(this.billingOrderSell !== null) {
          return 'for-no-adress-idem'
        }*/
        return this.billAdress
      },
      set(newValue) {
        this.billAdress = newValue
        return newValue
      }
    }
  },
  watch: {
    modeDelivery() {

      if (this.modeDelivery.name == 'relay') {
        this.billAdress = "for-no-adress-idem";
      } else {
        this.billAdress = "for-adress-idem";
      }
      if(this.shippingConfiguration === 'numerical'  || this.billingOrderSell !== null) {
        this.billAdress = "for-no-adress-idem";
      }
      if(this.shippingConfiguration === 'pay') {
        this.billAdress = "for-no-adress-idem";
      }

    }
  },
  mounted() {
    this.setUpStripe();
  },
  beforeMount() {

    if (this.$route.params.orderSellId != undefined && this.$route.params.reference != undefined) {
      this.billAdress = "for-no-adress-idem";
    } else {
      this.billAdress = "for-adress-idem";
    }
    if(this.shippingConfiguration === 'numerical') {
      this.billAdress = "for-no-adress-idem";
    }

  },
  methods: {
    ...mapActions("orderManager", [
      "updateStep",
      "updatePaymentThanks",
      "updateOrderReference",
      "updateOrderBtnPayIsLoading",
    ]),
    ...mapActions("order", [
      "updateOrderId"
    ]),
    setUpStripe() {
      let stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
      this.stripe = stripe;
      const elements = stripe.elements();

      this.cardCvc = elements.create("cardCvc");
      this.cardExpiry = elements.create("cardExpiry");
      this.cardNumber = elements.create("cardNumber");

      this.cardCvc.mount("#card-cvc");
      this.cardExpiry.mount("#card-expiry");
      this.cardNumber.mount("#card-number");

      this.listenForErrors();
    },
    listenForErrors() {
      this.cardNumber.addEventListener("change", (event) => {
        this.toggleError(event);
        this.cardNumberError = "";
        this.card.number = event.complete ? true : false;
      });

      this.cardExpiry.addEventListener("change", (event) => {
        this.toggleError(event);
        this.cardExpiryError = "";
        this.card.expiry = event.complete ? true : false;
      });

      this.cardCvc.addEventListener("change", (event) => {
        this.toggleError(event);
        this.cardCvcError = "";
        this.card.cvc = event.complete ? true : false;
      });
    },
    toggleError(event) {
      if (event.error) {
        // let key = event.error
        // console.log(event.error)
        this.stripeError = this.errorsMessageFrench[event.error.code];
        // console.log(key)
        // console.log(this.errorsMessageFrench[event.error.code])
      } else {
        this.stripeError = "";
      }
    },
    prev(id) {
         // Google Tag Manager : push event order form
            //******************************************** */
            if (id == 1) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.1.3.Shipping_type_modify')
            }
            //******************************************** */
      this.updateStep(id);
    },
    finalPayment() {
      let billAdressReady = false;
      this.updateOrderBtnPayIsLoading(true);

       // Google Tag Manager : push event order form
            //******************************************** */
                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.4.1.Payment_started')
            //******************************************** */
      
      // a ton besoin de 'billing'
      // non
      if (this.billAdress == "for-adress-idem") {
        billAdressReady = true;
      }

      // a ton besoin de 'billing'
      // oui
      if (this.billAdress == "for-no-adress-idem" || this.modeDelivery.name == 'relay' || this.shippingConfiguration === 'numerical') {
        if (this.$refs.formBilling.saveBilling()) {
          billAdressReady = true;
        } else {
          billAdressReady = false;
          this.updateOrderBtnPayIsLoading(false);
        }
      }

      if (billAdressReady) {
        let valid = true;
        // let champsManquant = ''

        if (this.paymentMode == "card") {
          this.updateOrderBtnPayIsLoading(true);

          if (!this.card.number) {
            valid = false;
            this.cardNumberError = "Numéro incorrect";
            // champsManquant = champsManquant + ' N° carte'
          }
          if (!this.card.cvc) {
            valid = false;
            this.cardCvcError = "CVC incorrect";
            // champsManquant = champsManquant + ' CVC'
          }
          if (!this.card.expiry) {
            valid = false;
            this.cardExpiryError = "Date incorrecte";
            // champsManquant = champsManquant + ' Date'
          }
        }


          // Google Tag Manager : push event order form
            //******************************************** */
                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.3.1.Payment_CB_started')
            //******************************************** */

        if (this.stripeError) {
          // console.log(this.stripeError)
          valid = false;
        }

        if (valid) {
          // console.log('cree le token')
          this.createToken();
        } else {
          // console.log('erreur, pas de creation de token')
          this.$refs.errorFormCardExplain.scrollIntoView({
            behavior: "smooth",
          });
          this.updateOrderBtnPayIsLoading(false);
        }
      }
    },
    createToken() {
      // console.log("API Payment Create => createtoken");

      if (this.paymentMode == "card") {
        this.stripe
          .createPaymentMethod({
            type: "card",
            card: this.cardNumber,
            billing_details: {
              name: this.firstname + " " + this.lastname,
            },
          })
          .then((result) => {
            if (result.error) {
              // console.log(result);
              // this.errorPayment = true; non utiliser
              this.stripeError = result.error.message;
            } else {
              // console.log(result.result);
              this.token = result.paymentMethod.id;
              this.thirdDSecure(this.token);

            // Google Tag Manager : push event order form
              //******************************************** */
                  this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.4.2.Payment_done')
              //******************************************** */
            }
          });
      } else if (this.paymentMode == "virement") {
        this.makerOrder(null, null);
         // Google Tag Manager : push event order form
            //******************************************** */
                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.3.2.Payment_Virement_started')
            //******************************************** */
      
      }
    },
    thirdDSecure(paymentMethod) {
      let amount = this.totalAmountTaxIncl

      if(this.needShipping) {
        amount += this.priceDelivery
      }

      if(this.isDeposit) {
        amount = amount * this.deposit / 100
      }

      if(this.discountAmountTaxIncl) {
        amount -= this.discountAmountTaxIncl
      }
      let dataThird = {
        thirdDSecure: paymentMethod,
        amount: amount,
        orderType: "print",
      };

      dataThird.address = {}
      dataThird.address.city = this.shipping.city
      dataThird.address.country = 'FR'
      dataThird.address.line1 = this.shipping.adress
      dataThird.address.line2 = this.shipping.adress2
      dataThird.address.postal_code = this.shipping.zipcode

      if (this.customerId == '') {
        dataThird.token = this.$route.query.token
      } else {
        dataThird.user_id = this.customerId
      }
      
      this.$http
        .post(process.env.VUE_APP_API_BASE_URL + "third-3-D-secure", dataThird)
        .then(
          (response) => {
            // console.log("API 3D Secure => success", response);

            var dataThird = JSON.parse(response.body);

            if (dataThird.error) {
              // Show error from server on payment form
              //console.log('3D Secure En erreur',dataThird);
              this.stripeError =
                "Echec dans l'authentification de votre carte. Le paiement a été annulé (" +
                dataThird.error +
                ")";
              this.updateOrderBtnPayIsLoading(false);
            } else if (dataThird.requires_action) {
              // Use Stripe.js to handle required card action
              //console.log('3D secure required');
              this.stripe
                .handleCardAction(dataThird.payment_intent_client_secret)
                .then( (result) => {
                  if (result.error) {
                    //console.log('3D secure error sur le handleCardAction %s',dataThird.payment_intent_id);
                    //this.stripeError = result.error.message;
                    this.stripeError =
                      "Echec dans l'authentification de votre carte. Le paiement a été annulé ";
                    this.updateOrderBtnPayIsLoading(false);
                    //this.cancelPayment(dataThird.payment_intent_id)
                    // Show error in payment form
                  } else {
                    let token = JSON.parse(response.body);
                    this.makerOrder(
                      token.payment_intent_id,
                      token.payment_method_id
                    );
                  }
                });
            } else {
              this.makerOrder(
                dataThird.payment_intent_id,
                dataThird.payment_method_id
              );
            }
          },
          () => {
            // console.log("API 3D Secure => error Et rien ne se passe", response);
            this.updateOrderBtnPayIsLoading(false);
          }
        );
      // console.log("API Payment Create => thirdEnd");
    },
    makerOrder(token = null, paymentMethodId = null) {
      // console.log("API Payment Create => makerOrder");
      this.token = token;

      let formData = new FormData();

      if ( this.$route.params != undefined && this.$route.params.orderSellId != undefined) {
        this.updateOrderId(this.$route.params.orderSellId)
      }
      // console.log('ici : debug this.shipping')
      // console.log(this.shipping)

      formData.append("order_id", this.orderId);
      if(this.needShipping) {
        formData.append("amounts[shipping_amount_tax_incl]", this.priceDelivery);
      }
      if(this.promoCode && this.discountAmountTaxIncl && this.discountAmountTaxExcl) {
        formData.append("amounts[discount_amount_tax_incl]", this.discountAmountTaxIncl);
        formData.append("amounts[discount_amount_tax_excl]", this.discountAmountTaxExcl);
        formData.append("coupon", this.promoCode);
      }
      formData.append("shipping_type", this.modeDelivery.name);


      if (this.modeDelivery.name == 'relay') {
   
        formData.append('relay_address[firstname]', this.relayAddress.firstname)
        formData.append('relay_address[lastname]', this.relayAddress.lastname)
        formData.append('relay_address[street1]', this.relayAddress.adress)
        formData.append('relay_address[street2]', this.relayAddress.adress2)
        formData.append('relay_address[zipcode]', this.relayAddress.zipcode)
        formData.append('relay_address[city]', this.relayAddress.city)
        formData.append('relay_address[enterprise]', this.relayAddress.enterprise)
        formData.append('relay_address[country]', 'FR')
        formData.append('relay_address[telephone]', '')
        formData.append("shipping_relay_identifier", this.shippingRelayIdentifier);

      } else if(this.shippingConfiguration !== 'numerical') {
        formData.append("shipping_address[firstname]", this.shipping.firstname);
        formData.append("shipping_address[lastname]", this.shipping.lastname);
        formData.append("shipping_address[street1]", this.shipping.adress);
        formData.append("shipping_address[street2]", this.shipping.adress2 !== null ? this.shipping.adress2 : "");
        formData.append("shipping_address[zipcode]", this.shipping.zipcode);
        formData.append("shipping_address[city]", this.shipping.city);
        formData.append("shipping_address[country]", "FR");
        formData.append("shipping_address[telephone]", this.shipping.phone);
        formData.append("shipping_address[enterprise]", this.shipping.enterprise !== null ? this.shipping.enterprise : "");
        formData.append('save_address', this.savingShippingForLater)
      }

      

      if (this.billAdress == 'for-no-adress-idem' || this.modeDelivery.name == 'relay' || this.shippingConfiguration === 'numerical') {
        formData.append("billing_address[firstname]", this.billing.firstname);
        formData.append("billing_address[lastname]", this.billing.lastname);
        formData.append("billing_address[street1]", this.billing.adress);
        let billingAddress2 = '';
        if(this.billing.adress2 == null) {
          billingAddress2 = ''
        } else {
          billingAddress2 = this.billing.adress2
        }
        formData.append("billing_address[street2]", billingAddress2);
        formData.append("billing_address[zipcode]", this.billing.zipcode);
        formData.append("billing_address[city]", this.billing.city);
        formData.append("billing_address[country]", "FR");
        formData.append("billing_address[telephone]", this.billing.phone);
        formData.append("billing_address[enterprise]", this.billing.enterprise !== null ? this.billing.enterprise : "");
      }

      

      // amounts[shipping_amount_tax_excl] = 10
      // amounts[shipping_amount_tax_incl] = 12
      let theToken = ''
      if (this.$route.query.token != undefined) {
        theToken = '/' + this.$route.query.token
      }

      this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + "payment/placeOrder"+ theToken,
          formData,
          { credentials: true }
        )
        .then(
          () => {
            // console.log("API Order Create => success", response);
            this.validateOrder(this.token, paymentMethodId);
            this.updateOrderBtnPayIsLoading(false);
          },
          () => {
            // console.log("API Order Create => error", response);
            this.updateOrderBtnPayIsLoading(false);
          }
        );
    },
    validateOrder(token) {
      // console.log("Function validateOrder");

      let formData = new FormData();
      formData.append("order_id", this.orderId);
      formData.append("type", this.paymentMode);
      formData.append("token", token);

      this.$http
        .post(process.env.VUE_APP_API_BASE_URL + "payment/save", formData, {
          credentials: true,
        })
        .then(
          (response) => {
            // console.log("API Payment Create => success", response);
            this.updateOrderReference(response.body.reference);
            this.updatePaymentThanks(true);
            this.updateOrderBtnPayIsLoading(false);
          },
          () => {
            // console.log("API Payment Create => error", response);
            this.updateOrderBtnPayIsLoading(false);
          }
        );
    },
    // cancelPayment(paymentIntentId) {
    //   console.log("Function Cancel Payment intent");

    //   let data = {
    //     cancel_intent: true,
    //     payment_id: paymentIntentId,
    //   };
     
    //   this.$http.post(process.env.VUE_APP_API_BASE_URL + "payment/save", data, {
    //       credentials: true,
    //     }).then(
    //     (response) => {
    //       console.log('API Payment cancel => success',response)
    //       var data = JSON.parse(response.body);
    //       console.log(data)
    //     },
    //     (response) => {
    //       console.log('API Payment Cancel => error :',response)

    //       this.updateOrderBtnPayIsLoading(false);
    //       // this.errorPayment = true;
    //       this.stripeError = "Erreur sur la confirmation de la carte. Vous ne serez pas débité"; //data.message
    //     }
    //   );
    // },
    // clearElementsInput() {
    //   this.cardNumber.clear()
    //   this.cardExpiry.clear()
    //   this.cardCvc.clear()
    // },
    // clearCardsErrors() {
    //   this.stripeError = ""
    //   this.cardNumberError = ""
    //   this.cardExpiryError = ""
    //   this.cardCvcError = ""
    // },
    // clearIbanErrors() {
    //   this.stripeError = ""
    // },
    // reset() {
    //   this.clearElementsInput()
    // },
  },
  beforeDestroy() {
    this.updateOrderBtnPayIsLoading(false);
  },
  filters: {
    ucFirst(str) {
      let word = "";
      if (str) {
        word = (str + "").charAt(0).toUpperCase() + str.substr(1);
      }

      return word;
    },
  },
};
</script>