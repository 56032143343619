<template>
  <div 
    class="a-accordion-header" 
    :class="[lastChildActive == 4 ? 'theme--active' : '', stepNextPossible >= 4 ? '' : 'theme--disabled']"
    >
    <icon-option :width="32" :color="lastChildActive == 4 ? '#7F1F23' : '#161616'"></icon-option>
    <h4 class="h4" v-if="color.id == null && layer.id == null && finishing.id == null">Choisir vos options</h4>
    <h4 class="h4" v-else>
      <span class="pe-1">{{ color.name }}</span>
      <span v-if="layer.id !== null"> | {{ layer.layerLevel }} </span>
      <span class="ps-1" v-if="finishing.id !== null"> | {{ finishing.name }}</span>
    </h4>
    <span class="d-inline-block tooltip-print" tabindex="0" data-bs-toggle="tooltip" title="Selon le matériau choisi, vous aurez des choix d’options différents : les couleurs, la qualité (la hauteur de couche) et les finitions.">
      <i class="bi bi-question-circle"></i>
    </span>
    <span class="t3 color-secondary" v-if="color.name !== null">modifier</span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import IconOption from '../icon/IconOption.vue';

export default {
  name: "AccordHeaderStep1",
  components: { IconOption  },
  computed: {
    ...mapState("print", {
      color: (state) => state.printFile.color,
      layer: (state) => state.printFile.layer,
      finishing: (state) => state.printFile.finishing
    }),
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      stepNextPossible: (state) => state.stepNextPossible
    }),
  },
};
</script>