<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <div v-if="isRdvDone !== true">
              <h4 class="h4 text-center">Merci pour votre inscription</h4>
              <p class="mt-5">Prochaine étape : Prendre un rendez-vous avec United 3D Makers.</p>
              <div v-if="rdvDate">
                Prochain rendez vous avec United 3D Maker le {{rdvDate}}. <a v-if="rdvGoogleMeetLink" target="_blank" :href="rdvGoogleMeetLink">Lien visio Google Meet.</a>
              </div>
              <div v-if="rdvWaiting">
                En attente de confirmation du rendez-vous.
              </div>
            </div>

            <div class="d-flex justify-content-center mt-5">
              <router-link to="/maker/mon-rendez-vous" :class="rdvDate ? 'element-to-complete-green' : ''" class="elementToComplete">
                Mon rendez-vous <span v-if="rdvDate">- {{ rdvDate }}</span>
              </router-link>
              <div class="elementToComplete elementToComplete-disabled">
                Mes technologies
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <div to="#" class="elementToComplete elementToComplete-disabled">
                Ma société
              </div>
              <div v-if="this.isPrinter" to="#" class="elementToComplete elementToComplete-disabled">
                Mes prix d'impressions
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu},
  name: "MakerAccount",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Tableau de bord',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      urlBase: process.env.VUE_APP_APP_BASE_URL,
      rdvGoogleMeetLink: null,
      rdvDate: null,
      rdvWaiting: false,
      isRdvDone: true,
    }
  },
  computed: {
    ...mapState("authentication", {
      isMaker: (state) => state.isMaker,
      isPrinter: (state) => state.isPrinter,
      isDesigner: (state) => state.isDesigner,
      firstname: (state) => state.firstname,
      lastname: (state) => state.lastname,
      email: (state) => state.email,
      isChecked: (state) => state.isChecked,
    })
  },
  beforeMount() {
    if(this.isChecked) {
      this.$router.replace({name: 'maker-dashboard'})
    }
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'rdv/get', {credentials: true})
        .then(({body}) => {
          this.isRdvDone = body.rendezvous.done !== undefined ? body.rendezvous.done : false
        })
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'rdv/next', {credentials: true})
        .then(({body}) => {
          if(body === true) {
            this.rdvWaiting = true
          } else {
            this.rdvDate = body.date
            let test = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(body.google_meet_link)
            if(test) {
              this.rdvGoogleMeetLink = body.google_meet_link
            } else {
              this.rdvGoogleMeetLink = 'https://' + body.google_meet_link
            }
          }
        })
  },
  methods: {
    ...mapActions("authentication", [
      "logout",
      "setConnected",
    ]),
    logoutAccount: function () {
      this.logout()
          .then(() => {
            window.location.href = process.env.VUE_APP_WWW_BASE_URL
          })
    },
  }
}
</script>
