<template>
    <div class="accord-layers-container pl-5">
        <h6 class="h6">Qualité de l'impression</h6>

        <div class="layers-cards-container">
            <accord-layers-item 
                v-for="(item, index) in sortLayersItemsByPrice" 
                :key="index" :item="item"
                class="print-appearLayer"
                :class="['print-appearLayer-item--'+(index + 1)]"></accord-layers-item>
        </div>
    </div>
</template>

<script>
import AccordLayersItem from './AccordLayersItem.vue';

import { mapState, mapGetters } from "vuex";

export default {
    components: { AccordLayersItem },
    name: "AccordLayers",

    computed: {
    ...mapState("print", {
      layersList: (state) => state.printFile.layersList,
      layer: (state) => state.printFile.layer
    }),
    ...mapGetters("print", {
      sortLayersItemsByPrice: 'sortLayersItemsByPrice',
    })
    }
  }

</script>