<template>
  <div
    class="
      accord-color-container
      d-flex
      flex-wrap
      pl-5
    "
  >
    <accord-colors-item
      v-for="(item, index) in sortColorsItemsByPrice"
      :key="index"
      :item="item"
    ></accord-colors-item>
  </div>
</template>

<script>
import AccordColorsItem from "./AccordColorsItem.vue";

import { mapGetters } from "vuex";

export default {
  components: { AccordColorsItem },
  name: "AccordColors",
  computed: {
    ...mapGetters("print", {
      sortColorsItemsByPrice: 'sortColorsItemsByPrice',
    })
  },
};
</script>