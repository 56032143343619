<template>
  <div class="order-view">

    <sidebar-order></sidebar-order>

    <div v-if="!paymentThanks" class="container">
      <h4 class="h4 visible-mobile-only text-center m-0 pt-4 pb-4">
        Finaliser votre commande
      </h4>

      <div class="order-inner row h-100">
        <accordion-order></accordion-order>

        <product-order></product-order>
      </div>
      
    </div>
    
    <payment-thanks v-if="paymentThanks"></payment-thanks>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AccordionOrder from "../components/order/AccordionOrder.vue";
import PaymentThanks from '../components/order/PaymentThanks.vue';
import ProductOrder from "../components/order/ProductOrder.vue";
import sidebarOrder from "../components/order/sidebarOrder.vue";
export default {
  components: { sidebarOrder, AccordionOrder, ProductOrder, PaymentThanks },
  name: "Order",
  computed: {
    ...mapState("orderManager", {
      paymentThanks: (state) => state.paymentThanks
    })
  },  
  methods: {
    ...mapActions('orderManager', [
      "updateStep",
      "updateShippingConfiguration",
      "updatePaymentThanks",
      "updateOrderBtnPayIsLoading",
      "updateNeedShipping",
      "updateAddressLabel",
      "updateDeposit",
      "updateIsDeposit",
      "updateTimeline",
      "updateIsQuotation",
    ]),
    ...mapActions('order', [
      "updateOrderId"
    ]),
    ...mapActions("shop", [
      "addProductPrint", 
      "addProductProject",
      "updateProductProjectName",
      "updateTotalAmountIncl", 
      "updateTotalAmountExcl", 
      "updateFeeShowAmountTaxExcl","updateFeeShowDescription",
      "resetBasket"
    ]),
  },
  beforeMount() {
    this.updateOrderBtnPayIsLoading(false)

    if (this.$route.params.orderSellId != undefined && this.$route.params.reference != undefined) {
      let getQuotationUrl
      if (this.$route.query.token != undefined) {
        getQuotationUrl = process.env.VUE_APP_API_BASE_URL + 'design/quotation/' + this.$route.params.reference + '/' + this.$route.query.token
      } else {
        getQuotationUrl = process.env.VUE_APP_API_BASE_URL + 'design/quotation/' + this.$route.params.reference
      }

      this.$http.get(getQuotationUrl, {credentials: true})
          .then((response) => {
            // console.log(response)
            this.addProductProject(response.body.lines)
            this.updateProductProjectName(response.body.name)
            this.updateTotalAmountIncl(response.body['totalTaxIncl'])
            this.updateTotalAmountExcl(response.body['totalTaxExcl'])
            this.updateOrderId(response.body['orderSellId'])
            this.updateIsDeposit(response.body.order_sell.is_deposit)
            if(response.body.order_sell.is_deposit) {
              this.updateDeposit(response.body.order_sell.deposit)
              if(response.body.order_sell.timeline !== undefined) {
                this.updateTimeline(response.body.order_sell.timeline)
              }
            }
          }, () => {
            // console.log(error)
          })

    } else {
      let getOrdersUrl
      if (this.$route.query.token != undefined) {
        getOrdersUrl = process.env.VUE_APP_API_BASE_URL + 'application/get-orders/' + this.$route.query.token
      } else {
        getOrdersUrl = process.env.VUE_APP_API_BASE_URL + 'application/get-orders'
      }

      this.$http.get(getOrdersUrl, {credentials: true})
          .then((response) => {
            // console.log(response)
            this.addProductPrint(response.body.items)
            this.updateFeeShowAmountTaxExcl(response.body['fee_show_amount_tax_excl'])
            if (response.body["fee_show_description"] != undefined) {
              this.updateFeeShowDescription(response.body["fee_show_description"]);
            }
          
            this.updateTotalAmountIncl(response.body['total_amount_tax_incl'])
            this.updateTotalAmountExcl(response.body['total_amount_tax_excl'])
            this.updateOrderId(response.body['id'])
          }, () => {
            // console.log(error)
          })
    }

    if (this.$route.params.orderSellId != undefined && this.$route.params.reference != undefined) {

      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'payment/shipping/' + this.$route.params.orderSellId)
          .then((response) => {

            if (response.body.length == 0) {
              this.updateNeedShipping(false)
              this.updateStep(3)
              this.updateShippingConfiguration('numerical')
            } else if(response.body['shipping_configuration'] === 'pay') {
              this.updateNeedShipping(false)
              this.updateStep(3)
              this.updateShippingConfiguration(response.body['shipping_configuration'])
              if(response.body['isQuotation']) {
                this.updateIsQuotation(true)
              }
            } else if(response.body['shipping_configuration'] === 'colissimo') {
              this.updateNeedShipping(false)
              this.updateStep(1)
              this.updateShippingConfiguration(response.body['shipping_configuration'])
              if(response.body['isQuotation']) {
                this.updateIsQuotation(true)
              }
            } else if(response.body['shipping_configuration'] === 'chronopost') {
              this.updateNeedShipping(false)
              this.updateStep(1)
              this.updateShippingConfiguration(response.body['shipping_configuration'])
              if(response.body['isQuotation']) {
                this.updateIsQuotation(true)
              }
            } else if(response.body['shipping_configuration'] === 'maker') {
              this.updateNeedShipping(false)
              this.updateShippingConfiguration(response.body['shipping_configuration'])
              this.updateStep(2)
            } else if(response.body['shipping_configuration'] === 'ask_address') {
              this.updateNeedShipping(false)
              this.updateShippingConfiguration(response.body['shipping_configuration'])
              this.updateAddressLabel(response.body['address_label'])
              this.updateStep(2)
            } else {
              this.updateNeedShipping(true)
              this.updateStep(1)
            }
          })
    } else {
      this.updateNeedShipping(true)
      this.updateStep(1)
    }
  },
  beforeDestroy() {
    this.updateStep(1)
    this.updatePaymentThanks(false)
    this.updateOrderBtnPayIsLoading(false)
    this.updateNeedShipping(true)
    this.resetBasket()

    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'application/get-orders', {credentials: true})
        .then((response) => {
            this.addProductPrint(response.body.items)
            this.updateFeeShowAmountTaxExcl(response.body['fee_show_amount_tax_excl'])
            if (response.body["fee_show_description"] != undefined) {
              this.updateFeeShowDescription(response.body["fee_show_description"]);
            }
          
            this.updateTotalAmountIncl(response.body['total_amount_tax_incl'])
            this.updateTotalAmountExcl(response.body['total_amount_tax_excl'])
            this.updateOrderId(response.body['id'])
            }, () => {
            // console.log(error)
        })
  },
};
</script>