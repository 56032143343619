<template>
    <svg :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5217 24H5.33543C4.04789 24 3 22.9487 3 21.6569V2.34353C3 1.05132 4.04789 0 5.33543 0H13.4727C13.4732 0 13.4736 0 13.4745 0C13.4749 0 13.4754 0 13.4758 0C13.5919 0.000441176 13.7014 0.0291176 13.7977 0.0794118C13.8522 0.107647 13.9032 0.143382 13.949 0.185735L21.6294 7.25691C21.7745 7.39059 21.8571 7.57941 21.8571 7.77706V21.6569C21.8571 22.9487 20.8093 24 19.5217 24ZM5.33543 1.41176C4.82358 1.41176 4.40715 1.83 4.40715 2.34353V21.6565C4.40715 22.1704 4.82358 22.5882 5.33543 22.5882H19.5217C20.0336 22.5882 20.45 22.1704 20.45 21.6569V8.08721L13.1992 1.41176H5.33543Z" :fill="color"/>
        <path d="M12.0614 16.0068C11.9484 16.0068 11.8353 15.9795 11.7324 15.9248L6.88833 13.3527C6.6579 13.2305 6.51367 12.9905 6.51367 12.7289C6.51367 12.4673 6.6579 12.2273 6.88833 12.105L11.7324 9.53299C11.9382 9.42357 12.1849 9.42357 12.3907 9.53299L17.2344 12.105C17.4648 12.2273 17.6091 12.4673 17.6091 12.7289C17.6091 12.9905 17.4648 13.2305 17.2344 13.3527L12.3907 15.9248C12.2878 15.9795 12.1744 16.0068 12.0614 16.0068ZM8.72158 12.7289L12.0614 14.5024L15.4012 12.7289L12.0614 10.9553L8.72158 12.7289Z" :fill="color"/>
        <path d="M12.061 21.5254C11.9352 21.5254 11.8099 21.4915 11.6987 21.4244C11.4871 21.2969 11.3574 21.0671 11.3574 20.8196V15.3009C11.3574 15.0393 11.5017 14.7993 11.7321 14.6771L16.5758 12.105C16.7939 11.9894 17.0564 11.9965 17.2679 12.124C17.4794 12.2519 17.6087 12.4813 17.6087 12.7288V18.2387C17.6087 18.4999 17.4649 18.7394 17.2349 18.8621L12.3912 21.4429C12.2879 21.4981 12.1744 21.5254 12.061 21.5254ZM12.7646 15.7257V19.6452L16.2011 17.8143V13.901L12.7646 15.7257Z" :fill="color"/>
        <path d="M12.0614 21.5255C11.9479 21.5255 11.8349 21.4981 11.7316 21.443L6.88745 18.8621C6.65747 18.7394 6.51367 18.4999 6.51367 18.2387V12.7289C6.51367 12.4814 6.64295 12.2519 6.85447 12.124C7.06598 11.9961 7.3285 11.989 7.54661 12.1046L12.3907 14.6766C12.6211 14.7989 12.7654 15.0389 12.7654 15.3005V20.8191C12.7654 21.0671 12.6361 21.2965 12.4241 21.424C12.3129 21.4915 12.1871 21.5255 12.0614 21.5255ZM7.92126 17.8143L11.3582 19.6452V15.7258L7.92126 13.9011V17.8143Z" :fill="color"/>
        <path d="M10.7212 7.92922H7.13912C6.7504 7.92922 6.43555 7.61334 6.43555 7.22334C6.43555 6.83334 6.7504 6.51746 7.13912 6.51746H10.7212C11.1099 6.51746 11.4248 6.83334 11.4248 7.22334C11.4248 7.61334 11.1095 7.92922 10.7212 7.92922Z" :fill="color"/>
        <path d="M9.48687 5.84829H7.13912C6.7504 5.84829 6.43555 5.53241 6.43555 5.14241C6.43555 4.75241 6.7504 4.43652 7.13912 4.43652H9.48687C9.87559 4.43652 10.1904 4.75241 10.1904 5.14241C10.1904 5.53241 9.87515 5.84829 9.48687 5.84829Z" :fill="color"/>
        <path d="M21.154 8.48299H14.6863C13.6297 8.48299 12.7695 7.62049 12.7695 6.5599V0.705933H14.1767V6.5599C14.1767 6.84181 14.4053 7.07123 14.6863 7.07123H21.154V8.48299Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'IconFile',
    props: {
        color: {
            type: String,
            default: 'inherit'
        },
        width: {
            type: Number,
            default: 24
        },
    }
}
</script>