<template>

<div class="mt-4">


  <div v-if="infoTechnologyVisible == true && technologySelect != undefined " >
    
    <h4 class="h4 ps-3 mt-3"> Impression en {{technologySelect.name}}</h4>
    <div class="a-info-print__content" >

      <p class="material-carousel-description t1" v-html="technologySelect.description"></p>
      <p class="material-carousel-description-alert t1" v-html="technologySelect.technologyMinOrder"></p>
      <img height="180" :src="pictureTechnologyBaseUrl + technologySelect.id" alt="">
      <br>
    </div>
  </div>

  <div v-if="infoMaterialVisible == true  && item != undefined">
    
    <h4 class="h4 ps-3 mt-3">{{ item.name }}</h4>
    <div class="a-info-print__content" >
      <p class="t3 color-secondary mt-2" v-html="item.description"></p>
      <div v-if="technology != undefined " >
        <p class="material-carousel-description-alert t1" v-html="technology.technologyMinOrder"></p>
      </div>
   
      <img height="180" :src="pictureMaterialBaseUrl + item.id" alt="">
      <div class="material-carousel-color">
        <h6 class="material-carousel-color-title h6">Couleurs disponibles</h6>
        <div>
          <span v-for="(color, index) in item.colors" :key="index">
            <img v-if="color['hexadecimal_code'] === 'multicouleurs1'" :src="require('@/assets/images/print/color.png')" alt="Multicouleurs" class="material-carousel-color-circle">
            <img v-if="color['hexadecimal_code'] === 'multicouleurs2'" :src="require('@/assets/images/print/color_uhd.png')" alt="Multicouleurs UHD" class="material-carousel-color-circle">
            <span v-if="color['hexadecimal_code'] !== 'multicouleurs1' && color['hexadecimal_code'] !== 'multicouleurs2'"
                :style="{background: color['hexadecimal_code']}"
                class="material-carousel-color-circle"
            ></span>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div v-if="infoLayerVisible == true">
    <h4 class="h4 ps-3 mt-3"> Hauteur de couche  : {{ layerInfoShow.layerLevel }}  </h4>  
    <div class="a-info-print__content" >
        <img height="180" :src="pictureLayerBaseUrl + layerInfoShow.technologyId + '/' + layerInfoShow.layerId" alt="">
        <p class="material-carousel-description t1" v-html="layerInfoShow.layerDescription"></p>
      </div>  
  </div>

  
</div>

</template>

<script>


import { mapState } from 'vuex';

export default {
  name: "InfoPrint",
  data() {
    return {
      pictureMaterialBaseUrl: process.env.VUE_APP_API_BASE_URL + 'application/material_image/',
      pictureTechnologyBaseUrl: process.env.VUE_APP_API_BASE_URL + 'application/technology_image/',
      pictureLayerBaseUrl: process.env.VUE_APP_API_BASE_URL + 'application/layer_image/',
      
      
    }
  },
  components: {},

  computed: {
    ...mapState("print", {
      volumeDisplay: (state) => state.printFile.volumeDisplay,
      materialItems: (state) => state.printFile.materialItems,
      materialSelected: (state) => state.printFile.material,
      materialInfoShow: (state) => state.printFile.infoMaterial,
      layerInfoShow: (state) => state.printFile.infoLayer,
      technologySelect: (state) => state.printFile.infoTechnology,
      technology: (state) => state.printFile.technology,
     
    }),
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      stepNextPossible: (state) => state.stepNextPossible,
      infoLayerVisible: (state) => state.manageOptions.infoLayerVisible,
      infoMaterialVisible: (state) => state.manageOptions.infoMaterialVisible,
      infoTechnologyVisible: (state) => state.manageOptions.infoTechnologyVisible,

    }),
    item() {

      let mat = 0
      for (let i=0; i < this.materialItems.length ; i++) {

        if (this.lastChildActive > 3) { 
          mat = this.materialSelected.id 

        } else {
          mat = this.materialInfoShow.id 

        }
        if (this.materialItems[i].id == mat ) {
          
          return this.materialItems[i] }
      }
      return null 
    },


  },
   

};
</script>