<template>
   <!-- <div class="layer-card" :class="layer.id == item.id ? 'is-selected' : ''" @click="saveLayer" @mouseover="showInfoLayer"  > {{ (application.id == 0 ? item.height +" µm" : item.technologyName +" - " + item.height +" µm") }} -->
    <div class="layer-card" :class="layer.id == item.id ? 'is-selected' : ''" @click="saveLayer" @mouseover="showInfoLayer"  > {{ (application.id == 0 ? item.layerLevel  : item.technologyName +" - " + item.layerLevel ) }}
      <span v-if="item.plusPrice !== null" class="t4 color-secondary mt-2 d-block">+ {{ (item.plusPrice / 100) | formatPrice }} €</span>

    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: "AccordLayersItem",
    props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      gtagGroupEvent: 'impression_form'
    }
  },
   computed: {
    ...mapState("print", {
      layer: (state) => state.printFile.layer,
      finishing: (state) => state.printFile.finishing,
      application: (state) => state.printFile.application
    }),
  },
  methods: {
    ...mapActions("print", [
      "updateLayer","updateInfoLayer", "updateFinishing","hideInfoLayer","updateInfoTechnology"
    ]),
    ...mapActions("printManager", [
      "updateOptionsIndexActive",
      "updateInfoTechnologyVisible",
      "updateInfoMaterialVisible",
      "updateInfoLayerVisible",
    ]),
    saveLayer() {
      this.updateFinishing({id:null,name:null,description:null,price:null})
      this.updateInfoLayer(this.item)
      this.updateLayer(this.item)
      //console.log("item select : ")
      //console.log(this.item )

       // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I5.1.Layer')
        //******************************************** */
    },
    showInfoLayer() {
      this.updateInfoTechnology({id: this.item.technologyId, name: this.item.technologyName, description: this.item.technologyDescription, technologyMinOrder: this.item.technologyMinOrder})

      this.updateInfoLayer(this.item)
      this.updateInfoTechnologyVisible ((this.application.id != 0))
      this.updateInfoMaterialVisible (false)
      this.updateInfoLayerVisible (true)



    }

  },
}
</script>