<template>
  <div class="devis-view background-light">
    <navigation-site :navThemeTransparent="false"></navigation-site>

    <div class="devis-view-inner space-nav-foot min-vh-100">

      <div v-if="dataLoaded == true" class="container min-h-800 mt-nav">

        <!-- breadcrumb -->
        <div v-if="this.$route.params.token === undefined">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="h6 breadcrumb-item">
                <router-link to="/account">Mon compte</router-link>
              </li>
              <li class="h6 breadcrumb-item">
                <router-link to="/account">Devis</router-link>
              </li>
              <li class="h6 breadcrumb-item active" aria-current="page">
                <span>Projet N° {{ referenceInUrl }}</span> 
                <!-- <span v-if="data.quotation == undefined">Projet N° {{ referenceInUrl }}</span> 
                <span v-if="data.quotation != undefined">Devis N°{{ data.quotation.reference }}</span>  -->
              </li>
            </ol>
          </nav>
        </div>

        <!--  Projet - devis du client -->  
        <div class=" mt-5 p-4 mx-auto max-w-1050 background-white">
          <h4 class="h4 text-center">Votre projet</h4>
          <h6 class="h6 text-end pe-sm-3">Référence du projet {{ referenceInUrl }}</h6>
          <h6 v-if="data.quotation == undefined  && data.status > 1 && data.status !== 8" class="h6 text-end pe-sm-3">en attente de devis</h6>
          <h6 v-if="data.quotation == undefined  && data.status == 8" class="h6 text-end pe-sm-3">En attente de correction</h6>
          <h6 v-if="data.quotation == undefined  && data.status == 1" class="h6 text-end pe-sm-3">Projet incomplet</h6>
          <h6 v-if="false" class="h6 mb-1">id : {{ data.id }}, status du projet : {{ data.status }} </h6>
          <!-- name -->
          <div class="mt-4">
            <h6 class="t3 color-secondary mb-1">Nom du projet :</h6>  
            <h6 class="h6">{{ data.name }}</h6> 
          </div>
          <!-- description -->
          <div v-if="data.description != undefined" class="mt-4">
            <h6 class="t3 color-secondary mb-1">Description :</h6> 
            <div class="overflow-hidden">
              <p v-for="(line, index) in data.description.split('\n')" :key="index" class="h6 mb-0">
                {{ line }}<br />
              </p> 
            </div> 
          </div>
          <!-- application -->
          <div v-if="data.fields != undefined && data.fields.length > 0" class="mt-4">
            <h6 class="t3 color-secondary mb-1">Application : </h6>
            <h6 class="h6 d-inline" v-for="(field, index) in data.fields" :key="index"><span v-if="index > 0">, </span> {{ field.name }} </h6>
          </div>
          <!-- files -->
          <div v-if="data.files.length != undefined && data.files.length > 0" class="mt-4">
            <h6 class="t3 color-secondary mb-1">Fichiers deposées : </h6>
            <h6 v-for="(file, index) in data.files" :key="index" class="h6 mb-0"><a :href="urlApp + file.download_url">{{ file.original_name }}</a></h6>
          </div>
          <!-- delais -->
          <div v-if="data.delivery_time != undefined" class="mt-4">
            <h6 class="t3 color-secondary mb-1">Delais souhaitées : </h6>
            <h6 class="h6">{{ REF_DATES[data.delivery_time] }}</h6>
          </div>
          <!-- dimensions -->
          <div v-if="data.dimensions != undefined && data.dimensions.x >= 0" class="mt-4">
            <h6 class="t3 color-secondary mb-1">Dimensions en mm (longueur x Largeur x Hauteur) :</h6>
            <h6 class="h6 mb-0">{{ data.dimensions.x }} x {{ data.dimensions.y }} x {{ data.dimensions.z }}</h6>
          </div>
          <!-- code postal -->
          <div v-if="data.zipcode != undefined" class="mt-4">
            <span class="t3 color-secondary mb-1">Code postal : </span>
            <h6 class="h6 mb-0">{{ data.zipcode }}</h6>
          </div>
          <!-- devis pour impression -->
          <div v-if="data.quote_option_for_printing != undefined" class="mt-4">
            <span class="t3 color-secondary mb-1">demande de devis pour une impression :</span>
            <h6 class="h6 mb-0">{{ data.quote_option_for_printing  == true ? 'Oui' : 'Non'}}</h6>
          </div>
          <!-- solution pour scanner l'objet -->
          <div v-if="data.how_scan != undefined" class="mt-4">
            <span class="t3 color-secondary mb-1">Solution pour scanner l'objet :</span>
            <h6 v-if="data.how_scan == 1" class="h6 mb-0">Déplacement du maker</h6>
            <h6 v-if="data.how_scan == 2" class="h6 mb-0">Envoie et retour de l'objet</h6>
          </div>

           <div v-if="data.scan_on_site != undefined" class="mt-4">
            <span class="t3 color-secondary mb-1">object scanner sur site :</span>
            <h6 v-if="data.scan_on_site == true" class="h6 mb-0">Oui</h6>
            <h6 v-if="data.scan_on_site == false" class="h6 mb-0">Non</h6>
          </div>

          <div v-if="data.quotation == undefined && this.$route.params.token === undefined && data.status == 2" class="text-center mt-5">
            <router-link to="/account" class="h6 color-secondary text-center hover-c-text">Retour sur votre compte</router-link>
          </div>

          <div v-if="data.quotation == undefined  && data.status == 1" class="mt-5 text-center">
            <router-link :to="'/project/' + referenceInUrl + token" class="h6 color-secondary">Reprendre votre projet</router-link>
          </div>

          <div v-if="data.quotation == undefined  && data.status == 8" class="mt-5 text-center">
            <router-link :to="'/correction/project/' + referenceInUrl + token" class="h6 color-secondary">Compléter votre projet</router-link>
          </div>

        </div> <!-- fin Projet - devis du client -->



        <!-- Devis proposée-->
        <div v-if="data.quotation != undefined">

        
          <!-- title -->
          <h4 class="h4 mt-5 text-center">Devis</h4>
          <p class="t3 text-center color-secondary">
            Devis valide jusqu'au {{ data.quotation.closeDate }}
          </p>

          <!-- content main -->
          <div class="mt-5 mx-auto max-w-1050">

            <div class="pt-5 pb-5 ps-xl-4 pe-xl-4 background-white">

              <!-- maker -->
              <div class="mb-3 ms-1">
                <div v-if="data.quotation.order_sell.is_deposit">
                  <h6 class="h6 ms-2 d-inline">Acompte : </h6>
                  <span class="t1 ms-2 d-inline color-secondary">{{ data.quotation.order_sell.deposit }}%</span>
                </div>
                <div v-if="data.quotation.order_sell.is_deposit && data.quotation.order_sell.timeline !== undefined">
                  <h6 class="h6 ms-2 d-inline">Échéancier : </h6>
                  <span class="t1 ms-2 d-inline color-secondary">{{data.quotation.order_sell.timeline[0].toUpperCase() + data.quotation.order_sell.timeline.substring(1)}}</span>
                </div>
                <br>
                <div class="mb-3">
                  <h6 class="h6 mb-2 ms-2">Description du projet :</h6>
                  <span class="t1 ms-2 d-inline color-secondary" v-html="data.quotation.description"></span>
                </div>
                <div>
                  <h6 class="h6 ms-2 d-inline">Délais de Réalisation : </h6>
                  <span class="t1 ms-2 d-inline color-secondary">{{ data.quotation.production_time }} Jour(s)</span>
                </div>
              </div>
              <!-- table -->
              <div class="table-responsive-sm">
                <table class="table table-borderless devis-table">
                  <thead>
                    <tr class="pt-5 pb-5">
                      <th scope="col">Description</th>
                      <th scope="col" style="text-align: center">Quantité</th>
                      <th scope="col" style="text-align: center">Prix unitaire HT</th>
                      <th scope="col" style="text-align: center">Total HT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(line, index) in quotation.lines" :key="index" class="pt-5 pb-5">
                      <th><span class="badge rounded-pill bg-danger optional" v-if="line.optional">Option</span> {{ line.description }}</th>
                      <td style="text-align: center">
                        <div v-if="data.quotation.status != 11" class="quantity me-md-5">
                          <input type="number" :min="line.minimum" :max="line.maximum" @keyup="changeQuantityByLineId(line.id)" v-model="line.quantity" class="h6 mb-0 choice-quantity" step="1" onkeydown="if(event.key==='.' || event.key===','){event.preventDefault();}">
                          <div class="quantity-ajust">
                            <div class="quantity-ajust--more" @click="moreByLineId(line.id)">
                              <svg v-bind:style="{ opacity: moreButtonOpacityByLine(line) }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.71429 6L4.5 2.25L1.28571 6L6.55671e-08 5.25L4.5 -8.7024e-07L9 5.25L7.71429 6Z" fill="#161616"/>
                              </svg>
                            </div>
                            <div class="quantity-ajust--less" @click="lessByLineId(line.id)">
                              <svg v-bind:style="{ opacity: lessButtonOpacityByLine(line) }" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.28571 0L4.5 3.75L7.71429 0L9 0.75L4.5 6L0 0.75L1.28571 0Z" fill="#161616"/>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div v-if="data.quotation.status == 11">
                          {{ line.quantity }}
                        </div>
                      </td>
                      <td style="text-align: center" v-if="line.quantity !== 0">{{ (line.price / 100) | formatPrice }} €</td>
                      <td style="text-align: center" v-if="line.quantity === 0">{{ getPriceByQuantityLineId(line.minimum, line.id) / 100 | formatPrice }} €</td>
                      <td style="text-align: center">{{ ((line.price / 100) * line.quantity) | formatPrice }} €</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- livraison -->
              <p class="ms-1" v-if="data.quotation.isShippingCalculatedLater">
                Les frais de port sont calculés plus tard.
              </p>

              <div class="mt-5 pe-sm-3">
                <div class="d-flex justify-content-end pt-2 pb-2">
                  <span class="min-w-100 h6 mb-0">Total HT</span>
                  <span class="min-w-100 t1 color-secondary text-center">{{ (this.getTotalTaxExcl() / 100) | formatPrice }} €</span>
                </div>
                <div class="d-flex justify-content-end pt-2 pb-2">
                  <span class="min-w-100 h6 mb-0">TVA (20%)</span>
                  <span class="min-w-100 t1 color-secondary text-center">{{ (this.getTotalTaxExcl() / 10000 * this.quotation.taxRate) | formatPrice }} €</span>
                </div>
                <div class="d-flex justify-content-end pt-2 pb-2">
                  <span class="min-w-100 h6 mb-0">Total TTC</span>
                  <span class="min-w-100 t1 color-secondary text-center">{{ (this.getTotalTaxExcl() * (1 + this.quotation.taxRate / 100) / 100) | formatPrice }} €</span>
                </div>
              </div>
            </div><!-- fin background white -->
            
            <!-- action devis -->
            <div class="row mt-4">
              <div class="col-lg-3 mt-3">
                <div class="col-lg-12 mt-3">
                  <a v-if="data.quotation !== undefined" class="btn-u3dm btn-u3dm--secondary" :href="urlDownloadQuotation">
                    Télécharger le devis
                  </a>
                </div>
              </div>
            </div>
          </div>

       
        </div><!-- fin Devis proposée-->

        <div class="mt-5 mx-auto max-w-1050">
          <!-- action devis -->
          <div class="row mt-4">
            <div class="col-lg-6 mt-3">
              <button
                  class="btn-u3dm btn-u3dm--secondary"
                  @click="drawerDiscussionIsOpen = true"
              >
                Messagerie
              </button>
            </div>
            <div v-if="data.quotation !== undefined && data.quotation.status != 11" class="col-lg-6 mt-3 text-lg-end">
              <!-- :class="cgvAccept == false ? 'btn-u3dm--disabled' : ''" -->
              <!--  :to="{path: '/order', query: {token: $route.params.token}}" -->
              <!-- :to="'/order/' + data.quotation.orderSellId + '/' + data.quotation.reference"  -->
              <button
                  class="btn-u3dm btn-u3dm--primary flex-grow-1"
                  :class="!data.quotation.isBuyable == true || !getTotalQuantity() ? 'btn-u3dm--disabled' : ''"
                  @click="data.quotation.isBuyable == true && getTotalQuantity() ? validateQuotation() : ''"
              >Valider le devis</button>
            </div>
          </div>
        </div>

      </div><!-- fin de la zone du devis -->
      <!-- container -->

      <!-- drawer discution -->
      <transition name="discussion-appearDrawer">
        <drawer-discussion
          :referenceOfproject="this.$route.params.reference"
          :statusProject="data.status"
          :canSendMessage="data.status === 7 || data.status === 5 || data.status === 6 ? false : true"
          :orderSellReference="data.quotation !== undefined ? data.quotation.orderSellReference : null "
          v-if="drawerDiscussionIsOpen == true"
          @close="drawerDiscussionIsOpen = false"
        ></drawer-discussion>
      </transition>
    </div>

    <footer-site></footer-site>
  </div>
</template>

<script>
import DrawerDiscussion from "../components/devis/DrawerDiscussion.vue";
import FooterSite from '../components/ui/FooterSite.vue';
import NavigationSite from "../components/ui/NavigationSite.vue";

import {reference_dates} from '../variables/ref_dates.js'

export default {
  components: { NavigationSite, DrawerDiscussion, FooterSite },
  name: "Devis",
  computed: {
    urlDownloadQuotation () {
      return this.$route.params.token === undefined ?
          this.urlApp + '/fr/quotation/' + this.data.quotation.reference + '/pdf' : this.urlApp + '/fr/quotation/' + this.data.quotation.reference + '/pdf/' + this.$route.params.token
    }
  },
  data() {
    return {
      dataLoaded: false,
      REF_DATES: reference_dates,
      data: '',
      quotation: null,
      // drawer
      drawerDiscussionIsOpen: false,

      token: '',
      referenceInUrl: '',
      urlApp: process.env.VUE_APP_APP_BASE_URL_WITHOUT_SLASH,
    };
  },
  beforeMount() {
    this.$googleTagManager(this.id, this.ismaker != null, 'project_form', 'o1.1.quotation_view')

    if (this.$route.params.token != undefined) {
      this.token = '/' + this.$route.params.token
    } else {
      this.token = ''
    }

    if (this.$route.params.reference) {
      this.referenceInUrl = this.$route.params.reference
    }

    // http://app.u3dm.local/fr/api/design/quotation/{REFERENCE_DEVIS}
    this.$http.get(process.env.VUE_APP_API_BASE_URL + "design/project/" + this.$route.params.reference + this.token, {credentials: true})
        .then((response) => {
              this.data = response.body
              this.quotation = response.body.quotation
              if (this.quotation !== undefined) {
                if (this.quotation.orderSellReference !== null) {
                  if(this.$route.params.token != undefined) {
                    window.location = '/commande/' + this.quotation.orderSellReference + '/' + this.$route.params.token
                  } else {
                    window.location = '/commande/' + this.quotation.orderSellReference
                  }
                }
                this.quotation.lines.forEach(line => {
                  line.minimum = this.minimumByPricesRanges(line, false)
                  line.maximum = this.maximumByPricesRanges(line)
                })
              }

              this.dataLoaded = true
            },
            () => {
              // console.log(error);
            }
        );
  },
  methods: {
    validateQuotation() {
      this.$googleTagManager(this.id, this.ismaker != null, 'project_form', 'o1.2.quotation_validate')

      this.$router.push({path: '/order/' + this.quotation.orderSellId + '/' + this.quotation.reference , query: {token: this.$route.params.token}})
    },
    moreButtonOpacityByLine(line) {
      if(line.quantity == this.maximumByPricesRanges(line)) {
        return 0.2
      } else {
        return 1
      }
    },
    lessButtonOpacityByLine(line) {
      if(line.quantity == this.minimumByPricesRanges(line)) {
        return 0.2
      } else {
        return 1
      }
    },
    moreByLineId(lineId) {
      for (let i = 0; i < this.quotation.lines.length; i++) {
        if(this.quotation.lines[i].id === lineId) {
          let line = this.quotation.lines[i];

          if(line.quantity + 1 < this.minimumPriceRange(line)) {
            line.quantity = this.minimumPriceRange(line)
            line.price = this.getPriceByQuantityLineId(line.quantity, line.id)
          } else if(line.quantity < this.maximumPriceRange(line)) {
            line.quantity += 1
            line.price = this.getPriceByQuantityLineId(line.quantity, line.id)
          }
        }
      }
      this.updateQuotation();
    },
    lessByLineId(lineId) {
      for (let i = 0; i < this.quotation.lines.length; i++) {
        if(this.quotation.lines[i].id === lineId) {
          let line = this.quotation.lines[i];

          if(line.quantity - 1 < this.minimumPriceRange(line)) {
            if(line.optional) {
              line.quantity = 0
              line.price = this.getPriceByQuantityLineId(line.quantity, line.id)
            }
          } else {
            line.quantity -= 1
            line.price = this.getPriceByQuantityLineId(line.quantity, line.id)
          }
        }
      }
      this.updateQuotation();
    },
    updateQuotation() {
      let formData = new FormData()
      for (let i = 0; i < this.quotation.lines.length; i++) {
        formData.append('quantity[' + this.quotation.lines[i].id + ']', this.quotation.lines[i].quantity)
        formData.append('price[' + this.quotation.lines[i].id + ']', this.quotation.lines[i].price)
      }

      return this.$http.post(process.env.VUE_APP_API_BASE_URL + 'design/quotation/' + this.quotation.reference + this.token, formData, {credentials: true})
    },
    getPriceByQuantityLineId(quantity, lineId) {
      let price = 0

      if(quantity === 0) {
        for (let i = 0; i <= this.quotation.lines.length; i++) {
          if(this.quotation.lines[i].id === lineId) {
            return this.getPriceByQuantityLineId(this.minimumPriceRange(this.quotation.lines[i]), lineId)
          }
        }
      }

      this.quotation.lines.forEach(line => {
        if(line.id === lineId) {
          line.prices_ranges.forEach(priceRange => {
            if(quantity >= priceRange.beginning && quantity <= priceRange.end) {
              price = priceRange.price
            }
          })
        }
      })
      return price;
    },
    minimumPriceRange(line) {
      let minimum = null
      for(let i = 0; i < line.prices_ranges.length; i++) {
        if(line.prices_ranges[i].beginning < minimum || minimum === null) {
          minimum = line.prices_ranges[i].beginning
        }
      }
      return minimum
    },
    maximumPriceRange(line) {
      let maximum = null
      for(let i = 0; i < line.prices_ranges.length; i++) {
        if(line.prices_ranges[i].end > maximum || maximum === null) {
          maximum = line.prices_ranges[i].end
        }
      }
      return maximum
    },
    minimumByPricesRanges(line, isOptional = true) {
      let minimum = null
      if(line.optional && isOptional) {
        return 0
      }
      line.prices_ranges.forEach(priceRange => {
        if(priceRange.beginning < minimum || minimum === null) {
          minimum = priceRange.beginning
        }
      });
      return minimum
    },
    maximumByPricesRanges(line) {
      let maximum = null
      line.prices_ranges.forEach(priceRange => {
        if(priceRange.end > maximum || maximum === null) {
          maximum = priceRange.end
        }
      });
      return maximum
    },
    changeQuantityByLineId(lineId) {
      this.quotation.lines.forEach(line => {
        if (line.id === lineId) {
          let isQuantityOutOfPricesRanges = this.isQuantityOutOfPricesRanges(line.quantity, line)
          if (isQuantityOutOfPricesRanges === 'max') {
            line.quantity = this.maximumByPricesRanges(line)
          } else if (isQuantityOutOfPricesRanges === 'min') {
            line.quantity = this.minimumByPricesRanges(line)
          }
          line.price = this.getPriceByQuantityLineId(line.quantity, line.id)

        }
      })
      this.updateQuotation();
    },
    isQuantityOutOfPricesRanges(quantity, line) {
      if(quantity > this.maximumByPricesRanges(line)) {
        return 'max'
      }
      if(quantity < this.minimumByPricesRanges(line, false)) {
        return 'min'
      }
      return false
    },
    getTotalTaxExcl() {
      let totalTaxExcl = 0
      for (let i = 0; i < this.quotation.lines.length; i++) {
        totalTaxExcl += this.quotation.lines[i].price * this.quotation.lines[i].quantity
      }
      return totalTaxExcl
    },
    getTotalQuantity() {
      let totalQuantity = 0
      for (let i = 0; i < this.quotation.lines.length; i++) {
        totalQuantity += this.quotation.lines[i].quantity
      }
      return totalQuantity
    },
  },
};
</script>