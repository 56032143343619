<template>
    <svg :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.8518 18.941H2.76729C2.34336 18.941 2 18.5943 2 18.1663C2 17.7384 2.34336 17.3917 2.76729 17.3917H20.8518C21.2757 17.3917 21.619 17.7384 21.619 18.1663C21.619 18.5943 21.2757 18.941 20.8518 18.941Z" :fill="color"/>
        <path d="M9.92354 20.4799C9.49961 20.4799 9.15625 20.1333 9.15625 19.7053V16.6272C9.15625 16.1992 9.49961 15.8525 9.92354 15.8525C10.3475 15.8525 10.6908 16.1992 10.6908 16.6272V19.7053C10.6908 20.1333 10.3475 20.4799 9.92354 20.4799Z" :fill="color"/>
        <path d="M20.8518 12.8824H2.76729C2.34336 12.8824 2 12.5357 2 12.1077C2 11.6798 2.34336 11.3331 2.76729 11.3331H20.8518C21.2757 11.3331 21.619 11.6798 21.619 12.1077C21.619 12.5357 21.2757 12.8824 20.8518 12.8824Z" :fill="color"/>
        <path d="M6.51924 14.4221C6.09531 14.4221 5.75195 14.0754 5.75195 13.6474V10.5693C5.75195 10.1413 6.09531 9.79468 6.51924 9.79468C6.94317 9.79468 7.28653 10.1413 7.28653 10.5693V13.6474C7.28653 14.0749 6.94317 14.4221 6.51924 14.4221Z" :fill="color"/>
        <path d="M20.8518 7.08818H2.76729C2.34336 7.08818 2 6.74154 2 6.31356C2 5.88558 2.34336 5.53894 2.76729 5.53894H20.8518C21.2757 5.53894 21.619 5.88558 21.619 6.31356C21.619 6.74154 21.2757 7.08818 20.8518 7.08818Z" :fill="color"/>
        <path d="M14.4938 8.62738C14.0699 8.62738 13.7266 8.28073 13.7266 7.85276V4.77462C13.7266 4.34664 14.0699 4 14.4938 4C14.9178 4 15.2611 4.34664 15.2611 4.77462V7.85276C15.2611 8.28073 14.9178 8.62738 14.4938 8.62738Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'IconOption',
    props: {
        color: {
            type: String,
            default: '#161616'
        },
        width: {
            type: Number,
            default: 24
        },
    }
}
</script>