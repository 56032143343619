<template>
  <div class="color-card print-base-card" :class="color.id == item.id ? 'is-selected' : ''" @click="saveColor">
    <div class="color-card-content">
      <div class="color-card-media">
        <img v-if="item['hexadecimal_code'] === 'multicouleurs1'" :src="require('@/assets/images/print/color.png')" alt="Multicouleurs" class="circle-color">
        <img v-if="item['hexadecimal_code'] === 'multicouleurs2'" :src="require('@/assets/images/print/color_uhd.png')" alt="Multicouleurs" class="circle-color">
        <span v-if="item['hexadecimal_code'] !== 'multicouleurs1' && item['hexadecimal_code'] !== 'multicouleurs2'"
          :style="{ background: item['hexadecimal_code'] }"
          class="circle-color"
        ></span>
      </div>
      <div>
        <h6 class="color-card-title h6">{{ item.name }}</h6>
        <span class="color-card-price t3">+ {{ (item.plusPrice / 100) | formatPrice }} € HT</span>
      </div>
    </div>
    <p class="description-multicolor" v-if="item['hexadecimal_code'] === 'multicouleurs1' || item['hexadecimal_code'] === 'multicouleurs2'">
      Merci de transmettre le fichier de couleur après la commande via la messagerie de  votre compte.
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: "AccordColorsItem",
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("print", {
      color: (state) => state.printFile.color,
      layer: (state) => state.printFile.layer,
    }),
  },
  methods: {
    ...mapActions("print", [
      "updateColor"
    ]),
    ...mapActions("printManager", [
      "updateOptionsIndexActive"
    ]),
    saveColor() {
      this.updateColor(this.item)

      if (this.layer.id == null) {
        this.updateOptionsIndexActive(1)
      }

       // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I4.1.Color')
        //******************************************** */
    }
  },
};
</script>