<template>
  <div class="contain-print-sidebar">


    <!-- mobile submenu -->
    <div class="s-mobile pt-3 pb-5"
    :class="[subMenuIsOpen == true ? 's-mobile__active': '']">
      <div class="container">

        <div class="mt-3">
          <router-link to="/account">
            <span 
              v-if="initialName.length == 0" 
              class="btn-u3dm btn-u3dm--primary d-block text-center">Se connecter</span>
            <span 
              v-if="initialName.length > 0" 
              class="btn-u3dm btn-u3dm--primary d-block text-center">Mon compte</span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="print-sidebar">

        <!-- button hamburger -->
        <div class="s-menu" @click="subMenuIsOpen = !subMenuIsOpen">
          <img
            :src="require('@/assets/icon-svg/icon-menu.svg')"
            alt="icon menu"
          />
        </div>
        
        <!-- logo -->
        <div class="s-brand">
            <a :href="urlWWWHome">
              <img class="logo--mobile"
                :src="require('@/assets/icon-svg/logo-3dm-mobile-red.svg')"
                alt="logo u3dm"
              />
              <img class="logo--desktop"
                :src="require('@/assets/icon-svg/u3dm-logo-duotone.svg')"
                alt="logo u3dm"
              />
            </a> 
        </div>

        
        <!-- button enregistrer plus tard -->
        <div v-if="stepNextPossible >= 2" 
          class="s-save--later btn-u3dm btn-u3dm--tertiary" 
          @click="sendProject"
          >Enregistrer et continuer plus tard</div>

        <h1 class="level-1 s-print-title">
          Lancez une impression 3D en ligne
        </h1>

          <ul class="level-1 s-contact">
            <li class="level-1-item">
              <div class="level-1-link">
                <span @click="clickContactUs" class="btn--devis btn--quotation">Contactez-nous</span>
              </div>
            </li>
          </ul>


        <div class="s-account">
          <router-link to="/account">
            <img 
              v-if="initialName.length == 0" 
            :src="require('@/assets/icon-svg/icon-connexion.svg')" alt="icon account2" />
            <span 
              v-if="initialName.length > 0" 
              class="s-user-initial initial-name">{{ initialName }}</span>
          </router-link>
        </div>

        <div class="s-card" :class="shopCompleted == true ? 's-card--articles' : ''">
          <router-link v-if="token !== '' && !this.tokenUser" :to="'/basket' + token">
            <img
              :src="require('@/assets/icon-svg/icon-card.svg')"
              alt="icon card"
            />
          </router-link>
          <router-link v-if="this.tokenUser" :to="'/basket' + '/' + this.tokenUser">
            <img
              :src="require('@/assets/icon-svg/icon-card.svg')"
              alt="icon card"
            />
          </router-link>
          <router-link v-if="token === '' && !this.tokenUser" :to="'/basket'">
            <img
                :src="require('@/assets/icon-svg/icon-card.svg')"
                alt="icon card"
            />
          </router-link>
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: "SidebarPrint",
   data() {
    return  {
      subMenuIsOpen: false,
      token: '',
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
      urlContact: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_CONTACT,
    }
  },
  computed: {
    ...mapState("printManager", {
      stepNextPossible: (state) => state.stepNextPossible,
      tokenUser: (state) => state.tokenUser,
    }),
    ...mapState("authentication", {
      customerId: (state) => state.id,
    }),
    ...mapGetters('authentication', {
      initialName: "initialName"
    }),
    ...mapGetters("authentication", {
        userConnected: "isConnected",
    }),
    ...mapGetters("shop", {
      shopCompleted: "shopCompleted"
    }),
  },
  beforeMount() {
		if (this.$route.params.token != undefined) {
			this.token = '/' + this.$route.params.token
		} else {
			this.token = ''
		}
	},
  methods: {
    ...mapActions("print", [
      "savePrint"
    ]),
    clickContactUs() {
      this.$googleTagManager(this.id, this.ismaker != null, 'impression_form', 'Ii1.5.contact_us')
      window.open(this.urlContact,'_newtab');
    },
      sendProject() {

      let data = {}

      if (this.$route.params.token !== undefined) {
        data.enableToken = this.$route.params.token
      }

      if (this.userConnected) {

        this.savePrint(data)
        .then(() => {
        //  console.log('project temporaire enregister')


        })
      } else {
        this.$parent.modalVisible = true
      }
    },
  
  }
};
</script>