<template>
<svg :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9992 12.3088C11.8858 12.3088 11.7724 12.2815 11.6692 12.2268L1.37393 6.77823C1.14275 6.65603 0.998047 6.41603 0.998047 6.15441C0.998047 5.89279 1.14275 5.65279 1.37393 5.53059L11.6688 0.0820588C11.8752 -0.0273529 12.1227 -0.0273529 12.3292 0.0820588L22.6236 5.53059C22.8548 5.65279 22.9995 5.89279 22.9995 6.15441C22.9995 6.41603 22.8548 6.65603 22.6236 6.77823L12.3292 12.2268C12.226 12.2815 12.1126 12.3088 11.9992 12.3088ZM3.21319 6.15441L11.9992 10.8044L20.7843 6.15441L11.9992 1.50441L3.21319 6.15441Z" :fill="color"/>
<path d="M12.0008 23.9995C11.8746 23.9995 11.7489 23.9656 11.6373 23.8985C11.4251 23.771 11.2949 23.5412 11.2949 23.2937V11.6029C11.2949 11.3413 11.4396 11.1013 11.6708 10.9791L21.9652 5.53059C22.184 5.41456 22.4474 5.42206 22.6596 5.54956C22.8718 5.67706 23.0015 5.90692 23.0015 6.15442V17.8266C23.0015 18.0878 22.8573 18.3273 22.6265 18.45L12.3317 23.917C12.228 23.9722 12.1142 23.9995 12.0008 23.9995ZM12.7067 12.0278V22.1193L21.5893 17.4022V7.32662L12.7067 12.0278Z" :fill="color"/>
<path d="M11.9993 23.9995C11.8854 23.9995 11.772 23.9722 11.6684 23.917L1.37305 18.45C1.14231 18.3273 0.998047 18.0878 0.998047 17.8266V6.15439C0.998047 5.90689 1.12775 5.67748 1.33996 5.54954C1.55217 5.4216 1.81555 5.41454 2.03437 5.53013L12.3293 10.9787C12.5604 11.1009 12.7051 11.3409 12.7051 11.6025V23.2932C12.7051 23.5412 12.5754 23.7706 12.3628 23.8981C12.2512 23.9656 12.1254 23.9995 11.9993 23.9995ZM2.41026 17.4022L11.2934 22.1192V12.0278L2.41026 7.3266V17.4022Z" :fill="color"/>
<path d="M11.2949 23.2941V23.2937H12.7067L11.2949 23.2941Z" :fill="color"/>
<path d="M0.705881 3.62558C0.315882 3.62558 0 3.3097 0 2.9197V0.705881C0 0.315882 0.315882 0 0.705881 0H2.9197C3.3097 0 3.62558 0.315882 3.62558 0.705881C3.62558 1.09588 3.3097 1.41176 2.9197 1.41176H1.41176V2.9197C1.41176 3.30926 1.09588 3.62558 0.705881 3.62558Z" :fill="color"/>
<path d="M2.9197 24H0.705881C0.315882 24 0 23.6841 0 23.2941V21.0803C0 20.6903 0.315882 20.3744 0.705881 20.3744C1.09588 20.3744 1.41176 20.6903 1.41176 21.0803V22.5882H2.9197C3.3097 22.5882 3.62558 22.9041 3.62558 23.2941C3.62558 23.6841 3.30926 24 2.9197 24Z" :fill="color"/>
<path d="M23.2947 24H21.0809C20.6909 24 20.375 23.6841 20.375 23.2941C20.375 22.9041 20.6909 22.5882 21.0809 22.5882H22.5888V21.0803C22.5888 20.6903 22.9047 20.3744 23.2947 20.3744C23.6847 20.3744 24.0006 20.6903 24.0006 21.0803V23.2941C24.0006 23.6841 23.6847 24 23.2947 24Z" :fill="color"/>
<path d="M23.2947 3.62558C22.9047 3.62558 22.5888 3.3097 22.5888 2.9197V1.41176H21.0809C20.6909 1.41176 20.375 1.09588 20.375 0.705881C20.375 0.315882 20.6909 0 21.0809 0H23.2947C23.6847 0 24.0006 0.315882 24.0006 0.705881V2.9197C24.0006 3.30926 23.6847 3.62558 23.2947 3.62558Z" :fill="color"/>
<path d="M1.70481 18.5326C1.4657 18.5326 1.23276 18.4112 1.09996 18.192C0.898345 17.8584 1.00511 17.4243 1.33864 17.2227L7.72024 13.3646C8.05377 13.163 8.48789 13.2698 8.68951 13.6033C8.89112 13.9368 8.78436 14.3709 8.45083 14.5726L2.06923 18.4306C1.9554 18.4999 1.82923 18.5326 1.70481 18.5326Z" :fill="color"/>
<path d="M22.2934 18.5325C22.169 18.5325 22.0433 18.4994 21.929 18.4306L15.5478 14.5725C15.2143 14.3709 15.1071 13.9368 15.3091 13.6032C15.5108 13.2697 15.9449 13.1625 16.2784 13.3646L22.6596 17.2227C22.9931 17.4243 23.1003 17.8584 22.8983 18.1919C22.7655 18.4112 22.5325 18.5325 22.2934 18.5325Z" :fill="color"/>
</svg>
</template>

<script>
export default {
    name: 'IconNum',
    props: {
        color: {
            type: String,
            default: 'inherit'
        },
        width: {
            type: Number,
            default: 24
        }
    }
}
</script>