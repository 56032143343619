<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <div class="m-login-content file-error modal-login-content-appear">

        <img class="m-login-cross"
             :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
             alt="icon fermer"
             @click="close"
        />

        <div class="m-login-password">
          <!-- heading -->
          <h4 class="m-login-heading h4">Votre fichier est en erreur, nous ne pouvons pas vous proposer d'applications.</h4>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModalFileErrorPrint",
  methods: {
    close() {
      this.$emit('close');
    },

  },
};
</script>