<template>
<div class="order-payment-thanks d-flex justify-content-center align-items-center">

    <div class="text-center appearOnBot">
      <div class="max-w-460 p-2">
        <h2 class="h4">
          Merci {{ firstname | capitalizeFirstLetter }}
          {{ lastname | capitalizeFirstLetter }}, nous avons bien reçu votre
          commande.
        </h2>

        <p class="t1 mt-4">
          Veuillez consulter votre messagerie pour obtenir la confirmation de commande et les détails de la livraison.
        </p>

        <div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
          <a :href="urlWWWHome" class="btn-u3dm btn-u3dm--primary m-1 mt-3">
            Retour a l'accueil
          </a>
          <router-link :to="'/commande/'+ orderReference + token" class="btn-u3dm btn-u3dm--secondary m-1 mt-3">
            Voir ma commande
          </router-link>
      </div>

    </div>

  </div>
</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "PaymentThanks",
  data() {
    return {
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
      token: ''
    }
  },
  beforeMount() {
    if (this.$route.query.token != undefined) {
      this.token = '/' + this.$route.query.token
    } else {
      this.token = ''
    }
    this.resetBasket()
  },
  computed: {
    ...mapState("authentication", {
      firstname: (state) => state.firstname,
      lastname: (state) => state.lastname,
    }),
    ...mapState("orderManager", {
      orderReference: (state) => state.orderReference
    }),
  },
  filters: {
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  methods:  {
    ...mapActions("shop", [
      "resetBasket"
    ])
  },

};
</script>