<template>
  <div>
    <!-- accordion-header -->
    <div>

      <slot name="header">titre</slot>
      
    </div>

    <!-- accordion-content -->
    <transition
      name="grabbed-accordion"
      @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">

        <slot name="content"></slot>

    </transition>

  </div>
</template>

<script>

export default {
  name: "AccordionGrabbedItem",
  props: {
    formule: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
    
    };
  },
  methods: {
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
};
</script>