<template>
    <div class="discussion-drawer-outer">

       

        <div class="discussion-drawer other-drawer">

            <div class="discussion-drawer-heading border-bottom">
                <h3 class="h5 m-0">Validation du fichier</h3>
                <span class="cursor-p" @click="close">
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1647 17.9401C16.9669 17.9401 16.7686 17.8646 16.6177 17.7137L6.285 7.38055C5.98313 7.07867 5.98313 6.58883 6.285 6.28695C6.58687 5.98508 7.07672 5.98508 7.37859 6.28695L17.7117 16.6201C18.0136 16.922 18.0136 17.4118 17.7117 17.7137C17.5603 17.8646 17.3625 17.9401 17.1647 17.9401Z" fill="#7C7C7C"/>
                        <path d="M6.83203 17.9401C6.63422 17.9401 6.43594 17.8646 6.285 17.7137C5.98312 17.4118 5.98312 16.922 6.285 16.6201L16.6181 6.28695C16.92 5.98508 17.4099 5.98508 17.7117 6.28695C18.0136 6.58883 18.0136 7.07867 17.7117 7.38055L7.37906 17.7132C7.22813 17.8646 7.03031 17.9401 6.83203 17.9401Z" fill="#7C7C7C"/>
                    </svg>
                </span>
            </div>

            <!-- accept-devis -->
            <div class="discussion-drawer-other-outer mt-5">
                <div class="mt-5 pt-3 m500">
                    <div class="text-center p-2">
                        <h5 class="h5 texet-center">Accepter le fichier de la commande ?</h5>
                        <p class="t1 mb-0">En l'absence de réponse, le livrable est validé sous 2 jours ouvrés.</p>
                        <p class="t1">En cas de refus, veuillez préciser les motifs justifiant ce refus.</p> 
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn-u3dm btn-u3dm--tertiary me-2" @click="acceptFile(1)">Oui</button>
                        <button class="btn-u3dm btn-u3dm--tertiary ms-2" @click="acceptFile(0)">Non</button>
                    </div>

                    <!-- send message -->
                    <div v-if="needMessage" class="mt-5 p-2 w-100">
                        <div v-if="formError" class="text--error mb-2">{{ formError }}</div>
                        <textarea name="message" class="w-100" v-model="message" id="" cols="30" rows="10" placeholder="Entrer votre message"></textarea>
                        <!-- action -->
                        <div class="mt-3">
                            <button class="btn-u3dm btn-u3dm--primary" @click="sendMessage">Envoyer</button>
                        </div>
                    </div>         
                </div>
            </div>

            
        </div><!-- discussion-drawer -->
    </div><!-- discussion-drawer-outer -->
</template>

<script>
export default {
    name: "DrawerFileValidation",
    data() {
        return {
            message: null,
            formError: null,
            needMessage: false,
            token: ''
        }
    },
    props: {
        orderReference: {
            type: String,
            default: 'reference non definie'
        },
    },
    beforeMount() {
        if (this.$route.params.token != undefined) {
            this.token = '/' + this.$route.params.token
        } else {
            this.token = ''
        }
    },
    methods: {
        acceptFile(nbBool) {
            
            if (nbBool == 0) {

                this.needMessage = true

            } else {
                let formData = new FormData();
                formData.append('validate', 1)
                this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/file/'+this.orderReference+'/validate'+this.token, formData, {credentials: true})
                .then(() => {  

                    location.reload()
                    this.close()
                })  
            }

        },
        sendMessage() {

            if (this.message != '' && this.message != null) {

                let formData = new FormData();
                    formData.append('validate', 0)
                    formData.append('text', this.message);

                this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/file/'+this.orderReference+'/validate'+this.token, formData, {credentials: true})
                    .then(() => {
                        location.reload()
                        this.close()
                    })  
            } else {
                this.formError = "Un message est nécessaire pour refuser le devis"
            }
        },
        close() {
            this.$emit('close')
        }
    },
    filters: {
        formatInitial(str) {
            return (str.substr(0, 1)).toUpperCase()
        }
    }
}
</script>