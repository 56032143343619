<template>
  <div class="mt-4">

      <div v-if="sortMaterialItemsAlphabetically.length > 0">
        <div class="d-flex flex-wrap pl-5">

            <div
              v-for="(item, index) in sortMaterialItemsAlphabetically"
              :key="index"
              class="material-card print-base-card print-appearItem"
              :class="[material.id == item.id ? 'is-selected' : '',(index + 1) % 2 == 1 ? 'print-appearItem__item--impair' : 'print-appearItem__item--pair']"
          
              @click="selectMaterial(item)"  @mouseover="showInfoMaterial(item)"
            >
              <h6 class="material-card-title h6">{{ item.name }}</h6>
              <span class="material-card-price t3">{{ (item.basePrice / 100) | formatPrice }} € HT</span>
              <!--
              <div  class="material-card-i" @click="openDrawer($event, index)">
                <icon-tooltip :color="'#C6C6C6'"></icon-tooltip>
              </div>
              -->
            </div>

        </div>
      </div>

      <div v-else>
        Pas de matériaux disponible pour ce volume
      </div>


  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
//import IconTooltip from '../../icon/IconTooltip.vue';

export default {
  //components: { IconTooltip },
  name: "TabsTechnologieItem",
  data() {
    return {
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("print", {
      material: (state) => state.printFile.material,
      dimensions: (state) => state.printFile.dimensions,
      quantity: (state) => state.printFile.quantity,
      volumeDisplay: (state) => state.printFile.volumeDisplay,
      followPrintId: (state) => state.followId,
      applicationName: (state) => state.printFile.application.name,
      technologyName: (state) => state.printFile.technology.name,
    }),
    ...mapGetters("print", {
      //sortMaterialItemsByTechnologyAlphabetically: 'sortMaterialItemsByTechnologyAlphabetically',
      sortMaterialItemsAlphabetically: 'sortMaterialItemsAlphabetically',
    })
  },
  methods: {
    ...mapActions("print", [
      "updateMaterial", "updateColor", "updateLayer", "updateColorsList", "updateLayersList", "updateFinishing","updateInfoMaterial"
    ]),
    ...mapActions("printManager", [
      "updateDrawerMaterialIsVisible", "updateIndexActive", "updateStepNextPossible", "updateLastChildActive",
      "updateInfoTechnologyVisible",
      "updateInfoMaterialVisible",
      "updateInfoLayerVisible",
    ]),
    selectMaterial(item) {
     
      
      // At each material choice => save the choice
      let formData = new FormData()
      formData.append("volume", this.volumeDisplay * 1000)
      formData.append("quantity", this.quantity)
      formData.append("price", item.basePrice)
      formData.append("dimension[x]", this.dimensions.x)
      formData.append("dimension[y]", this.dimensions.y)
      formData.append("dimension[z]", this.dimensions.z)
      formData.append("follow_print_id", this.followPrintId)
      formData.append("material_name", item.name)
      formData.append("application_name", this.applicationName)
      formData.append("technology_name", this.technologyName)

      this.$http.post(process.env.VUE_APP_API_BASE_URL + "application/follow-price", formData);

      this.updateColorsList(item.colors)
      this.updateLayersList(item.layers)
      this.updateMaterial({id: item.id, name: item.name, basePrice:item.basePrice})
      this.updateStepNextPossible(4)
      // reset :  color selected, layer selected, finishing selected
      this.updateColor({id:null,name:null,plusPrice:null,hexadecimalCode:null})
      this.updateLayer({id:null,height:null,plusPrice:null})
      this.updateFinishing({id:null,name:null,description:null,price:null})

      this.updateLastChildActive(4)

      // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I3.2.Material_select')
        //******************************************** */

    },
    showInfoMaterial(item) {

      this.updateInfoMaterial(item)
      this.updateInfoTechnologyVisible (false)
      this.updateInfoMaterialVisible (true)
      this.updateInfoLayerVisible (false)
      
    },
    openDrawer(e, index) {
      e.stopPropagation()
      this.updateIndexActive(index)
      this.updateDrawerMaterialIsVisible(true)


      // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I3.1.Material_info')
        //******************************************** */
    }
  }
};
</script>