<template>
    <div>
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
</template>

<script>
export default {
    name: 'LoaderOne'
}
</script>

