<template>
<svg :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M23.2941 24H1.74794C0.783971 24 0 23.216 0 22.252V21.9758C0 21.0119 0.783971 20.2279 1.74794 20.2279H18.5184C18.7037 20.2279 18.8546 20.077 18.8546 19.8917C18.8546 19.7064 18.7037 19.5555 18.5184 19.5555H12.9212C11.9572 19.5555 11.1732 18.7716 11.1732 17.8076V16.3124C11.1732 15.9224 11.4891 15.6066 11.8791 15.6066C12.2691 15.6066 12.585 15.9224 12.585 16.3124V17.8076C12.585 17.9929 12.7359 18.1438 12.9212 18.1438H18.5184C19.4824 18.1438 20.2663 18.9277 20.2663 19.8917C20.2663 20.8557 19.4824 21.6397 18.5184 21.6397H1.74794C1.56265 21.6397 1.41176 21.7905 1.41176 21.9758V22.252C1.41176 22.4373 1.56265 22.5882 1.74794 22.5882H23.2941C23.6841 22.5882 24 22.9041 24 23.2941C24 23.6841 23.6841 24 23.2941 24Z" :fill="color"/>
<path d="M17.8762 3.52632H6.12504C5.33357 3.52632 4.68945 2.88221 4.68945 2.09074V1.43559C4.68945 0.644118 5.33357 0 6.12504 0H17.8762C18.6677 0 19.3118 0.644118 19.3118 1.43559V2.09118C19.3118 2.88265 18.6677 3.52632 17.8762 3.52632ZM6.12504 1.41176C6.11181 1.41176 6.10122 1.42235 6.10122 1.43559V2.09118C6.10122 2.10441 6.11181 2.115 6.12504 2.115H17.8762C17.8895 2.115 17.9 2.10441 17.9 2.09118V1.43559C17.9 1.42235 17.8895 1.41176 17.8762 1.41176H6.12504Z" :fill="color"/>
<path d="M13.5365 9.03661H10.4642C9.21739 9.03661 8.20312 8.02278 8.20312 6.77602V2.82087C8.20312 2.43087 8.51901 2.11499 8.90901 2.11499C9.29901 2.11499 9.61489 2.43087 9.61489 2.82087V6.77646C9.61489 7.24455 9.99562 7.62528 10.4642 7.62528H13.5365C14.0046 7.62528 14.3853 7.24455 14.3853 6.77646V2.82087C14.3853 2.43087 14.7012 2.11499 15.0912 2.11499C15.4812 2.11499 15.7971 2.43087 15.7971 2.82087V6.77646C15.7971 8.02278 14.7828 9.03661 13.5365 9.03661Z" :fill="color"/>
<path d="M11.9995 13.0491C10.8948 13.0491 9.99609 12.1504 9.99609 11.0457V8.33113C9.99609 7.94113 10.312 7.62524 10.702 7.62524C11.092 7.62524 11.4079 7.94113 11.4079 8.33113V11.0457C11.4079 11.3722 11.6734 11.6373 11.9995 11.6373C12.3255 11.6373 12.5911 11.3717 12.5911 11.0457V8.33113C12.5911 7.94113 12.907 7.62524 13.297 7.62524C13.687 7.62524 14.0029 7.94113 14.0029 8.33113V11.0457C14.0029 12.1504 13.1042 13.0491 11.9995 13.0491Z" :fill="color"/>
<path d="M12.0008 14.2694C11.6108 14.2694 11.2949 13.9535 11.2949 13.5635V12.3432C11.2949 11.9532 11.6108 11.6373 12.0008 11.6373C12.3908 11.6373 12.7067 11.9532 12.7067 12.3432V13.5635C12.7067 13.9535 12.3908 14.2694 12.0008 14.2694Z" :fill="color"/>
<path d="M13.536 1.41176H10.4637C10.0737 1.41176 9.75781 1.09588 9.75781 0.705882C9.75781 0.315882 10.0737 0 10.4637 0H13.536C13.926 0 14.2419 0.315882 14.2419 0.705882C14.2419 1.09588 13.926 1.41176 13.536 1.41176Z" :fill="color"/>
<path d="M22.6221 1.41176H1.38166C0.991664 1.41176 0.675781 1.09588 0.675781 0.705882C0.675781 0.315882 0.991664 0 1.38166 0H22.6221C23.0121 0 23.328 0.315882 23.328 0.705882C23.328 1.09588 23.0117 1.41176 22.6221 1.41176Z" :fill="color"/>
</svg>

</template>

<script>
export default {
    name: 'IconPrint',
    props: {
        color: {
            type: String,
            default: 'inherit'
        },
        width: {
            type: Number,
            default: 24
        }
    }
}
</script>