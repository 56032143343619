<template>
  <div class="min-h-80vh">
    <navigation-site :navThemeTransparent="false"></navigation-site>
    <div class="container space-nav-foot">

        <!-- breadcrumb -->
        <div class="mt-5">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mt-3">
                    <li class="h6 breadcrumb-item"><router-link to='/account'>Mon compte</router-link></li>
                    <li class="h6 breadcrumb-item active" aria-current="page">Changer votre mot de passe</li>
                </ol>
            </nav>
        </div>

      <div v-if="changePasswordSuccess == false" class="row justify-content-center mt-3 appearContentFromBot2">
        <div class="col-md-6 col-xl-5 mt-5">

          <h1 class="h2 text-center">Modifier votre mot de passe</h1>

          <form class="row g-2 mt-5" @submit.prevent="() => send">
            <div class="col-12">
              <div class="text--error mb-1">{{ errorPassword }}</div>
            </div>
            <div class="col-md-12">
              <input type="password" v-model="password1" placeholder="Entrer votre nouveau mot de passe" class="form-control" @keyup.enter="send">
              <!-- <div class="t3 mt-1 color-secondary">
                    Le mot de passe doit être de 8 caractères minimum et contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial. Exemple : @Mi12Lg09#
              </div> -->
            </div>
            <div class="col-md-12">
              <input type="password" v-model="password2" placeholder="Entrer à nouveau votre mot de passe" class="form-control" @keyup.enter="send">
            </div>
            <div class="mt-4">
              <button class="btn-u3dm btn-u3dm--primary w-100" @click="send" @keyup.enter="send">Changer</button>
            </div>
          </form>              
        </div>
      </div>

      <div v-if="changePasswordSuccess == true" class="mt-5 pt-5 appearContentFromBot2">
        <div class="mt-5 pt-5">
          <div class="text-center h5">Votre mot de passe à été mofifiée avec succès</div>
          <div class="text-center mt-4">
            <router-link to="/account" class="btn-u3dm btn-u3dm--primary">Retour</router-link>
          </div>
        </div>
      </div>

    </div>

    <footer-site></footer-site>
  </div>
</template>

<script>
import FooterSite from '../ui/FooterSite.vue';
import NavigationSite from '../ui/NavigationSite.vue';
export default {
  name: "PasswordReset",
  components: { NavigationSite, FooterSite },
  data() {
    return {
      password1: null,
      password2: null,
      errorPassword: null,
      changePasswordSuccess: false
    }
  },
  
  methods: {
    send() {

      let formData = new FormData();    

      formData.append('first_password', this.password1);
      formData.append('second_password', this.password2); 

      this.$http.post(process.env.VUE_APP_API_BASE_URL + "user/password", formData, {credentials: true}).then(() => {
        this.changePasswordSuccess = true
      }
      , (error) => {
          this.errorPassword = error.body.error
          this.changePasswordSuccess = false
        
      })

    },
  },
}
</script>