<template>
    <svg :width="width" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6928 22.1312C11.5741 22.1312 11.4559 22.1038 11.3478 22.0487L2.39093 17.486C2.15039 17.3634 2 17.1238 2 16.8626V7.12146C2 6.87396 2.13522 6.64455 2.35644 6.51661C2.57766 6.38911 2.85223 6.38161 3.08035 6.49764L12.0373 11.0448C12.2783 11.167 12.4291 11.407 12.4291 11.6687V21.4253C12.4291 21.6732 12.2939 21.9026 12.0722 22.0301C11.9554 22.0972 11.8239 22.1312 11.6928 22.1312ZM3.47174 16.4382L10.9569 20.2509V12.0935L3.47174 8.29366V16.4382Z" :fill="color"/>
        <path d="M10.957 21.4257V21.4253H12.4288L10.957 21.4257Z" :fill="color"/>
        <path d="M12.6862 3.78618C12.5703 3.78618 12.4525 3.75971 12.3426 3.70412L11.3469 3.19853C10.9877 3.01632 10.8507 2.58882 11.0406 2.24426C11.2305 1.89971 11.6762 1.76824 12.0354 1.95044L13.0311 2.45603C13.3903 2.63824 13.5274 3.06574 13.3374 3.41029C13.2054 3.64941 12.9502 3.78618 12.6862 3.78618Z" :fill="color"/>
        <path d="M17.3285 6.14338C17.2126 6.14338 17.0949 6.1169 16.985 6.06132L14.6633 4.88249C14.3041 4.70029 14.1671 4.27278 14.357 3.92823C14.5469 3.58367 14.9926 3.4522 15.3518 3.6344L17.6735 4.81323C18.0327 4.99543 18.1697 5.42293 17.9798 5.76749C17.8478 6.00661 17.5925 6.14338 17.3285 6.14338Z" :fill="color"/>
        <path d="M20.648 8.90818C20.2415 8.90818 19.9122 8.5923 19.9122 8.2023V7.54628L19.3078 7.23966C18.9486 7.05746 18.8116 6.62996 19.0015 6.2854C19.1915 5.94084 19.6371 5.80937 19.9963 5.99158L20.992 6.49717C21.233 6.61937 21.3839 6.85937 21.3839 7.12099V8.20186C21.3839 8.5923 21.0546 8.90818 20.648 8.90818Z" :fill="color"/>
        <path d="M20.648 13.9611C20.2414 13.9611 19.9121 13.6452 19.9121 13.2552V10.7286C19.9121 10.3386 20.2414 10.0227 20.648 10.0227C21.0545 10.0227 21.3838 10.3386 21.3838 10.7286V13.2552C21.3838 13.6452 21.0545 13.9611 20.648 13.9611Z" :fill="color" />
        <path d="M19.6524 18.0754C19.3888 18.0754 19.134 17.9396 19.002 17.7004C18.8116 17.3563 18.9478 16.9288 19.307 16.7457L19.9113 16.4378V15.7813C19.9113 15.3913 20.2406 15.0754 20.6472 15.0754C21.0537 15.0754 21.383 15.3913 21.383 15.7813V16.8622C21.383 17.1234 21.2326 17.3629 20.9921 17.4856L19.9968 17.9925C19.8869 18.049 19.7692 18.0754 19.6524 18.0754Z" :fill="color"/>
        <path d="M15.0098 20.4414C14.7463 20.4414 14.4915 20.3056 14.3595 20.0664C14.1691 19.7223 14.3052 19.2948 14.6644 19.1117L16.9865 17.929C17.3457 17.7463 17.7909 17.8769 17.9818 18.2215C18.1722 18.5656 18.0361 18.9931 17.6769 19.1762L15.3547 20.3589C15.2444 20.4145 15.1262 20.4414 15.0098 20.4414Z" :fill="color"/>
        <path d="M11.6914 22.1312C11.4279 22.1312 11.1731 21.9953 11.0411 21.7562C10.8507 21.4121 10.9868 20.9846 11.346 20.8015L12.3413 20.2946C12.7 20.1119 13.1457 20.2425 13.3366 20.5871C13.527 20.9312 13.3908 21.3587 13.0316 21.5418L12.0364 22.0487C11.926 22.1047 11.8078 22.1312 11.6914 22.1312Z" :fill="color"/>
        <path d="M19.6547 8.33292C19.3907 8.33292 19.1359 8.19659 19.0035 7.95704C18.8135 7.61248 18.9506 7.18542 19.3098 7.00278L20.3055 6.49719C20.6647 6.31499 21.1099 6.44646 21.3003 6.79101C21.4903 7.13557 21.3532 7.56263 20.994 7.74527L19.9983 8.25086C19.8884 8.30644 19.7706 8.33292 19.6547 8.33292Z" :fill="color"/>
        <path d="M15.0102 10.6902C14.7462 10.6902 14.4914 10.5538 14.359 10.3143C14.169 9.96973 14.3061 9.54267 14.6653 9.36002L16.9869 8.18164C17.3461 7.99944 17.7913 8.13091 17.9817 8.47547C18.1717 8.82002 18.0346 9.24708 17.6754 9.42973L15.3538 10.6081C15.2438 10.6637 15.1261 10.6902 15.0102 10.6902Z" :fill="color"/>
        <path d="M11.6938 12.3745C11.4298 12.3745 11.175 12.2382 11.0425 11.9987C10.8526 11.6541 10.9897 11.227 11.3489 11.0444L12.3446 10.5388C12.7038 10.3566 13.149 10.4881 13.3394 10.8326C13.5298 11.1772 13.3923 11.6042 13.0331 11.7869L12.0374 12.2925C11.9274 12.3481 11.8097 12.3745 11.6938 12.3745Z" :fill="color"/>
        <path d="M11.6918 12.3746C11.5759 12.3746 11.4582 12.3481 11.3483 12.2925L2.39185 7.74529C2.15085 7.62309 2 7.38309 2 7.12147C2 6.85985 2.15085 6.61985 2.39185 6.49765L11.3483 1.95044C11.7075 1.76824 12.1527 1.89971 12.3431 2.24426C12.533 2.58882 12.396 3.01588 12.0368 3.19853L4.30924 7.12147L12.0368 11.0444C12.396 11.2266 12.533 11.6541 12.3431 11.9987C12.2111 12.2378 11.9558 12.3746 11.6918 12.3746Z" :fill="color"/>
        <path d="M11.6929 24C11.2863 24 10.957 23.6841 10.957 23.2941V0.705882C10.957 0.315882 11.2863 0 11.6929 0C12.0995 0 12.4288 0.315882 12.4288 0.705882V23.2941C12.4288 23.6841 12.0995 24 11.6929 24Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'IconNum',
    props: {
        color: {
            type: String,
            default: 'inherit'
        },
        width: {
            type: Number,
            default: 24
        }
    }
}
</script>