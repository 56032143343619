<template>
    <div class="accord-notification">
        <div class="accord-notification-close" @click="close">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.1647 17.9401C16.9669 17.9401 16.7686 17.8646 16.6177 17.7137L6.285 7.38055C5.98313 7.07867 5.98313 6.58883 6.285 6.28695C6.58687 5.98508 7.07672 5.98508 7.37859 6.28695L17.7117 16.6201C18.0136 16.922 18.0136 17.4118 17.7117 17.7137C17.5603 17.8646 17.3625 17.9401 17.1647 17.9401Z" fill="#7C7C7C"/>
                <path d="M6.83203 17.9401C6.63422 17.9401 6.43594 17.8646 6.285 17.7137C5.98312 17.4118 5.98312 16.922 6.285 16.6201L16.6181 6.28695C16.92 5.98508 17.4099 5.98508 17.7117 6.28695C18.0136 6.58883 18.0136 7.07867 17.7117 7.38055L7.37906 17.7132C7.22813 17.8646 7.03031 17.9401 6.83203 17.9401Z" fill="#7C7C7C"/>
            </svg>
        </div>

        <div class="accord-notification-content">
            <p class="mb-0 h6">{{ message }}</p>
        </div>

    </div>
</template>

<script>
export default {
    name: "AccordNotification",
    props: {
        message: {
            type: String,
            default() {
                return "non définie"
            } 
        }
    },
    data() {
        return {
            hidden: false
        }
    },
    methods: {
        close() {
            this.$emit('close')       
        }
    }
}
</script>