<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <div class="m-login-content-save-project modal-login-content-appear">

        <img class="m-login-cross"
             :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
             alt="icon fermer"
             @click="close"
        />

        <div class="m-login-prospect">

          <h4 class="m-login-heading h4">Votre projet est enregistré</h4>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSaveProject',
  methods: {
    close() {
      this.$emit('close');
    },
  }
}
</script>