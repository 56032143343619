<template>
  <div class="project">
    <div class="project-wrapp project-wrapp--lg project-dateCometop">

       <h2 class="project-heading">Délai souhaité</h2>

      <div class="date-cards-wrapper">
        <!-- Items  -->
        <div
          v-for="(item, index) in items"
          :key="index"
          class="date-card project-appearDate"
          :class="[{ 'date-card--active': currentSelected == item.title }, 'project-appearDate-item--'+(index + 1)]"
          @click="next(item.title)"
        >
          <h6 class="date-card__text">{{ item.text }}</h6>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    items: {
      type: Array,
      default() { return { } }
    }
  },
  data() {
    return {
      currentSelected: "one_month",

      gtagGroupEvent: 'project_form'
    };
  },
  computed: { 
    ...mapState('projectDesign',{
      deliveryTimeOnStore: state => state.projectDesign.deliveryTime,
      returnToRecap: state => state.returnToRecap,
      wayCurrent: state => state.steps.wayCurrent
    }),
  },
  beforeMount() {
   
    this.currentSelected = this.deliveryTimeOnStore

    // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A5.Delai.0_view')
          this.updateCurrentGoogleTag('P3Imp_A5.Delai')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B5.Delai.0_view')
          this.updateCurrentGoogleTag('P3Imp_B5.Delai')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C7.Delai.0_view')
          this.updateCurrentGoogleTag('P3Imp_C7.Delai')
        }  else if (this.wayCurrent == 'MODELISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A5.Delai.0_view')
          this.updateCurrentGoogleTag('P3Mod_A5.Delai')
        } else if (this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_B6.Delai.0_view')
          this.updateCurrentGoogleTag('P3Mod_B6.Delai')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C8.Delai.0_view')
          this.updateCurrentGoogleTag('P3Mod_C8.Delai')
        } 
        //******************************************** */
  },
  methods: {
    ...mapActions('projectDesign', [
      'updateDeliveryTime', 'increment', 'goOnStepByName', 'updateCurrentGoogleTag'
    ]),
    next(title) {
      this.currentSelected = title;
      this.updateDeliveryTime(this.currentSelected)


      if(this.returnToRecap) {
        this.goOnStepByName('recap');
      } else {


        // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A5.Delai.2_click_delai') 
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B5.Delai.2_click_delai')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C7.Delai.2_click_delai')
        } else if (this.wayCurrent == 'MODELISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A5.Delai.2_click_delai')
        } else if (this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_B6.Delai.2_click_delai')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C8.Delai.2_click_delai')
        } 
        //******************************************** */

        this.increment()
      }
    },
  },
  name: "ProjectDate",
};
</script>