<template>
  <div>

    <!-- <div v-if="referentialItems.length <= 0" class="mt-3">
      <p>Pour ce type de volume nous vous proposons de passer sur un devis</p>
      <span class="btn-u3dm btn-u3dm--primary">Obtenir un devis</span>
    </div> -->

    <div v-if="referentialItems.length >= 1" 
      class="d-flex flex-wrap pl-5 inner-scroll inner-scroll-400">
      <!-- <div class="d-flex flex-wrap pl-5 inner-scroll inner-scroll-400"> -->

      <accord-application-item
        v-for="(item, index) in referentialItems"
        :key="index"
        :item="{id: item.id, name: item.name, quote: item.quote}"
        :class="(index + 1) % 2 == 1 ? 'print-appearItem__item--impair' : 'print-appearItem__item--pair'"
        ></accord-application-item
      >
      <!-- autre -->
      <accord-application-item 
      :item="{id: 0, name: 'Autre', quote: false}" class="print-appearItem__item--pair"></accord-application-item>

    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

import AccordApplicationItem from "./AccordApplicationItem.vue";

export default {
  components: { AccordApplicationItem },
  name: "AccordApplications",
  computed: {
    ...mapState("print", {
      referentialItems: (state) => state.printFile.referentialItems,
    }),
  },
  
};
</script>