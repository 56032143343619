<template>
  <div class="contain-sidebar">

    <!-- mobile submenu -->
    <div class="s-mobile pt-3 pb-5"
    :class="[subMenuIsOpen == true ? 's-mobile__active': '']">
      <div class="container">
        <div v-if="stepNumActive > 0 && getStatusOfProject == 1"
        @click="sendProject"
        class="mt-2 btn-u3dm btn-u3dm--tertiary d-block text-center">Enregistrer et continuer plus tard</div>
        <div class="mt-3">
          <router-link to="/account">
            <span 
              v-if="initialName.length == 0" 
              class="btn-u3dm btn-u3dm--primary d-block text-center">Se connecter</span>
            <span 
              v-if="initialName.length > 0" 
              class="btn-u3dm btn-u3dm--primary d-block text-center">Mon compte</span>
          </router-link>
        </div>
      </div> 
    </div>

    <div class="container">
      <div class="project-sidebar">
        
        <!-- button hamburger -->
        <div class="s-menu" @click="subMenuIsOpen = !subMenuIsOpen">
            <img :src="require('@/assets/icon-svg/icon-menu.svg')" alt="icon menu" />     
        </div>
        
        
        <!-- logo -->
        <div class="s-brand">
          <a :href="urlWWWHome">
            <img class="logo--mobile"
              :src="require('@/assets/icon-svg/logo-3dm-mobile-red.svg')"
              alt="logo u3dm"
            />
            <img class="logo--desktop"
              :src="require('@/assets/icon-svg/u3dm-logo-duotone.svg')"
              alt="logo u3dm"
            />
          </a> 
        </div>

        <!-- button back -->
        <div v-if="stepNumActive > 0 && getStatusOfProject == 1" class="s-return" @click="back">Retour</div>

        <!-- button enregistrer plus tard -->
        <transition name="fade">
        <div 
          v-if="stepNumActive > 0 && getNbSteps !== 1"
          @click="sendProject"
          class="s-save--later btn-u3dm btn-u3dm--tertiary">Enregistrer et continuer plus tard</div>
        </transition>
        
        <!-- account -->
        <div class="s-account">
          <router-link to="/account">
            <img 
              v-if="initialName.length == 0" 
              :src="require('@/assets/icon-svg/icon-connexion.svg')" alt="icon account" />
            <span 
              v-if="initialName.length > 0" 
              class="s-user-initial initial-name">{{ initialName }}</span>
          </router-link>
        </div>

        <!-- basket -->
        <div class="s-card" :class="shopCompleted == true ? 's-card--articles' : ''">
          <router-link to="/basket">
            <img
              :src="require('@/assets/icon-svg/icon-card.svg')"
              alt="icon card"
            />
          </router-link> 
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: "SidebarProject",
  data() {
    return  {
      subMenuIsOpen: false,
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
      gtagGroupEvent: 'project_form',
      projectId: null,
    }
  },
  computed: {
    ...mapState('projectDesign', {
      stepNumActive: state => state.steps.stepNumActive,
      wayCurrent: state => state.steps.wayCurrent,
      currentGoogleTag: state => state.currentGoogleTag,
    }),
    ...mapState("authentication", {
      customerId: (state) => state.id,
    }),
    ...mapGetters('authentication', {
      initialName: 'initialName'
    }),
    ...mapGetters("authentication", {
        userConnected: 'isConnected',
    }),
    ...mapGetters("projectDesign", {
        getStatusOfProject: 'getStatusOfProject',
        getNbSteps: 'getNbSteps',
    }),
    ...mapGetters("shop", {
      shopCompleted: "shopCompleted"
    }),
  },
  methods: {
    ...mapActions('projectDesign', [
      'decrement', 'saveProject', 'updateProjectId',
    ]),
    back() {
      // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent, this.currentGoogleTag + '.1_back') 
        //****

      this.decrement()
    },
    sendProject() {

      let data = {
        status: status,
        save: true,
      }

      data.projectId = this.projectId

      if (this.$route.params.token != undefined) {
        data.enableToken = this.$route.params.token
      } else {
        data.customerId = this.customerId
      }

      if (this.userConnected) {
        this.saveProject(data)
            .then((response) => {
              this.updateProjectId(JSON.parse(response.body).project_id)
              if (this.$route.params.token == undefined) {
                this.$parent.isModalSaveProjectVisible = true
              }
            })
      } else {
        this.$parent.modalSaveVisible = true
      }
    },
  }
};
</script>