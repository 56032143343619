<template>
  <div class="shop-bar">
    <div v-if="color.id == null || layer.id == null" class="overlay-disabled"></div>
    <div v-if="lastChildActive == 5" class="shop-bar-type-2">
        <div class="confirmation-action d-flex justify-content-center align-items-center flex-wrap">
            <span class="btn-u3dm btn-u3dm--secondary me-sm-4 me-sm-1" @click="goStepMaterial">Ajoutez un matériau</span>
            <span class="btn-u3dm btn-u3dm--secondary ms-sm-4 ms-sm-1 mt-2 mt-sm-0" @click="resetPrintView">Ajouter un nouveau fichier</span>
        </div>
    </div>
    <div class="add-on-shop shop-bar-action">
      <button
          v-if="!userConnected && this.$route.params.token == undefined"
          class="btn-u3dm btn-u3dm--primary"
          @click="openModalConnexion"
        >
          Ajouter au panier
        </button>
        <button
          v-if="userConnected || this.$route.params.token !== undefined"
          class="btn-u3dm btn-u3dm--primary"
          @click="addBasket"
        >
          Ajouter au panier
        </button>
    </div>
    <div class="shop-bar-text">
      <div class="quantity me-md-5">
        <label class="h6 m-0 me-3">quantité</label>
        <input type="number" min="1" v-model="quantitySelected" class="h6 mb-0 choice-quantity">
        <div class="quantity-ajust">
          <div class="quantity-ajust--more" @click="more">
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.71429 6L4.5 2.25L1.28571 6L6.55671e-08 5.25L4.5 -8.7024e-07L9 5.25L7.71429 6Z" fill="#161616"/>
              </svg>
          </div>
          <div class="quantity-ajust--less" @click="less">
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.28571 0L4.5 3.75L7.71429 0L9 0.75L4.5 6L0 0.75L1.28571 0Z" fill="#161616"/>
              </svg>
          </div>
        </div>
      </div>
      <div class="shop-bar-price">
        <div class="h3 ht">{{ (priceHT / 100) | formatPrice }} € HT</div>
        <div class="h6 ttc">{{ (priceTTC / 100) | formatPrice }} € TTC</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: "ShopBar",
  data() {
    return {
      quantitySelected: 1,
      photo: null,
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("print", {
      volumeDisplay: (state) => state.printFile.volumeDisplay,
      application: (state) => state.printFile.application,
      material: (state) => state.printFile.material,
      color: (state) => state.printFile.color,
      layer: (state) => state.printFile.layer,
      finishing: (state) => state.printFile.finishing,
      file: (state) => state.printFile.file,
      quantity: (state) => state.printFile.quantity,
      dimensions: (state) => state.printFile.dimensions,
      screenshot: (state) => state.screenshot,
      technology: (state) => state.printFile.technology
    }),
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      addBasketAfterConnection: (state) => state.addBasketAfterConnection,
      tokenUser: (state) => state.tokenUser
    }),
    ...mapGetters("print", {
      priceHtShopBar: 'priceHtShopBar',
    }),
    ...mapState("authentication", {
      customerId: (state) => state.id,
    }),
    ...mapGetters("authentication", {
      userConnected: "isConnected",
    }),
    priceHT() {
      let total = 0

      if(this.material.id) {
        total += this.material.basePrice
      }
      if(this.color.id) {
        total += this.color.plusPrice
      }

      if(this.layer.layerId) {
        total += this.layer.plusPrice
      }
      if(this.finishing.id) {
        total += this.finishing.price
      }
      total = total * this.quantitySelected
      


        return total
    },
    priceTTC() {
      return (this.priceHT * 20 / 100) + this.priceHT
    }
  },
  watch: {
    addBasketAfterConnection() {
      if (this.addBasketAfterConnection == true) {
        this.addBasket()
      } else {
        this.updateAddBasketAfterConnection(false)
      }
    }
  },
  methods: {
    ...mapActions("print", [
        "updateQuantity",
        "updateScreenshot",
        "resetPrint"
    ]),
    ...mapActions("printManager", [
        "resetPrintManager",
        "updateLastChildActive",
        "updateModalVisibleIsOpen",
        "updateAddBasketAfterConnection"
    ]),
    ...mapActions("shop", [
        "addProductPrint",
    ]),
    openModalConnexion() {
      this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I6.0.before_add_basket')

      this.updateModalVisibleIsOpen(true)
    },
    addBasket() {
      if (this.addBasketAfterConnection === false) {
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I6.0.before_add_basket')
      }

      if (this.quantitySelected >= 1) {

        this.takeScreenshot().then(() => {
  
          this.updateLastChildActive(5)
          this.callApiAddBasket().then(() => {
            let getOrdersUrl

            if (this.$route.params.token != undefined) {
              getOrdersUrl = process.env.VUE_APP_API_BASE_URL + 'application/get-orders/' + this.$route.params.token
            } else {
              getOrdersUrl = process.env.VUE_APP_API_BASE_URL + 'application/get-orders'
            }

            this.$http.get(getOrdersUrl, {credentials: true})
                .then((response) => {
                  // console.log(response)
                  this.addProductPrint(response.body.items)
                }, () => {
                  // console.log(error)
                })
          })

        }) 
        // Google Tag Manager : push event impression form
          //******************************************** */
            this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I6.1.Add_Basket')
          //******************************************** */
      }
    },
    callApiAddBasket() {

      


      let formData = new FormData();

      if (this.finishing.id != null) {
        formData.append("finishing_id", this.finishing.id);
      }
      if (this.application.id == 0) {
        formData.append("technology_id", this.technology.id);
      } else {

        formData.append("application_id", this.application.id);
        formData.append("technology_id", this.layer.technologyId);
      }

      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("material_id", this.material.id);
      formData.append("color_id", this.color.id);
      formData.append("layer_id", this.layer.layerId);
      formData.append("quality_id", this.layer.id);
      formData.append("price", this.priceHT);
      formData.append("file", this.file);
      formData.append("quantity", this.quantitySelected);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);
      formData.append("photo", this.photo)


      let token = ''
      if (this.tokenUser != '') {
        token = '/' + this.tokenUser
      } else if(this.$route.params.token !== undefined) {
        token = '/' + this.$route.params.token
      }

      return this.$http
          .post(process.env.VUE_APP_API_BASE_URL + "application/add-basket" + token, formData, {credentials: true})
          .then((response) => {

           console.log(response)

          }).then((error) => {
        console.log(error)
      })

    },
    more() {
      this.quantitySelected = Number(this.quantitySelected) + 1
      this.updateQuantity(this.quantitySelected)
    },
    less() {
      if (this.quantitySelected > 1) {
        this.quantitySelected = Number(this.quantitySelected)- 1
        this.updateQuantity(this.quantitySelected)
      }
    },
    goStepMaterial() {
      this.updateLastChildActive(3)

        // Google Tag Manager : push event impression form
          //******************************************** */
            this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I7.2.order_add_material')
          //******************************************** */
    },
    takeScreenshot() {
      return new Promise((resolve) => {
        let canvas = document.getElementById('canvas')
        let dataURL = canvas.toDataURL()

        // fetch(dataURL)
        // .then(res => res.blob())
        // .then(blob => {
        //   var objectURL = URL.createObjectURL(blob);
        //   this.photo = objectURL;
        //   console.log(objectURL)
        // })
        fetch(dataURL)
        .then(res => res.blob())
        .then(response => {
          this.photo = response
          resolve()
        })
        // ----------
        // this.updateScreenshot(objectURL)
        // this.updateScreenshot(dataURL)
      });

    },
    resetPrintView() {
      this.resetPrint()
      this.resetPrintManager()
      this.updateLastChildActive(1)

      // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I7.2.order_add_file')
        //******************************************** */
    }
  },
};
</script>