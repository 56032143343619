<template>
  <div class="contain-order-sidebar">

    <!-- mobile submenu -->
    <div class="s-mobile pt-3 pb-5"
    :class="[subMenuIsOpen == true ? 's-mobile__active': '']">
        <div class="container">
          <div class="mt-3">
            <router-link to="/account">
              <span 
                v-if="initialName.length == 0" 
                class="btn-u3dm btn-u3dm--primary d-block text-center">Se connecter</span>
              <span 
                v-if="initialName.length > 0" 
                class="btn-u3dm btn-u3dm--primary d-block text-center">Mon compte</span>
            </router-link>
          </div>
      </div>
    </div>

    <div class="container">
      <div class="order-sidebar">

        <!-- button hamburger -->
        <div class="s-menu" @click="subMenuIsOpen = !subMenuIsOpen">
          <img
            :src="require('@/assets/icon-svg/icon-menu.svg')"
            alt="icon menu"
          />
        </div>

        <!-- logo -->
        <div class="s-brand">
            <a :href="urlWWWHome">
              <img class="logo--mobile"
                :src="require('@/assets/icon-svg/logo-3dm-mobile-red.svg')"
                alt="logo u3dm"
              />
              <img class="logo--desktop"
                :src="require('@/assets/icon-svg/u3dm-logo-duotone.svg')"
                alt="logo u3dm"
              />
            </a> 
        </div>


        <!-- finaliser votre commande -->
        <div class="s-order">
          <h4 class="h4">Finaliser votre commande</h4>
          <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
            <path d="M19 10.6298V6.70833C19 3.00917 15.86 0 12 0C8.14 0 5 3.00917 5 6.70833V10.6298C4.419 10.8282 4 11.3553 4 11.9792V20.6042C4 21.9247 5.122 23 6.5 23H17.5C18.878 23 20 21.9247 20 20.6042V11.9792C20 11.3553 19.581 10.8282 19 10.6298ZM8 6.70833C8 4.59425 9.794 2.875 12 2.875C14.206 2.875 16 4.59425 16 6.70833V10.5417H8V6.70833ZM13.974 18.5361C14.025 18.6817 13.999 18.8427 13.906 18.9683C13.812 19.0929 13.661 19.1667 13.5 19.1667H10.5C10.339 19.1667 10.188 19.0929 10.094 18.9673C10 18.8427 9.974 18.6817 10.026 18.5351L10.65 16.7411C10.237 16.3827 10 15.8786 10 15.3333C10 14.2763 10.897 13.4167 12 13.4167C13.103 13.4167 14 14.2763 14 15.3333C14 15.8786 13.763 16.3827 13.35 16.7421L13.974 18.5361Z" fill="#161616"/>
            </g>
          </svg>
        </div>
        
        <!-- account -->
        <div class="s-account">
          <router-link to="/account">
            <img 
              v-if="initialName.length == 0" 
            :src="require('@/assets/icon-svg/icon-connexion.svg')" alt="icon account"  />
            <span 
              v-if="initialName.length > 0" 
              class="s-user-initial initial-name">{{ initialName }}</span>
          </router-link>
        </div>

        <!-- card -->
        <div class="s-card" :class="shopCompleted == true ? 's-card--articles' : ''">
          <router-link to="/basket">
            <img
              :src="require('@/assets/icon-svg/icon-card.svg')"
              alt="icon card"
            />
          </router-link> 
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "SidebarOrder",
   data() {
    return  {
      subMenuIsOpen: false,
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL
    }
  },
  computed: {
    ...mapGetters('authentication', {
      initialName: "initialName"
    }),
    ...mapGetters("shop", {
      shopCompleted: "shopCompleted"
    }),
  },
};
</script>