<template>
  <div>
    <button class="btn-u3dm" @click="returnToStep">Modifier</button>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ReturnToStepButton",
  props: {
    stepName: {
      type: String
    }
  },
   data() {
    return {
      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState("projectDesign", {
      currentGoogleRecapTag: (state) => state.currentGoogleRecapTag,
    }),
  },
  methods: {
    ...mapActions('projectDesign', [
      'goOnStepByName',
      'updateReturnToRecap',
      'goOnStep'
    ]),
    returnToStep() {
      window.location.href = '#recap'
      this.goOnStepByName(this.stepName);
      this.updateReturnToRecap(true);

      // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.currentGoogleRecapTag)
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent, this.currentGoogleRecapTag + '.1_modify') 
        //****
    },
  }
}
</script>