<template>
    <div class="discussion-drawer-outer">

       

        <div class="discussion-drawer">

            <div class="discussion-drawer-heading border-bottom">
                <h3 class="h5 m-0">Messagerie</h3>
                <span class="cursor-p" @click="close">
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1647 17.9401C16.9669 17.9401 16.7686 17.8646 16.6177 17.7137L6.285 7.38055C5.98313 7.07867 5.98313 6.58883 6.285 6.28695C6.58687 5.98508 7.07672 5.98508 7.37859 6.28695L17.7117 16.6201C18.0136 16.922 18.0136 17.4118 17.7117 17.7137C17.5603 17.8646 17.3625 17.9401 17.1647 17.9401Z" fill="#7C7C7C"/>
                        <path d="M6.83203 17.9401C6.63422 17.9401 6.43594 17.8646 6.285 17.7137C5.98312 17.4118 5.98312 16.922 6.285 16.6201L16.6181 6.28695C16.92 5.98508 17.4099 5.98508 17.7117 6.28695C18.0136 6.58883 18.0136 7.07867 17.7117 7.38055L7.37906 17.7132C7.22813 17.8646 7.03031 17.9401 6.83203 17.9401Z" fill="#7C7C7C"/>
                    </svg>
                </span>
            </div>

            <!-- message -->

           
            <div id="scrollDiscussion" class="discussion-drawer-history-outer">
                <div class="discussion-drawer-history">
                  <div v-if="this.statusProject === 7 || this.statusProject === 5" class="discussion-drawer-history-content">
                    <div class="message-subscription">
                      La communication est close
                    </div>
                  </div>
                  <div v-if="this.statusProject === 6" class="discussion-drawer-history-content">
                    <div class="message-subscription">
                      La communication avec le maker doit se faire à partir de <router-link :to="{ name: 'Commande', params: { reference: this.orderSellReference }}">la commande</router-link>.
                    </div>
                  </div>
                  <div v-if="this.$route.params.token" class="discussion-drawer-history-content">
                    <div class="message-subscription">
                      Pour communiquer avec le maker, merci de bien vouloir activer votre compte. <br><br>
                      <button class="btn-u3dm btn-u3dm--primary w-100"
                              @click="this.redirectToSubscription">
                        Activation de votre compte
                      </button>
                    </div>
                  </div>
                    <div v-for="(message, index) in tabMessage"
                    :key="index"
                    id="testing"
                    class="discussion-drawer-history-content"
                    :class="message['author_maker'] == true ? 'discussion-drawer-history-left' : 'discussion-drawer-history-right'">
                            <div class="discussion-drawer-history-author">
                                <img class="maker" src="@/assets/icon-svg/logo-3dm-mobile-white.svg" v-if="message['author_maker']">
                                <span v-if="message['author_maker'] == false">
                                    {{ message.author.firstname | formatInitial }}{{ message.author.lastname | formatInitial }}
                                </span>
                            </div>
                            <div class="discussion-drawer-history-text">
                                <span v-if="message.text != 'null'">{{ message.text }}</span> 
                                <!-- <a v-if="message['attachment_original_name']" 
                                :href="process.env.VUE_APP_API_BASE_URL + message['attachment_original_path']"
                                ><br>{{ message['attachment_original_name'] }}</a> -->
                                <a :href="baseUrl + message['attachment_download_path']" v-if="message['attachment_original_name']"><br>{{ message['attachment_original_name'] }}</a>
                            </div>
                            
                    </div>
                </div>
            </div>

            <!-- send message -->
            <div v-if="canSendMessage" class="discussion-drawer-write">
                <div v-if="formError" class="text--error mb-2">
                    {{ formError }}
                </div>
                <textarea name="message" v-model="message" id="" cols="30" rows="10" placeholder="Entrer votre message"></textarea>
                <div class="mt-2">
                    <input type="file" @change="addFile($event.target.files[0])">
                </div>

                <!-- action -->
                <div class="mt-3">
                    <button class="btn-u3dm btn-u3dm--primary" @click="sendMessage">Envoyer</button>
                </div>
            </div>
            

        </div>
    </div><!-- discussion-drawer-outer -->
</template>

<script>
export default {
  name: "DrawerDicussion",
  props: {
    referenceOfproject: {
      type: String,
      default: ''
    },
    statusProject: {
      type: Number,
      default: null
    },
    canSendMessage: {
      type: Boolean,
      default: true
    },
    orderSellReference: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tabMessage: null,
      message: null,
      file: null,
      fileError: null,
      formError: null,
      baseUrl: process.env.VUE_APP_APP_BASE_URL_WITHOUT_SLASH
      // ===== base url pour pouvoir download
    }
  },
  beforeMount() {
    if (this.$route.params.token === undefined) {
      this.getMessages()
    }
  },
    methods: {
      redirectToSubscription() {
        this.$router.replace({ name: 'prospect-subscription', params: { prospectToken: this.$route.params.token, emailAlreadyVerified: 1 } })
      },
        scrollAjust() {
            setTimeout(()=> {
                let element = document.getElementById("scrollDiscussion");
                element.scrollTo(0,element.scrollHeight);
            }, 50)
        },
        sendMessage() {
            let tokenEndpoint = ''

            if(this.$route.params.token !== undefined) {
                tokenEndpoint = '/' + this.$route.params.token
            }

            if (this.message != '' && this.message != null) {
            let formData = new FormData();
                formData.append('text', this.message);
                formData.append('file', this.file);
                formData.append('from', 'customer');
            this.$http.post(process.env.VUE_APP_API_BASE_URL + 'message/project/' + this.referenceOfproject + tokenEndpoint, formData, {credentials: true})
            .then(() => {
                this.message = null
                this.formError = null
                this.getMessages()
                this.scrollAjust()
            })
            } else {
                this.formError = "Entrer votre message"
            }
        },
        addFile(file) {
            if (file.size <= 60000000) {
               this.file = file 
            } else {
                this.fileError = 'ce fichier est trop trop lourd'
            }
        },
        getMessages() {
            let tokenEndpoint = ''

            if(this.$route.params.token !== undefined) {
                tokenEndpoint = '/' + this.$route.params.token
            }

            this.$http.get(process.env.VUE_APP_API_BASE_URL + 'message/project/'+this.referenceOfproject + tokenEndpoint, {credentials: true})
            .then((response) => {
                    this.tabMessage = response.body.messages
                    this.scrollAjust()
                }, () => {
                // console.log(error)
            })
        },
        close() {
            this.$emit('close')
        }
    },
    filters: {
        formatInitial(str) {
            return (str.substr(0, 1)).toUpperCase()
        }
    }
}
</script>