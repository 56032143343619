<template>
    <div class="zone-order-form col-xl-7">
        <div class="zone-order-form-inner position-relative">

            <!-- step 1 : livraison -->
            <transition name="appear-order-accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
                <form-order-delivery v-show="step == 1"></form-order-delivery>
            </transition>
            <!-- step 2 : coordonné -->
            <transition name="appear-order-accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
                <form-order-ordered v-show="step == 2"></form-order-ordered>
            </transition>
            <!-- step 3 : paiment -->
            <transition name="appear-order-accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
                <form-ordered-payment v-show="step == 3"></form-ordered-payment>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import FormOrderDelivery from './FormOrderDelivery.vue'
import FormOrderedPayment from './FormOrderedPayment.vue'
import FormOrderOrdered from './FormOrderOrdered.vue'
export default {
    name: "AccordionOrder",
    components: { FormOrderOrdered, FormOrderDelivery, FormOrderedPayment },
    computed: {
        ...mapState('orderManager', {
            step: (state) => state.step,
        })
    },
    methods: {
        start(el) {
            el.style.height = el.scrollHeight + "px";
        },
        end(el) {
            el.style.height = "";
        }
    }
}
</script>