<template>
  <div class="background-light">
    <div class="position-relative">


      <navigation-site :navThemeTransparent="false"></navigation-site>
      <div class="container min-h-800 space-nav-foot">

        <!-- breadcrumb -->
        <div v-if="this.$route.params.token === undefined" class="mt-nav">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="h6 breadcrumb-item">
                <router-link to='/account'>Mon compte</router-link>
              </li>
              <li class="h6 breadcrumb-item">
                <router-link to='/account'>Mes commandes</router-link>
              </li>
              <li v-if="isLoaded" class="h6 breadcrumb-item active" aria-current="page">Commande N°{{
                  data.reference
                }}
              </li>
            </ol>
          </nav>
        </div>

        <!-- title -->
        <h4 v-if="isLoaded" class="h4 mt-5 text-center">Commande N°{{ data.reference }}</h4>
        <!-- content -->
        <div v-if="isLoaded" class="mt-4 mx-auto max-w-1200">

          <div class="row" id="top-buttons">
            <div class="col-sm-6">
              <a id="quotation-btn" v-if="data.quotation !== undefined" class="btn-u3dm btn-u3dm--secondary" :href="urlDownloadQuotation">
                Devis
              </a>
<!--           Old invoice
                <a id="quotation-btn" :href="urlApp + '/fr/order_sell/invoice/' + data.reference + token"
                 v-if="[8,11].indexOf(data['status_id']) != -1" class="btn-u3dm btn-u3dm&#45;&#45;secondary">Facture</a>-->
              <a class="btn-u3dm btn-u3dm--secondary" :href="urlApp + '/fr/order_sell/' + data.id + '/order_pdf_download'">
                Bon de commande
              </a>
            </div>
            <div class="col-sm-6 text-end">
              <button class="btn-u3dm btn-u3dm--secondary" @click="openSendBoxOrderSell(data['reference'])">
                Messagerie
              </button>
            </div>
          </div>
          <div class="row" id="top-buttons" v-if="data.quotation !== undefined || data.type === 'print' && data.status_id === 11 || areOrdersPrintDelivered">
            <div class="col-sm-12">
              <a v-for="payment in data.payments" :key="payment.invoice_ref" class="btn-u3dm" v-bind:class="{ 'btn-u3dm--green': payment.invoice_paid, 'btn-u3dm--orange': !payment.invoice_paid }" :href="urlApp + '/fr/order_sell/payment/' + payment.id + '/invoice_download'" data-bs-toggle="tooltip" 
              :title=" payment.invoice_type === 'credit'  ? payment.invoice_description != undefined ? payment.invoice_description : '' : payment.invoice_paid && payment.invoice_type === 'deposit' || payment.invoice_paid && payment.invoice_type === 'final' ? 'Payé' : payment.invoice_type === 'deposit' && !payment.invoice_paid || payment.invoice_type === 'final' && !payment.invoice_paid ? 'En attente de paiement' : ''">
                <div v-if= "payment.invoice_type === 'credit'"> Avoir {{ payment.invoice_ref }}</div> <div v-else> Facture {{ payment.invoice_ref }} </div> 
              </a>
            </div>
          </div>

          <div class="mt-3 p-4 background-white" v-if="data.quotation !== undefined && data.quotation.project.object_send">
            <div class="row">

              <!-- reference -->
<!--             FLEX STYLE REMOVED <h6 class="h6 m-0 mt-2 me-2"></h6>-->
              <!-- libelé -->
              <div class="col-sm-4">
              </div>
              <div class="col-sm-4" style="text-align: center;">
                <h6 class="h6 mt-2 me-2">Envoi de la pièce d'origine</h6>
              </div>

              <!-- status -->
              <div v-if="!data.is_sending_ready" class="col-sm-4" style="text-align: right;">
                <h6 class="h6 m-0 mt-2 me-2">
                  En préparation
                </h6>
              </div>
              <div v-if="data.is_sending_ready && (data.send_shipments === undefined || data.send_shipments.length === 0)" class="col-sm-4" style="text-align: right;">
                <button class="btn-u3dm btn-u3dm--secondary ms-1 mt-2"
                        @click="generateSendLabel(data.reference)">Générer l'étiquette
                </button>
              </div>
              <div v-if="data.send_shipments !== undefined && data.send_shipments.length == 1 && (data.send_shipments['0'] !== undefined && data.send_shipments['0'].status === 1)" class="col-sm-4" style="text-align: right;">
                <a class="btn-u3dm btn-u3dm--secondary ms-1 mt-2"
                   :href="data.send_shipments['0'].label_pdf_url">Télécharger l'étiquette
                </a>
              </div>
              <div v-if="data.send_shipments['0'] !== undefined && data.send_shipments['0']['receiver_maker'] === true && data.send_shipments['0'].status === 3" class="col-sm-4" style="text-align: right;">
                <h6 class="h6 m-0 mt-2 me-2">
                  Récéptionné
                </h6>
              </div>
              <div v-if="data.send_shipments !== undefined && data.send_shipments.length > 0 && (data.send_shipments['0'].status === 2 || data.send_shipments['0']['receiver_maker'] === false && data.send_shipments['0'].status === 3 || (data.send_shipments.length > 1 && (data.send_shipments[0].status !== 3 || data.send_shipments[1].status !== 3)))" class="col-sm-4" style="text-align: right;">
                <h6 class="h6 m-0 mt-2 me-2">
                  Envoyé
                </h6>
              </div>
            </div>
          </div>

          <!-- order -->
          <div id="orders" v-if="data.orders.length > 0">

            <div v-for="(order, index) in data.orders" :key="index"
                 class="mt-3 p-4 background-white">
              <div class="d-flex flex-wrap justify-content-between align-items-start">

                <!-- reference -->
                <h6 class="h6 m-0 mt-2 me-2">{{ order.reference }}</h6>
                <!-- libelé -->
                <div class="mt-2 me-2">
                  <h6 v-if="data.type == 'print'" class="h6">Impression fichier 3D</h6>
                  <h6 v-if="data.type == 'design'" class="h6">{{ order.quotation.name }}</h6>
                  <span v-if="order.download_file_name !== undefined">{{ order.download_file_name }}</span>
                </div>

                <!-- status -->
                <button v-if="order.status_id == 16" class="btn-u3dm btn-u3dm--secondary ms-1 mt-2"
                        @click="openValidationBox(order.reference)">Validation du fichier
                </button>
                <div v-if="order.status_id !== 16 || (listStatusCanSendDownloadFile.indexOf(order['status_id']) != -1 && data.type == 'design' && order['download_url'] !== undefined)">
                  <h6 v-if="order.status_id !== 16" class="h6 mt-2 me-2">{{ order.status }}</h6>
                <!-- télécharger fichier -->
                  <a v-if="listStatusCanSendDownloadFile.indexOf(order['status_id']) != -1 && data.type == 'design' && order['download_url'] !== undefined"
                     :href="urlApp + order['download_url']" class="h6 m-0 mt-2">Télécharger le fichier</a>
                </div>
              </div>
              <div class="mt-3 d-flex flex-wrap justify-content-end align-items-start">

                <!-- action -->
                <div class="">
                  <a v-if="order.status_id == 7" :href="order.tracking_url" target="_blank"
                     class="btn-u3dm btn-u3dm--tertiary ms-1 mt-2">suivre livraison</a>
                  <button v-if="order.status_id == 8" class="btn-u3dm btn-u3dm--secondary ms-1 mt-2"
                          @click="openNotationBox(order.reference)">Noter
                  </button>
                  <!--                  <button class="btn-u3dm btn-u3dm&#45;&#45;secondary ms-1 mt-2"
                                            @click="openSendBox(order.reference, order['status_id'])">Messagerie
                                    </button>-->
                </div>
              </div>

              <!-- note -->
              <div class="mt-2" v-if="listStatusCanSeeRating.indexOf(order['status_id']) != -1">
                <h6 class="h6 mb-0">Note : {{ order.rating.rate }} / 5</h6>
                <p><span class="h6">Commentaire : </span><span class="t1">{{ order.rating.comment }}</span></p>
              </div>
            </div>
          </div>

          <div class="pt-5 pb-5 ps-xl-4 pe-xl-4 background-white">

            <!-- top -->
            <div class="d-flex flex-wrap justify-content-between ps-2">
              <p class="h6">Commande N°{{ data.reference }} <span class="ps-3">{{
                  data['created_at'] | formatDate
                }}</span></p>
              <p class="h6 pe-4 pe-xl-5 me-xl-4">{{ data.status }}</p>
            </div>

            <div v-if="data.expected_delivery_date !== '' && data.expected_delivery_date !== undefined" class="d-flex flex-wrap justify-content-between ps-2">
              <p class="h6">Livraison prévue pour le : {{ data.expected_delivery_date }}</p>
            </div>

            <!-- iban : si attente virement -->
            <div v-if="data.status_id == 13" class="d-flex justify-content-md-end ps-2 pb-3">
              <div class="mt-4">
                <span class="d-block h6"> Virement à effectuer vers le compte :</span>
                <div class="text-bold mb-2">
                  <span class="d-block">{{ ibanU3dm.label }}</span>
                  <span class="d-block">{{ ibanU3dm.iban }}</span>
                  <span class="d-block">{{ ibanU3dm.rib }}</span>
                </div>
                <span
                    class="d-block text-secondary">Préciser la référence de la commande ({{ data.reference }}) dans le libellé du virement</span>
                <span
                    class="d-block text-secondary">A la réception du virement, votre commande passera en production <br> et vous recevrez une notification par mail</span>
              </div>
            </div>

            <!-- adress -->
            <div class="d-flex justify-content-between flex-wrap ps-2">
              <div class="mt-4" v-if="data['shipping_address'].street1 !== 'null'">
               
                <h6 class="h6">Adresse de livraison</h6>
                <p v-if="data['shipping_name'] !== undefined" class="m-0 t1 color-secondary">
                  <span class="uc-first d-inline-block">{{ data['shipping_name'] }}</span>
                </p>
                <p class="m-0 t1 color-secondary">
                  <span class="uc-first d-inline-block">{{ data['shipping_address'].firstname }}</span> <span
                    class="uc-first d-inline-block">{{ data['shipping_address'].lastname }}</span>
                </p>
                <p v-if="data['shipping_address'].company" class="m-0 t1 color-secondary">
                  {{ data['shipping_address'].company }}</p>
                <p class="m-0 t1 color-secondary">{{ data['shipping_address'].street1 }}</p>
                <p class="m-0 t1 color-secondary">{{ data['shipping_address'].zipcode }}
                  {{ data['shipping_address'].city }}, {{ data['shipping_address'].country }}</p>
              </div>
              <div class="mt-4 pe-4 pe-xl-5 me-xl-4">
                <h6 class="h6">Adresse de Facturation</h6>
                <p class="m-0 t1 color-secondary">
                  <span class="uc-first d-inline-block">{{ data['billing_address'].firstname }}</span> <span
                    class="uc-first d-inline-block">{{ data['billing_address'].lastname }}</span>
                </p>
                <p v-if="data['billing_address'].company" class="m-0 t1 color-secondary">
                  {{ data['billing_address'].company }}</p>
                <p class="m-0 t1 color-secondary">{{ data['billing_address'].street1 }}</p>
                <p class="m-0 t1 color-secondary">{{ data['billing_address'].zipcode }} {{
                    data['billing_address'].city
                  }}, {{ data['billing_address'].country }}</p>
              </div>
            </div>

            <!-- paiement mode -->
            <div class="mt-4 ps-2">
              <h6 class="h6">Mode de paiement</h6>
              <p class="m-0 t1 color-secondary" v-if="data.payments[0].type == 'card'">Carte bancaire</p>
              <p class="m-0 t1 color-secondary" v-if="data.payments[0].type == 'virement'">Virement bancaire</p>
            </div>

            <!-- table -->
            <div class="table-responsive-sm mt-4">
              <table class="table table-borderless commande-table">
                <thead>
                <tr class="pt-5 pb-5">
                  <th scope="col">Description</th>
                  <th scope="col" style="text-align: center">Prix unitaire HT</th>
                  <th scope="col" style="text-align: center">Quantité</th>
                  <th scope="col" style="text-align: center">Total HT</th>
                </tr>
                </thead>
                <!-- projet print -->
                <tbody v-if="data.type == 'print'">
                <tr v-for="(item, index) in data.items" :key="index" class="pt-5 pb-5">
                  <th scope="row">
                    <a v-if="item.download_url !== undefined" :href="urlApp + item.download_url">{{ item.filename }}</a>
                    <span v-else>{{ item.filename }}</span>
                    | {{ item.dimensions.x }} x {{ item.dimensions.y }} x {{ item.dimensions.z }} mm | {{ item.volume }}
                    cm³ | {{ item.material }} | {{ item.color }} | {{ item.technology }} {{ item.layer }} µm  | {{ item.application }}
                  </th>
                  <td style="text-align: center">{{ (item.unitSellAmountTaxExcl / 100) | formatPrice }} €</td>
                  <td style="text-align: center">{{ item.quantity }}</td>
                  <td style="text-align: center">{{
                      ((item.unitSellAmountTaxExcl / 100) * item.quantity) | formatPrice
                    }} €
                  </td>
                </tr>
                </tbody>
                <!-- projet desgin -->
                <tbody v-if="data.type == 'design'">
                <tr v-for="(item, index) in data.quotation.lines" :key="index" class="pt-5 pb-5">
                  <th scope="row">{{ item.description }}</th>
                  <td style="text-align: center">{{ (item.price / 100) | formatPrice }} €</td>
                  <td style="text-align: center">{{ item.quantity }}</td>
                  <td style="text-align: center">{{ ((item.price / 100) * item.quantity) | formatPrice }} €</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- ------ -->

            <!-- <div class="ps-2">livraison {{ data['shipping_amount_tax_incl'] / 100 | formatPrice }} €</div> -->

            <div class="mt-5 pe-sm-4">
              <div v-if="data['fee_show_amount_tax_excl'] > 0" class="d-flex justify-content-end pt-2 pb-2">
                <span class="min-w-100 h6 mb-0">Complément de commande</span>
                <span class="min-w-100 t1 color-secondary text-center">{{
                    data['fee_show_amount_tax_excl'] / 100 | formatPrice
                  }} €</span>
              </div>
              <div class="d-flex justify-content-end pt-2 pb-2">
                <span class="min-w-100 h6 mb-0">Frais de port HT</span>
                <span class="min-w-100 t1 color-secondary text-center">{{
                    data['shipping_amount_tax_excl'] / 100 | formatPrice
                  }} €</span>
              </div>
              <div v-if="data['discount_amount_tax_excl'] !== undefined && data['coupon'] !== undefined" class="d-flex justify-content-end pt-2 pb-2">
                <span class="min-w-100 h6 mb-0">{{ data['coupon']['label'] }} HT</span>
                <span class="min-w-100 t1 color-secondary text-center">
                  -{{ data['discount_amount_tax_excl'] / 100 | formatPrice }} €
                </span>
              </div>
              <div class="d-flex justify-content-end pt-2 pb-2">
                <span class="min-w-100 h6 mb-0">Total HT</span>
                <span class="min-w-100 t1 color-secondary text-center">{{
                    data['total_amount_tax_excl'] / 100 | formatPrice
                  }} €</span>
              </div>
              <div class="d-flex justify-content-end pt-2 pb-2">
                <span class="min-w-100 h6 mb-0">TVA (20%)</span>
                <span class="min-w-100 t1 color-secondary text-center">{{
                    (data['total_amount_tax_incl'] - data['total_amount_tax_excl']) / 100 | formatPrice
                  }} €</span>
              </div>
              <div class="d-flex justify-content-end pt-2 pb-2">
                <span class="min-w-100 h6 mb-0">Total TTC</span>
                <span class="min-w-100 t1 color-secondary text-center">
                  {{ (data['total_amount_tax_incl']) / 100 | formatPrice }} €
                </span>
              </div>
            </div>

<!--            <div class="text-center mt-5">
              <button v-if="data['status_id'] == 2" class="btn-u3dm btn-u3dm&#45;&#45;primary ms-3" @click="cancelOrdered">
                Annuler la commande
              </button>
            </div>-->

          </div><!-- bg-white -->

          <!-- ------------------- -->
          <!-- ------------------- -->


        </div><!-- content -->

      </div>

      <transition name="discussion-appearDrawer">
        <drawer-discussion
            v-if="drawerDiscussionIsOpen == true"
            @close="drawerDiscussionIsOpen = false"
            :orderReference="orderActive"
            :projectReference="data.quotation !== undefined ? data.quotation.project.reference : ''"
            :sendIsPossible="orderStatus"></drawer-discussion>
      </transition>

      <transition name="discussion-appearDrawer">
        <drawer-file-validation
            v-if="drawerValidationIsOpen == true"
            @close="drawerValidationIsOpen = false"
            :orderReference="orderActive"></drawer-file-validation>
      </transition>

      <transition name="discussion-appearDrawer">
        <drawer-notation
            v-if="drawerNotationIsOpen == true"
            @close="drawerNotationIsOpen = false"
            :orderReference="orderActive"></drawer-notation>
      </transition>

    </div>


    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import NavigationSite from '../components/ui/NavigationSite.vue'
import DrawerDiscussion from '../components/commande/DrawerDiscussion.vue';
import DrawerFileValidation from '../components/commande/DrawerFileValidation.vue';

import {Iban} from '../variables/variables.js'

import {mapActions} from "vuex";
import DrawerNotation from '../components/commande/DrawerNotation.vue';

export default {
  components: {NavigationSite, FooterSite, DrawerDiscussion, DrawerFileValidation, DrawerNotation,},
  name: "Commande",
  computed: {
    urlDownloadQuotation () {
      return this.$route.params.token === undefined ?
          this.urlApp + '/fr/quotation/' + this.data.quotation.reference + '/pdf' : this.urlApp + '/fr/quotation/' + this.data.quotation.reference + '/pdf/' + this.$route.params.token
    },
    areOrdersPrintDelivered() {
      if (this.data.type === 'print') {
        let isStatusNotDelivered = this.data.orders.filter(order => {
          return order.status_id !== 8
        }).length > 0
        if(isStatusNotDelivered) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
  },
  data() {
    return {
      // autre
      ibanU3dm: Iban,
      data: null,
      isLoaded: false,
      drawerDiscussionIsOpen: false,
      drawerValidationIsOpen: false,
      drawerNotationIsOpen: false,
      orderActive: null,
      orderStatus: null,
      urlApp: process.env.VUE_APP_APP_BASE_URL_WITHOUT_SLASH,

      // list
      listStatusCanSeeRating: [11],
      listStatusCanSendMessage: [2, 5, 6, 15, 16, 17, 18],
      listStatusCanSendDownloadFile: [6, 7, 8, 9, 11, 15, 18, 23, 24, 25],

      token: '',
    }
  },
  methods: {
    ...mapActions("authentication", [
      "setConnected"
    ]),
    ...mapActions("routeRedirect", [
      "updateRoute"
    ]),
    checkDownloadLoading: () => {
      setInterval(function () {
        // Get the cookie
        const value = `; ${document.cookie}`;
        const parts = value.split(`; fileLoading=`);
        if (parts.length === 2) {
          var fileLoading = parts.pop().split(';').shift();
        }

        // Remove cookie
        document.cookie = 'fileLoading=; domain=' + process.env.VUE_APP_COOKIE_DOMAIN + '; path=/; Max-Age=0;';

        if (fileLoading !== undefined) {
          location.reload();
        }

      }.bind(this), 1000);
    },
    generateSendLabel(referenceOrderSell) {

      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/generate/label/' + referenceOrderSell + this.token, {credentials: true})
          .then(() => {
            this.getOrderSell()
          }, () => {
            // console.log(error)
          })
    },
    openSendBoxOrderSell(reference) {
      this.drawerDiscussionIsOpen = true
      this.orderActive = reference
      this.orderStatus = true
    },
    openSendBox(reference, status) {
      this.drawerDiscussionIsOpen = true
      this.orderActive = reference

      if (this.listStatusCanSendMessage.indexOf(status) == -1) {
        this.orderStatus = false
      } else {
        this.orderStatus = true
      }
    },
    openValidationBox(reference) {
      this.drawerValidationIsOpen = true
      this.orderActive = reference
    },
    openNotationBox(reference) {
      this.drawerNotationIsOpen = true
      this.orderActive = reference
    },
    cancelOrdered() {

      this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/' + this.$route.params.reference + this.token, {credentials: true})
          .then(() => {
            // console.log(response)
            this.getOrderSell()
          }, () => {
            // console.log(error)
          })
    },
    getOrderSell() {
      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/' + this.$route.params.reference + this.token, {credentials: true})
          .then((response) => {
            // console.log(response)
            this.data = response.body
            this.isLoaded = true
          }, () => {
            // console.log(error)
          })
    }
  },
  mounted() {
    this.checkDownloadLoading();
  },
  beforeMount() {

    this.setConnected().then(
        () => {
        },
        () => {
          if (this.$route.params.token == undefined) {

            this.updateRoute(this.$route.path)
            this.$router.push({name: 'Login'})
          }
        }
    );

    if (this.$route.params.token != undefined) {
      this.token = '/' + this.$route.params.token
    }

    this.getOrderSell()
  }
}
</script>