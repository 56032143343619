<template>
  <div class="material-drawer" v-if="drawerMaterialIsVisible">
    <!-- Backdrop -->
    <div class="material-drawer-overlay appearOpacity"></div>

    <!-- Sidebar -->
    <div class="material-drawer-sidebar ">
        <div class="material-drawer-close" @click="close">
          <icon-cross :color="'#7C7C7C'"></icon-cross>
        </div>

        

        <carousel-material></carousel-material>
        
       
        

    </div><!-- material-drawer-sidebar -->

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import IconCross from '../icon/IconCross.vue';
import CarouselMaterial from './component/CarouselMaterial.vue';

export default {
  components: { IconCross, CarouselMaterial },
  name: "DrawerMaterial",
  computed: {
    ...mapState("printManager", {
      drawerMaterialIsVisible: (state) => state.drawerMaterialIsVisible,
    }),
  },  
  methods: {
    ...mapActions("printManager", [
      "updateDrawerMaterialIsVisible"
    ]),
    close() {
        this.updateDrawerMaterialIsVisible(false)
    }
  }
};
</script>