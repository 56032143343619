<template>
<svg :width="width" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
<path d="M11.1647 11.94C10.9669 11.94 10.7686 11.8645 10.6177 11.7136L0.285 1.38043C-0.016875 1.07855 -0.016875 0.588707 0.285 0.286831C0.586875 -0.015044 1.07672 -0.015044 1.37859 0.286831L11.7117 10.62C12.0136 10.9218 12.0136 11.4117 11.7117 11.7136C11.5603 11.8645 11.3625 11.94 11.1647 11.94Z" :fill="color"/>
<path d="M0.833985 11.94C0.636173 11.94 0.437891 11.8645 0.286953 11.7136C-0.014922 11.4117 -0.014922 10.9218 0.286953 10.62L10.6201 0.286831C10.922 -0.015044 11.4118 -0.015044 11.7137 0.286831C12.0156 0.588707 12.0156 1.07855 11.7137 1.38043L1.38102 11.7131C1.23008 11.8645 1.03227 11.94 0.833985 11.94Z" :fill="color"/>
</svg>
</template>

<script>
export default {
    name: 'IconValid',
    props: {
        color: {
            type: String,
            default: 'inherit'
        },
        width: {
            type: Number,
            default: 24
        }
    }
}
</script>