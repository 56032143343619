<template>


<!-- <svg :width="width" viewBox="0 -46 417.81333 417"  xmlns="http://www.w3.org/2000/svg">
<path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" :fill="color"/>
</svg> -->

<svg :width="width" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667" xml:space="preserve">
		<path d="M421.876,56.307c-6.548-6.78-17.352-6.968-24.132-0.42c-0.142,0.137-0.282,0.277-0.42,0.42L119.257,334.375
			l-90.334-90.334c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.713l102.4,102.4
			c6.665,6.663,17.468,6.663,24.132,0L421.456,80.44C428.236,73.891,428.424,63.087,421.876,56.307z" :fill="color"/>
</svg>



</template>

<script>
export default {
    name: 'IconValid',
    props: {
        color: {
            type: String,
            default: 'inherit'
        },
        width: {
            type: Number,
            default: 24
        }
    }
}
</script>