<template>
  <div>
    <div class="a-accordion-headers-mobile">

      <div 
      :class="lastChildActive == 1 ? 'theme--active' : ''"
      @click="open(1)">
        <icon-file :width="32" :color="lastChildActive == 1 ? '#7F1F23' : '#161616'"></icon-file>
      </div>
      <div 
      :class="[lastChildActive == 2 ? 'theme--active' : '', stepNextPossible >= 2 ? '' : 'theme--disabled']"
      @click="openViewerMobile()">
        <icon-dimension :width="32" :color="lastChildActive == 0 ? '#7F1F23' : '#161616'"></icon-dimension>
      </div>

      <div 
      :class="[lastChildActive == 2 ? 'theme--active' : '', stepNextPossible >= 2 ? '' : 'theme--disabled']"
      @click="open(2)">
        <icon-project :width="32" :color="lastChildActive == 2 ? '#7F1F23' : '#161616'"></icon-project>
      </div>
      <div 
      :class="[lastChildActive == 3 ? 'theme--active' : '', stepNextPossible >= 3 ? '' : 'theme--disabled']"
      @click="open(3)">
        <icon-thread :width="32" :color="lastChildActive == 3 ? '#7F1F23' : '#161616'"></icon-thread>
      </div>
      <div 
      :class="[lastChildActive == 4 ? 'theme--active' : '', stepNextPossible >= 4 ? '' : 'theme--disabled']"
      @click="open(4)">
        <icon-option :width="32" :color="lastChildActive == 4 ? '#7F1F23' : '#161616'"></icon-option>
      </div>
    </div>
    <div>
      <h4 v-if="lastChildActive == 1" class="h4 ps-3 mt-3">Déposer votre fichier 3D</h4>
      <h4 v-if="lastChildActive == 2" class="h4 ps-3 mt-3">Sélectionner votre application</h4>
      <h4 v-if="lastChildActive == 3" class="h4 ps-3 mt-3">Sélectionner un matériaux</h4>
      <h4 v-if="lastChildActive == 4" class="h4 ps-3 mt-3">Sélectionner vos options</h4>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import IconDimension from '../icon/IconDimension.vue';
import IconFile from '../icon/IconFile.vue';
import IconOption from '../icon/IconOption.vue';
import IconProject from '../icon/IconProject.vue';
import IconThread from '../icon/IconThread.vue';


export default {
  components: { IconFile, IconProject, IconThread, IconOption,IconDimension },
  name: "AccordionNavMobile",
  computed: {
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      stepNextPossible: (state) => state.stepNextPossible,
    }),
  },
  methods: {
    ...mapActions("printManager", ["updateLastChildActive","updateViewerMobileIsOpen"]),
    open(index) {
        if (this.stepNextPossible < index) {
          return
        }
        if (this.lastChildActive == index) {
          this.updateLastChildActive(0)
        } else {
          this.updateLastChildActive(index)
        }
    },
    openViewerMobile() {
      this.updateViewerMobileIsOpen(true)
    },
  },
};
</script>