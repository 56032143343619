<template>
  <div class="view-project">
     
    <sidebar-project></sidebar-project>
 
    <!-- Progress bar -->
    <div class="project-progress">
      <div class="project-progress__bar" :style="{ width: barStep + '%' }"></div>
    </div>
    
    <!-- projects -->
    <div class="contain__projects">
      
     
      <div class="container">

        
        <loader-one v-if="loaderUi"></loader-one>

        <project-name v-if="stepNameActive == 'name'"></project-name>
       
        <project-type v-if="stepNameActive == 'projectType'"></project-type>

        <project-date v-if="stepNameActive == 'deliveryTime'" :items="REF_dates"></project-date>

        <project-description v-if="stepNameActive == 'description'"></project-description>

        <project-upload :is-mandatory="false" v-if="stepNameActive == 'file'"></project-upload>

        <project-upload :is-mandatory="true" v-if="stepNameActive == 'fileMandatory'"></project-upload>

        <project-zipcode v-if="stepNameActive == 'zipcode'"></project-zipcode>

        <project-apply v-if="stepNameActive == 'fields'" :items="REF_fields"></project-apply>

        <project-dimension v-if="stepNameActive == 'dim'"></project-dimension>

        <project-recap v-if="stepNameActive == 'recap'" :refFields="REF_fields" :refTypes="REF_types" :refDates="REF_dates"></project-recap>

        <project-thanks v-if="stepNameActive == 'thanks'"></project-thanks>

        <project-how-scan v-if="stepNameActive == 'howScan'"></project-how-scan>

        <project-ask-one v-if="stepNameActive == 'projectAskOne'"></project-ask-one>

        <project-ask-two v-if="stepNameActive == 'projectAskTwo'"></project-ask-two>

        <project-want-devis v-if="stepNameActive == 'wantDevis'"></project-want-devis>

        <project-ask-four v-if="stepNameActive == 'projectAskFour'"></project-ask-four>

        <project-ask-five v-if="stepNameActive == 'projectAskFive'"></project-ask-five>

        <project-reprise v-if="stepNameActive == 'reprise'" :stepConfig="givenWay" :refFields="REF_fields" :refDates="REF_dates"></project-reprise>

      </div>
      
    </div>


    <transition name="fade-modal-login">        
      <modal-login-project v-if="modalVisible" @close="modalToggle"></modal-login-project>
    </transition>
    <transition name="fade-modal-login">
      <modal-save-login-project v-if="modalSaveVisible" @close="modalSaveToggle"></modal-save-login-project>
    </transition>
    <transition name="fade-modal-login">
      <modal-save-project v-if="isModalSaveProjectVisible" @close="isModalSaveProjectVisible = false"></modal-save-project>
    </transition>

  </div>
</template>

<script>
import ProjectName from '../components/project/ProjectName.vue';
import ProjectDescription from '../components/project/ProjectDescription.vue';
import ProjectType from '../components/project/ProjectType.vue';
import ProjectDate from '../components/project/ProjectDate.vue';
import ProjectUpload from '../components/project/ProjectUpload.vue';
import ProjectApply from '../components/project/ProjectApply.vue';
import ProjectDimension from '../components/project/ProjectDimension.vue';
import SidebarProject from '../components/project/SidebarProject.vue';
import ProjectRecap from '../components/project/ProjectRecap.vue';
import ProjectThanks from '../components/project/ProjectThanks.vue';
import ProjectZipcode from '../components/project/ProjectZipcode.vue';
import ProjectAskOne from '../components/project/ProjectAskOne.vue';
import ProjectAskTwo from '../components/project/ProjectAskTwo.vue';
import ProjectHowScan from '../components/project/ProjectHowScan.vue';
import ProjectAskFour from '../components/project/ProjectAskFour.vue';
import ProjectAskFive from '../components/project/ProjectAskFive.vue';
import ProjectWantDevis from '../components/project/ProjectWantDevis.vue';

import ModalLoginProject from '../components/modal/ModalLoginProject.vue';
import ModalSaveProject from '../components/modal/ModalSaveProject.vue';
import ModalSaveLoginProject from "../components/modal/ModalSaveLoginProject";
import LoaderOne from '../components/loaders/LoaderOne.vue';
import ProjectReprise from '../components/project/ProjectReprise.vue';

import REF_Static_dates from '../components/project/static_ref/ref_dates.json'

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Project',
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Projet 3D',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Décrivez votre projet 3D, et nous vous contacterons très rapidement pour votre proposer un devis.' }
    ],
  },
  components: {
    ProjectName,
    ProjectDescription,
    ProjectType,
    ProjectDate,
    ProjectUpload,
    ProjectApply,
    ProjectDimension,
    SidebarProject,
    ProjectRecap,
    ProjectThanks,
    ProjectZipcode,
    ProjectAskOne,
    ProjectAskTwo,
    ProjectHowScan,
    ProjectAskFour,
    ProjectAskFive,
    ProjectWantDevis,
    ModalLoginProject,
    LoaderOne,
    ProjectReprise,
    ModalSaveProject,
    ModalSaveLoginProject
  },
  data() {
      return{
      REF_dates: REF_Static_dates,
      REF_fields: null,
      REF_types: null,
      modalVisible: false,
      isModalSaveProjectVisible: false,
      modalSaveVisible: false,
      loaderUi: false,
      givenWay: {}
    };
  },
  computed: {
    ...mapState('projectDesign', {
      projectDesign: state => state.projectDesign,
      stepNameActive: state => state.steps.stepNameActive,
      stepNumActive: state => state.steps.stepNumActive,
      stepList: state => state.steps.stepList,
    }),
    ...mapGetters('projectDesign', {
      barStep: 'barStep',
    })
	},
  beforeMount() {
    if(this.$route.name === 'ProjectCorrection') {
      window.location.href = '#recap'
    } else {
      window.location.href = '#start'
    }

    window.onhashchange = (e) => {
      let oldStepNumActive = parseInt(e.oldURL.split('#')[1])
      let newStepNumActive = parseInt(e.newURL.split('#')[1])
      let isOldStepRecap = e.oldURL.split('#')[1] === 'recap'
      let isNewStepRecap = e.newURL.split('#')[1] === 'recap'
      // let isOldStepStart = e.oldURL.split('#')[1] === 'start'
      // let isNewStepStart = e.newURL.split('#')[1] === 'start'
      let isOldStepThanks = e.oldURL.split('#')[1] === 'thanks'
      let isNewStepThanks = e.newURL.split('#')[1] === 'thanks'

      // console.log(oldStepNumActive, newStepNumActive, this.stepNumActive, isOldStepRecap, isNewStepRecap)
      // console.log(isOldStepStart, isNewStepStart)
      if(isOldStepThanks || isNewStepThanks) {
        window.location.href = '#thanks'
        e.preventDefault()
        return false
      }
      else if(isOldStepRecap || isNewStepRecap) {
        this.goOnStepByName('recap')
        window.location.href = '#recap'
        e.preventDefault()
        return false
      } else if (oldStepNumActive !== undefined && newStepNumActive < this.stepNumActive && newStepNumActive > 0 || oldStepNumActive > 0 && isNaN(newStepNumActive)) {
        this.decrementWithoutAnchor({oldStepNumActive: oldStepNumActive, newStepNumActive: newStepNumActive})
        e.preventDefault()
        return false
      }
    }

    this.loaderUi = !this.loaderUi

    // ----- Get referentiel
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'design/referentiel').then((response) => {

      this.REF_fields = response.body.fields
      this.REF_types = response.body.projectTypes

    
      
    }, () => {
        //  console.log(error)
    })

    if(this.$route.name === 'ProjectCorrection') {
      let getProjectEndpoint = process.env.VUE_APP_API_BASE_URL + 'project/' + this.$route.params.ref
      if(this.$route.params.token !== undefined) {
        getProjectEndpoint = getProjectEndpoint + '/' + this.$route.params.token
      }
      this.$http.get(getProjectEndpoint, {credentials: true})
          .then(response => {
            this.loaderUi = !this.loaderUi
            if(response.body.status !== 8) {
              this.goOnStepByName('thanks')
              this.updateStepsList(response.body.way)
              let token = this.$route.params.token === undefined ? '' : '/' + this.$route.params.token
              this.updateSeeProjectAfterSave({token: token, reference: this.$route.params.ref})
            } else {
              this.goOnStepByName('recap')
              this.updateProjectDesign(response.body)
              this.updateStepsList(response.body.way)
              this.updateStepNumActive(this.stepList.length - 2)
            }
          }, error => {
            if (error.body.redirect === 'home') {
              window.location.replace(process.env.VUE_APP_WWW_BASE_URL)
            }
            if (error.body.redirect === 'login') {
              this.$router.push("account")
            }
          })
    } else {
      if (this.$route.params.ref !== undefined && this.$route.params.token !== undefined) {
        this.$http.get(process.env.VUE_APP_API_BASE_URL + 'project/' + this.$route.params.ref + '/' + this.$route.params.token, {credentials: true})
            .then((response) => {
              this.goOnStepByName('reprise')
              this.updateProjectDesign(response.body)

              this.givenWay = {
                list: response.body.way,
                num: response.body.last_step,
                name: ''
              }

              this.loaderUi = !this.loaderUi

            }, (error) => {
              if (error.body.redirect === 'login') {
                // window.location.replace(process.env.VUE_APP_APP_BASE_URL + 'fr/mon-compte/login')
                this.$router.push("account")
              }
            })

      } else if (this.$route.params.ref !== undefined && this.$route.params.token == undefined) {

        this.$http.get(process.env.VUE_APP_API_BASE_URL + 'project/' + this.$route.params.ref, {credentials: true})
            .then(response => {
              this.goOnStepByName('reprise')
              this.updateProjectDesign(response.body)

              this.givenWay = {
                list: response.body.way,
                num: response.body.last_step,
                name: ''
              }

              this.loaderUi = !this.loaderUi

            }, error => {
              if (error.body.redirect === 'home') {
                window.location.replace(process.env.VUE_APP_WWW_BASE_URL)
              }
              if (error.body.redirect === 'login') {
                this.$router.push("account")
              }
            })

      } else {
        // this.initialiseWay({list: 'IMPRESSION_A', num: 0})

        this.updateStepsList('IMPRESSION_A')
        this.updateStepNumActive(0)
        this.updateWayCurrent('IMPRESSION_A')
        this.goOnStep()
        this.loaderUi = !this.loaderUi
      }
    }

  },
  methods: {
    ...mapActions('projectDesign', [
      'resetCurrentGoogleTag',
      'resetCurrentGoogleRecapTag',
      'resetProject',
      'resetSteps',
      'updateProjectDesign',
      'goOnStepByName',
      'updateName',
      'updateStepsList',
      'updateStepNumActive',
      'updateWayCurrent',
      'goOnStep',
      'updateReturnToRecap',
      'updateSeeProjectAfterSave',
      'decrement',
      'decrementWithoutAnchor',
    ]),
    modalToggle() {
      this.modalVisible = !this.modalVisible
    },
    modalSaveToggle() {
      this.modalSaveVisible = !this.modalSaveVisible
    }
  },
/*  beforeRouteLeave(to, from, next) {
    if (confirm('Vous avez peut être des changements non sauvegardés. Voulez vous quitter la création de projet ?')) {
      return next()
    }
  },*/
  destroyed() {
    this.resetCurrentGoogleTag()
    this.resetCurrentGoogleRecapTag()
    this.resetProject()
    this.resetSteps()
    this.updateReturnToRecap(false)
  }
};
</script>