<template>
  <div class="project">
    <div class="project-wrapp project-wrapp--lg">

      <h2 class="text-center h3 mt-5">Votre projet : "{{ projectDesign.name }}"</h2>

      <div class="mt-5">
        <!-- Nom -->
        <div class="p-3 background-white mt-3">
          <h5 class="t3 color-secondary">Nom du projet</h5>
          <h6 class="h6">{{ projectDesign.name }}</h6>
        </div>

        <!-- Type -->
        <div v-if="projectDesign.projectType" class="p-3 background-white">
          <h5 class="t3 color-secondary">Type du projet</h5>
          <h6 class="h6">{{ projectDesign.projectType.name }}</h6>
        </div>

        <!-- Delais souhaité -->
        <div v-if="getDate" class="p-3 mt-3 background-white">
          <span class="t3 color-secondary">Delais souhaité</span>
          <h6 class="h6">{{ getDate.text }}</h6>
        </div>

        <!-- description -->
        <div v-if="projectDesign.description !== ''" class="p-3 mt-3 background-white">
            <h5 class="t3 color-secondary">Description</h5>
            <div class="pe-2 overflow-hidden ">
              <p
                v-for="(line, index) in projectDesign.description.split('\n')"
                :key="index"
                class="h6 mb-0"
              >
                {{ line }}<br />
              </p>
            </div>
          </div>


        <!-- application -->
        <div v-if="getFieldsName.length > 0" class="p-3 mt-3 background-white">
          <h5 class="t3 color-secondary">Application(s)</h5>
          <div>
            <h6 v-for="(value, index) in getFieldsName" :key="index" class="h6 d-inline mb-0">
              <span v-if="index > 0">, </span>{{ value.name }}
            </h6>
          </div>
        </div>

        <!-- Dimension -->
        <div  v-if="projectDesign.dim.x > 0" class="p-3 mt-3 background-white">
          <h5 class="t3 color-secondary">Dimensions en mm (l x L x H)</h5>
          <div>
            <h6 class="h6 d-inline">{{ projectDesign.dim.x }} </h6>
            <h6 class="h6 d-inline">x {{ projectDesign.dim.y }} </h6>
            <h6 class="h6 d-inline">x {{ projectDesign.dim.z }}</h6>
          </div>
        </div>

        <!-- Code postal -->
        <div v-if="projectDesign.zipcode" class="p-3 mt-3 background-white">
          <h5 class="t3 color-secondary">Code postal</h5>
          <h6 class="h6">{{ projectDesign.zipcode }}</h6>
        </div>

        <!-- Solution pour scanner l'object -->
        <div v-if="projectDesign.howScan > 0" class="p-3 mt-3 background-white">
          <h5 class="t3 color-secondary">Solution pour scanner l'object</h5>
          <div>
            <h6 v-if="projectDesign.howScan == 1" class="h6">Déplacement du maker</h6>
            <h6 v-if="projectDesign.howScan == 2" class="h6">Envoie et retour de l'object</h6>
            <h6 v-if="projectDesign.howScan == 3" class="h6">Autre préciser dans la description</h6>
          </div>
        </div>

        <!-- Fichiers -->
        <div v-if="projectDesign.savedFile.length > 0" class="p-3 mt-3 background-white">
          <h5 class="t3 color-secondary">Fichier(s)</h5>
          <div>
            <div v-for="savedFile in projectDesign.savedFile" :key="savedFile.original_name">
              <h6 class="h6 mb-0">{{ savedFile.original_name }}</h6>
            </div>
          </div>
        </div>

        <!-- Devis pour une impression en option -->
        <div v-if="projectDesign.quoteOptionForPrinting" class="p-3 mt-3 background-white">
          <h5 class="t3 color-secondary">Devis pour une impression en option</h5>
          <div>
            <h6 class="h6">{{projectDesign.quoteOptionForPrinting == true ? "oui" : "non"}}</h6>
          </div>
        </div>
      </div>

      <!-- action -->
      <div  v-if="projectDesign.status == 1" class="project-action--fixed">
        <button class="btn-u3dm btn-u3dm--primary" @click="repriseProject">
          Reprendre mon projet
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectReprise",
  props: {
    refDates: {
      type: Array,
      default() {
        return [];
      },
    },
    refFields: {
      type: Array,
      default() {
        return [];
      },
    },
    stepConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState("projectDesign", {
      projectDesign: (state) => state.projectDesign,
    }),
    ...mapState("authentication", {
      customerId: (state) => state.id,
    }),
    ...mapGetters("authentication", {
      userConnected: "isConnected",
    }),
    getFieldsName() {
      let tab = this.refFields.filter((elt) => {
        return this.projectDesign.fields.indexOf(elt.id) > -1;
      });

      return tab;
    },
    getProjectTypes() {
      let tab = [];
      tab = this.refTypes.filter((elt) => {
        return elt.id == this.projectDesign.projectType;
      });
      return tab[0];
    },
    getDate() {
      let tab = [];
      tab = this.refDates.filter((elt) => {
        return elt.title == this.projectDesign.deliveryTime;
      });
      return tab[0];
    },
  },
  methods: {
    modalToggle() {
      this.modalVisible = !this.modalVisible;
    },
    ...mapActions("projectDesign", [
      "increment",
      "saveProject",
      "updateStepsList",
      "updateStepNumActive",
      "updateWayCurrent",
      "goOnStep",
    ]),
    repriseProject() {
      this.updateStepsList(this.stepConfig.list);
      this.updateStepNumActive(this.stepConfig.num);
      this.updateWayCurrent(this.stepConfig.list);

      this.goOnStep();
    },
  },
};
</script>