<template>
    <div class="material-carousel-outer">
        <div class="material-carousel">
           
            <div class="print-appearContentFromBottom h-100">
                
                <carousel-material-item></carousel-material-item>
     
            </div>

                <!-- carousel - action : prev -  next -->
                <div class="material-carousel-pagination">
                   
                    <span class="material-carousel-pagination-prev h6" @click="goPrev">Matériau précédent</span>
                    <span class="material-carousel-pagination-next h6" @click="goNext">Matériau suivant</span>
                </div>


        </div><!-- material-carousel -->
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CarouselMaterialItem from './CarouselMaterialItem.vue';

export default {
    name: "CarouselMaterial",
    components: { CarouselMaterialItem },
    computed: {
        ...mapState("printManager", {
            indexActive: (state) => state.manageCarousel.indexActive,
            lengthOftabMaterials: (state) => state.manageCarousel.lengthOftabMaterials
        }),
    },
    methods: {
        ...mapActions("printManager", [
        "updateIndexActive"
        ]),
        goPrev() {

            if (this.indexActive == 0 ) {
               
                this.updateIndexActive(this.lengthOftabMaterials - 1)
            } else {

                this.updateIndexActive(this.indexActive - 1)
            }
        
        },
        goNext() {

            if (this.indexActive + 1 == this.lengthOftabMaterials ) {
                
                this.updateIndexActive(0)
            } else {

                this.updateIndexActive(this.indexActive + 1)
            }
            
        }
    }
}
</script>
        