<template>
  <div class="a-accordion">
    <!-- STEP 1 - upload -->
    <accordion-item :index="1">
      <template v-slot:header>
        <accord-header-step-1></accord-header-step-1>
      </template>

      <template v-slot:content>
        <!-- form upload -->
        <accord-upload></accord-upload>
      </template>
    </accordion-item>

    <!-- STEP 2 - select application -->
    <accordion-item :index="2">
      <template v-slot:header>
        <accord-header-step-2></accord-header-step-2>
      </template>
      <template v-slot:content>
        <accord-applications></accord-applications>
      </template>
    </accordion-item>

    <!-- STEP 3 - select material -->
    <accordion-item :index="3">
      <template v-slot:header>
        <accord-header-step-3></accord-header-step-3>
      </template>
      <template v-slot:content>
        <div>
          <accord-materials></accord-materials>
        </div>
      </template>
    </accordion-item>

    <!-- STEP 4 - select options -->
    <accordion-item :index="4">
      <template v-slot:header>
        <accord-header-step-4></accord-header-step-4>
      </template>
      <template v-slot:content> 

        <accord-options></accord-options>

      </template>
    </accordion-item>
  </div>
</template>

<script>
import AccordApplications from "../AccordApplications.vue";
import AccordHeaderStep1 from "../AccordHeaderStep1.vue";
import AccordHeaderStep2 from "../AccordHeaderStep2.vue";
import AccordHeaderStep3 from "../AccordHeaderStep3.vue";
import AccordHeaderStep4 from "../AccordHeaderStep4.vue";
import AccordMaterials from "../AccordMaterials.vue";
import AccordOptions from '../AccordOptions.vue';
import AccordUpload from "../AccordUpload.vue";
import AccordionItem from "./AccordionItem.vue";

export default {
  components: {
    AccordionItem,
    AccordUpload,
    AccordApplications,
    AccordMaterials,
    AccordHeaderStep1,
    AccordHeaderStep2,
    AccordHeaderStep3,
    AccordHeaderStep4,
    AccordOptions,
  },
  name: "AccordionPrint",
  data() {
    return {};
  },
};
</script>