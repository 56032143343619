import { render, staticRenderFns } from "./MakerAccount.vue?vue&type=template&id=5e8faa5c&"
import script from "./MakerAccount.vue?vue&type=script&lang=js&"
export * from "./MakerAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports