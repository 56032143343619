<template>
  <div class="the-viewer">

    <!-- img empty -->
    <div v-if="file == null" class="viewer-img-empty">
      <svg
        width="172"
        height="142"
        viewBox="0 0 172 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M127.848 81.6973C127.848 108.063 101.909 129.512 70.019 129.512C38.1286 129.512 12.1897 108.063 12.1897 81.6973V12.3623H31.9621V81.6973C31.9621 99.8577 49.0276 114.623 70.019 114.623C91.0103 114.623 108.076 99.8577 108.076 81.6973V0.337891H63.9241V81.6973C63.9241 89.3894 67.0612 96.7101 72.4928 102.492C71.6861 102.563 70.8615 102.599 70.019 102.599C55.7499 102.599 44.1517 93.2266 44.1517 81.6973V0.337891H0V81.6973C0 114.694 31.4064 141.536 70.019 141.536H101.981C140.594 141.536 172 114.694 172 81.6973V0.337891H127.848V81.6973ZM76.1138 81.6973V12.3623H95.8862V81.6973C95.8862 88.3461 92.0142 94.2699 85.991 98.1071C79.8424 94.1638 76.1138 88.0808 76.1138 81.6973ZM140.038 12.3623H159.81V81.6973C159.81 104.809 139.877 124.136 113.49 128.557C129.641 117.594 140.038 100.671 140.038 81.6973V12.3623Z"
          fill="white"
        />
      </svg>
    </div>
    <!-- canvas -->
    <canvas id="canvas" ref="model" width="500" height="500"></canvas>

    <!-- dimension -->
    <print-dimensions></print-dimensions>
  </div>
</template>

<script>
import PrintDimensions from "./PrintDimensions.vue";

import { mapState, mapActions } from "vuex";

export default {
  components: { PrintDimensions },
  name: "TheViewer",
  data() {
    return {
      viewer: null,
      errorFileFormat: null,
    };
  },
  computed: {
    ...mapState("print", {
      file: (state) => state.printFile.file,
      color: (state) => state.printFile.color
    }),
  },
  watch: {
    file: function (newFile) {
      if (this.file) {

        if (this.viewer != null) {
          this.preview(newFile);
        } else {
          this.initViewer()
          this.preview(newFile);
        }

      } else {
        this.initViewer()
      }
    },
    color: function(newColor) {
      let hexacolor = null
      let colorFormatted = null

      if (newColor != null) {
        hexacolor = newColor['hexadecimal_code']
      } else {
          hexacolor = '0xC6C6C6'
      }
      
      if (hexacolor != null) {
        colorFormatted = '0x'+hexacolor.substring(1,7)
      } else {
        colorFormatted = '0xC6C6C6'
      }
      
      if (this.file) {
        this.viewer.getScene().forEachChild(function (mesh) {
          mesh.setMaterial(new window.JSC3D.Material("", 0, colorFormatted, 0, true));
        });
        this.viewer.update();
      }
        
    }
  },
  mounted() {
    // ----- CONFIG VIEWER AND INIT
    this.initViewer()
  },
  methods: {
    ...mapActions("print", ["updateFilename", "updateExtension"]),
    changeColor() {
      
    },
    preview(f) {
      let stlpath = ""; //"../../../assets/2013-10-23/stl/box.STL"
      this.viewer.setParameter("SceneUrl", stlpath);
      this.viewer.setParameter("InitRotationX", 20);
      this.viewer.setParameter("InitRotationY", 20);
      this.viewer.setParameter("InitRotationZ", 0);
      this.viewer.init();
      this.viewer.resetScene();

      let reader = new FileReader();
      let ext = f.name.split(".");
      ext = ext[ext.length-1];

      reader.onload = (() => {
        return (e) => {
          let theNewScene = new window.JSC3D.Scene();
          let extension = ext.toLowerCase();

          if (extension == "stl") {
            let stl_loader = new window.JSC3D.StlLoader();
            stl_loader.parseStl(theNewScene, e.target.result);
          } else if (extension == "obj") {
            let obj_loader = new window.JSC3D.ObjLoader();
            obj_loader.parseObj(theNewScene, e.target.result);
          }

          this.updateFilename(f.name);
          this.updateExtension(extension);

          this.viewer.replaceScene(theNewScene);
          this.viewer.resetScene();
          this.viewer.update();
        };
      })(f);
      reader.readAsBinaryString(f);
    },
    initViewer() {
      // ----- CONFIG VIEWER AND INIT
      
        if (typeof window.JSC3D !== typeof {}) {
          // this.$forceUpdate();
          this.$router.go(0)
        }
        this.viewer = new window.JSC3D.Viewer(document.getElementById("canvas"));
        this.viewer.setParameter("SceneUrl", ""); // ajout recent pour contrer le bug
        this.viewer.setParameter("ModelColor", "#C6C6C6");
        this.viewer.setParameter("BackgroundColor1", "#FAFAFA");
        this.viewer.setParameter("BackgroundColor2", "#FAFAFA");
        this.viewer.setParameter("Definition", "high");
        this.viewer.setParameter("RenderMode", "flat");
        //  this.viewer.setParameter("RenderMode", "wireframe");
        this.viewer.setParameter("Render", "webgl");
        this.viewer.init();

    }
  },
};
</script>