<template>
  <div class="project-action">
    <button :class="'btn-u3dm btn-u3dm--primary ' + this.classBtn" @click="returnToRecap" :disabled="this.disabled">
      Retour au récapitulatif
    </button>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ReturnToRecapButton",
  props: {
    updateValue: {
      type: Function
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classBtn: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('projectDesign', [
      'goOnStepByName'
    ]),
    returnToRecap() {
      if(this.updateValue()) {
        this.goOnStepByName('recap');
        window.location.href = '#recap'
      }
    }
  },
}
</script>
