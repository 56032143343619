
<template>
    <div>
        <div v-if="listProductsProject.length == 0">
            <product-order-print-item v-for="(item, index) in listProductsPrint" :key="index" :item="item" ></product-order-print-item>
        </div>
       
        <div v-if="listProductsProject.length > 0">
            <h5 class="h5">{{ productProjectName }}</h5>
          <table id="quotation-cat">
            <thead>
              <tr>
                <th>Prestation</th>
                <th>Quantité</th>
                <th>Prix Unitaire</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
            <product-order-project-item v-for="(line, i) in filteredListProductsProject" :key="i"
                                        :item="line"></product-order-project-item>
            </tbody>
          </table>
        </div>
    </div>
</template>

<script>
import ProductOrderPrintItem from './ProductOrderPrintItem.vue'
import ProductOrderProjectItem from './ProductOrderProjectItem.vue'

import { mapState } from 'vuex'

export default {
  components: { ProductOrderPrintItem, ProductOrderProjectItem },
    name: "ProductOrderList",
    computed: {
    ...mapState("shop", {
        listProductsPrint: (state) => state.listProductsPrint,
        listProductsProject: (state) => state.listProductsProject,
        productProjectName: (state) => state.productProjectName
        }),
      filteredListProductsProject: function () {
        return this.listProductsProject.filter(i => i.quantity > 0)
      },
    },
}
</script>