<template>
  <div>
    <!-- mode selection -->
    <div v-if="viewAllTechnology == false">

      <p v-if="application !== null" class="t3 color-secondary mt-2">
        Notre sélection de matériaux pour votre projet 
        <span class="lower-case">{{ application.name }}</span>
      </p>

      <div class="accord-material-container d-flex flex-wrap pl-5">

        <accord-material-item 
            v-for="(item, index) in sortMaterialItemsAlphabetically"
            :key="index"
            :item="item"
            :indexItemInTab="index"
            :class="(index + 1) % 2 == 1 ? 'print-appearItem__item--impair' : 'print-appearItem__item--pair'"
            ></accord-material-item>

      </div>

      <div class="mt-5 t3 pb-4 text-end" 
        @click="callApiAllTechnologie">Voir toutes les technologies</div>

    </div>

    <!-- mode all (voir tous les matériaux) -->
    <div v-if="viewAllTechnology == true">
      
      <div class="mode-all-nav">
        <tabs-technologie-nav></tabs-technologie-nav>
        
      

      </div>

      <tabs-technologie-item></tabs-technologie-item>
      <div v-if="oldApplication.id > 0 || application.id >= 1"
            class="t3 come-back text-end" 
            @click="comeBackSelection">Revenir à notre sélection </div>

    </div>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import AccordMaterialItem from './AccordMaterialItem.vue';
import TabsTechnologieItem from './component/TabsTechnologieItem.vue';
import TabsTechnologieNav from './component/TabsTechnologieNav.vue';

export default {
  name: "AccordMaterials",
  components: { AccordMaterialItem, TabsTechnologieNav, TabsTechnologieItem, },
  data() {
    return {
      modeAll: false
    }
  },
  computed: {
    ...mapState("print", {
      volumeDisplay: (state) => state.printFile.volumeDisplay,
      application: (state) => state.printFile.application,
      dimensions: (state) => state.printFile.dimensions,
      oldApplication: (state) => state.printFile.oldApplication
    }),
    ...mapState("printManager", {
      viewAllTechnology: (state) => state.viewAllTechnology,
    }),
    ...mapGetters("print", {
      sortMaterialItemsAlphabetically: 'sortMaterialItemsAlphabetically',
    })
  },


  watch: {
    application() {
      if (this.application.id == 0) {
        this.updateViewAllTechnology(true)
      } else {
        this.updateViewAllTechnology(false)
      }
    }
  },
  methods: {
    ...mapActions("print", [
      "updateTechnologieCategoryList", 
      //"updateMaterialItemsByTechnologieCategory",
      "updateMaterialItems",
      "updateApplication",
      "updateMaterialItems",
      "updateMaterial",
      "updateColor",
      "updateLayer",
      "updateFinishing",

    ]),
    ...mapActions("printManager", [
      "updateViewAllTechnology",
      "updateStepNextPossible",
      "updateLengthOfTabMaterials",
      "updateInfoTechnologyVisible",
      "updateInfoMaterialVisible",
      "updateInfoLayerVisible",
    ]),
    comeBackSelection() {
      if (this.oldApplication.name != null ) {
        this.callApiMakersMaterialsLayersColors(this.oldApplication)
      }

      this.updateViewAllTechnology(false)
    },
    callApiAllTechnologie() {

      this.updateViewAllTechnology(true)

      let formData = new FormData();
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);

       this.$http.post(process.env.VUE_APP_API_BASE_URL + "application/technologies", formData)
        .then((response) => {
          if (Object.keys(response.body).length > 0) {
            this.updateTechnologieCategoryList(response.body)

            this.updateApplication({id: 0, name: 'Autre'})

            // first request for tabsNavTEchnology
            if (this.technology.id == null) {
              this.callApiTechnologyById(response.body[0].id)
              this.updateTechnology(response.body[0])

            }
            
          }

      });
      // reset : material selected, color selected, layer selected
      this.updateMaterial({id: null, name: null, basePrice: null})
      this.updateColor({id: null, name: null, plusPrice: null, hexadecimalCode: null})
      this.updateLayer({id: null, height: null, plusPrice: null})
      this.updateFinishing({id: null, name: null, description: null, price: null})

      this.updateInfoTechnologyVisible (true)
      this.updateInfoMaterialVisible (false)
      this.updateInfoLayerVisible (false)

    },
    callApiTechnologyById(id) {
        let formData = new FormData();
        formData.append("technology_id", id);
        formData.append("volume", this.volumeDisplay * 1000);
        formData.append("dimension[x]", this.dimensions.x);
        formData.append("dimension[y]", this.dimensions.y);
        formData.append("dimension[z]", this.dimensions.z);

        this.$http
          .post(
            process.env.VUE_APP_API_BASE_URL + "application/technology-materials-layers-colors",
            formData
          )
          .then((response) => {

            //this.updateMaterialItemsByTechnologieCategory(response.body)
            this.updateMaterialItems(response.body);
            this.updateLengthOfTabMaterials(response.body.length)
            
      });
    },
    callApiMakersMaterialsLayersColors(item) {
      
      let formData = new FormData();

      formData.append("application_id", item.id);
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);


      this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "application/makers-materials-layers-colors",
          formData
        )
        .then((response) => {
        
          this.updateApplication(item)
          this.updateMaterialItems(response.body);
          this.updateLengthOfTabMaterials(response.body.length)
          this.updateStepNextPossible(3)
     
        });
        // reset : material selected, color selected, layer selected
        this.updateMaterial({id: null,name:null,basePrice:null})
        this.updateColor({id:null,name:null,plusPrice:null,hexadecimalCode:null})
        this.updateLayer({id:null,height:null,plusPrice:null})
        this.updateFinishing({id:null,name:null,description:null,price:null})
    },
  },
}
</script>