<template>
  <div 
    class="a-accordion-header" 
    :class="[lastChildActive == 2 ? 'theme--active' : '', stepNextPossible >= 2 ? '' : 'theme--disabled']"
    @mouseover="showInfoTechnologie"
    >
    <icon-project :width="32" :color="lastChildActive == 2 ? '#7F1F23' : '#161616'"></icon-project>
    <h4 class="h4">{{ application.name !== null ?  ( (lastChildActive == 4 && application.id==0  ) ? "Technologie : "+ technology.name : application.name ) : 'Sélectionner votre type de projet' }}</h4>
    <span class="d-inline-block tooltip-print" tabindex="0" data-bs-toggle="tooltip" title="Sélectionnez votre domaine d’activité. Cela permet d’affiner le choix des matériaux à l’étape suivante. Les domaines médicaux et architecturaux vous conduiront vers la page projet car les matériaux et les dimensions peuvent être très spécifiques en fonction de votre besoin. Si vous ne trouvez pas votre domaine, cliquez sur “Autre”.">
      <i class="bi bi-question-circle"></i>
    </span>
    <span class="t3 color-secondary" v-if="stepNextPossible >= 3">modifier</span>
  </div>
</template>

<script>
import IconProject from '../icon/IconProject.vue';

import { mapState, mapActions } from 'vuex';

export default {
  name: "AccordHeaderStep2",
  components: { IconProject },
  computed: {
    ...mapState("print", {
      volumeDisplay: (state) => state.printFile.volumeDisplay,
      application: (state) => state.printFile.application,
      technology: (state) => state.printFile.technology
    }),
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      stepNextPossible: (state) => state.stepNextPossible,
    }),
  },
  methods: {
    ...mapActions("printManager", [
      "updateInfoTechnologyVisible",
      "updateInfoMaterialVisible",
      "updateInfoLayerVisible",
    ]),
    showInfoTechnologie() {
      if (this.lastChildActive == 4 && this.application.id==0  ) {
        this.updateInfoTechnologyVisible (true)
        this.updateInfoMaterialVisible (false)
        this.updateInfoLayerVisible (false)
      }

    },
  }
};
</script>