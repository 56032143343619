<template>
  <div class="pt-3 pb-3">

    <div ref="errorFormExplain">
        <div v-if="errors.length" id="errorExplain" class="color-accent">
            <p class="mb-2">Des erreur(s) dans votre formulaire :</p>
            <ul>
                <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
        </div>
    </div>


    <div class="overflow-hidden">
        <div class="row g-2">
            <div class="col-md-6">
                <input type="text" v-model="billingFirstname" placeholder="Prénom" class="form-control" :class="errorsCls.indexOf('firstname') != -1 ? 'b-error' : ''" >
            </div>
            <div class="col-md-6">
                <input type="text" v-model="billingLastname" placeholder="Nom" class="form-control" :class="errorsCls.indexOf('lastname') != -1 ? 'b-error' : ''" >
            </div>
            <div class="col-12">
                <input type="text" v-model="billingEnterprise" placeholder="Entreprise (facultatif)" class="form-control">
            </div>
            <div class="col-12">
                <input type="text" v-model="billingAdress" placeholder="Adresse" class="form-control" :class="errorsCls.indexOf('adress') != -1 ? 'b-error' : ''">
            </div>
            <div class="col-12">
                <input type="text" v-model="billingAdress2" placeholder="Appartement, suite, etc. (factultatif)" class="form-control">
            </div>
            <div class="col-md-8">
                <input type="text" v-model="billingCity" placeholder="Ville" class="form-control" :class="errorsCls.indexOf('city') != -1 ? 'b-error' : ''">
            </div>
            <div class="col-md-4">
                <input type="text" v-model="billingZipcode" placeholder="Code Postal" maxlength="5" class="form-control" :class="errorsCls.indexOf('zipcode') != -1 ? 'b-error' : ''">
            </div>
            <div class="col-12">
                <input type="text" v-model="billingPhone" placeholder="Telephone" class="form-control" :class="errorsCls.indexOf('phone') != -1 ? 'b-error' : ''">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {zipcode_fr} from "../../../zipcode_fr.json";

export default {
  name: "FormBilling",
  data() {
    return {
        errors: [],
        errorsCls: [],
        billingFirstname: null,
        billingLastname: null,
        billingEnterprise: null,
        billingAdress: null,
        billingAdress2: null,
        billingCity: null,
        billingZipcode: null,
        billingPhone: null,
    };
  },
  computed: {
    ...mapState("order", {
        billing: (state) => state.billing
        }),
    ...mapState("orderManager", {
      billingOrderSell: (state) => state.billing
    }),
    },
     beforeMount() {
    setTimeout(()=> {
      if(this.billingOrderSell === null) {
        this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/connected', {credentials: true})
            .then((response) => {

              let data = response.data.data.address_billing

              this.billingFirstname = data.firstname !== undefined ? data.firstname : null
              this.billingLastname = data.lastname !== undefined ? data.lastname : null
              this.billingEnterprise = data.enterprise !== undefined ? data.enterprise : ''
              this.billingAdress = data.street1 !== undefined ? data.street1 : ''
              this.billingAdress2 = data.street2 !== undefined ? data.street2 : ''
              this.billingCity = data.city !== undefined ? data.city : ''
              this.billingZipcode = data.zipcode !== undefined ? data.zipcode : ''
              this.billingPhone = data.phone !== undefined ? data.phone : ''

            })
      } else {
        this.billingFirstname = this.billingOrderSell.firstname !== undefined ? this.billingOrderSell.firstname : null
        this.billingLastname = this.billingOrderSell.lastname !== undefined ? this.billingOrderSell.lastname : null
        this.billingEnterprise = this.billingOrderSell.enterprise !== undefined ? this.billingOrderSell.enterprise : ''
        this.billingAdress = this.billingOrderSell.street1 !== undefined ? this.billingOrderSell.street1 : ''
        this.billingAdress2 = this.billingOrderSell.street2 !== undefined ? this.billingOrderSell.street2 : ''
        this.billingCity = this.billingOrderSell.city !== undefined ? this.billingOrderSell.city : ''
        this.billingZipcode = this.billingOrderSell.zipcode !== undefined ? this.billingOrderSell.zipcode : ''
        this.billingPhone = this.billingOrderSell.telephone !== undefined ? this.billingOrderSell.telephone : ''
      }
    }, 1500)
    },
    methods: {
        ...mapActions("order", [
            "updateBilling"
        ]),
        saveBilling() {
         
            if(this.checkForm()) {

                this.updateBilling({
                    firstname: this.billingFirstname,
                    lastname: this.billingLastname,
                    enterprise: this.billingEnterprise,
                    adress: this.billingAdress,
                    adress2: this.billingAdress2,
                    city: this.billingCity,
                    zipcode: this.billingZipcode,
                    phone: this.billingPhone
                })

                return true
            
            } else {
                return false
            }

        },
        checkForm() {
       
            this.errors = [];
            this.errorsCls = [];

            if (!this.billingFirstname) {
                this.errors.push('prénom manquant');
                this.errorsCls.push('firstname');
            }
            if (!this.billingLastname) {
                this.errors.push('nom manquant');
                this.errorsCls.push('lastname');
            }
            if (!this.billingAdress) {
                this.errors.push('adresse manquante');
                this.errorsCls.push('adress');
            }
            if (!this.billingCity) {
                this.errors.push('ville manquante');
                this.errorsCls.push('city')
            }
            if (!this.billingZipcode) {
                this.errors.push('code de postal manquant');
                this.errorsCls.push('zipcode')
            } else if (RegExp(/^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/).test(this.billingZipcode) == false) {
                this.errors.push('code postal invalide');
                this.errorsCls.push('zipcode')
            } else if (zipcode_fr.find(zipcode => zipcode == this.zipcode)) {
                this.errors.push('code postal invalide');
                this.errorsCls.push('zipcode')
            }
            if (!this.billingPhone) {
                this.errors.push('numéro de téléphone manquant');
                this.errorsCls.push('phone')
            } else if (RegExp(/^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/).test(this.billingPhone) == false) {
                this.errors.push('numéro de téléphone invalide');
                this.errorsCls.push('phone')
            }

            if (this.errors.length > 0) {
                this.$refs.errorFormExplain.scrollIntoView({behavior: "smooth"})
            }

            return this.errors.length >= 1 ? false : true

        }
    },
};
</script>