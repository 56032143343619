<template>
  <div class="a-accordion__item">
    <!-- accordion-header -->
    <div @click="open">

      <slot name="header">titre</slot>
      
    </div>

    <!-- accordion-content -->
    <transition
      name="a-accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div class="a-accordion__content" v-show=" index == lastChildActive ? true : false ">

        <slot name="content">contenu</slot>

      </div>
    </transition>

  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AccordionItem",
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      stepNextPossible: (state) => state.stepNextPossible
    }),
  },
  methods: {
      ...mapActions("printManager", [
      "updateLastChildActive",
    ]),
    open() {
     
      if (this.stepNextPossible < this.index) {
        return
      }
      if (this.lastChildActive == this.index) {
        this.updateLastChildActive(0)
        
      } else {
        this.updateLastChildActive(this.index)
        //  console.log(this.lastChildActive)

         // Google Tag Manager : push event impression form
          //******************************************** */
              if (this.lastChildActive == 1) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I1.4.upload_back')
              } else if (this.lastChildActive == 2) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I2.1.Select_appli_back')
              } else if (this.lastChildActive == 3) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I3.2.Material_back')
              } 
              // else if (this.lastChildActive == 4) {
              //   this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I4.2.Color_back')
              // }
          //******************************************** */
      }

       
      
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
};
</script>