<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Modifier votre mot de passe</h4>

            <form class="row g-2 mt-5" @submit.prevent="() => send">
              <div class="col-12">
                <div class="text--error mb-1">{{ errorPassword }}</div>
              </div>
              <div class="col-md-12">
                <input type="password" v-model="password1" placeholder="Entrer votre nouveau mot de passe" class="form-control" @keyup.enter="send">
                <!-- <div class="t3 mt-1 color-secondary">
                      Le mot de passe doit être de 8 caractères minimum et contenir au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial. Exemple : @Mi12Lg09#
                </div> -->
              </div>
              <div class="col-md-12">
                <input type="password" v-model="password2" placeholder="Entrer à nouveau votre mot de passe" class="form-control" @keyup.enter="send">
              </div>
              <div class="mt-4">
                <button class="btn-u3dm btn-u3dm--primary w-100" @click="send" @keyup.enter="send">Changer</button>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>

import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu},
  name: "MakerPasswordUpdate",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Tableau de bord',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      password1: null,
      password2: null,
      errorPassword: null,
    }
  },
  methods: {
    send() {

      let formData = new FormData();

      formData.append('first_password', this.password1);
      formData.append('second_password', this.password2);

      this.$http.post(process.env.VUE_APP_API_BASE_URL + "user/password", formData, {credentials: true}).then(() => {
            this.errorPassword = null
            this.$notify({ type: 'success', text: 'Votre mot de passe à été mofifiée avec succès' })
          }
          , (error) => {
            this.errorPassword = error.body.error
            this.changePasswordSuccess = false
          })
    },
  },
}
</script>
