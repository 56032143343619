<template>
  <div 
    class="a-accordion-header" 
    :class="lastChildActive == 1 ? 'theme--active' : ''"
    >
    <icon-file :width="32" :color="lastChildActive == 1 ? '#7F1F23' : '#161616'"></icon-file>
    <h4 class="h4">{{ filename ? filename.toLowerCase() : 'Déposer votre fichier 3D' }}</h4>
    <span class="d-inline-block tooltip-print" tabindex="0" data-bs-toggle="tooltip" title="Pour connaître le prix de votre projet, importez ou faites un glisser/déposer de votre fichier 3D au format .STL ou .OBJ. C’est ce fichier qui permet l’impression 3D.">
      <i class="bi bi-question-circle"></i>
    </span>
    <span class="t3 color-secondary" v-if="stepNextPossible >= 2">modifier</span>
  </div>
</template>

<script>
import IconFile from "../icon/IconFile.vue";

import { mapState, mapActions } from 'vuex';

export default {
  name: "AccordHeaderStep1",
  components: { IconFile },
  computed: {
    ...mapState("print", {
      filename: (state) => state.printFile.filename,
      referentialItems: (state) => state.printFile.referentialItems,
    }),
    ...mapState("printManager", {
      lastChildActive: (state) => state.lastChildActive,
      stepNextPossible: (state) => state.stepNextPossible
    }),
  },
   watch: {
    filename() {
      if(this.filename !== null) {
        this.updateStepNextPossible(2)
      }
    }
  },
  methods: {
    ...mapActions("printManager", [
      "updateStepNextPossible",
    ]),
  },
};
</script>