<template>
  <div class="tabs-techno-nav-outer">
    <div class="tabs-techno-nav-container">
      <div class="tabs-techno-nav-wrapper swiper-wrapper">
        <div
          v-for="(item, index) in technologieCategoryList"
          :key="index"
          class="swiper-slide tabs-techno-nav-link"
          :class="indexSelect == index ? 'is-active' : ''"
          @click="categorySelect(index, item)"
          @mouseover="showInfoTechnologyl(item)"
        >
          <h6 class="h6">{{ item.name }}</h6>
        </div>
      </div>
      <!-- swiper-wrapper -->
    </div>
<!--    <span class="indication-swipe">>></span>-->
    <!-- tabs-techno-nav-container -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Swiper from "swiper";

import "swiper/swiper-bundle.css";

export default {
  name: "TabsTechnologieNav",
  data() {
    return {
      indexSelect: 0,
      swiper: null,
    };
  },
  computed: {
    ...mapState("print", {
      technologieCategoryList: (state) => state.printFile.technologieCategoryList,
      volumeDisplay: (state) => state.printFile.volumeDisplay,
      dimensions: (state) => state.printFile.dimensions,
      application: (state) => state.printFile.application,
      materialItems: (state) => state.printFile.materialItems,
      technology: (state) => state.prinFile.technology,
    }),
  },
  methods: {
    ...mapActions("print", [
      //"updateMaterialItemsByTechnologieCategory", 
      "updateTechnology","updateMaterialItems","updateInfoTechnology","updateIndexSelect"
    ]),
    ...mapActions("printManager", [
      "updateLengthOfTabMaterials",
      "updateInfoTechnologyVisible",
      "updateInfoMaterialVisible",
      "updateInfoLayerVisible",
    ]),
    categorySelect(index, item) {
          this.indexSelect = index
          this.callApiTechnologyById(item);
          this.updateInfoTechnologyVisible (true)
          this.updateInfoMaterialVisible (false)
          this.updateInfoLayerVisible (false)
    },
    showInfoTechnologyl(item) {
          this.updateInfoTechnology(item)
          this.updateInfoTechnologyVisible (true)
          this.updateInfoMaterialVisible (false)
          this.updateInfoLayerVisible (false)

    },
    callApiTechnologyById(item) {
  
      let formData = new FormData();
      formData.append("technology_id", item.id);
      formData.append("volume", this.volumeDisplay * 1000);
      formData.append("dimension[x]", this.dimensions.x);
      formData.append("dimension[y]", this.dimensions.y);
      formData.append("dimension[z]", this.dimensions.z);


      this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL +
            "application/technology-materials-layers-colors",
          formData
        )
        .then((response) => {

          //this.updateMaterialItemsByTechnologieCategory(response.body);
          this.updateMaterialItems(response.body);
          this.updateTechnology(item)
          this.updateInfoTechnology(item)
          this.updateLengthOfTabMaterials(response.body.length)

        });
    },
    setCorrectNavbarWidth() {
      setTimeout(function () {
 
        this.swiper = new Swiper(".tabs-techno-nav-container", {
          direction: "horizontal",
          freeMode: true,
          freeModeMomentum: false,
          slidesPerView: "auto",
          normalizeSlideIndex: false,
        });
      }, 100);
       
    }
  },
  created() {
    this.$root.$refs.TabsTechnologieNav = this;

  },
  mounted() {
    setTimeout(function () {
 
        this.swiper = new Swiper(".tabs-techno-nav-container", {
          direction: "horizontal",
          freeMode: true,
          freeModeMomentum: false,
          slidesPerView: "auto",
          normalizeSlideIndex: false,
        });
      }, 100);
    // if(this.application.id !== null) {
    //   this.setCorrectNavbarWidth()
    // }
  },
};
</script>