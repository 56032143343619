<template>
  <div class="project d-flex justify-content-center align-items-center">
    <div class="text-center appearOnBot">

        <div class="max-w-360">
            <h2 class="project-heading">
                Merci {{ firstname | capitalizeFirstLetter }} {{ lastname | capitalizeFirstLetter }}, nous avons bien reçu votre projet.
            </h2>

            <p class="t1">
                Nous mettons tout en oeuvre pour analyser et démarrer au plus vite
                votre projet. Merci de votre confiance.
            </p>

            <div class="d-flex justify-content-center justify-content-sm-between flex-wrap">
                <a :href="urlWWWProject" class="btn-u3dm btn-u3dm--primary mt-2 m-1">Retour à l'accueil</a>
                <router-link :to="'/devis/' + reference + token" class="btn-u3dm btn-u3dm--secondary mt-2 m-1">Voir le projet</router-link>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ProjectThanks',
  data() {
    return {
      urlWWWProject: process.env.VUE_APP_WWW_BASE_URL + process.env.VUE_APP_WWW_URL_PROJECT
    }
  },
  computed: {
    ...mapState('authentication', {
      'firstname': state => state.firstname,
      'lastname': state => state.lastname,
    }),
    ...mapState('projectDesign', {
      token: state => state.projectDesign.seeProjectAfterSave.token,
      reference: state => state.projectDesign.seeProjectAfterSave.reference
    }),
  },
  filters: {
   capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
};
</script>