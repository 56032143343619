<template>
    <div class="inner-scroll">

        <!-- before -->
        <div class="order-form-before" v-if="needShipping">
            <div class="order-form-before-row">
                <h5 class="h5">Mode de livraison</h5>
                <p v-if="modeDelivery.name !== null">{{ modeDelivery.label }}</p>
                <div class="order-form-before-edit" @click="prev(1)">modifier</div>
            </div>
        </div> <!-- before -->
        
        <div>   
            <div v-if="modeDelivery.name == 'home_standard' || modeDelivery.name == 'home_express'">

                <h4 class="h4 mt-5 mb-5" v-if="addressLabel === ''">Où souhaitez-vous envoyer cette commande ?</h4>
                <h4 class="h4 mt-5 mb-5" v-if="addressLabel !== ''">{{ addressLabel }}</h4>

                <div ref="errorFormExplain">
                    <div v-if="errors.length" id="errorExplain" class="color-accent">
                        <p class="mb-2">Des erreur(s) dans votre formulaire :</p>
                        <ul>
                            <li v-for="error in errors" :key="error">{{ error }}</li>
                        </ul>
                    </div>
                </div>

                <div class="overflow-hidden">
                    <div class="row g-2">
                        <div class="col-md-6">
                            <input type="text" v-model="shippingFirstname" placeholder="Prénom" class="form-control" :class="errorsCls.indexOf('firstname') != -1 ? 'b-error' : ''" >
                        </div>
                        <div class="col-md-6">
                            <input type="text" v-model="shippingLastname" placeholder="Nom" class="form-control" :class="errorsCls.indexOf('lastname') != -1 ? 'b-error' : ''" >
                        </div>
                        <div class="col-12">
                            <input type="text" v-model="shippingEnterprise" placeholder="Entreprise (facultatif)" class="form-control">
                        </div>
                        <div class="col-12">
                            <input type="text" v-model="shippingAdress" placeholder="Adresse" class="form-control" :class="errorsCls.indexOf('adress') != -1 ? 'b-error' : ''">
                        </div>
                        <div class="col-12">
                            <input type="text" v-model="shippingAdress2" placeholder="Appartement, suite, etc. (factultatif)" class="form-control">
                        </div>
                        <div class="col-md-8">
                            <input type="text" v-model="shippingCity" placeholder="Ville" class="form-control" :class="errorsCls.indexOf('city') != -1 ? 'b-error' : ''">
                        </div>
                        <div class="col-md-4">
                            <input type="text" v-model="shippingZipcode" placeholder="Code Postal" maxlength="5" class="form-control" :class="errorsCls.indexOf('zipcode') != -1 ? 'b-error' : ''">
                        </div>
                        <div class="col-12">
                            <input type="text" v-model="shippingPhone" placeholder="Telephone" class="form-control" :class="errorsCls.indexOf('phone') != -1 ? 'b-error' : ''">
                        </div>
                        <div class="ms-1 form-check">
                            <input type="checkbox" v-model="savingShippingForLater" class="form-check-input" id="savingShippingForLater">
                            <label for="savingShippingForLater" class="form-check-label t1">Sauvegarder mes coordonnées pour la prochaine fois</label>
                        </div>
                    </div>
                </div>

            </div>
            <!-- point relay -->
            <div v-else>
                <h4 class="h4 mt-5 mb-5">Où souhaitez-vous retirer cette commande ?</h4>
                <div ref="errorRelayFormExplain">
                    <div v-if="errorsRelayForm.length" id="errorRelayExplain" class="color-accent">
                        <p class="mb-2">Des erreur(s) détecter dans votre formulaire :</p>
                        <ul>
                            <li v-for="error in errorsRelayForm" :key="error">{{ error }}</li>
                        </ul>
                    </div>
                </div>
                 <div class="overflow-hidden">
                     <!-- form : ou , votre localisation -->
                    <div class="row g-2">
                        <div class="col-12">
                            <input type="text" v-model="relayStreet" placeholder="Adresse" class="form-control" :class="errorsClsRelayForm.indexOf('adress') != -1 ? 'b-error' : ''">
                        </div>
                        <div class="col-md-8">
                            <input type="text" v-model="relayCity" placeholder="Ville" class="form-control" :class="errorsClsRelayForm.indexOf('city') != -1 ? 'b-error' : ''">
                        </div>
                        <div class="col-md-4">
                            <input type="text" v-model="relayZipcode" placeholder="Code Postal" maxlength="5" class="form-control" :class="errorsClsRelayForm.indexOf('zipcode') != -1 ? 'b-error' : ''">
                        </div>
                        <div class="mt-3 text-end">
                            <button class="btn-u3dm btn-u3dm--tertiary" @click="listingRelay">Rechercher</button>
                        </div>
                    </div>
                </div>
                <div v-show="mapDisplay">
                    <h5 class="h5 mt-4 mb-3">Points relais disponible</h5>
                    <!-- nav mode: carte, liste -->
                    <div class="d-flex mt-4">
                        <span class="h6 pb-3 me-3 cursor-p" 
                        :class="[viewRelayMode == 'list' ? 'b-2-bot-accent color-accent' : '']"
                        @click="viewRelayMode = 'list'">Afficher la liste</span>
                        <span class="h6 pb-3 cursor-p"
                        :class="[viewRelayMode == 'map' ? 'b-2-bot-accent color-accent' : '']"
                         @click="viewRelayMode = 'map'">Afficher la carte</span>
                    </div>
                    <!-- list relay -->
                    <div v-show="viewRelayMode == 'list'">
                        <!-- list relais -->
                        <div v-for="(relay, index) in listRelay" :key="index" 
                            class="mt-2 p-3 b-1-light b-radius-4 background-light cursor-p"
                            :class="[indexRelaySelected == index ? 'b-1-dark' : '']"
                            @click="selectRelay(relay, index)">
                            <div>
                                <div class="d-flex justify-content-between">
                                    <h5 class="h5">{{ relay.name }}</h5>
                                    <span> {{ (relay.distance / 1000).toFixed(2) }} km</span>                    
                                </div>
                                <div>
                                    <span class="pe-3">{{ relay.street1 }},</span>
                                    <span>{{ relay.zipcode }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="pe-3">{{ relay.city }}</span>
                                </div>
                            </div>
                            <div v-if="indexRelaySelected == index" class="d-flex justify-content-between align-items-center">
                                <div class="mt-1">
                                    <span class="h6">Horaires :</span>
                                    <div v-for="(open, d) in relay.opening" :key="d">
                                        <p class="t1 mb-0 appearTall">{{ open }}</p>
                                    </div>
                                </div>
                                <span class="t3 cursor-p" @click="viewRelayMode = 'map'">Consulter la carte</span>
                            </div>
                        </div>
                    </div>
                    <!-- map relay -->
                    <div v-show="viewRelayMode == 'map'" class="mt-2">
                        <div class="w-100">
                            <GmapMap
                            :center="{lat: 0, lng: 0}"
                            :zoom="7"
                            ref="map"
                            map-type-id="roadmap"
                            style="width: 100%; height: 300px"
                            >
                            <GmapMarker v-for="(markerRelay, indexRelay) in listRelay" :key="indexRelay"
                                :icon="iconRelay"
                                ref="markers"
                                :position="{'lat': markerRelay.coordinates.lat, 'lng':markerRelay.coordinates.lng}"
                                :label="{'text': markerRelay.number, 'font-weight':'bold', 'color':'white'}"
                                @click="selectRelayOnMap(markerRelay, indexRelay)"
                            ></GmapMarker>
                            </GmapMap>
                        </div>

                        <div v-if="relaySelected != null"
                        class="mt-2 p-3 b-1-dark b-radius-4 background-light cursor-p">
                            <div>
                                <div class="d-flex justify-content-between">
                                    <h5 class="h5">{{ relaySelected.name }}</h5>
                                    <span> {{ (relaySelected.distance / 1000).toFixed(2) }} km</span>                    
                                </div>
                                <div>
                                    <span class="pe-3">{{ relaySelected.street1 }},</span>
                                    <span>{{ relaySelected.zipcode }}</span>
                                </div>
                                <div class="mt-1">
                                    <span class="pe-3">{{ relaySelected.city }}</span>
                                </div>
                            </div>
                            <div class="mt-1">
                                <span class="h6">Horaires :</span>
                                <div v-for="(open, d) in relaySelected.opening" :key="d">
                                    <p class="t1 mb-0 appearTall">{{ open }}</p>
                                </div>
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
        
            <!-- action -->
            <div class="mt-5">
                <button v-if="modeDelivery.name != 'relay'" class="btn-u3dm btn-u3dm--primary" @click="next(3)">Suivant</button>
                <button  v-if="modeDelivery.name == 'relay'"
                :class="relaySelected == null ? ['btn-u3dm--disabled'] : ''"
                class="btn-u3dm btn-u3dm--primary" @click="next(3)">Suivant</button>
            </div>
        </div> 
        <div class="order-form-after">
            <h5 class="h5">Mode de paiement</h5>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {zipcode_fr} from "../../zipcode_fr.json";
// import * as VueGoogleMaps from 'vue2-google-maps'

export default {
    name: "FormOrderOrdered",
    // components: { VueGoogleMaps },
    data() {
        return {
            errors: [],
            errorsCls: [],
            shippingFirstname: null,
            shippingLastname: null,
            shippingEnterprise: null,
            shippingAdress: null,
            shippingAdress2: null,
            shippingCity: null,
            shippingZipcode: null,
            shippingPhone: null,
            savingShippingForLater: false,

            firstnameUserToken: null,
            lastnameUserToken: null,


            relaySelected: null,
            indexRelaySelected: null,

            // new
            viewRelayMode: 'list',
            lastIndexRelay: 1,


            // new
            shippingRelayIdentifier: null,

            // new
            errorsRelayForm: [],
            errorsClsRelayForm: [],
            relayStreet:null,
            relayCity:null,
            relayZipcode:null,
            newRelayStreet:null,
            newRelayCity:null,
            newRelayZipcode:null,
            changeAddressReference:0,
            listRelay:{},

            zindex:1000,
            mapDisplay:false,
            markers: [],
            place: null,
            center:{},
            //iconRelay:'/assets/front/images/spotlight-poi-u3dm.png',
            iconRelay: {
                path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
                fillColor: "#FC3640",
                fillOpacity: 1,
                scale: .8,
                strokeColor: "#FC3640",
                strokeWeight: 1,
                labelOrigin: {x: 0, y: -25}
            },
            iconRelayOver: {
                path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
                fillColor: "#7F1F23",
                fillOpacity: 1,
                scale: .8,
                strokeColor: "#7F1F23",
                strokeWeight: 1,
                labelOrigin: {x: 0, y: -25},
            }
        }
    },
  computed: {
    ...mapState("order", {
      modeDelivery: (state) => state.modeDelivery,
      shipping: (state) => state.shipping
    }),
    ...mapState("orderManager", {
      needShipping: (state) => state.needShipping,
      addressLabel: (state) => state.addressLabel,
    }),
    ...mapState("authentication", {
      firstname: (state) => state.firstname,
      lastname: (state) => state.lastname
    }),
    ...mapState("shop", {
      listProductsProject: (state) => state.listProductsProject,
    }),
  },
    mounted() {
        this.$refs.map.$mapPromise.then((map) => {
            map.panTo({lat: 1.38, lng: 103.80})
        })
    },
    beforeMount() {
        // http://app.u3dm.local/fr/api/user/user-inactive/{token}
        // this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/connected', {credentials: true})
        // .then((response) => {  })
        if (this.$route.query.token != undefined) {
            this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/user-inactive/' + this.$route.query.token, {credentials: true})
            .then((response) => {

              // console.log(response)
              this.firstnameUserToken = response.body.firstname
              this.lastnameUserToken = response.body.lastname
              this.shippingFirstname = this.firstnameUserToken !== undefined ? this.firstnameUserToken : null
              this.shippingLastname = this.lastnameUserToken !== undefined ? this.lastnameUserToken : null
            })
        }

        if(this.$route.params.orderSellId !== undefined) {
          this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/address/' + this.$route.params.orderSellId, {credentials: true})
              .then((orderSellAdressResponse) => {
                var orderSellAdress = orderSellAdressResponse.data
                if(orderSellAdress.shipping_address !== undefined &&
                    orderSellAdress.shipping_address.city !== undefined &&
                    orderSellAdress.shipping_address.firstname !== undefined &&
                    orderSellAdress.shipping_address.lastname !== undefined &&
                    orderSellAdress.shipping_address.street1 !== undefined &&
                    orderSellAdress.shipping_address.telephone !== undefined &&
                    orderSellAdress.shipping_address.zipcode !== undefined) {
                  this.shippingFirstname = orderSellAdress.shipping_address.firstname
                  this.shippingLastname = orderSellAdress.shipping_address.lastname
                  this.shippingEnterprise = orderSellAdress.shipping_address.enterprise !== undefined ? orderSellAdress.shipping_address.enterprise : ''
                  this.shippingAdress = orderSellAdress.shipping_address.street1
                  this.shippingAdress2 = orderSellAdress.shipping_address.street2 !== undefined ? orderSellAdress.shipping_address.street2 : ''
                  this.shippingCity = orderSellAdress.shipping_address.city
                  this.shippingZipcode = orderSellAdress.shipping_address.zipcode
                  this.shippingPhone = orderSellAdress.shipping_address.telephone
                  this.updateBilling(orderSellAdress.billing_address)
                } else {
                  this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/connected', {credentials: true})
                      .then((response) => {

                        let data = response.data.data.address_shipping

                        this.shippingFirstname = data.firstname !== undefined ? data.firstname : null
                        this.shippingLastname = data.lastname !== undefined ? data.lastname : null
                        this.shippingEnterprise = data.enterprise !== undefined ? data.enterprise : ''
                        this.shippingAdress = data.street1 !== undefined ? data.street1 : ''
                        this.shippingAdress2 = data.street2 !== undefined ? data.street2 : ''
                        this.shippingCity = data.city !== undefined ? data.city : ''
                        this.shippingZipcode = data.zipcode !== undefined ? data.zipcode : ''
                        this.shippingPhone = data.phone !== undefined ? data.phone : ''
                      })
                }
              })
        } else {
          this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/connected', {credentials: true})
              .then((response) => {

                let data = response.data.data.address_shipping

                this.shippingFirstname = data.firstname !== undefined ? data.firstname : null
                this.shippingLastname = data.lastname !== undefined ? data.lastname : null
                this.shippingEnterprise = data.enterprise !== undefined ? data.enterprise : ''
                this.shippingAdress = data.street1 !== undefined ? data.street1 : ''
                this.shippingAdress2 = data.street2 !== undefined ? data.street2 : ''
                this.shippingCity = data.city !== undefined ? data.city : ''
                this.shippingZipcode = data.zipcode !== undefined ? data.zipcode : ''
                this.shippingPhone = data.phone !== undefined ? data.phone : ''
              })
        }
    },
    watch: {
        listRelay(markers) {
            if (markers.length > 2) {
                const bounds = new window.google.maps.LatLngBounds()
                for (let m of markers) {
                    let LatLng = {
                        lat: m.coordinates.lat,
                        lng: m.coordinates.lng
                    }
                    bounds.extend(LatLng)
                }
                this.$refs.map.fitBounds(bounds)
            }
        }
    },
    methods: {
        ...mapActions("order", [
            "updateShipping", "updateSavingShippingForLater", "updateShippingRelayIdentifier", "updateRelayAddress"
        ]),
        ...mapActions("orderManager", [
            "updateStep", "updateBilling"
        ]),
        selectRelayOnMap(item, index) {

       
            this.indexRelaySelected = index
            this.relaySelected = item

            this.$refs.markers[index].$markerObject.setIcon(this.iconRelayOver)
            this.$refs.markers[index].$markerObject.setZIndex(this.zindex++)
            this.$refs.markers[this.lastIndexRelay ].$markerObject.setIcon(this.iconRelay)
				
            this.lastIndexRelay = index

            // Google Tag Manager : push event order form
                //******************************************** */
                    this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.2.4.Shipping_relai_modify')
                //******************************************** */

        },
        selectRelay(item, index) {
            this.indexRelaySelected = index
            this.relaySelected = item

            // design point of map
            this.$refs.markers[index].$markerObject.setIcon(this.iconRelayOver)
            this.$refs.markers[index].$markerObject.setZIndex(this.zindex++)
            this.$refs.markers[this.lastIndexRelay ].$markerObject.setIcon(this.iconRelay)
				
            this.lastIndexRelay = index

             this.updateRelayAddress({
                firstname: this.firstname || this.firstnameUserToken,
                lastname: this.lastname || this.lastnameUserToken,
                enterprise: item.name,
                adress: item.street1,
                adress2: item.street2,
                city: item.city,
                zipcode: item.zipcode,
                phone: '',
                country: 'FR',
            })
            this.updateShippingRelayIdentifier(item.identifier)

            // Google Tag Manager : push event order form
                //******************************************** */
                    this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.2.4.Shipping_relai_modify')
                //******************************************** */
        },
        // modifyRelayAddress(){

            // this.relayStreet = this.newRelayStreet
            // this.relayCity = this.newRelayCity
            // this.relayZipcode = this.newRelayZipcode
            // this.changeAddressReference = 0
            // this.listingRelay()
        
            // this.listingRelay()
		// },
        listingRelay(){


                
            if (this.checkRelayForm()) {

                let formData = new FormData();
                formData.append('street', this.relayStreet);
                formData.append('zipcode', this.relayZipcode);
                formData.append('city', this.newRelayCity);
                this.$http.post(process.env.VUE_APP_API_BASE_URL + 'payment/relays', formData).then((response) => 
                {
                    // console.log('API Chronopost => success')
                    // console.log(response)
                    var data = response.body

                    this.listRelay = data

                    if(this.listRelay.length == 0){
                        this.mapDisplay = false
                    } else {
                        this.mapDisplay = true
                    }
                    
                }, () => {
                    // console.log('API Chronopost => error')
                    // console.log(error)
                    this.mapDisplay = false
                    this.listRelay = {}
                })
            } else {
                this.mapDisplay = false
                this.listRelay = {}
            }


            // Google Tag Manager : push event order form
            //******************************************** */
                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.2.2.Shipping_relai_search')
            //******************************************** */


        },
        next(id) {

            if (this.modeDelivery.name == 'relay') {
                // Google Tag Manager : push event order form
                    //******************************************** */
                        this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.2.3.Shipping_relai_select')
                    //******************************************** */

                if (this.relaySelected != null) {
               
                    this.updateStep(id)

                } 
            } else {

                 // Google Tag Manager : push event order form
                        //******************************************** */
                            this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.1.2.Shipping_address_dom_select')
                        //******************************************** */

                if(this.checkForm()) {
                    this.saveUserShippingGrabbed()
                    this.updateStep(id)


                }
               
            }

        },
        prev(id) {

            
              // Google Tag Manager : push event order form
            //******************************************** */
            if (id == 1) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.1.3.Shipping_type_modify')
            }
            //******************************************** */

            this.updateStep(id);
        },
        saveUserShippingGrabbed() {
            this.updateShipping({
                firstname: this.shippingFirstname,
                lastname: this.shippingLastname,
                enterprise: this.shippingEnterprise,
                adress: this.shippingAdress,
                adress2: this.shippingAdress2,
                city: this.shippingCity,
                zipcode: this.shippingZipcode,
                phone: this.shippingPhone
            })
            this.updateSavingShippingForLater(this.savingShippingForLater)
        },
        checkForm() {
       
            this.errors = [];
            this.errorsCls = []

            if (!this.shippingFirstname) {
                this.errors.push('prénom manquant');
                this.errorsCls.push('firstname')
            }
            if (!this.shippingLastname) {
                this.errors.push('nom manquant');
                this.errorsCls.push('lastname')
            }
            if (!this.shippingAdress) {
                this.errors.push('adresse manquante');
                this.errorsCls.push('adress')
            }
            if (!this.shippingCity) {
                this.errors.push('ville manquante');
                this.errorsCls.push('city')
            }
            if (!this.shippingZipcode) {
                this.errors.push('code de postal manquant');
                this.errorsCls.push('zipcode')
            } else if (RegExp(/^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/).test(this.shippingZipcode) == false) {
                this.errors.push('code postal invalide');
                this.errorsCls.push('zipcode')
            } else if (zipcode_fr.find(zipcode => zipcode == this.zipcode)) {
                this.errors.push('code postal invalide');
                this.errorsCls.push('zipcode')
            }
            if (!this.shippingPhone) {
                this.errors.push('numéro de téléphone manquant');
                this.errorsCls.push('phone')
            } 
            else if (RegExp(/^(0|\+33)[1-9]([-. ]?[0-9]{2}){4}$/).test(this.shippingPhone) == false) {
                this.errors.push('numéro de téléphone invalide');
                this.errorsCls.push('phone')
            }

            if (this.errors.length > 0) {
                this.$refs.errorFormExplain.scrollIntoView({behavior: "smooth"})
            }
  
            return this.errors.length >= 1 ? false : true

            // return true
        },
        checkRelayForm() {
       
            this.errorsRelayForm = [];
            this.errorsClsRelayForm = []

            if (!this.relayStreet) {
                this.errorsRelayForm.push('adresse manquante');
                this.errorsClsRelayForm.push('adress')
            }
            if (!this.relayCity) {
                this.errorsRelayForm.push('ville manquante');
                this.errorsClsRelayForm.push('city')
            }
            if (!this.relayZipcode) {
                this.errorsRelayForm.push('code de postal manquant');
                this.errorsClsRelayForm.push('zipcode')
            } else if (RegExp(/^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/).test(this.relayZipcode) == false) {
                this.errorsRelayForm.push('code postal invalide');
                this.errorsClsRelayForm.push('zipcode')
            } else if (zipcode_fr.find(zipcode => zipcode == this.zipcode)) {
                this.errorsRelayForm.push('code postal invalide');
                this.errorsClsRelayForm.push('zipcode')
            }
  
            if (this.errorsRelayForm.length > 0) {
                this.$refs.errorRelayFormExplain.scrollIntoView({behavior: "smooth"})
            }
  
            return this.errorsRelayForm.length >= 1 ? false : true

            // return true


        } 
    },
}
</script>