<template>
    <svg :width="width" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12.3021C11.9 12.3021 11.7999 12.2781 11.7087 12.2297L3.40551 7.83769C3.20184 7.72969 3.07422 7.51814 3.07422 7.28791C3.07422 7.05769 3.20184 6.84569 3.40551 6.73814L11.7087 2.34614C11.8911 2.24969 12.109 2.24969 12.2913 2.34614L20.5941 6.73814C20.7978 6.84614 20.9254 7.05769 20.9254 7.28791C20.9254 7.51814 20.7978 7.73014 20.5941 7.83769L12.2913 12.2297C12.2001 12.2777 12.1001 12.3021 12 12.3021ZM5.02774 7.28791L12 10.9759L18.9719 7.28791L12 3.59991L5.02774 7.28791Z" :fill="color"/>
        <path d="M11.9995 21.7262C11.8883 21.7262 11.7772 21.6964 11.6789 21.6373C11.4917 21.5249 11.377 21.3222 11.377 21.104V11.68C11.377 11.4493 11.5046 11.2377 11.7082 11.1302L20.011 6.73819C20.204 6.63597 20.4361 6.64264 20.6234 6.75508C20.8106 6.86752 20.9249 7.06975 20.9249 7.28797V16.6969C20.9249 16.9271 20.7977 17.1386 20.5945 17.2462L12.2912 21.6533C12.2001 21.7017 12.0996 21.7262 11.9995 21.7262ZM12.6221 12.0546V20.0689L19.6797 16.3226V8.3213L12.6221 12.0546Z" :fill="color"/>
        <path d="M12 21.7261C11.9 21.7261 11.7995 21.7021 11.7078 21.6532L3.40462 17.2466C3.2014 17.1386 3.07422 16.927 3.07422 16.6972V7.28834C3.07422 7.07011 3.1885 6.86789 3.37572 6.75545C3.56293 6.643 3.79506 6.63634 3.98805 6.73856L12.2913 11.1306C12.4949 11.2386 12.6226 11.4501 12.6226 11.6803V21.1044C12.6226 21.3226 12.5078 21.5252 12.3206 21.6377C12.2224 21.6964 12.1112 21.7261 12 21.7261ZM4.31935 16.3226L11.3774 20.0688V12.0546L4.31935 8.32123V16.3226Z" :fill="color"/>
        <path d="M11.377 21.1043V21.1039H12.6221L11.377 21.1043Z" :fill="color"/>
        <path d="M1.62347 6.73313C1.40024 6.73313 1.18456 6.61269 1.0725 6.40202C0.911525 6.09847 1.02759 5.72158 1.33176 5.56113L2.31452 5.04158C2.61869 4.88069 2.99534 4.99669 3.15587 5.30069C3.31685 5.60424 3.20078 5.98113 2.89662 6.14158L1.91385 6.66113C1.82091 6.71002 1.7213 6.73313 1.62347 6.73313Z" :fill="color"/>
        <path d="M4.28754 5.32391C4.0643 5.32391 3.84863 5.20346 3.73657 4.9928C3.57559 4.68924 3.69165 4.31235 3.99582 4.15191L5.67808 3.26213C5.98225 3.10124 6.3589 3.21724 6.51943 3.52124C6.68041 3.8248 6.56434 4.20169 6.26018 4.36213L4.57792 5.25191C4.48542 5.30035 4.38581 5.32391 4.28754 5.32391ZM7.6525 3.54391C7.42926 3.54391 7.21359 3.42346 7.10153 3.2128C6.94055 2.90924 7.05662 2.53235 7.36078 2.37191L9.04304 1.48213C9.34721 1.32124 9.72386 1.43724 9.88439 1.74124C10.0454 2.0448 9.92931 2.42169 9.62514 2.58213L7.94288 3.47191C7.85039 3.5208 7.75077 3.54391 7.6525 3.54391Z" :fill="color"/>
        <path d="M12.9802 1.76433C12.882 1.76433 12.7824 1.74122 12.6894 1.69189L11.9979 1.32611L11.3064 1.69189C11.0027 1.85278 10.6256 1.73678 10.4651 1.43278C10.3041 1.12922 10.4202 0.752333 10.7243 0.591889L11.7071 0.0723333C11.8894 -0.0241111 12.1073 -0.0241111 12.2896 0.0723333L13.272 0.591889C13.5757 0.752778 13.6917 1.12922 13.5308 1.43278C13.4196 1.64389 13.2035 1.76433 12.9802 1.76433Z" :fill="color"/>
        <path d="M19.7101 5.32383C19.6118 5.32383 19.5122 5.30072 19.4193 5.25138L17.7374 4.3616C17.4337 4.20072 17.3177 3.82427 17.4782 3.52071C17.6392 3.21716 18.0158 3.10116 18.3195 3.2616L20.0018 4.15138C20.3055 4.31227 20.4216 4.68872 20.261 4.99227C20.1494 5.20338 19.9333 5.32383 19.7101 5.32383ZM16.3456 3.54383C16.2473 3.54383 16.1477 3.52071 16.0547 3.47138L14.3725 2.5816C14.0688 2.42071 13.9527 2.04427 14.1132 1.74071C14.2742 1.43716 14.6509 1.32116 14.9546 1.4816L16.6368 2.37138C16.9406 2.53227 17.0566 2.90871 16.8961 3.21227C16.7849 3.42338 16.5688 3.54383 16.3456 3.54383Z" :fill="color"/>
        <path d="M22.3764 6.73315C22.2781 6.73315 22.1785 6.71004 22.0856 6.6607L21.1028 6.14115C20.7991 5.98026 20.683 5.60381 20.844 5.30026C21.005 4.9967 21.3816 4.8807 21.6853 5.04159L22.6681 5.56115C22.9718 5.72204 23.0879 6.09848 22.9269 6.40204C22.8153 6.61315 22.5996 6.73315 22.3764 6.73315Z" :fill="color"/>
        <path d="M22.3765 7.84433C22.0327 7.84433 21.7539 7.56566 21.7539 7.2221V6.11099C21.7539 5.76744 22.0327 5.48877 22.3765 5.48877C22.7202 5.48877 22.999 5.76744 22.999 6.11099V7.2221C22.999 7.56566 22.7202 7.84433 22.3765 7.84433Z" :fill="color"/>
        <path d="M22.3765 15.4736C22.0327 15.4736 21.7539 15.195 21.7539 14.8514V12.9438C21.7539 12.6003 22.0327 12.3216 22.3765 12.3216C22.7202 12.3216 22.999 12.6003 22.999 12.9438V14.8514C22.999 15.1954 22.7202 15.4736 22.3765 15.4736ZM22.3765 11.659C22.0327 11.659 21.7539 11.3803 21.7539 11.0367V9.12918C21.7539 8.78562 22.0327 8.50696 22.3765 8.50696C22.7202 8.50696 22.999 8.78562 22.999 9.12918V11.0367C22.999 11.3807 22.7202 11.659 22.3765 11.659Z" :fill="color"/>
        <path d="M21.3949 19.0136C21.1721 19.0136 20.9565 18.8936 20.8444 18.683C20.683 18.3794 20.7986 18.003 21.1023 17.8416L21.7538 17.4958V16.7589C21.7538 16.4154 22.0326 16.1367 22.3763 16.1367C22.7201 16.1367 22.9989 16.4154 22.9989 16.7589V17.8701C22.9989 18.1003 22.8717 18.3119 22.6685 18.4194L21.6866 18.9408C21.5937 18.9901 21.4936 19.0136 21.3949 19.0136Z" :fill="color"/>
        <path d="M14.6645 22.5857C14.4417 22.5857 14.226 22.4657 14.1139 22.255C13.9525 21.9514 14.0681 21.575 14.3718 21.4137L16.0546 20.5208C16.3583 20.3594 16.7349 20.475 16.8964 20.7785C17.0578 21.0821 16.9422 21.4585 16.6384 21.6199L14.9557 22.5128C14.8628 22.5625 14.7627 22.5857 14.6645 22.5857ZM18.0294 20.7999C17.8066 20.7999 17.591 20.6799 17.4789 20.4692C17.3175 20.1656 17.4331 19.7892 17.7368 19.6279L19.4195 18.735C19.7233 18.5736 20.0999 18.6892 20.2613 18.9928C20.4227 19.2963 20.3071 19.6728 20.0034 19.8341L18.3207 20.727C18.2278 20.7763 18.1277 20.7999 18.0294 20.7999Z" :fill="color"/>
        <path d="M11.9994 23.9998C11.8989 23.9998 11.7988 23.9758 11.7072 23.927L10.7254 23.4056C10.4216 23.2443 10.306 22.8678 10.4674 22.5643C10.6289 22.2607 11.0055 22.1452 11.3092 22.3065L11.999 22.6727L12.6887 22.3065C12.9924 22.1452 13.369 22.2607 13.5305 22.5643C13.6919 22.8678 13.5763 23.2443 13.2725 23.4056L12.2907 23.927C12.2 23.9754 12.0995 23.9998 11.9994 23.9998Z" :fill="color"/>
        <path d="M9.33408 22.5857C9.2358 22.5857 9.13575 22.5621 9.04281 22.5128L7.3601 21.6199C7.05638 21.4586 6.94076 21.0821 7.10218 20.7786C7.26361 20.475 7.64026 20.3595 7.94398 20.5208L9.62668 21.4137C9.93041 21.575 10.046 21.9515 9.8846 22.255C9.77254 22.4657 9.55687 22.5857 9.33408 22.5857ZM5.96867 20.7999C5.8704 20.7999 5.77034 20.7764 5.6774 20.727L3.9947 19.8341C3.69097 19.6728 3.5758 19.2964 3.73678 18.9928C3.8982 18.6892 4.27485 18.5741 4.57857 18.735L6.26128 19.6279C6.565 19.7893 6.68017 20.1657 6.5192 20.4693C6.40713 20.6799 6.19146 20.7999 5.96867 20.7999Z" :fill="color"/>
        <path d="M2.60399 19.0136C2.50572 19.0136 2.40566 18.9901 2.31272 18.9408L1.33085 18.4194C1.12718 18.3119 1 18.1003 1 17.8701V16.7589C1 16.4154 1.27882 16.1367 1.62256 16.1367C1.96631 16.1367 2.24513 16.4154 2.24513 16.7589V17.4958L2.8966 17.8416C3.20032 18.003 3.31594 18.3794 3.15452 18.683C3.04246 18.8936 2.82678 19.0136 2.60399 19.0136Z" :fill="color"/>
        <path d="M1.62256 15.4736C1.27882 15.4736 1 15.195 1 14.8514V12.9438C1 12.6003 1.27882 12.3216 1.62256 12.3216C1.96631 12.3216 2.24513 12.6003 2.24513 12.9438V14.8514C2.24513 15.1954 1.96631 15.4736 1.62256 15.4736ZM1.62256 11.659C1.27882 11.659 1 11.3803 1 11.0367V9.12918C1 8.78562 1.27882 8.50696 1.62256 8.50696C1.96631 8.50696 2.24513 8.78562 2.24513 9.12918V11.0367C2.24513 11.3803 1.96631 11.659 1.62256 11.659Z" :fill="color"/>
        <path d="M1.62256 7.84433C1.27882 7.84433 1 7.56566 1 7.2221V6.11099C1 5.76744 1.27882 5.48877 1.62256 5.48877C1.96631 5.48877 2.24513 5.76744 2.24513 6.11099V7.2221C2.24513 7.56566 1.96631 7.84433 1.62256 7.84433Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'IconProject',
    props: {
        color: {
            type: String,
            default: '#161616'
        },
        width: {
            type: Number,
            default: 24
        },
    }
}
</script>