<template>
  <div class="project">
    <div class="project-wrapp project-wrapp--lg">

      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">Avez vous un fichier 3D ?</h2>
    
      <!-- Content page -->
      <div class="project-content--flex">
        
        <div class="ask-card project-appearBigCard project-appearBigCard__item--1" @click="next(true)">
          <div class="ask-card__content">
            <div class="ask-card__media">
              <component :is="'icon-valid'" :width="30"></component>
            </div>
            <p class="ask-card__text">Oui, jai un fichier 3D</p>
          </div>
        </div>

        <div class="ask-card project-appearBigCard project-appearBigCard__item--1" @click="next(false)">
          <div class="ask-card__content">
             <div class="ask-card__media">
               <component :is="'icon-cross'" :width="28"></component>
             </div>
            <p class="ask-card__text">Non, je n'en ai pas</p>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import IconValid from '../icon/IconValid.vue';
import IconCross from '../icon/IconCross.vue';

export default {
    name: 'projectAskOne',
    components: { IconValid, IconCross },
    data() {
      return {
        gtagGroupEvent: 'project_form'
      }
    },
    beforeMount() {
       // Google Tag Manager : push event projet view
        //******************************************** */
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A0.Objet_Fichier3D.0_view')
        this.updateCurrentGoogleTag('P3Imp_A0.Objet_Fichier3')
        //****
    },
    methods: {
    ...mapActions('projectDesign', [
       'increment', 'adaptWay', 'updateCurrentGoogleTag'
    ]),
    next(bool) {

      this.adaptWay({key: 'projectAskOne', value: bool })

       // Google Tag Manager : push event projet view
      let endTag = bool == true ? '2_Click_yes' : '3_Click_no'
        //******************************************** */
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A0.Objet_Fichier3D.' + endTag)
        //****

      this.increment()
    },
  },
}
</script>