<template>
  <div class="project">
  
    <div class="project-wrapp project-wrapp--lg">

      <h2 class="project-heading recap-heading">
        Projet : "{{ projectDesign.name }}"
      </h2>

      <div class="project-content">

        <div v-if="projectDesign.returnReason !== ''" class="p-3 mt-3 background-white" v-html="projectDesign.returnReason"></div>
        <br>
        <div class="d-flex flex-wrap p-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Nom du projet</h5>
            <h6 class="h6">{{ projectDesign.name }}</h6>
          </div>
          <div>
            <return-to-step-button step-name="name"></return-to-step-button>
          </div>
        </div>

        <!-- Description -->
        <div class="d-flex flex-wrap flex-lg-nowrap p-3 mt-3 background-white">
          <div class="flex-grow-1 overflow-hidden">
            <h5 class="t3 color-secondary">Description</h5>
            <p
              v-for="(line, index) in projectDesign.description.split('\n')"
              :key="index"
              class="h6 mb-0"
            >
              {{ line }}<br />
            </p>
            <!-- <p class="h6" style="white-space: normal">{{projectDesign.description}}</p> -->
            <!-- <template>
              <div v-html='projectDesign.description'></div>
            </template> -->
          </div>
          <div>
            <return-to-step-button step-name="description"></return-to-step-button>
          </div>
        </div>

        <!-- Delais souhaité -->
        <div v-if="getDate" class="d-flex flex-wrap p-3 mt-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Délai souhaité</h5>
            <h6 class="h6">{{ getDate.text }}</h6>
          </div>
          <div>
            <return-to-step-button step-name="deliveryTime"></return-to-step-button>
          </div>
        </div>

        <!-- Application -->
        <div v-if="getFieldsName.length > 0" class="d-flex flex-wrap flex-lg-nowrap p-3 mt-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Application(s)</h5>
            <span
                v-for="(value, index) in getFieldsName"
                :key="index"
                class="h6"
              >
                <span v-if="index > 0">, </span>{{ value.name }}</span>
          </div>
          <div>
            <return-to-step-button step-name="fields"></return-to-step-button>
          </div>
        </div>

        <!-- Dimension -->
        <div v-if="projectDesign.dim.x > 0" class="d-flex flex-wrap p-3 mt-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Dimensions en mm (l x L x H)</h5>
            <h6 class="h6 d-inline">{{ projectDesign.dim.x }} </h6>
            <h6 class="h6 d-inline">x {{ projectDesign.dim.y }} </h6>
            <h6 class="h6 d-inline">x {{ projectDesign.dim.z }}</h6>
          </div>
          <div>
             <return-to-step-button step-name="dim"></return-to-step-button>
          </div>
        </div>

        <!-- Code postal -->
        <div v-if="projectDesign.zipcode != undefined && projectDesign.zipcode != '' " class="d-flex flex-wrap p-3 mt-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Code postal</h5>
            <h6 class="h6">{{ projectDesign.zipcode }}</h6>
          </div>
          <div>
            <return-to-step-button step-name="zipcode"></return-to-step-button>
          </div>
        </div>

        <!-- Solution pour scanner l'object -->
        <div v-if="projectDesign.howScan > 0" class="d-flex flex-wrap p-3 mt-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Solution pour scanner l'objet</h5>
            <h6 v-if="projectDesign.howScan == 1" class="h6">Déplacement du maker</h6>
            <h6 v-if="projectDesign.howScan == 2" class="h6">Envoie et retour de l'objet</h6>
          </div>
          <div>
            <return-to-step-button step-name="howScan"></return-to-step-button>
          </div>
        </div>

        <!-- Fichiers -->
        <div class="d-flex flex-wrap p-3 mt-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Fichier(s)</h5>
              <div v-if="projectDesign.file.length === 0 && projectDesign.savedFile.length === 0">
                <h6 class="h6">Aucun fichier</h6>
              </div>
              <div v-for="file in projectDesign.file" :key="file.name">
                <h6 class="h6">{{ file.name }}</h6>
              </div>
              <div v-for="savedFile in projectDesign.savedFile" :key="savedFile.original_name">
                <h6 class="h6">{{ savedFile.original_name }}</h6>
              </div>
          </div>
          <div>
            <div v-if="steps.wayCurrent === 'IMPRESSION_C' || steps.wayCurrent === 'NUMERISATION_A'">
              <return-to-step-button step-name="file"></return-to-step-button>
            </div>
            <div v-else>
              <return-to-step-button step-name="fileMandatory"></return-to-step-button>
            </div>
          </div>
        </div>

        <!-- Devis pour une impression en option -->
        <div v-if="steps.wayCurrent === 'NUMERISATION_A' || steps.wayCurrent === 'MODELISATION_A' || steps.wayCurrent === 'MODELISATION_B'" 
            class="d-flex flex-wrap p-3 mt-3 background-white">
          <div class="flex-grow-1">
            <h5 class="t3 color-secondary">Devis pour une impression en option</h5>
            <h6 class="h6">{{ projectDesign.quoteOptionForPrinting == true ? "Oui" : "Non" }}</h6>
          </div>
          <div>
            <div v-if="steps.wayCurrent === 'NUMERISATION_A'">
              <return-to-step-button step-name="wantDevis"></return-to-step-button>
            </div>
            <div v-if="steps.wayCurrent === 'MODELISATION_A' || steps.wayCurrent === 'MODELISATION_B'">
              <!-- <return-to-step-button step-name="projectAskFour"></return-to-step-button>-->
            </div>
          </div>
        </div>

      </div><!-- project-content -->

      <!-- action -->
      <div class="project-action--fixed">
        <button
          v-if="!userConnected && this.$route.params.token == undefined"
          class="btn-u3dm btn-u3dm--primary"
          @click="openPanelConnect"
        >
          Valider mon projet
        </button>

        <button-loader v-if="(userConnected || this.$route.params.token !== undefined) && this.$route.name !== 'ProjectCorrection'"
        class="btn-u3dm btn-u3dm--primary"
         :isLoading="btnIsLoading"
         :title="'Envoyer mon projet'"
         @clickLoading="sendProject"></button-loader>

        <button-loader v-if="this.$route.name === 'ProjectCorrection'"
                       class="btn-u3dm btn-u3dm--primary"
                       :isLoading="btnIsLoading"
                       :title="'Renvoyer mon projet'"
                       @clickLoading="sendProject"></button-loader>
      </div>
    
    </div><!-- project-wrapp -->

    <!-- Modal connexion -->
    <transition name="fade">
      <modal-login-project v-if="modalVisible" @close="modalToggle"></modal-login-project>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import ModalLoginProject from "../modal/ModalLoginProject.vue";

import refFields from "./static_ref/ref_project.json";
import ReturnToStepButton from "./ReturnToStepButton";
import ButtonLoader from '../ButtonLoader.vue';

export default {
  components: {ReturnToStepButton, ModalLoginProject, ButtonLoader },
  name: "ProjectRecap",
  props: {
    refFields: {
      type: Array,
      default() {
        return [];
      },
    },
    refTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    refDates: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      REF_fields: refFields[2].data,
      modalVisible: false,
      btnIsLoading: false,

      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState("projectDesign", {
      projectDesign: (state) => state.projectDesign,
      steps: (state) => state.steps,
      wayCurrent: state => state.steps.wayCurrent
    }),
    ...mapState("authentication", {
      customerId: (state) => state.id,
    }),
    ...mapGetters("authentication", {
      userConnected: "isConnected",
    }),
      ...mapGetters("projectDesign", {
        getStatusOfProject: 'getStatusOfProject',
    }),
    getFieldsName() {
      let tab = this.refFields.filter((elt) => {
        return this.projectDesign.fields.indexOf(elt.id) > -1;
      });
      return tab;
    },
    getDate() {
      let tab = [];
      tab = this.refDates.filter((elt) => {
        return elt.title == this.projectDesign.deliveryTime;
      });
      return tab[0];
    },
  },
  beforeMount() {
     // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'p3.valideprojet.0_view')

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A6.ValideProjet.0_view')
          this.updateCurrentGoogleRecapTag('P3Imp_A6.ValideProjet')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B6.ValideProjet.0_view')
          this.updateCurrentGoogleRecapTag('P3Imp_B6.ValideProjet')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C8.ValideProjet.0_view')
          this.updateCurrentGoogleRecapTag('P3Imp_C8.ValideProjet')
        } else if (this.wayCurrent == 'MODELISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A6.ValideProjet.0_view')
          this.updateCurrentGoogleRecapTag('P3Mod_A6.ValideProjet')
        } else if (this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_B7.ValideProjet.0_view')
          this.updateCurrentGoogleRecapTag('P3Mod_B7.ValideProjet')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C9.ValideProjet.0_view')
          this.updateCurrentGoogleRecapTag('P3Mod_C9.ValideProjet')
        } 
        //******************************************** */
  },
  methods: {
    modalToggle() {
      this.modalVisible = !this.modalVisible;
    },
    ...mapActions("projectDesign", [
      "increment", "saveProject", "updateSeeProjectAfterSave", "updateCurrentGoogleRecapTag"
    ]),
    next() {
      this.increment();
    },
    openPanelConnect() {
      this.modalToggle();
    },
    sendProject() {
      this.btnIsLoading = true
      let data = {
        status: 2  ,
      };

      if (this.$route.params.token !== undefined) {
        data.enableToken = this.$route.params.token;
      } else {
        data.customerId = this.customerId;
      }


      // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'p3.valideprojet.1_validate')

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A6.ValideProjet.1_validate')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B6.ValideProjet.1_validate')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C8.ValideProjet.1_validate')
        } else if (this.wayCurrent == 'MODELISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A6.ValideProjet.1_validate')
        } else if (this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_B7.ValideProjet.1_validate')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C9.ValideProjet.1_validate')
        } 
        //******************************************** */

      this.saveProject(data).then((response) => {
        window.location.href = '#thanks'
        let token = this.$route.params.token === undefined ? '' : '/' + this.$route.params.token
        this.updateSeeProjectAfterSave({token: token, reference: JSON.parse(response.body).project_reference})
        this.next();
        this.btnIsLoading = false
      });
    },
  },
};
</script>