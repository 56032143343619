<template>
    <div>
        <navigation-site :navThemeTransparent="false"></navigation-site>
        <div class="space-nav-foot">
            <div class="min-h-80vh mt-5 pt-5 text-center">
                <h3 class="h3 mt-5">Page introuvable</h3>
                <a :href="urlWWWHome" class="mt-3 btn-u3dm btn-u3dm--primary">Accueil</a>
            </div>
        </div>
        <footer-site></footer-site>
    </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import NavigationSite from '../components/ui/NavigationSite.vue'
export default {
    name: "PageNotFound",
    components: { NavigationSite, FooterSite },
    data() {
        return {
            urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
        }
    }
}
</script>
        