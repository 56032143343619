<template>
  <div class="project">
    <div class="project-wrapp">
      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">Description de votre projet</h2>

      <!-- Content page -->
      <div class="project-content d-flex justify-content-center appear-form">
        <div class="project-form">
          <textarea cols='60' rows='8'
            v-model="description"
            class="project-form__textarea"
            placeholder="Description de votre projet"
            resize="none"
            
            maxlength="1000"></textarea>
          <span class="project-form-textarea__helper">{{ nbWords }} / 1000</span>
          <p v-if="errorForm" class="project-form__textarea--error">{{ errorMessage }}</p>
        </div>
      </div>

      <!-- Button action go next step -->
      <div v-if="!returnToRecap" class="project-action appear-form">
        <button class="btn-u3dm btn-u3dm--primary" @click="next">
          Etape suivante
        </button>
      </div>

      <return-to-recap-button v-if="returnToRecap" :updateValue="updateValue"></return-to-recap-button>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ReturnToRecapButton from "./ReturnToRecapButton";

export default {
  name: "ProjectDescription",
  components: {ReturnToRecapButton},
  data() {
    return {
      description: "",
      errorForm: false,
      errorMessage: 'Vérifier la description de votre projet',

      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState("projectDesign", {
      descriptionOnStore: (state) => state.projectDesign.description,
      returnToRecap: state => state.returnToRecap,
      wayCurrent: state => state.steps.wayCurrent
    }),
    nbWords() {
      return this.description != undefined ? this.description.length : 0;
    },
  },
  beforeMount() {
    if (this.descriptionOnStore == undefined || this.descriptionOnStore == null) {
       this.description = ''
    } else {
      this.description = this.descriptionOnStore;
    }

    // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)
        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A3.Description.0_view')
          this.updateCurrentGoogleTag('P3Imp_A3.Description')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B2.Description.0_view')
          this.updateCurrentGoogleTag('P3Imp_B2.Description')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C4.Description.0_view')
          this.updateCurrentGoogleTag('P3Imp_C4.Description')
        } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A2.Description.0_view')
          this.updateCurrentGoogleTag('P3Mod_A2.Description')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C4.Description.0_view')
          this.updateCurrentGoogleTag('P3Mod_C4.Description')
        } 
        //******************************************** */
  },
  methods: {
    ...mapActions("projectDesign", [
      "updateDescription", "increment", "updateCurrentGoogleTag"
    ]),
    next() {
      if(this.updateValue()) {
        this.updateValue();


        // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A3.Description.3_next')          
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B2.Description.3_next')          
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C4.Description.3_next')          
        } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A2.Description.3_next')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C4.Description.3_next')
        } 
        //******************************************** */

        this.increment();
      }
    },
    updateValue() {
      if (this.description.trim() == '') {
        this.errorForm = true
        return false;
      } else {
        this.errorForm = false
        this.updateDescription(this.description);
        return true;
      }
    },
  },
};
</script>