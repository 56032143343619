<template>
  <div class="project">
    <div class="project-wrapp project-wrapp--lg">

      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">Souhaitez-vous en option, un devis pour l'impression ?</h2>
  
      <!-- Content page -->
      <div class="project-content project-content--flex">

          <div class="ask-card project-appearBigCard project-appearBigCard__item--1" @click="next(true)">
            <div class="ask-card__content">
              <div class="ask-card__media">
                <component :is="'icon-valid'" :width="30"></component>
              </div>
              <p class="ask-card__text">Oui, je souhaite un devis</p>
            </div>
          </div>

          <div class="ask-card project-appearBigCard project-appearBigCard__item--2" @click="next(false)">
            <div class="ask-card__content">
              <div class="ask-card__media">
                <component :is="'icon-cross'" :width="30"></component>
              </div>
              <p class="ask-card__text">Non, je n'en veut pas</p>
            </div>
          </div>

          
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import IconValid from '../icon/IconValid.vue';
import IconCross from '../icon/IconCross.vue';

export default {
    name: 'projectAskFour',
    components: { IconValid, IconCross },
    data() {
      return {
        gtagGroupEvent: 'project_form'
      }
    },
    computed: {
      ...mapState("projectDesign", {
        wayCurrent: state => state.steps.wayCurrent
      }),
    },
    beforeMount() {
       // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A4.Devis.0_view')
          this.updateCurrentGoogleTag('P3Mod_A4.Devis')
        } 
        //******************************************** */
    },  
    methods: {
    ...mapActions('projectDesign', [
       'increment', 'adaptWay', 'updateQuoteOptionForPrinting', 'updateCurrentGoogleTag'
    ]),
    next(bool) {
      this.updateQuoteOptionForPrinting(bool)
      this.adaptWay({key: 'projectAskFour', value: bool })

      // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        let endTag = bool == true ? '3_click_yes' : '2_click_no'
        this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_A4.Devis.' + endTag)
        //******************************************** */

      this.increment()
    },
  },
}
</script>