<template>
  <div class="position-relative background-light">
    <maker-navigation-site :navThemeTransparent="false"></maker-navigation-site>

    <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
      <div class="d-flex align-items-start flex-wrap">

        <!-- Menu -->
        <maker-menu></maker-menu>

        <div class="w-lg-75 overflow-x-hidden">

          <!-- Content -->
          <div class="mt-5" style="padding-bottom: 30px;">
            <h4 class="h4">Mon atelier</h4>
            <div v-if="rdvDate">
              Prochain rendez vous avec United 3D Maker le {{rdvDate}}. <a v-if="rdvGoogleMeetLink" target="_blank" :href="rdvGoogleMeetLink">Lien visio Google Meet.</a>
            </div>
            <div v-if="rdvWaiting">
              En attente de confirmation du rendez-vous.
            </div>

            <form @submit.prevent="() => sendForm" class="mt-3" id="maker-profil-form">
              <div class="row g-2">
                <div class="col-md-6">
                  <div class="ms-1 form-check">
                    <input v-model="printer" class="form-check-input" type="checkbox" id="for-maker">
                    <label class="form-check-label t1" for="for-maker">
                      Vous êtes imprimeur
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="ms-1 form-check float-end">
                    <input v-model="closed" class="form-check-input" type="checkbox" id="for-closed">
                    <label class="form-check-label t1" for="for-closed">
                      Je suis fermé
                      <i data-bs-toggle="tooltip" title="Indiquer votre disponibilité pour recevoir des devis et des commandes" class="bi bi-info-circle"></i>
                    </label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="ms-1 form-check">
                    <input v-model="designer" class="form-check-input" type="checkbox" id="for-designer">
                    <label class="form-check-label t1" for="for-designer">
                      Vous êtes designer
                    </label>
                  </div>
                </div>
                <div class="col-md-6">
                  <input v-model="firstname" required placeholder="Prénom*" type="text" class="form-control">
                </div>
                <div class="col-md-6">
                  <input v-model="lastname" required placeholder="Nom*" type="text" class="form-control">
                </div>
                <div class="col-md-12">
                  <input required v-model="email" placeholder="Email*" type="text" class="form-control" readonly>
                </div>
                <div class="col-md-12">
                  <input required type="text" v-model="compagny" placeholder="Nom commercial*" class="form-control">
                </div>
                <div class="col-md-12">
                  <input type="text" v-model="address.street1" placeholder="Rue*" class="form-control" required>
                </div>
                <div class="col-md-12">
                  <input type="text" v-model="address.street2" placeholder="Complément d'adresse" class="form-control">
                </div>
                <div class="col-md-8">
                  <input type="text" v-model="address.city" placeholder="Ville*" class="form-control" required>
                </div>
                <div class="col-md-4">
                  <input type="number" v-model="address.zipcode" placeholder="Code postal*" class="form-control" required>
                </div>
                <div class="col-md-12">
                  <Countries :default-country="address.country" @event-update-country="updateCountry" class="form-control" />
                </div>
                <div class="col-md-12">
                  <input v-model="address.phone" required type="tel" placeholder="Téléphone*" class="form-control">
                </div>
                <div class="col-md-12">
                  <input required v-model="website" type="text" placeholder="Site web*" class="form-control">
                </div>
                <div class="col-md-2">
                  Compétences
                </div>
                <div class="col-md-10">
                  <span class="badge-material" :class="skill.selected ? 'active' : ''" v-for="(skill, index) in skills" :key="index" @click="selectSkill(skill)">{{ skill.name }}</span>
                </div>
                <div class="col-md-2">
                  <label for="logo">Description*</label>
                </div>
                <div class="col-md-10">
                  <textarea v-model="description" required type="text" class="form-control"></textarea>
                </div>

                <div class="col-md-2">
                  <label for="portfolio">Portfolio* <br>(fichiers image)</label>
                </div>
                <div class="col-md-10">
                  <input @change="uploadPortfolio($event.target.files[0])" type="file" name="portfolio" id="portfolio-upload" accept="image/*">
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10">
                  <div v-for="(portfolioFile, index) in this.portfolioFiles" :key="index">{{portfolioFile.name}} <a style="color: #000" @click="(e) => {e.preventDefault(); removePortfolioFile(index);}" href="#"><i class="bi bi-trash"></i></a></div>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10">
                  <span style="margin-right: 10px" v-for="(savedPortfolioFile, index) in this.savedPortfolioFiles" :key="index">
                    <div style="width: 100px; display: inline-block">
                      <a target="_blank" :href="portfolioImgBaseUrl + savedPortfolioFile.id">
                        <img width="100px" :src="portfolioImgBaseUrl + savedPortfolioFile.id">
                      </a>
                      <br>
                      <div class="text-center mt-1">
                        <a style="color: #000" @click="(e) => { e.preventDefault(); removeSavedPortfolioFile(index);}" href="#">
                         <i class="bi bi-trash"></i>
                       </a>
                      </div>
                    </div>
                  </span>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10 text--error">
                  {{ errorPortfolio }}
                </div>
                <div class="col-md-2">
                  <label for="logo">Logo <br>(fichier image)</label>
                </div>
                <div class="col-md-10">
                  <input @change="uploadLogo($event)" type="file" name="logo" accept="image/*">
                </div>
                <div v-if="savedLogoFile" class="col-md-2">
                </div>
                <div v-if="savedLogoFile" class="col-10">
                  <div style="width: 100px; display: inline-block">
                    <a target="_blank" :href="logoImgBaseUrl">
                      <img width="100px" :src="logoImgBaseUrl">
                    </a>
                  </div>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-10 text--error">
                  {{ errorLogo }}
                </div>
                <div class="col-12 text--error">
                  {{ errorMakerProfil }}
                </div>
                <div class="col-12 text--error">
                  {{ errorTypeMaker }}
                </div>
              </div><!-- row -->

              <div class="mt-3">
                <button class="btn-u3dm btn-u3dm--primary" @click="sendForm" @keyup.enter="sendForm">Valider</button>
              </div>
            </form>

          </div>

        </div>

      </div>
    </div>

    <footer-site></footer-site>

  </div>
</template>

<script>
import FooterSite from '../components/ui/FooterSite.vue'
import MakerNavigationSite from '../components/ui/MakerNavigationSite.vue'
import MakerMenu from '../components/ui/MakerMenu.vue'
import Countries from "../components/Countries";

export default {
  components: {MakerNavigationSite, FooterSite, MakerMenu, Countries},
  name: "MakerProfil",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mon atelier',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      rdvGoogleMeetLink: null,
      rdvDate: null,
      printer: 0,
      designer: 0,
      closed: 0,
      firstname: '',
      lastname: '',
      description: '',
      email: '',
      compagny: '',
      website: '',
      errorPortfolio: '',
      errorTypeMaker: '',
      errorLogo: '',
      errorMakerProfil: '',
      skills: [],
      portfolioFiles: [],
      logoFile: null,
      savedPortfolioFiles: [],
      removedSavedPortfolioFiles: [],
      savedLogoFile: null,
      address:  {
        street1: null,
        street2: '',
        city: null,
        zipcode: null,
        country: null,
        phone: null
      },
      portfolioImgBaseUrl: process.env.VUE_APP_API_BASE_URL + 'user/portfolio/',
      logoImgBaseUrl: process.env.VUE_APP_API_BASE_URL + 'user/logo',
      rdvWaiting: false,
    }
  },
  beforeMount() {
    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'ref/skill', {credentials: true})
        .then((response) => {
          this.skills = response.body
              this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/get-maker-profile', {credentials: true})
                  .then(({body}) => {
                    this.printer = body.maker.printer
                    this.designer = body.maker.designer
                    this.firstname = body.maker.firstname
                    this.lastname = body.maker.lastname
                    this.email = body.email
                    this.compagny = body.maker.company
                    this.website = body.maker.web_site
                    this.description = body.maker.bio
                    this.savedLogoFile = body.maker.profile_picture_original_name
                    this.savedPortfolioFiles = body.maker.portfolio_images
                    this.address.street1 = body.maker.address.street1
                    this.address.street2 = body.maker.address.street2 !== undefined ? body.maker.address.street2 : null
                    this.address.city = body.maker.address.city
                    this.address.zipcode = body.maker.address.zipcode
                    this.address.country = body.maker.address.country
                    this.address.phone = body.maker.address.telephone
                    this.closed = !body.maker.available

                    // Set already selected skills
                    for (let makerSkill of body.maker.design_skills) {
                      for (let skill of this.skills) {
                        if(makerSkill.id === skill.id) {
                          skill.selected = true
                        }
                      }
                    }
                  })
        })

    this.$http.get(process.env.VUE_APP_API_BASE_URL + 'rdv/next', {credentials: true})
        .then(({body}) => {
          if(body === true) {
            this.rdvWaiting = true
          } else {
            this.rdvDate = body.date
            let test = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(body.google_meet_link)
            if(test) {
              this.rdvGoogleMeetLink = body.google_meet_link
            } else {
              this.rdvGoogleMeetLink = 'https://' + body.google_meet_link
            }
          }
        })
  },
  methods: {
    updateCountry(payload) {
      this.address.country = payload
    },
    uploadPortfolio(file) {
      let fileExist = this.portfolioFiles.filter((portfolioFile) => {
        return portfolioFile.name === file.name;
      });
      if(fileExist.length === 0) {
        this.portfolioFiles.push(file);
      } else {
        alert('Cette image a déjà été ajoutée')
      }
    },
    removePortfolioFile(index) {
      this.portfolioFiles.splice(index, 1)
    },
    removeSavedPortfolioFile(index) {
      this.removedSavedPortfolioFiles.push(this.savedPortfolioFiles[index])
      this.savedPortfolioFiles.splice(index, 1)
    },
    selectSkill(skill) {
      if(skill.selected) {
        skill.selected = false
      } else {
        skill.selected = true
      }
      this.$forceUpdate();
    },
    uploadLogo(event) {
      this.logoFile = event.target.files[0]
    },
    sendForm() {
      this.errorPortfolio = ''
      this.errorLogo = ''
      this.errorMakerProfil = ''
      this.errorTypeMaker = ''

      for (let portfolioFile of this.portfolioFiles) {
        if(!portfolioFile['type'].includes('image')) {
          this.errorPortfolio = "Les images du portfolio doivent toutes être des fichiers image."
        }
      }

      if(this.logoFile && !this.logoFile['type'].includes('image')) {
        this.errorLogo = "Le logo doit être un fichier image."
      }

      if(this.savedPortfolioFiles.length <= 0 && this.portfolioFiles.length <= 0) {
        this.errorPortfolio = "Les images de votre portfolio sont obligatoires."
      }

      if(!this.printer && !this.designer) {
        this.errorTypeMaker = "Vous devez soit être imprimeur ou designer."
      }

      var form = document.getElementById('maker-profil-form');

      let formData = new FormData();
      formData.append('printer', this.printer ? 1 : 0);
      formData.append('designer', this.designer ? 1 : 0);
      formData.append('available', this.closed ? 0 : 1);
      formData.append('firstname', this.firstname);
      formData.append('lastname', this.lastname);
      formData.append('compagny', this.compagny);
      formData.append('description', this.description);
      formData.append('website', this.website);
      formData.append('street1', this.address.street1);
      formData.append('street2', this.address.street2 !== '' && this.address.street2 !== null ? this.address.street2 : '');
      formData.append('zipcode', this.address.zipcode);
      formData.append('city', this.address.city);
      formData.append('phone', this.address.phone);
      formData.append('country', this.address.country);
      if(this.logoFile) {
        formData.append('logo', this.logoFile);
      }
      this.portfolioFiles.forEach((portfolioFile) => {
        formData.append("portfolios[]", portfolioFile);
      })
      this.removedSavedPortfolioFiles.forEach((removedSavedPortfolioFile) => {
        formData.append("removedPortfolios[]", removedSavedPortfolioFile.id);
      })
      this.skills.forEach((skill) => {
        if(skill.selected) {
          formData.append("skills[]", skill.id);
        }
      })

      if (!this.errorTypeMaker && !this.errorPortfolio && !this.errorLogo && form.checkValidity() !== false) {
        this.$http.post(process.env.VUE_APP_API_BASE_URL + "user/update-maker-profile", formData, {credentials: true}).then(() => {
          window.location.reload()
        }, (response) => {
          for (let key of Object.keys(response.body)) {
            this.errorMakerProfil = this.errorMakerProfil + response.body[key][0]
          }
        })
      }

    },
  }
}
</script>
