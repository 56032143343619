<template>
  <div class="material-carousel-item inner-scroll">
    <h5 class="material-carousel-title h5 ">{{ item.name }}</h5>
    <p class="material-carousel-description t1" v-html="item.description"></p>
    <div class="material-carousel-color">
      <h6 class="material-carousel-color-title h6">Couleurs disponibles</h6>
      <div>
        <span v-for="(color, index) in item.colors" :key="index">
          <img v-if="color['hexadecimal_code'] === 'multicouleurs1'" :src="require('@/assets/images/print/color.png')" alt="Multicouleurs" class="material-carousel-color-circle">
          <img v-if="color['hexadecimal_code'] === 'multicouleurs2'" :src="require('@/assets/images/print/color_uhd.png')" alt="Multicouleurs UHD" class="material-carousel-color-circle">
          <span v-if="color['hexadecimal_code'] !== 'multicouleurs1' && color['hexadecimal_code'] !== 'multicouleurs2'"
              :style="{background: color['hexadecimal_code']}"
              class="material-carousel-color-circle"
          ></span>
        </span>
      </div>
    </div>
    <div class="material-carousel-action">
      <button class="btn-u3dm btn-u3dm--primary" @click="selectMaterial(item)">Sélectionner ce matériau</button>
    </div>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: "CarouselMaterialItem",
   data() {
    return {
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("print", {
      materialItems: (state) => state.printFile.materialItems,
      //materialItemsByTechnologieCategory: (state) => state.printFile.materialItemsByTechnologieCategory,
      application: (state) => state.printFile.application
    }),
    ...mapState("printManager", {
      indexActive: (state) => state.manageCarousel.indexActive,
    }),
    item() {
      if (this.application.id == 0) {
        //return this.materialItemsByTechnologieCategory[this.indexActive]
        return this.materialItems[this.indexActive]
      } else {
        return this.materialItems[this.indexActive]
      }
         
    }
  },
  methods: {
    ...mapActions("print", [
      "updateColorsList", "updateLayersList", "updateMaterial",  "updateColor", "updateLayer"
    ]),
    ...mapActions("printManager", [
      "updateDrawerMaterialIsVisible", "updateIndexActive", "updateStepNextPossible", "updateLastChildActive"
    ]),
    selectMaterial(item) {
      this.updateColorsList(item.colors)
      this.updateLayersList(item.layers)
      this.updateMaterial({id: item.id, name: item.name, basePrice: item.basePrice})

      // reset : color selected, layer selected
      this.updateColor({id:null,name:null,plusPrice:null,hexadecimalCode:null})
      this.updateLayer({id:null,height:null,plusPrice:null})

      this.updateDrawerMaterialIsVisible(false)
      this.updateStepNextPossible(4)
      this.updateLastChildActive(4) 

       // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I3.2.Material_select')
        //******************************************** */

    },
  }
};
</script>