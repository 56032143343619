<template>
  <div class="project">
    <div class="project-wrapp">
      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">Vos fichiers</h2>

      <!-- Content -->
      <div class="project-content appear-form">
        <p class="project-upload-explain">
          Pour permettre de comprendre au mieux votre projet, nous acceptons
          tous types de fichiers. Image, spécification, croquis, plan,…
        </p>

        <div class="project-upload-zone">
          <form id="file-form-drop" action="" enctype="multipart/form-data" class="project-form-upload">
            <img
              :src="require('@/assets/icon-svg/icon-upload-red.svg')"
              alt="icon upload"
              class="project-form-upload__icon"
            />
            <span id="file-label" class="project-form-upload__label h6">
              Importer ou glisser / déposez vos fichiers ici
            </span>
            <div class="project-form-upload__actions">
              <span class="btn-u3dm btn-u3dm--secondary">Importer</span>
            </div>
            <input
                type="file"
                id="project-design-upload"
                name="file"
                ref="fichier"
                class="project-form-upload__button btn--upload"
                @change="addFile($event.target.files[0])"
            />
          </form>
          <p v-if="fileTooBig" class="project-upload-zone--error">Fichier trop volumineux (maximum 60 MO)</p>
          <ul class="project-form-upload__filename t1">
            <li v-for="(file, index) in savedFiles" :key="index">
              <img
                src="@/assets/icon-svg/icon-cross-grey.svg"
                alt="icon cross"
                @click="removeSavedFile(file.original_name)"
              />
              {{ file.original_name }}
            </li>
            <li v-for="(file, index) in files" :key="index">
              <img
                  src="@/assets/icon-svg/icon-cross-grey.svg"
                  alt="icon cross"
                  @click="removeFile(file.name)"
              />
              {{ file.name }}
            </li>
          </ul>
        </div>
      </div>

      <!-- Action -->
      <div class="project-action appear-form">
        <button
            v-if="!returnToRecap"
            class="btn-u3dm btn-u3dm--primary"
            :class="files.length > 0 || savedFiles.length > 0  ? '' : 'btn--disabled'"
            :disabled="files.length <= 0 && savedFiles.length <= 0 "
            @click="next"
        >
          Etape suivante
        </button>
        <p
            v-if="!isMandatory && !returnToRecap"
            class="t1 mt-2 cursor-p"
            :class="files.length > 0 || savedFiles.length > 0 ? 'btn--disabled' : ''"
            @click="next"
        >
          Ignorer cette etape
        </p>

        <return-to-recap-button v-if="returnToRecap && isMandatory" :updateValue="updateValue"
                                :classBtn="files.length <= 0 ? 'btn--disabled' : ''"
                                :disabled="files.length <= 0"></return-to-recap-button>

        <return-to-recap-button v-if="returnToRecap && !isMandatory" :updateValue="updateValue"></return-to-recap-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import ReturnToRecapButton from "./ReturnToRecapButton";

export default {
  name: "ProjectUpload",
  components: {ReturnToRecapButton},
  props: {
    isMandatory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      files: [],
      savedFiles: [],
      fileTooBig: false,

      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState('projectDesign', {
      fileOnStore : state => state.projectDesign.file,
      savedFileOnStore : state => state.projectDesign.savedFile,
      returnToRecap: state => state.returnToRecap,
      wayCurrent: state => state.steps.wayCurrent
    })
  },
  beforeMount() {
    if (this.fileOnStore == undefined || this.fileOnStore == null) {
      this.files = []
    } else {
      this.files = Object.assign(this.fileOnStore)
    }

    if (this.savedFileOnStore == undefined || this.savedFileOnStore == null) {
      this.savedFiles = []
    } else {
      this.savedFiles = Object.assign(this.savedFileOnStore)
    }

      // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A1.UploadFile.0_view')
          this.updateCurrentGoogleTag('P3Imp_A1.UploadFile')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
            this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B1.uploadFile.0_view')
            this.updateCurrentGoogleTag('P3Imp_B1.uploadFile')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C6.UploadFile.0_view')
          this.updateCurrentGoogleTag('P3Imp_C6.UploadFile')
        } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3mod_A1.uploadFile.0_view')
          this.updateCurrentGoogleTag('P3mod_A1.uploadFile')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C6.UploadFile.0_view')
          this.updateCurrentGoogleTag('P3Mod_C6.UploadFile')
        } 
        //******************************************** */

  },
  mounted() {
    let form_drop = document.getElementById('file-form-drop')
    let dropZone = document.querySelectorAll('#file-form-drop, #file-form-drop #file-label')
    let fileInput = document.getElementById('project-design-upload')

    form_drop.addEventListener('click', () => {
      fileInput.click()
    })

    form_drop.addEventListener('dragenter', (e) => {
      e.preventDefault()
      form_drop.classList.add("hovered");
    })

    form_drop.addEventListener("dragleave",  (e) => {
      e.preventDefault()
      form_drop.classList.remove("hovered");
    });

    dropZone.forEach(item => {
      item.addEventListener('dragover', e => {
        e.preventDefault()
        form_drop.classList.add("hovered");
      })
    })

    form_drop.addEventListener("drop", (e) => {
      e.preventDefault()
      form_drop.classList.remove("hovered");
      this.addFile(e.dataTransfer.files[0]);
    });
  },
  methods: {
    ...mapActions('projectDesign', [
      'increment', 'updateFile', 'updateSavedFile', 'updateCurrentGoogleTag'
      ]),
    next() {
      this.updateValue()

       // Google Tag Manager : push event projet view
        //******************************************** */
        if (this.wayCurrent == 'IMPRESSION_A') { 
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A1.UploadFile.4_next')          
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B1.uploadFile.4_next')         
        }  else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C6.UploadFile.4_next')          
        } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3mod_A1.uploadFile.4_next')          
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C6.UploadFile.4_next')
        } 
        //******************************************** */

      this.increment()
    },
    updateValue() {
      this.updateFile(this.files);
      this.updateSavedFile(this.savedFiles)
      return true
    },
    removeFile(str) {
      this.files = this.files.filter((elt) => {
        return elt.name !== str;
      });
      this.updateFile(this.files);
    },
    removeSavedFile(str) {
      this.savedFiles = this.savedFiles.filter((elt) => {
        return elt.original_name !== str;
      });
      this.updateSavedFile(this.savedFiles);
    },
    selectFile(event) {
      this.addFile(event.target.files[0]);
    },
    addFile(file) {

      // Google Tag Manager : push event projet view
        //******************************************** */
        if (this.wayCurrent == 'IMPRESSION_A') { 
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A1.UploadFile.2_upload_started')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B1.uploadFile.2_upload_started')
        }  else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C6.UploadFile.2_upload_started')
        } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3mod_A1.uploadFile.2_upload_started')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C6.UploadFile.2_upload_started')
        } 
        //******************************************** */
     
       

      document.getElementById("project-design-upload").value = '';
      let fileExist = this.files.filter((elt) => {
        return elt.name === file.name;
      });
      let savedFileExist = this.savedFiles.filter((savedFile) => {
        return savedFile.original_name === file.name;
      });
      if(fileExist.length === 0 && savedFileExist.length === 0) {
        if (file.size <= 60000000) {
          this.fileTooBig = false
          this.files.push(file);
          this.updateFile(this.files);

            // Google Tag Manager : push event projet view
              //******************************************** */
              if (this.wayCurrent == 'IMPRESSION_A') { 
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A1.UploadFile.3_upload_complete')
              } else if (this.wayCurrent == 'IMPRESSION_B') {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B1.uploadFile.3_upload_complete')
              } else if (this.wayCurrent == 'IMPRESSION_C') {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C6.UploadFile.3_upload_complete')
              } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3mod_A1.uploadFile.3_upload_complete')
              } else if (this.wayCurrent == 'NUMERISATION_A') {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C6.UploadFile.3_upload_complete')
              } 
              //******************************************** */
        } else {
          this.fileTooBig = true
        }
      }
    },
  },
};
</script>