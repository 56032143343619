
<template>
  <div>
    <basket-product-item v-for="(item, index) in listProductsPrint" :key="index" :item="item"></basket-product-item>
  </div>
</template>

<script>
import BasketProductItem from './BasketProductItem.vue'

import { mapState } from 'vuex'

export default {
  components: { BasketProductItem },
  name: "BasketProductList",
  computed: {
    ...mapState("shop", {
        listProductsPrint: (state) => state.listProductsPrint,
    }),
  },
}
</script>