<template>
  <div>
    <!-- form upload -->
    <transition class="appear-notification">
      <accord-notification 
        v-if="notif" 
        :message="errorFileFormat.message" @close="closeNotif"></accord-notification>
    </transition>

    <form id="file-form-drop" action="" enctype="multipart/form-data" class="print-form-upload">
      <img
          :src="require('@/assets/icon-svg/icon-upload-red.svg')"
          alt="icon upload"
          class="print-form-upload__icon"
      />
      <span id="file-label" class="print-form-upload__label h6">
              Importer ou glisser / Déposer votre fichier 3D au format .STL ou .OBJ ici
            </span>
      <div class="print-form-upload__actions">
        <span class="btn-u3dm btn-u3dm--secondary">Importer</span>
      </div>
      <input
          type="file"
          accept=".stl, .obj"
          id="print-upload"
          name="file"
          ref="fichier"
          class="project-form-upload__button btn--upload"
          @change="uploadFile($event.target.files[0])"
      />
    </form>
    <div id="text-project">
      <strong><router-link @click.native="switchQuotationLink" to='/project'>Vous n'avez pas de fichier 3D ? Démarrez un projet 3D</router-link></strong>
    </div>
    

  </div>
</template>


<script>
import AccordNotification from './AccordNotification.vue';

import { mapState, mapActions } from "vuex";

export default {
  components: { AccordNotification },
  name: "AccordUpload",
  data() {
    return {
      errorFileFormat: {
        isError: false,
        message: null,
      },
      notif: false,
      gtagGroupEvent: 'impression_form'
    }
  },
  computed: {
    ...mapState("printManager", {
      viewerMobileIsOpen: (state) => state.viewerMobileIsOpen
    }),
  },
  methods: {
    ...mapActions("print", [
      "updateFile",
    ]),
     ...mapActions("printManager", [
      "updateViewerMobileIsOpen"
    ]),
    switchQuotationLink() {
      this.$googleTagManager(this.id, this.ismaker != null, 'impression_form', 'i1.6.switch_quotation')
    },
    closeViewerMobile() {
      this.updateViewerMobileIsOpen(true)
    },
    uploadFile(file) {

      // Google Tag Manager : push event impression form
        //******************************************** */
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I1.2.upload.started')
        //******************************************** */
    
      if (file != null) {
      
        if (this.isFileValid(file.name)) {

          if (file.size <= 60000000) {
            
            this.updateFile(file)
            this.updateViewerMobileIsOpen(true)

            // Google Tag Manager : push event impression form
              //******************************************** */
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I1.3.upload.complete')
              //******************************************** */

          } else {
            this.notif = true
            this.errorFileFormat.message = "Votre fichiers dépasse le poids autoriser"
          }
              document.getElementById("print-upload").value = '';
        } else {
        
          //this.errorFileFormat.isError = true
          this.notif = true
          this.errorFileFormat.message = "les fichiers doivent avoir l'extension .STL ou .OBJ"
            document.getElementById("print-upload").value = '';
        }
      }
    },
    isFileValid(f) {
      let fileExtension = f.split(".").pop().toLowerCase();
      if (fileExtension == "obj" || fileExtension == "stl") { return true } else { return false }
    },
    closeNotif() {
      this.notif = false
    },
  },
  mounted() {
    let form_drop = document.getElementById('file-form-drop')
    let dropZone = document.querySelectorAll('#file-form-drop, #file-form-drop #file-label')
    let fileInput = document.getElementById('print-upload')

    form_drop.addEventListener('click', () => {
      fileInput.click()
    })

    form_drop.addEventListener('dragenter', (e) => {
      e.preventDefault()
      form_drop.classList.add("hovered");
    })

    form_drop.addEventListener("dragleave",  (e) => {
      e.preventDefault()
      form_drop.classList.remove("hovered");
    });

    dropZone.forEach(item => {
      item.addEventListener('dragover', e => {
        e.preventDefault()
        form_drop.classList.add("hovered");
      })
    })

    form_drop.addEventListener("drop", (e) => {
      e.preventDefault()
      form_drop.classList.remove("hovered");
      this.uploadFile(e.dataTransfer.files[0]);
    });
  },
};
</script>