<template>
    <div class="background-light">
        <div class="position-relative">
            <navigation-site :navThemeTransparent="false"></navigation-site>
            <div class="container  space-nav-foot">

                <div class="d-flex justify-content-center  align-items-center">
                    <div class="row justify-content-center mt-5 mb-5 pt-5 pb-5 appearContentFromBot2">
                        <div v-if="isLoaded == true">

                        
                            <!-- rate exist -->
                            <div v-if="isRated == true" class="appearContentFromBot2">
                                <h1 class="h2">La note a déjà été donnée, merci.</h1>
                                <div class="mt-3 text-center">
                                    <a :href="urlWWWHome" class="btn-u3dm btn-u3dm--primary">Aller sur le site</a>
                                </div>
                            </div>

                            <!-- give rate -->
                            <div v-if="isRated == false">
                                <!-- rate -->
                                <div v-if="isRateSend == false" class="appearContentFromBot2">
                                    <div class="text-center p-2">
                                        <h1 class="h2">Donner une note à la prestation</h1>
                                        <div class="mt-5 d-flex justify-content-between">
                                            <svg v-for="(rating, index) in ratings" :key="index"
                                                class="cursor-p"
                                                :class="{'is-selected': ((rate >= rating) && rate != null)}" 
                                                @click="set(rating)"
                                                xmlns="http://www.w3.org/2000/svg" fill="#C6C6C6" width="34" height="34" viewBox="0 0 24 24">
                                            <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
                                            </svg>
                                        </div>
                                    </div>


                                    <div class="p-2 w-100">
                                        <textarea class="w-100" name="message" v-model="message" id="" cols="30" rows="5" placeholder="Entrer votre message"></textarea>
                                    
                                        <!-- action -->
                                        <div class="mt-3">
                                            <button class="btn-u3dm btn-u3dm--primary" @click="sendMessage">Envoyer</button>
                                        </div>
                                    </div>
                                </div>

                                <!-- thanks -->
                                <div v-if="isRateSend == true" class="text-center appearContentFromBot2">
                                    <h2 class="h2">Merci ! Votre note va nous permettre d'améliorer nos services</h2>
                                    <div class="mt-3">
                                        <a :href="urlWWWHome" class="btn-u3dm btn-u3dm--primary">Aller sur le site</a>
                                    </div>
                                </div>

                            
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- container -->
        </div>
        <footer-site></footer-site>
     </div>
</template>

<script>

import FooterSite from '../components/ui/FooterSite.vue'
import NavigationSite from '../components/ui/NavigationSite.vue'
export default {
    components: {NavigationSite, FooterSite,},
    name: "NotationFromMail",
    data() {
        return {
            rate: 5,
            ratings: [1, 2, 3, 4, 5],
            message: '',
            orderToken: '',
            isRateSend: false,
            isRated: false,
            isLoaded: false,
            urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
        }
    },
    beforeMount() {

        if (this.$route.params.orderToken != undefined) {
            this.orderToken = '/' + this.$route.params.orderToken
        }

        this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/order/rating' + this.orderToken, {credentials: true})
            .then((response) => {
                // console.log(response)
                // this.isRateSend = true
                this.isRated = response.body.rated
                this.isLoaded = true
        })  


    },
    methods: { 
        set(value) {
            this.rate = value;
            return this.value = value;
        },  
        sendMessage() {

            let formData = new FormData();
                formData.append('rate', this.rate)
                formData.append('comment', this.message);
            // http://app.u3dm.local/fr/api/user/order/rating/{orderToken}
            this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/order/rating' + this.orderToken, formData, {credentials: true})
                .then(() => {
                    // console.log(response)
                    this.isRateSend = true
                })  
            
        },
    },
}
</script>

<style lang="scss" scoped>
.is-selected {
  fill: #FFD700;
}
</style>