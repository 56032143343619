<template>
  <div class="project">
    <div class="project-wrapp project-appearTitleFromBot">
      <h2 class="project-heading">Application</h2>

      <div class="project-content project-content--flex">
        <!-- Items -->
        <div
          v-for="(item, index) in items"
          :key="index"
          class="apply-card project-appearItem"
          :class="[{ 'apply-card--active': fields.indexOf(item.id) > -1 },'project-appearItem__item--'+(index+1)]"
          @click="selectItems(item.id)"
        >
          <div>
            <h6 class="apply-card__text">{{ item.name }}</h6>
          </div>
        </div>
      </div>

      <!-- action -->
      <div class="project-action--fixed">
        <div class="project-action__content">
          <button
              v-if="!returnToRecap"
              @click="next"
              class="btn-u3dm btn-u3dm--primary"
              :class="fields.length <= 0 ? 'btn--disabled' : ''"
              :disabled="fields.length <= 0"
          >
            Etape suivante
          </button>
          <return-to-recap-button v-if="returnToRecap" :updateValue="updateValue"
                                  :classBtn="fields.length <= 0 ? 'btn--disabled' : ''"
                                  :disabled="fields.length <= 0"></return-to-recap-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ReturnToRecapButton from "./ReturnToRecapButton";
//  :class="[ {'apply-card--active': fields.indexOf(item.id) > -1 }, 'appearItem__item--'+ index]"
export default {
  name: "ProjectApply",
  components: {ReturnToRecapButton},
  props: {
    items: {
      type: Array,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      fields: [],
      error: false,

      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState("projectDesign", {
      applyOnStore: (state) => state.projectDesign.fields,
      returnToRecap: state => state.returnToRecap,
      wayCurrent: state => state.steps.wayCurrent
    }),
  },
  beforeMount() {
    this.fields = this.applyOnStore;

     // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {

          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A2.Application.0_view')
          this.updateCurrentGoogleTag('P3Imp_A2.Application')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B3.Application.0_view')
          this.updateCurrentGoogleTag('P3Imp_B3.Application')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C5.Application.0_view')
          this.updateCurrentGoogleTag('P3Imp_C5.Application')
        } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3mod_A3.Application.0_view')
          this.updateCurrentGoogleTag('P3mod_A3.Application')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C5.Application.0_view')
          this.updateCurrentGoogleTag('P3Mod_C5.Application')
        } 
        //******************************************** */
  },
  methods: {
    ...mapActions("projectDesign", [
      "updateFields", 
      "increment",
      "updateCurrentGoogleTag"
    ]),
    next() {
      this.updateFields(this.fields);
       // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A2.Aplication.3_next')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B3.Application.3_next')
        }  else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C5.Application.3_next')
        } else if (this.wayCurrent == 'MODELISATION_A' || this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3mod_A3.Application.3_next')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C5.Application.3_next')
        } 
        //******************************************** */
      this.increment();
    },
    selectItems(id) {
      if (this.fields.indexOf(id) !== -1) {
        this.fields = this.fields.filter((elt) => {
          return elt !== id;
        });
      } else {
        this.fields.push(id);
      }
    },
    updateValue() {
      this.updateFields(this.fields);
      return true;
    },
  },
};
</script>