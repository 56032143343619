<template>
    <div class="position-relative background-light">
        <navigation-site :navThemeTransparent="false"></navigation-site>

        <div class="container mt-5 pt-5 pb-5 mb-5 min-vh-100">
            <div class="d-flex align-items-start flex-wrap">

                <!-- Menu -->
                <div class="btn-menu-account">
                    <h6>
                        <span v-if="menuIsOpen == false" class="h6 mb-0" @click="menuIsOpen = !menuIsOpen">Menu</span>
                        <span v-if="menuIsOpen == true" class="h6 mb-0" @click="menuIsOpen = !menuIsOpen">Fermer</span>
                    </h6>
                </div>
                <div class="w-lg-25 mt-lg-5 ps-3 b-4-radius account-menu-content" :class="[menuIsOpen == true ? 'menu-is-open' : '']">
                    <div>
                        <div class="mb-4">
                            <h4 class="h4 mb-0 uc-first d-inline-block">{{ firstname }}</h4> 
                            <h4 class="h4 mb-0 uc-first d-inline-block ms-1">{{ lastname }}</h4>
                            <span class="t3 d-block color-secondary overflow-x-hidden">{{ email }}</span>
                        </div>
                    </div>
                    <div class="mt-2">
                        <h6 class="h5 mb-2">Général</h6>
                        <div class="h6 color-secondary mb-1 cursor-p hover-c-text" @click="callAll">Tout Afficher</div>
                        <div class="h6 color-secondary mb-1 cursor-p hover-c-text" @click="callDevis">Devis</div>
                        <div class="h6 color-secondary mb-1 cursor-p hover-c-text" @click="callOrder">Commande</div>
                        <router-link to="/releve-de-compte" class="h6 color-secondary mb-1 cursor-p d-block">Relevé de compte</router-link>
                    </div>
                    <div class="mt-4">
                        <h6 class="h5 mb-2">Compte</h6>
                        <router-link to="/profil" class="h6 color-secondary mb-1 cursor-p d-block">Mon profil</router-link>
                        <router-link to="/modifier-mot-de-passe" class="h6 color-secondary mb-1 cursor-p d-block">Mon mot de passe</router-link>
                        <div class="h6 color-secondary mb-1 cursor-p hover-c-text" @click="logoutAccount">Déconnexion</div>
                    </div>
                </div><!-- fin menu -->

                <div v-if="oneContentLoaded == false" class="w-lg-75 d-flex justify-content-center align-items-center mt-5">
                    Chargement
                </div>
                <div v-if="oneContentLoaded == true" class="w-lg-75 overflow-x-hidden">
                    
                    <!-- Devis -->
                    <div v-if="devisLoaded == true" class="mt-5 appearContentFromRight">
                        <h4 class="h4">Mes projets et devis<span v-if="listDevis.length > 0" class="ms-3 toast-rounded">{{ listDevis.length }}</span></h4>
                        <div v-if="listDevis.length < 1" class="t1 mt-1">Aucun devis en cours</div>

                        <!-- table devis -->
                        <div v-if="listDevis.length > 0" class="table-responsive mt-4">
                            <table class="table table-borderless align-middle table-in-account">
                                <thead>
                                    <tr class="pt-5 pb-5">
                                        <th scope="col" style="text-align: center" class="h6">Réference</th>
                                        <th scope="col" style="text-align: center" class="h6">Nom du projet</th>
                                        <th scope="col" style="text-align: center" class="h6">Validité des devis</th>
                                        <th scope="col" style="text-align: center" class="h6">Status</th>
                                        <th scope="col" style="text-align: center" class="h6">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listDevis" :key="index" class="pt-5 pb-5">
                                        <td style="text-align: center" class="h6">{{ item.project_reference }}</td>
                                        <td style="text-align: center;max-width:215px;overflow:hidden" class="h6">{{ item.name }}</td>
                                        <td style="text-align: center">
                                            <span v-if="item.type == 'quotation'">{{ item['available_quotation_at'] }}</span>
                                            <span v-if="item.type == 'project'">-</span>
                                        </td>
                                        <td style="text-align: center" class="h6">{{ item.status }}</td>
                                        <td style="text-align: center">
                                            <router-link v-if="item.type == 'quotation'" :to="'devis/'+item.project_reference" class="btn-u3dm btn-u3dm--primary">Voir le devis</router-link>
                                            <router-link v-if="item.type == 'project' && item.status_id !== 8" :to="'devis/'+item.reference" class="btn-u3dm btn-u3dm--tertiary">Voir le projet</router-link>
                                            <router-link v-if="item.type == 'project' && item.status_id === 8" :to="'correction/project/'+item.project_reference" class="btn-u3dm btn-u3dm--tertiary">Voir le projet</router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <!-- Commandes -->
                    <div v-if="orderSellLoaded == true" class="mt-5 appearContentFromRight">
                        <h4 class="h4">Mes commandes <span v-if="listOrderSell.length > 0" class="ms-3 toast-rounded">{{ listOrderSell.length }}</span></h4>

                        <div v-if="listOrderSell.length < 1" class="t1 mt-1">Aucune commande en cours</div>

                        <!-- table commande -->
                        <div v-if="listOrderSell.length > 0" class="table-responsive mt-4">
                            <table class="table table-borderless align-middle table-in-account">
                                <thead>
                                    <tr class="pt-5 pb-5">
                                        <th scope="col" style="text-align: center" class="h6">Réference</th>
                                        <th scope="col" style="text-align: center" class="h6">Status</th>
                                        <th scope="col" style="text-align: center" class="h6">Libellé</th>
                                        <th scope="col" style="text-align: center" class="h6">Date</th>
                                        <th scope="col" style="text-align: center" class="h6">Prix</th>
                                        <th scope="col" style="text-align: center" class="h6">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in listOrderSell" :key="index" class="pt-5 pb-5">
                                        <td style="text-align: center" class="h6">{{ item.reference }}</td>
                                        <td style="text-align: center" class="h6">{{ item.status_name }}</td>
                                        <td style="text-align: center" class="h6">
                                          <span v-if="item.type === 'design'">{{ item.quotation.project.name }}</span>
                                          <span v-if="item.type === 'print'">Impression Fichier 3D</span>
                                        </td>
                                        <td style="text-align: center">{{ item['created_at'] | formatDate }}</td>
                                        <td style="text-align: center" class="h6">{{ (item['total_amount_tax_incl'] - item['discount_amount_tax_incl']) / 100 | formatPrice }} €</td>
                                        <td style="text-align: center"><router-link :to="'/commande/'+item.reference" class="btn-u3dm btn-u3dm--tertiary">Consulter</router-link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

            </div>
        </div>

        <footer-site></footer-site>

    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import FooterSite from '../components/ui/FooterSite.vue'
import NavigationSite from '../components/ui/NavigationSite.vue'

export default {
  components: {NavigationSite, FooterSite},
  name: "Account",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Mon compte',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Déposez vos fichiers et/ou vos projets 3D.' }
    ],
  },
  data() {
    return {
      listOrderSell: null,
      listDevis: null,
      oneContentLoaded: false,
      devisLoaded: false,
      orderSellLoaded: false,

      menuIsOpen: false,
      urlBase: process.env.VUE_APP_APP_BASE_URL
    }
  },
  computed: {
    ...mapState("authentication", {
      isMaker: (state) => state.isMaker,
      isChecked: (state) => state.isChecked,
      firstname: (state) => state.firstname,
      lastname: (state) => state.lastname,
      email: (state) => state.email
    })
  },
  beforeMount() {
    if (this.isMaker) {
      if(this.isChecked) {
        this.$router.push({name: 'maker-dashboard'})
      } else {
        this.$router.push({name: 'MakerAccount'})
      }
    }

    this.contentLoaded = false
    this.getDesign()
    this.getOrderSell()
  },
  methods: {
    ...mapActions("authentication", [
      "logout",
      "setConnected",
    ]),
    logoutAccount: function () {
      this.logout()
          .then(() => {
            window.location.href = process.env.VUE_APP_WWW_BASE_URL
          })
    },
    callAll() {
      this.resetData()

      setTimeout(() => {
        this.getDesign()
        this.getOrderSell()
      }, 300)

    },
    callOrder() {
      this.resetData()
      setTimeout(() => {
        this.getOrderSell()
      }, 300)
    },
    callDevis() {
      this.resetData()
      setTimeout(() => {
        this.getDesign()
      }, 300)
    },
    resetData() {
      this.listOrderSell = null
      this.listDevis = null
      this.oneContentLoaded = false
      this.devisLoaded = false
      this.orderSellLoaded = false
    },
    getDesign() {
      // http://app.u3dm.local/fr/api/design/list
      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'design/list', {credentials: true})
          .then((response) => {
            // console.log(response)
            this.listDevis = response.body.projects_quotations
            this.devisLoaded = true
            this.oneContentLoaded = true
          }, () => {
            // console.log(error)
          })
    },
    getOrderSell() {
      // http://app.u3dm.local/fr/api/user/orderSell/
      this.$http.get(process.env.VUE_APP_API_BASE_URL + 'user/orderSell/', {credentials: true})
          .then((response) => {
            // console.log(response)
            this.listOrderSell = response.body
            this.orderSellLoaded = true
            this.oneContentLoaded = true
          }, () => {
            // console.log(error)
          })
    }
  }
}
</script>
        