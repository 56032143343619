<template>
    <div>
        <div class="tabs-options-nav">
            <h6 class="h6" @click="changeTab(0)" :class="indexActive == 0 ? 'link--active' : ''">Couleurs</h6>
            <h6 class="h6" @click="changeTab(1)" :class="indexActive == 1 ? 'link--active' : ''">Finitions</h6>
            <!-- <h6 class="h6" @click="changeTab(2)" :class="indexActive == 2 ? 'link--active' : ''">Post-traitement</h6> -->
        </div>

        <div class="tabs-options-content overflow-hidden">
            <transition name="option-accordion">
                <accord-colors v-if="indexActive == 0"></accord-colors>
            </transition>
 
            <transition name="option-accordion">
                <accord-layers v-show="indexActive == 1"></accord-layers>
            </transition>
            <!--
            <transition name="option-accordion">
                <accord-finishing v-show="indexActive == 2"></accord-finishing>
            </transition>
            -->

        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import AccordColors from './AccordColors.vue'
//import AccordFinishing from './AccordFinishing.vue'
import AccordLayers from './AccordLayers.vue'

export default {
    //components: { AccordColors, AccordLayers, AccordFinishing },
    components: { AccordColors, AccordLayers },

    name: "AccordOptions",
    data() {
        return {
            gtagGroupEvent: 'impression_form'
        }
    },
    watch: {
      lastChildActive: function () {
        this.updateOptionsIndexActive(0)
        if (this.colorsList.length === 1) {
          this.updateOptionsIndexActive(1)
          this.updateColor(this.colorsList[0])
        }
        if(this.layersList.length === 1 && this.colorsList.length === 1) {
          this.updateLayer(this.layersList[0])
        }
      }
    },
    computed: {
      ...mapState("printManager", {
        indexActive: (state) => state.manageOptions.indexActive,
        lastChildActive: (state) => state.lastChildActive,
      }),
      ...mapState("print", {
        colorsList: (state) => state.printFile.colorsList,
        layersList: (state) => state.printFile.layersList,
      }),
    },
    methods: {
      ...mapActions("printManager", [
        "updateOptionsIndexActive"
      ]),
      ...mapActions("print", [
        "updateColor",
        "updateLayer"
      ]),
        changeTab(index) {
            this.updateOptionsIndexActive(index)

        // Google Tag Manager : push event impression form
          //******************************************** */
            if (index == 0) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I4.2.Color_back')
            } else if (index == 1) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I5.1.Layer_back')
            } else if (index == 2) {
                this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'I5.1.finishing')
            }
          //******************************************** */
        },
    },
}
</script>