<template>
    <div class="bill-product">
        <div class="bill-product-media">
            <img v-if="item.photo === undefined" src="@/assets/images/product-empty.png" alt="image u3dm">
            <img v-if="item.photo" :src="this.photoBaseUrl + item.id" alt="img screenshot">
        </div>
        <div class="bill-product-description">
            <h5 class="h5">{{ item.file['original_name'] }}</h5>
            <p class="h6 m-0">{{ item.material.name }}, {{ item.quantity }} pcs</p>
            <p class="h6 m-0">{{ item.color.name }}</p>
            <!-- <span v-if="item['item_finishing']">, a{{ item.finishing.name }}</span> -->
        </div>
        <div class="bill-product-price">
            <h6 class="h6">{{ (priceItem(item) / 100) | formatPrice }} € HT</h6>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductOrderPrintItem",
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
      return {
        photoBaseUrl: process.env.VUE_APP_API_BASE_URL + 'application/photo/',
      }
    },
    methods: {
        priceItem(item) {
            let finishing = 0
            if (item['item_finishings'][0] !== undefined) {
                finishing = item['item_finishings'][0]['unit_sell_amount_tax_excl']
            }
    
            return item.quantity * (item['unit_sell_amount_tax_excl'] + finishing)
        },
    }
}
</script>