<template>
  <div class="project">
    <div class="project-wrapp">
      <!-- Title -->
      <h2 class="project-heading project-appearTitleFromBot">Code postal</h2>

      <!-- Cards -->
      <div class="project-content appear-form">
        <div class="project-form">
          <input
            type="text"
            v-model="zipcode"
            class="project-form__input"
            placeholder="Code postal"
            maxlength="5"
            @keypress.enter="next"
          />
          <div v-if="errorForm" class="project-form--error">{{ errorMessage }}</div>
        </div>
      </div>

      <!-- action -->
      <div class="project-action appear-form">
        <button v-if="!returnToRecap" class="btn-u3dm btn-u3dm--primary" @click="next">
          Etape suivante
        </button>
        <return-to-recap-button v-if="returnToRecap" :updateValue="updateValue"></return-to-recap-button>
      </div>
    </div>
  </div>
</template>

<script>
import {zipcode_fr} from "../../zipcode_fr.json";
import { mapState, mapActions } from 'vuex';
import ReturnToRecapButton from "./ReturnToRecapButton";

export default {
  name: 'ProjectZipcode',
  components: {ReturnToRecapButton},
  data() {
    return {
      zipcode: "",
      errorForm: false,
      errorMessage: 'Code postal non conforme',

      gtagGroupEvent: 'project_form'
    };
  },
  computed: {
    ...mapState('projectDesign', {
      zipcodeOnStore: (state) => state.projectDesign.zipcode,
      returnToRecap: state => state.returnToRecap,
      wayCurrent: state => state.steps.wayCurrent
    }),
  },
  beforeMount() {
    this.zipcode = this.zipcodeOnStore;

    // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A4.CodePostal.0_view')
          this.updateCurrentGoogleTag('P3Imp_A4.CodePostal')
        } else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B4.CodePostal.0_view')
          this.updateCurrentGoogleTag('P3Imp_B4.CodePostal')
        } else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_C3.CodePostal.0_view')
          this.updateCurrentGoogleTag('P3Imp_B4.CodePostal')
        } else if (this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_B5.CodePostal.0_view')
          this.updateCurrentGoogleTag('P3Mod_B5.Code Postal')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C3.CodePostal.0_view')
          this.updateCurrentGoogleTag('P3Mod_C3.CodePostal')
        } 
        //******************************************** */
  },
  methods: {
    ...mapActions('projectDesign', [
      'updateZipcode', 'increment', 'updateCurrentGoogleTag'
      ]),
    next() {
      if(this.updateValue()) {


         // Google Tag Manager : push event projet view
        //******************************************** */
        // console.log(this.wayCurrent)

        if (this.wayCurrent == 'IMPRESSION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_A4.CodePostal.3_next')
        }  else if (this.wayCurrent == 'IMPRESSION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B4.CodePostal.3_next')
        }  else if (this.wayCurrent == 'IMPRESSION_C') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Imp_B4.CodePostal.3_next')
        } else if (this.wayCurrent == 'MODELISATION_B') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_B5.CodePostal.3_next')
        } else if (this.wayCurrent == 'NUMERISATION_A') {
          this.$googleTagManager(this.id,this.ismaker!=null,this.gtagGroupEvent,'P3Mod_C3.CodePostal.3_next')
        } 
        //******************************************** */

        this.increment();
      }
    },
    updateValue() {
      if (this.zipcode == '' || RegExp(/^((0[1-9])|([1-8][0-9])|(9[0-8])|(2A)|(2B))[0-9]{3}$/).test(this.zipcode) == false) {
        this.errorForm = true
        return false
      } else {
        if(zipcode_fr.find(zipcode => zipcode == this.zipcode)) {
          this.errorForm = false
          this.updateZipcode(this.zipcode);
          return true
        } else {
          this.errorForm = true
          return false
        }
      }
    }

  },
};
</script>