<template>
<div>
    
    <div class="order-form">

        <h4 class="h4 mt-5 mb-5">Mode de livraison</h4>
        <p>
          Le délai indiqué est celui du transport après expédition.
        </p>
        <div class="order-form-delivery">
            <div class="form-radio" :class="this.shippingConfiguration" v-show="this.shippingConfiguration === 'colissimo' || this.isQuotation === false">
                <input type="radio" selected name="delivery" :value="tabPricesDelivery[0]" v-model="modeDelivery" @change="changePriceDelivery" id="for-standard">
                <label for="for-standard"><h6 class="h6">Standard (3 à 5 jours ouvrés)</h6></label>
                <h6 class="h6" v-if="isHydrated && this.isQuotation === false">{{ (tabPricesDelivery[0].price / 100) | formatPrice }} €</h6>
            </div>
            <div class="form-radio" v-show="this.shippingConfiguration === 'chronopost' || this.isQuotation === false">
                <input type="radio" name="delivery" :value="tabPricesDelivery[1]" v-model="modeDelivery" @change="changePriceDelivery" id="for-express">
                <label for="for-express"><h6 class="h6">Express à domicile (1 à 2 jours ouvrés)</h6></label>
                <h6 class="h6" v-if="isHydrated && this.isQuotation === false">{{ (tabPricesDelivery[1].price / 100) | formatPrice }} €</h6>
            </div>
            <div class="form-radio" v-show="this.shippingConfiguration === 'chronopost' || this.isQuotation === false">
                <input type="radio" name="delivery" :value="tabPricesDelivery[2]" v-model="modeDelivery" @change="changePriceDelivery" id="for-relais">
                <label for="for-relais"><h6 class="h6">Express en Point Relais (1 à 3 jours ouvrés)</h6></label>
                <h6 class="h6" v-if="isHydrated && this.isQuotation === false">{{ (tabPricesDelivery[2].price / 100) | formatPrice }} €</h6>
            </div>
        </div>

      <!-- action -->
        <div class="mt-5">   
            <button class="btn-u3dm btn-u3dm--primary" @click="next(2)">Suivant</button>
        </div>
       
    </div>

    <!-- after -->
    <div class="order-form-after">
        <h5 class="h5">Adresse de livraison</h5>
        <h5 class="h5">Mode de paiement</h5>
    </div>
</div>
    
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: "FormOrderedDelivery",
    data() {
        return {
            modeDelivery: {name: null, price: null, label: null},
            tabPricesDelivery: [],
            isHydrated: false,
        }
    },
  computed: {
    ...mapState('order', {
      orderId: (state) => state.orderId
    }),
    ...mapState("shop", {
      listProductsProject: (state) => state.listProductsProject,
    }),
    ...mapState("orderManager", {
      needShipping: (state) => state.needShipping,
      shippingConfiguration: (state) => state.shippingConfiguration,
      isQuotation: (state) => state.isQuotation,
    }),
  },
    beforeMount() {
      setTimeout(() => {
        // /api/payment/shipping/
        this.$http.get(process.env.VUE_APP_API_BASE_URL + 'payment/shipping')
            .then((response) => {
              if (response.body.length == 0) {
                this.next(3)
              } else {
                // creation des objet mode de livraison

                for (const key in response.body) {
                  const element = response.body[key];
                  let item = {}
                  let itemLabel = 'label'
                  item.name = key
                  item.price = element
                  switch (key) {
                    case 'home_standard':
                      itemLabel = 'Standard'
                      break;
                    case 'home_express':
                      console.log('home_express')
                      itemLabel = 'Express'
                      break;
                    case 'relay':
                      itemLabel = 'Point relais'
                      break;

                    default:
                      break;
                  }
                  item.label = itemLabel
                  this.tabPricesDelivery.push(item)
                  // mode delivery par default
                  this.modeDelivery = this.tabPricesDelivery[0]
                  this.isHydrated = true
                  this.updateModeDelivery(this.tabPricesDelivery[0])
                  this.updatePriceDelivery(this.tabPricesDelivery[0].price)
                }
                // mode delivery par default
                if(this.shippingConfiguration === 'chronopost') {
                  this.modeDelivery = this.tabPricesDelivery[1]
                }

                if(this.shippingConfiguration === 'chronopost') {
                  this.updateModeDelivery(this.tabPricesDelivery[1])
                }

              }
            })
      }, 1000)
        
    },
    methods: {
        ...mapActions("order", [
            "updateModeDelivery", "updatePriceDelivery"
        ]),
        ...mapActions("orderManager", [
            "updateStep"
        ]),
        changePriceDelivery() {
            this.updateModeDelivery(this.modeDelivery)
            this.updatePriceDelivery(this.modeDelivery.price)
        },
        next(id) {
            this.updateModeDelivery(this.modeDelivery)
            this.updatePriceDelivery(this.modeDelivery.price)


        // Google Tag Manager : push event order form
            //******************************************** */
            if (this.modeDelivery.name == 'relay') {
                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.2.1.Shipping_type_relai_select')
            } else {

                this.$googleTagManager(this.id,this.ismaker!=null,this.listProductsProject.length > 0 ? 'project_form' : 'impression_form','O2.1.Shipping_type_domicile_select')
            }
            //******************************************** */

            this.updateStep(id)
        }
    },
}
</script>