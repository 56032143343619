<template>
  <div class="login">
    <div class="container">

      <div class="row justify-content-center mt-5">
        <div class="col-md-6 col-xl-5 mt-5">

          <h1 class="h2 text-center">vous connecter</h1>

          <div class="mt-5">
            <div class="text--error mb-1">{{ errorLoginMsg }}</div>
            <div>
              <input type="text" v-model="newPassword" placeholder="Entrer votre nouveau mot de passe" class="form-control" @keyup.enter="changePassword">
            </div>
            <div class="mt-4">
              <button class="btn-u3dm btn-u3dm--primary w-100" @click="changePassword" @keyup.enter="changePassword">Changer</button>
            </div>
          </div>

              
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: "Password",
  data() {
    return {
        newPassword: null,
        errorLoginMsg: null,
    }
  },
   computed: {
    ...mapState("routeRedirect", {
      previousRoute: (state) => state.previousRoute
    }),
  },
  methods: {
    ...mapActions('authentication', [
      'login',
      'setConnected',
      'logout',
    ]),
    ...mapActions("routeRedirect", [
      "updateRoute"
    ]),
    changePassword() {
      let user = {
        login: this.loginEmail,
        password: this.loginPassword
      }
      this.login(user)
          .then(() => {
            this.errorLoginMsg = 'Identifiants OK'

            if (this.previousRoute == '') {

              this.updateRoute('')
              this.$router.push("account")

            } else {
              this.$router.push(this.previousRoute)
              this.updateRoute('')
            }

          }, () => {
            this.errorLoginMsg = 'Identifiants invalides ou compte inconnu'
          });
    },
  },
}
</script>