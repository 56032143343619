<script>
import mixinLogin from "./ModalLoginMixins.vue";

import { mapActions } from 'vuex';

export default {
  name: "ModalLoginPrint",
  mixins: [mixinLogin],
  data() {
    return {
      showMandatoryTelephone: false,
      errorMessages: [],
    };
  },
  methods: {
    ...mapActions("print", [
      "savePrint"
    ]),
    ...mapActions("printManager", [
      "updateTokenUser"
    ]),
    ...mapActions("printManager", [
      "updateAddBasketAfterConnection"
    ]),

    inactiveUserAndSave() {
      this.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_light.account_creation.attempt')

      if (this.checkEmail()) {
        // this.loader = true;
        let formData = new FormData();

        formData.append("email", this.registrationAccount.email);
        formData.append("firstname", this.registrationAccount.firstname);
        formData.append("lastname", this.registrationAccount.lastname);

        this.$http
          .post(
            process.env.VUE_APP_API_BASE_URL + "user/register_inactive",
            formData
          )
          .then((response) => {
            
            if (response.body.enabled == true) {
              this.newClient = false
            } else {
              this.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_light.account_creation.success')

              let enableToken = response.body["enable_token"]
  
              this.$router.replace({ name: 'Print', params: { token: response.body['enable_token'] } })

              this.$emit('close');
              this.loader = false
              this.updateTokenUser(enableToken)
              this.updateAddBasketAfterConnection(true)
            }
          }),
          () => {
            // console.log(error);
            this.loader = false;
          };

      }
    },
    connectUser() {
      let user = {
        login: this.loginEmail,
        password: this.loginPassword
      }
      this.loader = !this.loader
      this.login(user)
          .then(() => {
            this.errorLoginMsg = null
            this.$emit('close');
            this.loader = !this.loader
            // element 'watcher' par le composant shop bar pour lancer l'ajout au panier apres connection
            this.updateAddBasketAfterConnection(true)
          }, () => {
            this.loader = !this.loader
            this.errorLoginMsg = 'Compte inexistant ou erreur de mot de passe'
          });
    },

  },
};
</script>