<template>
  <div class="login">
    <navigation-site :navThemeTransparent="false"></navigation-site>
    <div class="container space-nav-foot">

      

      <div class="row justify-content-center mt-5 mb-5 pt-5 pb-5 appearContentFromBot2">
        <div class="col-md-6 col-xl-4 mt-5">

         
          <h1 class="h2 text-center">Vous connecter</h1>

          <div class="mt-5">
            <div class="text--error mb-1">{{ errorLoginMsg }}</div>
            <div>
              <input type="text" v-model="loginEmail" placeholder="Email" class="form-control" @keyup.enter="loginAccount">
            </div>
            <div class="mt-2">
              <input type="password" v-model="loginPassword" placeholder="Mot de passe" class="form-control" @keyup.enter="loginAccount">
            </div>
            <div class="mt-2 text-end"><router-link to="fr/password-forgot" class="color-secondary">Mot de passe oublié ?</router-link></div>
            <div class="mt-5">
              <button class="btn-u3dm btn-u3dm--primary w-100" @click="loginAccount" @keyup.enter="loginAccount">Vous connecter</button>
            </div>
          </div>

          <div class="mt-2">
            <span class="color-secondary">Vous n'avez pas de compte ?</span>
            <router-link to="fr/inscription" class="ms-1 color-main">s'inscrire</router-link>
          </div>

        </div>
      </div>
    </div>
    <footer-site></footer-site>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FooterSite from '../components/ui/FooterSite.vue'
import NavigationSite from '../components/ui/NavigationSite.vue'

export default {
  name: "Login",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: '- Authentification',
    meta: [
      { name: 'description', content: 'United 3D Maker est un service d\'impression et de modélisation 3D en ligne. Retrouver votre panier de demande d’impression.' }
    ],
  },
  components: { NavigationSite, FooterSite },
  data() {
    return {
      loginEmail: null,
      loginPassword: null,
      errorLoginMsg: null,
      started: false,
      urlWWWHome: process.env.VUE_APP_WWW_BASE_URL,
    }
  },
  watch: {
    loginEmail: {
      handler(){
        if(!this.started) {
          this.started = true
          this.$googleTagManager(this.id,this.ismaker!=null,'registration','login.started')
        }
      },
    },
    loginPassword: {
      handler(){
        if(!this.started) {
          this.started = true
          this.$googleTagManager(this.id,this.ismaker!=null,'registration','login.started')
        }
      },
    }
  },
   computed: {
     ...mapState("routeRedirect", {
       previousRoute: (state) => state.previousRoute
     }),
     ...mapState("authentication", {
       isMaker: (state) => state.isMaker,
       isChecked: (state) => state.isChecked,
     })
  },
  methods: {
    ...mapActions('authentication', [
      'login',
      'setConnected',
      'logout',
    ]),
    ...mapActions("routeRedirect", [
      "updateRoute"
    ]),
    loginAccount() {
      this.$googleTagManager(this.id,this.ismaker!=null,'registration','login.attempt')

      let user = {
        login: this.loginEmail,
        password: this.loginPassword
      }
      this.login(user)
          .then(() => {
            this.$googleTagManager(this.id,this.ismaker!=null,'registration','login.success')

            if (this.previousRoute == '') {

              this.updateRoute('')
              if(this.isMaker) {
                if(this.isChecked) {
                  this.$router.push("/maker")
                } else {
                  this.$router.push("/maker/account")
                }
              } else {
                this.$router.push("account")
              }

            } else {
              this.$router.push(this.previousRoute)
              this.updateRoute('')
            }

          }, (response) => {
            if(response.body.error === 'not_activated') {
              this.errorLoginMsg = 'Un email pour activer votre compte vient de vous être envoyé'
            } else {
              this.errorLoginMsg = 'Identifiants invalides ou compte inconnu'
            }
          });
    },
    // testIsConnected: function() {
    //   this.setConnected()
    //   .then(() => {
    //     console.log('Connected')
    //   }, () => {
    //     console.log('Not connected')
    //   })
    // },
    // testLogout: function() {
    //   this.logout()
    //   .then(() => {
    //     console.log('Logout')
    //   })
    // }
  },
  mounted() {
    this.$googleTagManager(this.id,this.ismaker!=null,'registration','login.view')
  }
}
</script>