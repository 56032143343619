<template>
    <div class="zone-bill h-100 col-xl-5 pb-3">
        
        <product-order-list></product-order-list>

      <div class="align-center mt-4 mb-4">
        <div class="d-flex justify-content-between ">
          <input v-model="promoCodeInput" type="text" class="input w-100 me-2" placeholder="Code Promo">
          <button class="btn-u3dm-submit--primary" @click="applyPromoCode()">Appliquer</button>
        </div>
        <div v-if="this.promoCodeError">
          Code promotionnel non applicable ou inconnu
        </div>
      </div>

        <div>
            <div v-if="feeShowAmountTaxExcl > 0 && listProductsProject.length == 0" class="d-flex justify-content-between align-center mt-2">
                <span class="t1">Complément de commande (HT)</span>

                <span  class="h6 m-0">{{ (feeShowAmountTaxExcl / 100) | formatPrice }} €</span>
            </div>
            <div class="d-flex justify-content-between align-center mt-2">
                <span class="t1">Sous-total (HT)</span>
                <span class="h6 m-0">{{ (totalAmountTaxExcl / 100) | formatPrice }} €</span>
            </div>
          <div v-if="promoCodeLabel" class="d-flex justify-content-between align-center mt-2">
            <span class="t1">{{ promoCodeLabel }} (HT)</span>
            <span  class="h6 m-0">-{{ (discountAmountTaxExcl / 100) | formatPrice }} €</span>
          </div>
            <div class="d-flex justify-content-between align-center mt-2">
                <span class="t1">TVA (20%)</span>
                <span class="h6 m-0">{{ ((totalAmountTaxIncl - totalAmountTaxExcl - (discountAmountTaxIncl - discountAmountTaxExcl)) / 100) | formatPrice }} €</span>
            </div>
            <div v-if="needShipping" class="d-flex justify-content-between align-center mt-2">
                <span class="t1">Frais de port</span>
                <span v-if="priceDelivery !== null" class="h6 m-0">{{ (priceDelivery / 100) | formatPrice }} €</span>
                <span v-else class="h6 m-0">Calculer plus tard</span>
            </div>

            <div class="d-flex justify-content-between align-center mt-4 pt-4 border-top">
                <span class="t4">Total TTC</span>
                <span class="h4 m-0">{{ resultTotalAmount | formatPrice }} €</span>
            </div>

          <div class="d-flex justify-content-between align-center mt-4 pt-4 border-top" v-if="isDeposit">
            <span class="t4">Acompte à la commande {{ deposit }}% :</span>
            <span class="h4 m-0">{{ resultTotalAmount*deposit/100 | formatPrice }} €</span>
          </div>
          <div>
            <span class="t4">{{ timeline }}</span>
          </div>
        </div>

    </div>
</template>

<script>
import ProductOrderList from './ProductOrderList.vue'

import {mapActions, mapState} from 'vuex'

export default {
  components: {ProductOrderList},
  name: "ProductOrder",
  data() {
    return {
      promoCodeInput : null,
      promoCodeError : false,
    }
  },
  beforeMount() {
    this.updateDiscountAmountTaxExcl(null)
    this.updateDiscountAmountTaxIncl(null)
    this.updatePromoCode(null)
    this.updatePromoCodeLabel(null)
  },
  methods: {
    ...mapActions('order', [
      "updateDiscountAmountTaxExcl",
      "updateDiscountAmountTaxIncl",
      "updatePromoCode",
      "updatePromoCodeLabel",
    ]),
      applyPromoCode() {
      if(this.promoCodeInput !== null) {
        let formData = new FormData();

        formData.append("coupon", this.promoCodeInput);
        formData.append("order_sell_id", this.orderId);
        if (this.$route.query.token != undefined) {
          formData.append("token", this.$route.query.token);
        }

        this.$http.post(process.env.VUE_APP_API_BASE_URL + 'payment/coupon/apply', formData, {credentials: true})
            .then(({body}) => {
              this.promoCodeError = false
              this.updateDiscountAmountTaxExcl(body.discount_amount_tax_excl)
              this.updateDiscountAmountTaxIncl(body.discount_amount_tax_incl)
              this.updatePromoCode(this.promoCodeInput)
              this.updatePromoCodeLabel(body.coupon_label)
            }, () => {
              this.promoCodeError = true
            })
      }
    },
  },
  computed: {
    ...mapState("shop", {
      totalAmountTaxExcl: (state) => state.totalAmountTaxExcl,
      totalAmountTaxIncl: (state) => state.totalAmountTaxIncl,
      feeShowAmountTaxExcl: (state) => state.feeShowAmountTaxExcl,
      listProductsProject: (state) => state.listProductsProject,
    }),
    ...mapState("order", {
      priceDelivery: (state) => state.priceDelivery,
      orderId: (state) => state.orderId,
      promoCodeLabel: (state) => state.promoCodeLabel,
      discountAmountTaxExcl: (state) => state.discountAmountTaxExcl,
      discountAmountTaxIncl: (state) => state.discountAmountTaxIncl,
    }),
    ...mapState("orderManager", {
      needShipping: (state) => state.needShipping,
      deposit: (state) => state.deposit,
      isDeposit: (state) => state.isDeposit,
      timeline: (state) => state.timeline,
    }),
    resultTotalAmount() {
      let price = 0
      // montant hors taxe
      price += this.totalAmountTaxIncl
      if (this.priceDelivery !== null && this.needShipping) {
        price += this.priceDelivery
      }

      if (this.discountAmountTaxIncl) {
        price -= this.discountAmountTaxIncl
      }

      return price / 100
    }
  },
}
</script>