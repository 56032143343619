<template>
    <div class="basket-product">
        <div class="basket-product-action">
            <!-- <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.7366 19.8706H6.42062C5.15688 19.8706 4.12891 18.8426 4.12891 17.5788V8.26619C4.12891 7.00057 5.15688 5.97119 6.42062 5.97119H9.67656C10.1036 5.97119 10.45 6.3176 10.45 6.74463C10.45 7.17166 10.1036 7.51807 9.67656 7.51807H6.42062C6.01 7.51807 5.67578 7.85369 5.67578 8.26619V17.5788C5.67578 17.9895 6.01 18.3237 6.42062 18.3237H15.7366C16.1472 18.3237 16.4814 17.9895 16.4814 17.5788V14.3262C16.4814 13.8992 16.8278 13.5528 17.2548 13.5528C17.6819 13.5528 18.0283 13.8992 18.0283 14.3262V17.5788C18.0283 18.8426 17.0003 19.8706 15.7366 19.8706Z" fill="#7C7C7C"/>
                    <path d="M11.0784 13.6944C10.8745 13.6944 10.6776 13.6138 10.5314 13.468C10.3645 13.3011 10.283 13.0677 10.3097 12.8333L10.6031 10.2641C10.6228 10.0902 10.7011 9.92848 10.8248 9.8052L15.9112 4.7188C16.3284 4.30161 16.9228 4.08927 17.5416 4.13568C18.1256 4.17974 18.6872 4.44318 19.1217 4.87817C19.5562 5.31271 19.8201 5.87427 19.8642 6.45833C19.9106 7.07708 19.6983 7.67145 19.2811 8.08864L14.1947 13.1746C14.0709 13.2983 13.9092 13.3761 13.7358 13.3963L11.1666 13.6897C11.137 13.693 11.1075 13.6944 11.0784 13.6944ZM12.1097 10.708L11.9573 12.0425L13.2919 11.8902L18.187 6.99458C18.3216 6.86005 18.3291 6.67348 18.3216 6.57411C18.3056 6.36177 18.1987 6.14239 18.0281 5.97177C17.8575 5.80114 17.6381 5.69427 17.4258 5.67833C17.3264 5.67083 17.1398 5.67833 17.0053 5.81286L12.1097 10.708Z" fill="#7C7C7C"/>
                    <path d="M17.4711 9.5787C17.2733 9.5787 17.075 9.50324 16.9241 9.3523L14.6483 7.07652C14.3464 6.77465 14.3464 6.2848 14.6483 5.98293C14.9502 5.68105 15.44 5.68059 15.7419 5.98293L18.0177 8.25871C18.3195 8.56058 18.3195 9.05042 18.0177 9.3523C17.8667 9.50324 17.6689 9.5787 17.4711 9.5787Z" fill="#7C7C7C"/>
                </svg>
            </div> -->
            <div @click="remove(item)">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.1647 17.9401C16.9669 17.9401 16.7686 17.8646 16.6177 17.7137L6.285 7.38055C5.98313 7.07867 5.98313 6.58883 6.285 6.28695C6.58687 5.98508 7.07672 5.98508 7.37859 6.28695L17.7117 16.6201C18.0136 16.922 18.0136 17.4118 17.7117 17.7137C17.5603 17.8646 17.3625 17.9401 17.1647 17.9401Z" fill="#7C7C7C"/>
                    <path d="M6.83203 17.9401C6.63422 17.9401 6.43594 17.8646 6.285 17.7137C5.98312 17.4118 5.98312 16.922 6.285 16.6201L16.6181 6.28695C16.92 5.98508 17.4099 5.98508 17.7117 6.28695C18.0136 6.58883 18.0136 7.07867 17.7117 7.38055L7.37906 17.7132C7.22813 17.8646 7.03031 17.9401 6.83203 17.9401Z" fill="#7C7C7C"/>
                </svg>
            </div>
        </div>
        <div class="basket-product-media">
            <img v-if="item.photo === undefined" class="p-3" src="@/assets/images/product-empty.png" alt="img u3dm">
            <img v-if="item.photo" :src="this.photoBaseUrl + item.id" alt="img screenshot">
        </div>
        <div class="basket-product-description">
            <h5 class="h5">{{ item.file['original_name'] }}</h5>
            <p class="t1">{{ item.technology.name }} - {{ item.material.name }}</p>
            <p class="t3 color-secondary">
                <span>{{ item.color.name }}</span>
                <!-- <span> | {{ item['item_finishings'].finishing.name }}</span> -->
            </p>
        </div>
        <div class="basket-product-quantity">
            <!-- <h6 class="h6">{{ item.quantity }}</h6> -->
            <input type="number" min="1" v-model="item.quantity" @change="changeQuantity(item)" class="h6 mb-0 choice-quantity">
            <div class="basket-product-ajust">
                <div class="basket-product-ajust--more" @click="more(item)">
                    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.71429 6L4.5 2.25L1.28571 6L6.55671e-08 5.25L4.5 -8.7024e-07L9 5.25L7.71429 6Z" fill="#161616"/>
                    </svg>
                </div>
                <div class="basket-product-ajust--less" @click="less(item)">
                    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.28571 0L4.5 3.75L7.71429 0L9 0.75L4.5 6L0 0.75L1.28571 0Z" fill="#161616"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="basket-product-price">
            <h6 class="h6">{{ (priceItem(item) / 100) | formatPrice }} € HT</h6>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: "BasketProductItem",
    props: {
        item: {
            type: Object,
            default() { return {} }
        }
    },
    data() {
        return {
            token: '',
            photoBaseUrl: process.env.VUE_APP_API_BASE_URL + 'application/photo/',
        }
    },
    beforeMount() {
        if (this.$route.params.token != undefined) {

            this.token = '/' + this.$route.params.token

        } else {

            this.token = ''
        }
    },
    methods: {
        ...mapActions("shop", [  
            "addProductPrint",
            "updateTotalAmountIncl", 
            "updateTotalAmountExcl", 
            "updateFeeShowAmountTaxExcl","updateFeeShowDescription",
        ]),
        priceItem(item) {
            let finishing = 0
            if (item['item_finishings'][0] !== undefined) {
                finishing = item['item_finishings'][0]['unit_sell_amount_tax_excl']
            }
            console.log(item);
    
            return item.quantity * (item['unit_sell_amount_tax_excl'] + finishing)
        },
        remove(item) {
            this.$http.delete(process.env.VUE_APP_API_BASE_URL + 'application/item/'+item.id + this.token, {credentials: true})
            .then((response) => {
                this.addProductPrint(response.body.items)
                this.updateFeeShowAmountTaxExcl(response.body['fee_show_amount_tax_excl'])
                if (response.body["fee_show_description"] != undefined) {
                    this.updateFeeShowDescription(response.body["fee_show_description"]);
                    } 
                this.updateTotalAmountIncl(response.body['total_amount_tax_incl'])
                this.updateTotalAmountExcl(response.body['total_amount_tax_excl'])
            }) 
        },
        changeQuantity(item) {
            this.$http.patch(process.env.VUE_APP_API_BASE_URL + 'application/item/'+item.id+'/quantity/'+(item.quantity) + this.token, null, {credentials: true})
                .then((response) => {
                    this.addProductPrint(response.body.items)
                    this.updateFeeShowAmountTaxExcl(response.body['fee_show_amount_tax_excl'])
                    if (response.body["fee_show_description"] != undefined) {
                    this.updateFeeShowDescription(response.body["fee_show_description"]);
                    } 
                    this.updateTotalAmountIncl(response.body['total_amount_tax_incl'])
                    this.updateTotalAmountExcl(response.body['total_amount_tax_excl'])
                })
        },
        more(item) {
            this.$http.patch(process.env.VUE_APP_API_BASE_URL + 'application/item/'+item.id+'/quantity/'+(item.quantity + 1) + this.token, null, {credentials: true})
                .then((response) => {
                    this.addProductPrint(response.body.items)
                    this.updateFeeShowAmountTaxExcl(response.body['fee_show_amount_tax_excl'])
                    if (response.body["fee_show_description"] != undefined) {
                    this.updateFeeShowDescription(response.body["fee_show_description"]);
                    } 
                    this.updateTotalAmountIncl(response.body['total_amount_tax_incl'])
                    this.updateTotalAmountExcl(response.body['total_amount_tax_excl'])
                })
        },
        less(item) {
            if (item.quantity > 1) {

                this.$http.patch(process.env.VUE_APP_API_BASE_URL + 'application/item/'+item.id+'/quantity/'+(item.quantity - 1) + this.token, null, {credentials: true})
                .then((response) => {
                    this.addProductPrint(response.body.items)
                    this.updateFeeShowAmountTaxExcl(response.body['fee_show_amount_tax_excl'])
                    if (response.body["fee_show_description"] != undefined) {
                    this.updateFeeShowDescription(response.body["fee_show_description"]);
                    } 
                    this.updateTotalAmountIncl(response.body['total_amount_tax_incl'])
                    this.updateTotalAmountExcl(response.body['total_amount_tax_excl'])
    
                })
            }
        },
    }
}
</script>