<template>
  <div class="m-login">
    <div class="m-login-wrapp">

      <!-- loader -->
      <div v-if="loader" class="m-login-loader">
        <div>
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
      </div>

      <div v-if="!loader" class="m-login-content modal-login-content-appear">

        <img class="m-login-cross"
            :src="require('@/assets/icon-svg/icon-cross-grey.svg')"
            alt="icon fermer"
            @click="close"
        />

        <!-- inscription prospect -->
        <div v-if="newClient && !projectSaved" class="m-login-prospect">

          <!-- heading -->
          <h4 class="m-login-heading h4">Vos coordonnées</h4>

          <!-- form -->
          <div class="m-login-form">
            <div v-if="errorsEmail.length >= 1" class="t1 text--error">
              {{this.errorsEmail[0]}}
            </div>
            <div v-for="(errorMessage, index) in errorMessages" :key="index" class="t1 text--error">
              {{ errorMessage }}
            </div>
            <input type="text" v-model="registrationAccount.lastname" placeholder="Nom" class="m-login-form__input" />
            <input type="text" v-model="registrationAccount.firstname" placeholder="Prenom" class="m-login-form__input" />
            <input type="email" v-model="registrationAccount.email" placeholder="Adresse mail" class="m-login-form__input" />
            <input v-if="this.showMandatoryTelephone" type="tel" v-model="registrationAccount.telephone" placeholder="Téléphone" class="m-login-form__input" />
          </div>

          <!-- action -->
          <div class="m-login-action">
            <button class="btn-u3dm btn-u3dm--primary btn--100" @click="inactiveUserAndSave">
              Enregistrer
            </button>

            <div class="count-exist">
              Vous avez déjà un compte ?
              <span class="link--primary" @click="newClient = false">Vous connecter</span>
            </div>
          </div>
        </div>

        <div v-if="projectSaved" class="m-login-project-saved">
          <!-- heading -->
          <h4 class="mt-5 m-login-heading h4">Votre projet est enregistré, vous allez recevoir le lien d'enregistrement par email</h4>

          <div class="mt-5 pt-3 text-center">
            <span class="h5 mt-5" @click="close">Fermer</span>
          </div>
        </div>

        <!-- inscription connection -->
        <div v-if="!newClient && !forgetPassword" class="m-login-connexion">
          <!-- heading -->
          <h4 class="m-login-heading h4">Vous connecter</h4>

          <!-- form -->
          <div class="m-login-form">
            <input type="email" v-model="loginEmail" placeholder="Email" required class="m-login-form__input" />
            <input type="password" v-model="loginPassword" placeholder="Mot de passe" required class="m-login-form__input" />
            <div v-if="errorLoginMsg" class="m-login-form--error">{{ errorLoginMsg }}</div>
          </div>

          <!-- action -->
          <div class="m-login-action">
            <button class="btn-u3dm btn-u3dm--primary btn--100" @click="connectUser">Connexion</button>
            <div class="count-exist">
              <span @click="forgetPassword = true">Mot de passe oublié ?</span>
            </div>
          </div>
        </div>

        <!-- inscription connection -->
        <div v-if="forgetPassword" class="m-login-password">
          <!-- heading -->
          <h4 class="m-login-heading h4">Mot de passe oublié</h4>

          <div v-if="!forgetPasswordSend">
            <!-- form -->
            <div class="m-login-form">
              <input type="email" v-model="forgetPasswordEmail" placeholder="Email" required
                     class="m-login-form__input"/>
              <div v-if="errorForgetPasswordMsg" class="m-login-form--error">{{ errorForgetPasswordMsg }}</div>
            </div>

            <!-- action -->
            <div class="m-login-action">
              <button class="btn-u3dm btn-u3dm--primary btn--100" @click="resetPassword">Envoyer</button>
              <div class="count-exist">
                <span @click="forgetPassword = false">Annuler</span>
              </div>
            </div>
          </div>

          <div v-if="forgetPasswordSend">
            {{ validForgetPasswordMsg }}
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "ModalLoginMixins",
  data() {
    return {
      loginEmail: null,
      loginPassword: null,
      errorLoginMsg: null,
      newClient: true,
      forgetPassword: false,
      forgetPasswordEmail: null,
      errorForgetPasswordMsg: null,
      validForgetPasswordMsg: null,
      forgetPasswordSend: false,
      loader: false,
      projectSaved: false,
      registrationAccount: {
        firstname: '',
        lastname: '',
        email: '',
        telephone: '',
      },
      errorsEmail: [],
      started: false,
    }
  },
  watch: {
    registrationAccount: {
      handler(){
        if(!this.started) {
          this.started = true
          this.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_light.account_creation.started')
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('authentication', [
      'login',
    ]),
    connectUser() {
      let user = {
        login: this.loginEmail,
        password: this.loginPassword
      }
      this.loader = !this.loader
      this.login(user)
          .then(() => {
            this.errorLoginMsg = null
            this.$emit('close');
            this.loader = !this.loader
          }, () => {
            this.loader = !this.loader
            this.errorLoginMsg = 'Compte inexistant ou erreur de mot de passe'
          });
    },
    resetPassword() {
      this.loader = true

      let formData = new FormData();
      formData.append('email', this.forgetPasswordEmail);

      this.$http.post(process.env.VUE_APP_API_BASE_URL + 'user/forgot_password', formData)
          .then((response) => {
            this.loader = false
            this.errorForgetPasswordMsg = null
            this.forgetPasswordSend = true
            this.validForgetPasswordMsg = response.body[0]
          }, (error) => {
            this.loader = false
            this.errorForgetPasswordMsg = error.body[0]
          })
    },
    checkEmail() {
      this.errorsEmail = [];

        if (!this.registrationAccount.email) {
            this.errorsEmail.push('adresse email invalide');
        } else if (RegExp(/\S+@\S+\.\S+/).test(this.registrationAccount.email) == false) {
            this.errorsEmail.push('adresse email invalide');
        }
  
        if (this.errorsEmail.length >= 1) {
          return false
        } else {
          return true
        }
    },
    close() {
      this.$emit('close');
    },

  },
  mounted() {
    this.$googleTagManager(this.id,this.ismaker!=null,'registration','user_form_light.account_creation.view')
  }
};
</script>